import React from "react";
import "./Blogs.css";
import Lottie from "react-lottie";
import animationData from "../../assets/blog.json";
import { Link } from "react-router-dom";
import JavaNew from "../../images/Blogs/JavaNew.webp";
import outside from "../../images/Blogs/outside.webp";
import ReactNew from "../../images/Blogs/ReactNew.webp";
import OopsNew from "../../images/Blogs/OopsNew.webp";
import annoucement from "../../images/Blogs/annoucementfinalimg.png";
import { Helmet } from "react-helmet";

const Blogs = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Helmet>
        <title>Blogs</title>
        <meta property="og:title" content="Blogs" />
        <meta property="og:type" content="article" />
        <meta
          property="og:description"
          content="Maintain up to date with the most recent innovations and trends in the rapidly changing field of technology. Keep yourself updated about the latest trends and changes that are affecting the innovation landscape."
        />
        <meta
          property="og:image"
          content="https://senwellsys.com/static/media/JavaNew.6aed4cbb3d25eff2c0a8.webp"
        />
        <meta property="og:url" content="https://senwellsys.com/blogs" />
      </Helmet>
      <div className="blog">
        <section className="BlogHeader-sections">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 Blog-banner-col">
                <div className="Blog-banner-content">
                  <h1>
                    <span>Blogs</span>
                  </h1>
                  <p className="sub-p">
                    Maintain up to date with the most recent innovations and
                    trends in the rapidly changing field of technology. Keep
                    yourself updated about the latest trends and changes that
                    are affecting the innovation landscape.
                  </p>
                  <div className="Blog-banner-button">
                    <Link to="/contact">
                      <a className="blog-theme-btn">Let's Talk</a>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="image-lottie" style={{ pointerEvents: "none" }}>
                  <Lottie options={defaultOptions} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="blog-home">
          <div
            className="container"
            style={{ left: "0", border: "none", padding: "50px 0" }}
          >
            <h2 className="blog-sesction-header">Our Blogs</h2>
            <div className="row" style={{ rowGap: "20px" }}>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="blog-main">
                  <Link
                    to="/api-business-blog"
                    title="is java future of App Development "
                    style={{ textDecoration: "none" }}
                  >
                    <img src={outside} alt="img" className="blog-img" />
                    <div className="blog-item-content">
                      <h3 className="blog-header">
                        Benefits of implementing APIs in a Business{" "}
                      </h3>
                      <div className="blog-content-para-wrapper">
                        <p>
                          In today's dynamic digital commerce landscape,
                          Application Programming Interfaces (APIs) have
                          emerged..
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div>
                  <Link
                    to="/new-logo-announcement-introducing-senwell-group-new-brand-identity"
                    title="microserviceBest"
                    style={{ textDecoration: "none" }}
                  >
                    <img src={annoucement} alt="img" className="blog-img" />
                    <div className="blog-item-content">
                      <h3 className="blog-header">
                        Announcement of Our New Logo & Brand Identity.
                      </h3>
                      <div className="blog-content-para-wrapper">
                        <p>
                          In the ever-evolving landscape of business, adaptation
                          is not just a necessit......
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div>
                  <Link
                    to="/object-orient-blog"
                    title="microserviceBest"
                    style={{ textDecoration: "none" }}
                  >
                    <img src={OopsNew} alt="img" className="blog-img" />
                    <div className="blog-item-content">
                      <h3 className="blog-header">
                        Most Popular Oops Languages in 2024
                      </h3>
                      <div className="blog-content-para-wrapper">
                        <p>
                          Learn more about this revolutionary concept and the
                          key languages considered essential to its success.....
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div>
                  <Link
                    to="/react-future-blog"
                    title="React Popular Blog"
                    style={{ textDecoration: "none" }}
                  >
                    <img src={ReactNew} alt="img" className="blog-img" />
                    <div className="blog-item-content">
                      <h3 className="blog-header">
                        Why is React js Popular in 2024 ?{" "}
                      </h3>
                      <div className="blog-content-para-wrapper">
                        <p>
                          ReactJS has really taken the tech world by storm, with
                          over 220,000 live websites already using its power....
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="blog-main">
                  <Link
                    to="/java-future-blog"
                    title="is java future of App Development "
                    style={{ textDecoration: "none" }}
                  >
                    <img src={JavaNew} alt="img" className="blog-img" />
                    <div className="blog-item-content">
                      <h3 className="blog-header">
                        Is java future of App Development ?
                      </h3>
                      <div className="blog-content-para-wrapper">
                        <p>
                          When developing applications, the choice of
                          programming language can be important....
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Blogs;
