import { SET_SCOPE } from "./scopeType";
import { GET_SCOPE } from "./scopeType";


export const setScope = (response) => {
  //console.log('setScope action', response)
  return {
    type: SET_SCOPE,
    payload: response,
  };
};

export const getScope = (response) => {
  return {
    type: GET_SCOPE,
    payload: response,
  };
};
