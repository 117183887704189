import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Breadcrumbs,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  QualitymainContainer: {
    background: "#F1F1F1",
    padding: "2rem 5rem",
    [theme.breakpoints.down("sm")]:{
      padding:"0rem"
    }
  },
  
  QualityfirstsectionTitleWrapper: {
    color: "black",
    marginBottom:"25px",
    fontFamily: "'Manrope', sans-serif",
    [theme.breakpoints.down("sm")]: {
      padding: " 0rem",
    },
  },
  QualityfirstsectionParaWrapper: {
    color: "black",
    fontFamily: "'Manrope', sans-serif",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem",
    },
  },
  QualityfirstsectionWrapper: {
    paddingBottom: '1rem',
    borderBottom: "1px solid #fff",
    width: '95%',
    margin: 'auto',
    fontFamily: "'Manrope', sans-serif",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem",
      width: '100%'
    },
  },
  TextWrapper: {
    textAlign: 'center',
    fontFamily: "'Manrope', sans-serif",
    fontWeight:"500",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem",
      textAlign: 'left',
      fontSize: "21px",
      fontWeight: "700",
      lineHeight: "28px",
    },
  },
  QualitysecondsectionWrapper: {
    color: "#fff",
    paddingRight: "2rem",
    width: '95%',
    margin: 'auto',
    fontFamily: "'Manrope', sans-serif",
    [theme.breakpoints.down("sm")]: {
      paddingleft: "4rem",
      width: '100%'
    },
  },
  persetText: {
    fontFamily: "'Manrope', sans-serif",
  },
  titleText: {
    color: "black",
    fontFamily: "'Manrope', sans-serif",
  },
  QualitysecondsectionParaWrapper: {
    color: "black",
    padding: "3rem 1rem 1rem 1rem",
    fontFamily: "'Manrope', sans-serif",
    [theme.breakpoints.down('sm')]: {
      paddingRight: "1rem",
    },

  },
  paraText: {
    fontFamily: "'Manrope', sans-serif",
    fontSize: "14px",
    textAlign:"center",
  },
  paraTextNew: {
    fontSize: "16px",
    textAlign: 'center',
    lineHeight: '1.4rem',
    padding:'10px 0px',
    [theme.breakpoints.down('sm')]: {
      // textAlign:"left",
      textAlign: "justify",
      wordSpacing: "-0.1em",
      hyphens: "auto",
      fontSize: "15px",
    },
  },

}));
const Skill = ({ Skill }) => {
  const classNamees = useStyles();
  return (
    <>
      {Skill?.map((val) => (
        <>
          <Container className={classNamees.QualitymainContainer} maxWidth={false}>
            <Container
              className={classNamees.QualityfirstsectionWrapper}
              maxWidth="lg"
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  className={classNamees.QualityfirstsectionTitleWrapper}
                >
                  <Typography variant="h4" className={classNamees.TextWrapper}>
                    {val.PerformanceTitle}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classNamees.QualityfirstsectionParaWrapper}
                >
                  <p className={classNamees.paraTextNew}>{val.PerformancePara} </p>
                </Grid>
              </Grid>
            </Container>

            <Container
              className={classNamees.QualitysecondsectionWrapper}
              maxWidth="lg"
            >
              <Grid container>
                {val.PerformanceList?.map((listitem) => (
                  <>
                    <Grid xs={12} sm={4} md={4} lg={4}>
                      <div className={classNamees.QualitysecondsectionParaWrapper}>
                        <Typography variant="h6" className={classNamees.persetText}>
                          {listitem.Numbers}
                        </Typography>
                        <Typography align="center" variant="h6" className={classNamees.titleText}>
                          {listitem.PerformanceListTitle}
                        </Typography>
                        <Typography className={classNamees.paraText}>
                          {listitem.PerformanceListPara}
                        </Typography>
                      </div>
                    </Grid>
                  </>
                ))}
              </Grid>
            </Container>
          </Container>
        </>
      ))}
    </>
  );
};

export default Skill;
