import React from "react";
import "./MobileDevelopment.css";
import Lottie from "react-lottie";
import animationData from "../../../assets/MobileAnimation.json";
import Android from "../../../images/Services/Android-App-Development.svg";
import Ios from "../../../images/Services/IoS-App-Development.svg";
import ReactNative from "../../../images/Services/React-Native-Cross.svg";
import Flutter from "../../../images/Services/Flutter-Cross-Platform-App-Development.svg";
import MobileMaintaince from "../../../images/Services/Mobile-App-Support-_-Maintenance.svg";
import MobileAppTesting from "../../../images/Services/Mobile-App-Testing.svg";
import Startrgy from "../../../images/Services/strategy.webp";
import Analysis from "../../../images/Services/analysis-and-planning.webp";
import UiUx from "../../../images/Services/ux-and-ui-design.webp";
import MobileDev from "../../../images/Services/MobileApp.webp";
import testing from "../../../images/Services/testing1.webp";
import DeveSupport from "../../../images/Services/deployment-and-support.webp";
import AndroidLogo from "../../../images/Services/androidLogo.webp";
import IosLogo from "../../../images/Services/IosLogo.webp";
import WindowLogo from "../../../images/Services/WindowLogo.webp";
import ReactNativeLogo from "../../../images/Services/ReactNative.webp";
import IonicLogo from "../../../images/Services/Ionic.webp";
import FlutterLogo from "../../../images/Services/Flutter.webp";
import Kotlin from "../../../images/Services/kotlin.webp";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const MobileDevelopment = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <div style={{ marginTop: "60px" }}>
        <section className="MobileHeader-sections">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 Mobile-banner-col">
                <div className="Mobile-banner-content">
                  <h1>Mobile App Development</h1>
                  <p className="sub-p">
                    Need a top mobile app developer? Look no further! We create
                    innovative iOS, Android & cross-platform apps. Let's bring
                    your business ideas to life. Contact us now!.
                  </p>
                  <div className="Mobile-banner-button">
                    <Link to="/contact">
                      {" "}
                      <a className="MobApp-btn">Let's Talk</a>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 mobile-image">
                <div className="image">
                  <Lottie options={defaultOptions} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ padding: "50px 0px" }}>
          <div
            className="container web-container"
            style={{ left: "0", border: "none" }}
          >
            <h2 className="text-center">Mobile App Development Company</h2>
            <p className="Mobile-para">
              We offer end-to-end custom mobile app development services
              encompassing design, integration, and management. Whether you need
              a consumer-oriented app or a transformative enterprise-level
              solution, our team handles the entire development process, from
              concept to delivery, including ongoing support. We deliver
              high-quality solutions that work seamlessly on Android, iOS, or
              cross-platform environments. We understand that creating a
              billion-dollar app requires exceptional effort, and our services
              are tailored to align with your vision, ensuring success within
              your budget and timeframe. Entrust your unique idea to our team
              and witness its splendid growth.
            </p>
          </div>
        </section>

        <section className="section_part1">
          <div
            className="container container1"
            style={{ left: "0", border: "none" }}
          >
            <div className="row" style={{ rowGap: "20px" }}>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-widget">
                  <div className="sw-head">
                    <div className="sw-logo">
                      <img src={Android} alt="logo" />
                    </div>
                    <div className="sw-title">Android App Development</div>
                  </div>
                  <div className="sw-content">
                    <p>
                      {" "}
                      Make your app truly unique among the 3 million+ apps in
                      the Play Store. Our Android app development services offer
                      customization, feature-rich functionality, and captivating
                      UI/UX to enhance user experience and set your app apart
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-widget">
                  <div className="sw-head">
                    <div className="sw-logo">
                      <img src={Ios} alt="logo" />
                    </div>
                    <div className="sw-title">iOS App Development</div>
                  </div>
                  <div className="sw-content">
                    <p>
                      With our iOS app development services, create a mobile app
                      compatible with all Apple devices and services. Our
                      comprehensive process covers ideation to launch, ensuring
                      a seamless development journey for your app.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-widget">
                  <div className="sw-head">
                    <div className="sw-logo">
                      <img src={ReactNative} alt="logo" />
                    </div>
                    <div className="sw-title">
                      React Native Cross Platform App Development
                    </div>
                  </div>
                  <div className="sw-content">
                    <p>
                      Leverage the expertise of Senwell Group Private Limited
                      with the power of React Native app development for the
                      perfect mobile application solution. Our cross-platform
                      approach enables effective app development for Android and
                      iOS.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-widget">
                  <div className="sw-head">
                    <div className="sw-logo">
                      <img src={Flutter} alt="logo" />
                    </div>
                    <div className="sw-title">
                      Flutter Cross Platform App Development
                    </div>
                  </div>
                  <div className="sw-content">
                    <p>
                      Create an app with a native look and feel using Flutter
                      app development. Our mobile app development services
                      enhance the Flutter process, delivering high-quality apps
                      for both Android and iOS users to cherish.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-widget">
                  <div className="sw-head">
                    <div className="sw-logo">
                      <img src={MobileMaintaince} alt="logo" />
                    </div>
                    <div className="sw-title">
                      Mobile App Support & Maintenance
                    </div>
                  </div>
                  <div className="sw-content">
                    <p>
                      Senwell Group Private Limited offers unmatched web app
                      testing services, combining automated and manual testing
                      for superior quality, usability, and security.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-widget">
                  <div className="sw-head">
                    <div className="sw-logo">
                      <img src={MobileAppTesting} alt="logo" />
                    </div>
                    <div className="sw-title">Mobile App Testing</div>
                  </div>
                  <div className="sw-content">
                    <p>
                      Senwell Group Private Limited delivers unmatched,
                      performance-oriented mobile app testing services. Whether
                      automated or manual, we ensure the highest level of
                      quality, usability, and security for your app.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ marginTop: "50px" }}>
          <div className="container" style={{ left: "0", border: "none" }}>
            <h2 className="text-center senwell-process">
              Senwell Group Private Limited Mobile App Development Process
            </h2>
            <div className="row" style={{ marginTop: "30px", rowGap: "30px" }}>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="mobile-card">
                  <img
                    src={Startrgy}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginBottom: "10px",
                    }}
                    alt="Startrgy"
                  ></img>
                  <span style={{ marginTop: "10px" }}>Strategy</span>
                  <p className="Mob-App-Process">
                    Defining is the foundational step in crafting a successful
                    app and connecting with your potential customers.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="mobile-card">
                  <img
                    src={Analysis}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginBottom: "10px",
                    }}
                    alt="Analysis"
                  ></img>
                  <span style={{ marginTop: "10px" }}>Analysis & Planning</span>
                  <p className="Mob-App-Process">
                    Collecting requirements and inputs for the app plays a
                    pivotal role in delivering a high-standard application
                    tailored to the client's specific needs.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="mobile-card">
                  <img
                    src={UiUx}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginBottom: "10px",
                    }}
                    alt="UiUx"
                  ></img>
                  <span style={{ marginTop: "10px" }}>UI/UX Design</span>
                  <p className="Mob-App-Process">
                    Possessing a seamless and user-friendly app will
                    consistently yield strong performance and deliver a higher
                    return on investment (ROI)
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="mobile-card">
                  <img
                    src={MobileDev}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginBottom: "10px",
                    }}
                    alt="MobileDev"
                  ></img>
                  <span style={{ marginTop: "10px" }}>App Development</span>
                  <p className="Mob-App-Process">
                    During this phase, we will handle the development of the
                    back-end, APIs, mobile app features, and all necessary
                    integrations.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="mobile-card">
                  <img
                    src={testing}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginBottom: "10px",
                    }}
                    alt="testing"
                  ></img>
                  <span style={{ marginTop: "10px" }}>Testing</span>
                  <p className="Mob-App-Process">
                    We conduct testing for functionality, usability,
                    compatibility, performance, and security to ensure smooth
                    and flawless operation.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="mobile-card">
                  <img
                    src={DeveSupport}
                    style={{
                      width: "50px",
                      height: "50px",
                      marginBottom: "10px",
                    }}
                    alt="DeveSupport"
                  ></img>
                  <span style={{ marginTop: "10px" }}>
                    Deployment & Support
                  </span>
                  <p className="Mob-App-Process">
                    We will launch it on the App Store or Google Play Store and
                    offer ongoing support for future updates and enhancements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ padding: "50px 0", backgroundColor: "#f9f9ff" }}>
          <div
            className="container"
            style={{
              left: "0",
              border: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h2 className="text-center App-Platform">App Platform</h2>
            <div
              className="row"
              style={{
                width: "80%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "30px",
                rowGap: "20px",
              }}
            >
              <div
                className="col-lg-4 col-md-6 col-sm-12"
                style={{ textAlign: "center" }}
              >
                <img
                  src={AndroidLogo}
                  alt="AndroidLogo"
                  style={{ width: "130px" }}
                ></img>
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12"
                style={{ textAlign: "center" }}
              >
                <img
                  src={IosLogo}
                  alt="IosLogo"
                  style={{ width: "130px" }}
                ></img>
              </div>
              <div
                className="col-lg-4 col-md-6 col-sm-12"
                style={{ textAlign: "center" }}
              >
                <img
                  src={WindowLogo}
                  alt="WindowLogo"
                  style={{ width: "130px" }}
                ></img>
              </div>
            </div>
          </div>
        </section>

        <section style={{ marginTop: "100px" }}>
          <div
            className="container"
            style={{
              left: "0",
              border: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "30px",
            }}
          >
            <h2 className="text-center Tech-we-use-Header">Technolgy We Use</h2>
            <p className="text-center Tech-we-use-Header-Para">
              As smartphones continue to redefine the importance of mobile apps,
              our role as industry pioneers becomes even more vital. We harness
              resilient and cutting-edge technologies throughout the app
              development process, enabling us to create and deliver exceptional
              apps that meet the highest standards for our clients.
            </p>
            <div
              className="row"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "30px",
                rowGap: "50px",
              }}
            >
              <div
                className="col-lg-3 col-md-6 col-sm-12"
                style={{ textAlign: "center" }}
              >
                <img
                  src={ReactNativeLogo}
                  style={{ width: "130px" }}
                  alt="ReactNativeLogo"
                ></img>
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-12"
                style={{ textAlign: "center" }}
              >
                <img
                  src={IonicLogo}
                  style={{ width: "130px" }}
                  alt="IonicLogo"
                ></img>
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-12"
                style={{ textAlign: "center" }}
              >
                <img
                  src={FlutterLogo}
                  style={{ width: "130px" }}
                  alt="FlutterLogo"
                ></img>
              </div>
              <div
                className="col-lg-3 col-md-6 col-sm-12"
                style={{ textAlign: "center" }}
              >
                <img src={Kotlin} style={{ width: "130px" }} alt="Kotlin"></img>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default MobileDevelopment;
