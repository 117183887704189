import { SET_PLATFORM } from "./platformType";
import { GET_PLATFORM } from "./platformType";


export const setPlatform = (response) => {
  //console.log('setPlatform action', response)
  return {
    type: SET_PLATFORM,
    payload: response,
  };
};

export const getPlatform = (response) => {
  return {
    type: GET_PLATFORM,
    payload: response,
  };
};




