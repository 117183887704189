import { DesignHeroBanner } from "../images/images";
import { SiAdobephotoshop } from "react-icons/si";
import { SiAdobeillustrator } from "react-icons/si";
import { SiAdobeaftereffects } from "react-icons/si";
import { huffpostImg } from "../images/images";
import { Dashlane } from "../images/images";
import { Code_Better } from "../images/images";
import { Distractify } from "../images/images";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import SearchIcon from "@material-ui/icons/Search";
import StarIcon from "@material-ui/icons/Star";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { DiJavascript } from "react-icons/di";
import { FaAngular } from "react-icons/fa";
import { SiTypescript } from "react-icons/si";
import { FaVuejs } from "react-icons/fa";
import { FaReact } from "react-icons/fa";
import Cronage from "../images/Pastproject/Cronage.png";
import Fooz1 from "../images/Pastproject/Fooz1.png";
import Fork from "../images/Pastproject/Fork1.png";
import Lotto from "../images/Pastproject/Lotto.png";
import Lumiere from "../images/Pastproject/Lumiere.png";
import parsl from "../images/Pastproject/parsl.JPG";
import Virus from "../images/Pastproject/Virus.png";
import mindlife from  "../images/Pastproject/MindLife.png";
import {IoLogoJavascript} from "react-icons/io";
import Java from "../images/Technologies/java2.jpg";
import Dotnet from "../images/Technologies/net-logo.png";
import Postman from "../images/Technologies/postman.png";
import Csharp from "../images/Technologies/csharp.png";
import Vue from"../images/Technologies/vue.png";
import Python from "../images/Technologies/python3.png";
import Kotlin1 from "../images/Technologies/Kotlin.png";
import Selenium1 from "../images/Technologies/Selenium.png"
import Jmeter from "../images/Technologies/jmeter.jpg";
import junit from "../images/Technologies/junit.webp";
//import cronage from "../images/logo/cronage.png";
import fooz from "../images/logo/fooz.png";
import GreekVirous from "../images/logo/GreekVirous.png";
import Lottosocialogo from "../images/logo/Lottosocialogo.png";
import Lumieres from "../images/logo/Lumieres.png";
import parslImg from "../images/logo/parslImg.png";
import { technologies } from "./HomeApi";
// import ForkLogo from "../images/logo/fork.png";
const DevelopmentData = [
  {
    name: "custom-software-development",
    HeroBanner: [
      {
        name: "custom-software-development",
        Page: " Custom software development",
        section: "Services",
        title: " Software Development Services",
        Breadcrumbs: "services",
        Link: "/services",
        para: "Our custom software development team can help you create troublesome solutions, breathtaking web apps, and business software that will help your enterprise meet key objectives.",
        button: "Get in Touch",
        img: DesignHeroBanner,
      },
    ],
    GetInTouch: [
      {
        GetInTouchImage: "",
        GetInTouchTitle:
          "Ready to start on your development,Digital Marketing or testing project? We are!",
        Button: "Get In Touch",
      },
    ],
    Benefits: [
      {
        title: "Ideal Software",
        Benefitstitle: "Development Solutions",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "Tailored Processes",
            description:
              "We are Agile, but our developers can adapt to development methodologies that fit best with your business, industry, or preferences. Whatever the process, we are always concentrate on the result.",
          },
          {
            Number: "02",
            title: "Professionals Only",
            description:
              "Only middle- or senior-level software developers will work on your project. Years of knowledge and specialists in the technologies used to construct your software and apps will be available to you.",
          },
          {
            Number: "03",
            title: "Transparent Code Monitoring",
            description:
              "In addition to comprehensive software testing and quality assurance, we will also provide detailed code analytics to help you ensure that your codebase is reliable, clean, and free of technical debt..",
          },
          {
            Number: "04",
            title: "Fast Start",
            description:
              "All of our internal processes are built around quality, the ability to begin work on a new project quickly. Our team begins drafting development plans instantly as you get in touch.",
          },
          {
            Number: "05",
            title: "Instant Resource Replacement",
            description:
              "We handpick the best developers for your project, but with a record of 280+ engineers, we’re always ready to change your development lineup.",
          },
          {
            Number: "06",
            title: "Pilot Projects",
            description:
              "Our team will gladly outright a pilot project to help you make sure that you’ve made the right choice in choosing Senwell as your software development service provider.",
          },
        ],
      },
    ],
    TechnologiesExpertiesTitle: "Our Technology Expertise",
    TechnologiesExpertiesPara:
      "When outsourcing software development to Senwell, be certain that you’ll work with only proven technologies, development practices. Our engineers combine niche expertise in a broad range of programming languages and tools with broad domain knowledge to help pick the right tech stack for your business needs.",

    TechnologiesExperties: [
      
      {
        icon: <IoLogoJavascript className="icon" style={{backgroundColor:"yellow", color:"black"}} />,
        title: "JavaScript",
      },
      {
        icon: <FaAngular className="icon" style={{ i: "#4eb7ff",color:"red" }} />,
        title: "Angular",
      },
      {
        icon: <FaReact className="icon" style={{ i: "#4eb7ff" }} />,
        title: "React",
      },
      {
        icon: <SiTypescript className="icon" style={{ i: "#4eb7ff", backgroundColor:"black",color:"yellow" }} />,
        title: "TypeScript",
      },
      {
        icon: <img src={Vue} className="icon"  style={{color:"green"}} />,
        title: "Vue.Js",
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Run a pilot project to ensure Senwell Group Private Limited’s React outsourcing expertise is exactly what your business needs.",
        Button: "Get In Touch",
      },
    ],

    ProjectPortfollio: [
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fooz",
        image1: Fooz1,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Cronage perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    whysenwell: [
      {
        icon: (
          <FlashOnIcon
            style={{
              color: "#3D8ED7",
              paddingBottom: "1rem",
              fontSize: "2.5rem",
            }}
          />
        ),
        title: "Start fast. Finish with confidence.",
        para: "Our goal is to help you launch quickly, make sure you’ll want to work with us again.",
      },
      {
        icon: (
          <SearchIcon
            style={{
              color: "#3D8ED7",
              paddingBottom: "1rem",
              fontSize: "2.5rem",
            }}
          />
        ),
        title: "100% Transparency",
        para: "explicit and consistent communication, flexible pricing, and full dedication to your success",
      },
      {
        icon: (
          <StarIcon
            style={{
              color: "#3D8ED7",
              paddingBottom: "1rem",
              fontSize: "2.5rem",
            }}
          />
        ),
        title: "Excellent Code Quality",
        para: "We check the quality of our code at all times of development to ensure best results.",
      },
      {
        icon: (
          <LocationOnIcon
            style={{
              color: "#3D8ED7",
              paddingBottom: "1rem",
              fontSize: "2.5rem",
            }}
          />
        ),
        title: "Cross-domain expertise",
        para: "Require services beyond Development services? We will have you covered.",
      },
    ],
    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },
];
export { DevelopmentData };
