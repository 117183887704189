import JavaNew from "../../../images/Blogs/JavaNew.webp";
import ReactNew from "../../../images/Blogs/ReactNew.webp";
import OopsNew from "../../../images/Blogs/OopsNew.webp";

import InsightCard from "./InsightCard";

const InsightSection = () => {
  return (
    <section className="insight-section section-padding-product">
      <div className="mb-30">
        <h2 className="title text-center">
          <span>Insights</span>
        </h2>
      </div>
      <div className="container" style={{ left: "0", border: "none" }}>
        <div className="row g-4">
          <InsightCard
            img={JavaNew}
            title={"Is Java Future Of App Development ?"}
            des={
              "When developing applications, the choice of programming language can be important. And from the wealth of options, one language stands ..."
            }
            url="/java-future-blog"
          />
          <InsightCard
            img={ReactNew}
            title={"Why is React js Popular in 2023 ? "}
            des={
              "ReactJS  has really taken the tech world by storm, with over 220,000 live websites already using its power..."
            }
            url="/react-future-blog"
          />
          <InsightCard
            img={OopsNew}
            title={"Most Popular Oops Languages in 2023"}
            des={
              "Learn more about this revolutionary concept and the key languages considered essential to its success. What is Object Oriented programming? ..."
            }
            url="/object-orient-blog"
          />
        </div>
      </div>
    </section>
  );
};

export default InsightSection;
