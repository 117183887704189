import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { AiOutlineClose } from "react-icons/ai";
import { Box, Container, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { addDoc, collection } from "firebase/firestore/lite";
import  db  from "../Pages/Components/firebase.config";

const validationSchema = yup.object({
  name: yup.string("Enter your name").required("name is required"),

  description: yup
    .string("Enter your description")

    .required("description is required"),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
});

const useStyles = makeStyles((theme) => ({
  TextField: {
    marginBottom: "2rem",
    "&:focus": {
      outline: "none",
    },
    "&:label": {
      fontWeight: "700",
    },
  },
  titleWrapper: {
    padding: "1rem 1rem",
    textAlign: "center",
  },
  title: {
    fontFamily: " 'Ubuntu', sans-serif",
  },
  Buttonsubmit: {
    borderRadius: "10px",
    height: "40px",
  },
}));

const WithMaterialUI = ({ Close }) => {
  const classNamees = useStyles();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      try {
        addDoc(collection(db, "tasks"), {
          name: values.name,
          email: values.email,
          description: values.description,
        });
        resetForm();
        Close();
        
        alert("message sent successfully");
      } catch (err) {
        // alert(err);
      }
    },
  });

  const [state, setState] = React.useState({
    checkedA: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        background: "#fff",
        padding: "1rem 3rem",
        backgroundColor: "white",
        borderRadius: "20px",
        boxShadow: "0 0 10  px black",
      }}
    >
      <Typography style={{ float: "right", paddingBottom: "1rem" }}>
        <AiOutlineClose
          onClick={Close}
          style={{
            fontSize: "1.5rem",
            position: "relative",
            left: "30px",
            cursor: "pointer",
          }}
        />
      </Typography>
      <Box className={classNamees.titleWrapper}>
        <Typography variant="h5" className={classNamees.title}>
          Tell us about your project{" "}
        </Typography>
        <p>The more we know, the more accurate our estimate!</p>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          inputProps={{
            style: { padding: 14 },
          }}
          id="name"
          name="name"
          label=" Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          className={classNamees.TextField}
          variant="outlined"
        />

        <TextField
          inputProps={{
            style: {
              padding: 14,
            },
          }}
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          className={classNamees.TextField}
          variant="outlined"
        />
        <TextField
          fullWidth
          multiline
          rows={3}
          maxRows={3}
          id="description"
          name="description"
          label="Project Description"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
          className={classNamees.TextField}
          variant="outlined"
        />
        <div className={classNamees.TextField}>
          <Checkbox
            color="primary"
            checked={state.checkedA}
            onChange={handleChange}
            name="checkedA"
            variant="outlined"
          />
          <span>I agree to the Senwell Cookie and Policy*</span>
        </div>

        <Button
          className={classNamees.Buttonsubmit}
          color="primary"
          // disabled={!(formik.isValid && formik.dirty)}
          variant="contained"
          fullWidth
          type="submit"
        >
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default WithMaterialUI;
