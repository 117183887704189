import React from "react";
import Slider from "react-slick";
import client1 from "../images/Pastproject/client1.webp";
import client2 from "../images/Pastproject/client2.webp";
import client3 from "../images/Pastproject/client3.webp";
import client4 from "../images/Pastproject/client4.webp";
import client5 from "../images/Pastproject/client5.webp";
import client6 from "../images/Pastproject/client6(new).webp";
import client7 from "../images/Pastproject/client7.webp";
import client8 from "../images/Pastproject/client8.webp";
import client9 from "../images/Pastproject/client9.webp";
import client10 from "../images/Pastproject/client10.webp";
import client11 from "../images/Pastproject/CiscoLogo.png";
import client12 from "../images/Pastproject/HR4UKNEW.png";

import "../SharedModule/OurClientNew.css";
import CountUpWithViewport from "./CounterUp";
const OurClientNew = () => {
  var settings = {
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div
        className="container"
        style={{ left: "0", border: "none", backgroundColor: "#ffffff" }}
      >
        <h2 className="text-center some-client">Some of Our Premium Clients</h2>
        <Slider {...settings}>
          <div className="swipper-slider">
            <a className="card card-body inside-card no-border px-1 mx-1">
              <img
                src={client1}
                className=" d-block mx-2 my-2 client-img"
                alt="client1"
              />
            </a>
          </div>
          <div className="swipper-slider">
            <a className="card card-body inside-card no-border px-1 mx-1">
              <img
                src={client2}
                className=" d-block mx-2 my-2 client-img"
                alt="client2"
              />
            </a>
          </div>

          <div className="swipper-slider">
            <a className="card card-body inside-card no-border px-1 mx-1">
              <img
                src={client3}
                className=" d-block mx-2 my-2 client-img"
                alt="client3"
              />
            </a>
          </div>

          <div className="swipper-slider">
            <a className="card card-body inside-card no-border px-1 mx-1">
              <img
                src={client4}
                className=" d-block mx-2 my-2 client-img"
                alt="client4"
              />
            </a>
          </div>

          <div className="swipper-slider">
            <a className="card card-body inside-card no-border px-1 mx-1">
              <img
                src={client5}
                className=" d-block mx-2 my-2 client-img"
                alt="client5"
              />
            </a>
          </div>

          <div className="swipper-slider">
            <a className="card card-body inside-card no-border px-1 mx-1">
              <img
                src={client6}
                className=" d-block mx-2 my-2 client-img"
                alt="client6"
              />
            </a>
          </div>
          <div className="swipper-slider">
            <a className="card card-body inside-card no-border px-1 mx-1">
              <img
                src={client7}
                className=" d-block mx-2 my-2 client-img"
                alt="client7"
              />
            </a>
          </div>
          <div className="swipper-slider">
            <a className="card card-body inside-card no-border px-1 mx-1">
              <img
                src={client8}
                className=" d-block mx-2 my-2 client-img"
                alt="client8"
              />
            </a>
          </div>

          <div className="swipper-slider">
            <a className="card card-body inside-card no-border px-1 mx-1">
              <img
                src={client9}
                className=" d-block mx-2 my-2 client-img"
                alt="client9"
              />
            </a>
          </div>

          <div className="swipper-slider">
            <a className="card card-body inside-card no-border px-1 mx-1">
              <img
                src={client10}
                className=" d-block mx-2 my-2 client-img"
                alt="client10"
              />
            </a>
          </div>

          <div className="swipper-slider">
            <a className="card card-body inside-card no-border px-1 mx-1">
              <img
                src={client11}
                className=" d-block mx-2 my-2 client-img"
                alt="client11"
              />
            </a>
          </div>

          <div className="swipper-slider">
            <a className="card card-body inside-card no-border px-1 mx-1">
              <img
                src={client12}
                className=" d-block mx-2 my-2 client-img"
                alt="client12"
              />
            </a>
          </div>
        </Slider>
      </div>

      <section style={{ backgroundColor: "#fffefe", padding: "20px 17px" }}>
        <div className="container" style={{ left: "0", border: "none" }}>
          <p className="text-center OurImpact">Our Impact</p>
          <div
            className="row"
            style={{
              padding: "40px 0px 20px",
              borderRadius: "10px 150px",
              backgroundColor: "#f3f6ff",
            }}
          >
            <div className="col-lg-3 col-md-3 col-sm-3">
              <div style={{ textAlign: "center" }}>
                <CountUpWithViewport start={0} end={1000} duration={2000} />
                <span className="elementor-counter-number">+</span>
                <p className="number-text">Project Delivered</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-3">
              <div style={{ textAlign: "center" }}>
                <CountUpWithViewport start={0} end={90} duration={2000} />
                <span className="elementor-counter-number">%</span>
                <p className="number-text">Contract Renewals</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-3">
              <div style={{ textAlign: "center" }}>
                <CountUpWithViewport start={0} end={500} duration={2000} />
                <span className="elementor-counter-number">+</span>
                <p className="number-text">Team of passionate Engineers</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-3">
              <div style={{ textAlign: "center" }}>
                <CountUpWithViewport start={0} end={15} duration={1500} />
                <span className="elementor-counter-number">+</span>
                <p className="number-text">Countries</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurClientNew;
