import { SET_ENGINEERS } from "./engineersType";
import { GET_ENGINEERS } from "./engineersType";

export const setEngineers = (response) => {
  // console.log("Engineers action", response);
  return {
    type: SET_ENGINEERS,
    payload: response,
  };
};

export const getEngineers = (response) => {
  return {
    type: GET_ENGINEERS,
    payload: response,
  };
};
