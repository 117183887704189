import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container, Typography, Grid } from "@material-ui/core";
import { firstSvgImg } from "../../images/images";
import { secondSvgImg } from "../../images/images";
import { thirdSvgImg } from "../../images/images";
import { fouthSvgImg } from "../../images/images";

const useStyles = makeStyles((theme) => ({
  QualityfirstsectionParaWrapper: {
    color: "#5a5a5a",
    lineHeight: "1.5rem",
    padding: "1.5rem 5rem 2rem 2rem",
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  Width: {
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      margin: "auto",
    },
  },
  QualityfirstsectionWrapper: {
    // padding: "4rem 0rem",
  },
  TextWrapper: {
    fontFamily: " 'Ubuntu', sans-serif",
    color: "#1c1c1c",
    padding: "2rem 2rem 2rem 5rem ",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  para: {
    // fontFamily: " 'Lora', serif",
    fontSize: "14px",
  },
  imgcolor: {
    fill: "blue",
  },
  flexbox: {
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
  },
  mainWrapper: {
    paddingBottom: "5rem",
  },
}));

const Entrustyourproject = () => {
  const classNamees = useStyles();
  return (
    <>
      <div className={classNamees.Width}>
        <Container className={classNamees.QualityfirstsectionWrapper} maxWidth="lg">
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classNamees.QualityfirstsectionTitleWrapper}
            >
              <Typography variant="h4" className={classNamees.TextWrapper}>
                Reasons you should entrust your project to Senwell Group Private Limited
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classNamees.QualityfirstsectionParaWrapper}
            >
              <p style={{ textAlign: "justify" }} className={classNamees.para}>
                {" "}
                We are a trustworthy partner who consistently attempts to exceed
                the client's expectations. We offer clear communication,
                transparency, and flexibility in cooperation with our clients.
              </p>
            </Grid>
          </Grid>
        </Container>

        <Container className={classNamees.mainWrapper} maxWidth="lg">
          <Grid container>
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={3}
              style={{ padding: "1rem 3rem" }}
            >
              <div className={classNamees.flexbox}>
                <svg width="100" height="100" style={{ color: "blue" }}>
                  <image href={firstSvgImg} />
                </svg>
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "500",
                }}
              >
                Transparent collaboration
              </p>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={3}
              style={{ padding: "1rem 3rem" }}
            >
              <div className={classNamees.flexbox}>
                <svg width="100" height="100">
                  <image href={secondSvgImg} />
                </svg>
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Easy scaling
              </p>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={3}
              style={{ padding: "1rem 3rem" }}
            >
              <div className={classNamees.flexbox}>
                <svg width="100" height="100">
                  <image href={thirdSvgImg} />
                </svg>
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                Proprietary code quality tools
              </p>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              lg={3}
              style={{ padding: "1rem 3rem" }}
            >
              <div className={classNamees.flexbox}>
                <svg width="100" height="100">
                  <image href={fouthSvgImg} />
                </svg>
              </div>
              <p
                style={{
                  textAlign: "center",
                  fontFamily: "'Poppins', sans-serif",
                }}
              >
                {" "}
                Protection of confidentiality
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Entrustyourproject;
