
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Aos from "aos";
import "aos/dist/aos.css";

const useStyle = makeStyles((theme) => ({
  mainWrapper: {
    background: "#000000",
    padding: "0rem 5rem ",
    marginTop:"20px",
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem'
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: 'Ubuntu',
    marginBottom:'20px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    },
  },
  linkWithIcons: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  PastProjectBlockPara: {
    color: "white",
    fontSize: "14px",
    margin: "0 0 30px",
    textAlign: "justify !important",
    lineHeight: "1.6rem",
    letterSpacing: "0.5px",
    [theme.breakpoints.between(380, 600)]: {
      maxWidth: "26rem",
    },
    [theme.breakpoints.between(320, 380)]: {
      maxWidth: "17.5rem",
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft:"16px"
    },
  },
  PastProjectBlockMainHeading: {
    fontSize: "36px",
    fontWeight: "600",
    lineHeight: "56px",
    color: "#fff",
    fontFamily: "'Poppins', sans-serif",
    [theme.breakpoints.down('sm')]: {
      fontWeight: '400',
      fontSize:"27px"
    },
  },
  technologies: {
    fontSize: "1rem",
    color: "#fff",
    paddingBottom: "0.5rem",
    fontFamily: "'Poppins', sans-serif",
    [theme.breakpoints.down('sm')]: {
      marginLeft:"21px",
    },
  },
  PastProjectBlockSubHeading: {
    fontWeight: "600",
    fontSize: "1.75rem",
    color: "#fff",
    paddingBottom: "1rem",
    fontFamily: "'Poppins', sans-serif",
    [theme.breakpoints.down('sm')]:{
      marginTop: "20px",
      display:"flex",
      alignItems:"center",
      justifyContent: "center",
      fontWeight: "500",
    },
  },
  BottomLinkWithIcons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.down('sm')]: {
      display:"flex",
      alignItems:"center",
      justifyContent:"center"
    },
  },
  viewallLink: {
    color: "#fff",
    
    
    marginRight: "1.3rem",
    fontSize: "1rem",
    fontFamily: " 'Lora', serif",
    fontSize: "14px",
    
  },

  arrowBlock: {
    display: "flex",
    corsur:"pointer",
  },
  
  DownArrowBlock: {
    marginTop: "10px",
    display: "flex",
    corsur:"pointer",
  },
  slider: {
    display: "flex !important",
    alignItems: "center !important",
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
      flexDirection: "column-reverse !important",

      alignItems: "center !important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex !important",
      flexDirection: "column-reverse !important",
      margin: "0 1rem",
    },
  },
  WrapPastProjectBlockSubHeading:{
    width:"80%",
    [theme.breakpoints.down('sm')]: {
      width: '93%'
    },
  },
  padd: {
    padding: '50px 0'
  },
  slider_imgs1: {
    width: "100%",
    height: "auto",
    
  },
  imageBlock: {
    width: "100%",
    height: "auto",
    padding:'0px 30px',
    
  },
}));

const PastProjects = ({ ProjectPortfolio }) => {
  const [sliderRef, setSliderRef] = useState(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const classNamees = useStyle();
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <Container
        maxWidth={false}
        className={classNamees.mainWrapper}
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <Container maxWidth="lg" className={classNamees.padd}>
          <div className={classNamees.header}>
            <h2 className={classNamees.PastProjectBlockMainHeading}>
              Our past projects
            </h2>
            <div className={classNamees.linkWithIcons}>
              <span className={classNamees.arrowBlock}>
                <ArrowBackIcon
                  style={{color:"white",marginRight:"15px",cursor:"pointer!important" }}
                  onClick={sliderRef?.slickPrev}
                />
                <ArrowForwardIcon
                  style={{ color: "white",cursor:"pointer !important" }}
                  onClick={sliderRef?.slickNext}
                />
              </span>
            </div>
          </div>
          <Slider ref={setSliderRef} {...settings} arrows={false}>
            {ProjectPortfolio?.map((data) => (
              <>
                <div className={classNamees.slider}>
                  <div className={classNamees.WrapPastProjectBlockSubHeading}>
                    <h3 className={classNamees.PastProjectBlockSubHeading}>
                      {data.title}
                    </h3>
                    <p className={classNamees.technologies}>
                      Technologies :{" "}
                      {data.technologies &&
                        data.technologies?.map((tech, index) =>
                          index < data.technologies.length - 1
                            ? `${tech}, `
                            : tech
                        )}
                    </p>

                    <p className={classNamees.PastProjectBlockPara}>{data.para}</p>
                  </div>
                  <div className={classNamees.imageBlock}>
                    <img
                      src={data.image1}
                      alt="HuffPost"
                      className={classNamees.slider_imgs1}
                      // style={{height:"250px"}}
                    />
                  </div>
                </div>
              </>
            ))}
          </Slider>
          <div>
            <div className={classNamees.BottomLinkWithIcons}>
              {/* <a href="#" className={classNamees.viewallLink}>
                view all
              </a> */}
              <span className={classNamees.DownArrowBlock}>
                <ArrowBackIcon
                  style={{ color: "#3D8ED7", marginRight: "15px" }}
                  onClick={sliderRef?.slickPrev}
                />
                <ArrowForwardIcon
                  style={{ color: "#3D8ED7" }}
                  onClick={sliderRef?.slickNext}
                />
              </span>
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default PastProjects;
