import React, { useEffect } from "react";
import { makeStyles, Modal, Backdrop, Fade } from "@material-ui/core";
import { Container, Grid, Button, Typography } from "@material-ui/core";
import Popup from "../Modules/Popupform";
import Aos from "aos";
import "aos/dist/aos.css";
const useStyles = makeStyles((theme) => ({
  ImageWrapper: {
    display: "flex",
    justifyContent:"center",
    alignItems: "center",
    padding: "0rem 0rem 0rem 5rem",
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    },
  },
  Image: {
    width: "70%",
    height: "auto",
    borderRadius: "22px",
    objectFit: "contain",
    "&:path": {
      fill: "#3D8ED7",
    },
  },
  TextWrapper: {
    fontFamily: '"Manrope", sans-serif',
    color: "#fff",

    [theme.breakpoints.down('sm')]: {
      fontSize:"29px"
  },
  },
 
  GetInTouch: {
    fontWeight: "600",
    padding: "0.7rem 3rem",
    color: "#3D8ED7",
    backgroundColor: "#fff",
    borderRadius: "25rem",
    border: "2px solid #fff",
    marginTop: "2rem",
    "&:hover": {
      backgroundColor: "transparent",
      border: "2px solid #fff",
      color: "#fff",
    },
  },
  mainWrapper: {
    padding: "2rem 5rem",
    background:"#15315e",
    [theme.breakpoints.down("sm")]:{
      padding:"2rem"
    }
  },
  flexbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Container: {
    padding: "1rem",
    width: '90%',
    margin: 'auto',
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
      width: '100%'
    },
  },
  paraText: {
    color: "#fff",
    padding: "1rem 0rem",
    fontFamily: '"Manrope", sans-serif',
    fontSize: "20px",

    [theme.breakpoints.down('sm')]: {
      fontSize:"16px"
  },
  },
  
  
  ImageSection: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  GetInTouchButtonwrapper: {
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Button: {
    padding: "0.7rem 3rem",
    color: "#3D8ED7",
    background: "#fff !important",
    borderRadius: "25rem",
    border: "2px solid #fff",
    marginTop: "2rem",
    "&:hover": {
      backgroundColor: "transparent",
      border: "2px solid #fff",
      color: "#fff",
    },
  },
}));

const WantFreeConsultantation = ({ GetInTouchSecondBanner }) => {
  const classNamees = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <Container maxWidth="lg" className={classNamees.mainWrapper}>
        <Container className={classNamees.Container} maxWidth="lg">
          {GetInTouchSecondBanner?.map((val) => (
            <>
              <Grid container style={{ alignItems: 'center' }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className={classNamees.QualityfirstsectionTitleWrapper}
                >
                  <Typography variant="h4" className={classNamees.TextWrapper}>
                    {val.GetInTouchTitle}
                    
                  </Typography>
                  <p className={classNamees.paraText}>{val.para}</p>
                 
                  <div className={classNamees.GetInTouchButtonwrapper}>
                    <Button className={classNamees.GetInTouch} onClick={handleOpen}>
                      {val.Button}
                    </Button>
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      className={classNamees.modal}
                      open={open}
                      onClose={handleClose}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <Fade in={open}>
                        {/* <div className={classNamees.paper}> */}
                        <Popup Close={handleClose} />

                        {/* </div> */}
                        {/* <Mail/> */}
                      </Fade>
                    </Modal>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className={classNamees.ImageSection}
                >
                  <div className={classNamees.ImageWrapper}>
                    <img
                      src={val.image}
                      alt={val.GetInTouchTitle}
                      className={classNamees.Image}
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          ))}
        </Container>
      </Container>
    </>
  );
};

export default WantFreeConsultantation;
