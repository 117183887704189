import React from "react";
import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import LocalCafeIcon from "@material-ui/icons/LocalCafe";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import ControlCameraIcon from "@material-ui/icons/ControlCamera";
import SettingsRemoteIcon from "@material-ui/icons/SettingsRemote";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import WhatshotIcon from "@material-ui/icons/Whatshot";

const useStyles = makeStyles((theme) => ({
  cardCol: {
    display: "flex",
  },
  Details: {
    boxShadow: "0.7px 0.7px 5px #8f8989",
    margin: "10px",
    borderRadius: "2px",
    padding: "1rem 1rem",
    background: "#fff",
    flex: 1,
  },
  icon: {
    color: "#3D8ED7",
    fontWeight: "700",
    marginRight: "10px",
    "& .MuiSvgIcon-root": {
      fontSize: "2rem",
    },
  },
  mainWrapper: {
    padding: "2rem 0 1rem",
    width: "90%",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
      width: "100%",
    },
  },
  UxTitle: {
    padding: "0.3rem 0rem",
    fontFamily: "'Manrope', sans-serif",
  },
  heading: {
    fontFamily: "'Manrope', sans-serif",
    paddingTop: "0rem",
    textAlign: "center",
    fontSize: "2.125rem",
    fontWeight: 400,
    color: "#202020",

    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
  },
  description: {
    fontFamily: "'Manrope', sans-serif",
    padding: " 1rem",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#5a5a5a",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15px",
      textAlign: "justify",
      wordSpacing: "-0.1em",
      hyphens: "auto",
    },
  },
  UXDesign: {
    padding: "0rem 5rem 4rem 5rem",
    color: "#111",
    backgroundColor: "#f6f6f6",
    [theme.breakpoints.down("xs")]: {
      padding: "0rem",
    },
  },
  desc: {
    lineHeight: "1.5rem",
    textAlign: "justify",
    fontFamily: "'Manrope', sans-serif",
    fontSize: "14px",
    color: " #5a5a5a",
  },
  titleNIconWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

const workWithUs = [
  {
    icon: <StarIcon />,
    title: "Endless career growth",
    description:
      "We offer several prospects for career advancement to our personnel, which is represented both monetarily and reputedly.",
  },
  {
    icon: <AccountBalanceIcon />,
    title: "Mentorship & learning",
    description:
      "We encourage peer help at work and offer mentoring programs such as IT Talks and in-house training for specific jobs.",
  },
  {
    icon: <LocalCafeIcon />,
    title: "Cozy & fun offices",
    description:
      "We offer four large R&D offices with everything you need for a nice work environment: a gym, yoga mats, candies and cookies, foosball, a VR set, and so on.",
  },
  {
    icon: <EmojiEmotionsIcon />,
    title: "Friendly atmosphere",
    description:
      "We never use our organizational structure against our people. People are Senwell's most precious asset, and how we treat our employees reflects this.",
  },

  {
    icon: <ControlCameraIcon />,
    title: "Career change option",
    description:
      "There is no need to hunt for new work if you wish to learn a new technology or move into a different role. We will assist you in making the shift within Senwell",
  },
  {
    icon: <SettingsRemoteIcon />,
    title: "Cross-domain experience",
    description:
      "Our projects come from a wide range of sectors, which will broaden your professional experience.",
  },
  {
    icon: <MonetizationOnIcon />,
    title: "Competitive salary",
    description:
      " At Senwell Group Private Limited , we believe in rewarding talent and dedication. Our competitive salary packages are designed to reflect your skills and contributions.",
  },
  {
    icon: <WhatshotIcon />,
    title: "Corporate events",
    description:
      "We can't get enough of one other's company when there's a holiday, from marathons to culinary courses to conventional business parties (or not).",
  },
];

const WorkWithUs = () => {
  const classNamees = useStyles();
  return (
    <div className={classNamees.UXDesign}>
      <>
        <Container maxWidth="lg" className={classNamees.mainWrapper}>
          <div style={{ marginBottom: "2rem" }}>
            <Typography variant="h4" className={classNamees.heading}>
              Why do people want to collaborate with us?
            </Typography>
            <Typography variant="h4" className={classNamees.description}>
              Even while we value professional accomplishments, a good work-life
              balance is our team's top focus. Wise business management, open
              communication, and precise resource allocation show that
              productivity and success need not necessitate team members
              forsaking their personal time.
            </Typography>
          </div>

          <Grid container>
            {workWithUs?.map((data) => (
              <>
                <Grid
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className={classNamees.cardCol}
                >
                  <div className={classNamees.Details}>
                    <div className={classNamees.titleNIconWrapper}>
                      <Typography variant="h6" className={classNamees.icon}>
                        {data.icon}
                      </Typography>
                      <Typography variant="h6" className={classNamees.UxTitle}>
                        {data.title}
                      </Typography>
                    </div>
                    <p className={classNamees.desc}>{data.description}</p>
                  </div>
                </Grid>
              </>
            ))}
          </Grid>
        </Container>
      </>
    </div>
  );
};

export default WorkWithUs;
