import { combineReducers } from "redux";

import { modalReducer } from "./chooseModal/modalReducer";
import { developerReducer } from "./developers/developerReducer";
import { durationReducer } from "./duration/durationReducer";
import { specialistReducer } from "./specialists/specialistsReducer";
import { estimateReducer } from "./estimate/estimateReducer";

import { serviceReducer } from "./services/serviceReducer";
import { platformReducer } from "./platforms/platformReducer";
import { scopeReducer } from "./scopes/scopeReducer";
import { techReducer } from "./tech/techReducer";
import {drawerReducer} from "./drawerModal/drawerReducers"


const rootReducer = combineReducers({
  modalReducer,
  developerReducer,
  durationReducer,
  estimateReducer,
  specialistReducer,

  
  serviceReducer,
  platformReducer,
  scopeReducer,

  techReducer,
  drawerReducer
});

export default rootReducer;
