import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Container,
} from "@material-ui/core";
import { Backdrop, Collapse, Modal } from "@mui/material";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import Logo2 from "../images/Company/FinalSenwellLogo.png";
import Logo from "../images/Company/finalsenwellgrouplogo.png";
import logo2 from "../images/Company/settiing.png";
import Logo1 from "../images/Company/FinalSenwellName.png";
import { Link, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SideDrawer from "./SideDrawer";
import Drawer from "@material-ui/core/Drawer";
import MenuItems from "./Menulist/CompanyMenuList";
import ServiceMenuItems from "./Menulist/ServicesMenuList";
import { useLocation } from "react-router-dom";
import "./style.css";
import { t } from "i18next";
const drawerWidth = 250;
import { useSelector, useDispatch } from "react-redux";
import { drawerOpenClose } from "../redux/drawerModal/drawerAction";
import { useNavigate } from "react-router-dom";
import { setTech } from "../redux/tech/techActions";

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "flex",
      marginTop: "1rem",
    },
  },
  sectionMobile: {
    display: "block",
    color: "#000",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logo: {
    display: "flex",
    [theme.breakpoints.up("lg")]: {},
  },
  LogoImg: {
    width: "3.5rem",
    height: "2.7rem",
    margin: "1.3rem 0rem 0.7rem 0rem",
    [theme.breakpoints.down("md")]: {
      width: "3.5rem",
      height: "2.7rem",
    },
  },
  logoTitle: {
    margin: "0",
    fontSize: "21px",
    marginTop: "0.7rem",
    paddingTop: "0px",
    color: "#2460a6",
    textTransform: "uppercase",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.2rem",
      lineHeight: "1.3rem",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0rem",
      lineHeight: "1.3rem",
    },
  },
  slogan: {
    fontSize: "11px",
    fontStyle: "italic",
    marginTop: "8px",
    paddingTop: "0px",
    color: "#566573",
    lineHeight: "0.7rem",
    textAlign: "right",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
      fontSize: "10px",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      marginTop: "0px",
      textAlign: "left",
    },
  },
  mainContainer: {
    background: "#fff",
    zIndex: "1000",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
    "& .MuiPaper-elevation4": {
      boxShadow:
        "0px 2px 2px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
  MenuItem: {
    padding: "1rem 5rem 1rem 2rem",
    "&:hover": {
      backgroundColor: "rgb(7, 177, 77, 0.42)",
    },
  },
  ButtonStyle: {
    [theme.breakpoints.up("lg")]: {
      padding: "16px 8px",
      fontSize: "0.9rem",
    },
    padding: "10px 16px !important",
    // borderRadius: "60px",
    fontSize: "0.7rem",
    fontWeight: "600",
    boxSize: "border-box",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    transition: "transform .3s linear",
    // color: "#4c5a67",
    backgroundColor: "transparent",
  },
  drawerPaper: {
    width: drawerWidth,
    flexShrink: 0,
  },
  Menu: {
    marginTop: "4rem",
  },

  drawer: {
    transition: "all 500ms ease-in-out",
  },

  ContactButton: {
    padding: "0.2rem 1.8rem",
    margin: "0 1rem",
    color: "#000",
    borderRadius: "25rem",
    fontSize: "0.5rem",
    border: "0.1rem solid #3D8ED7",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "700",
    transition: "width 2s",
    transitionTimingFunction: "linear",
    background: "none",

    "&:hover": {
      padding: "0.2rem 1.8rem",
      border: "0.1rem solid #3D8ED7",
      color: "#0084ff",
      background:
        "linear-gradient(90deg, hsla(200, 73%, 52%, 1) 44%, hsla(220, 60%, 37%, 1) 100%)",
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: "2.5rem",
      fontSize: "1rem",
    },
  },
  Menuitems: {
    position: "relative",
    zIndex: "2",
    display: "block",
    letterSpacing: "0.01rem",
    padding: "1rem 1rem",
    textDecoration: "none",
    fontWeight: "600",
    fontSize: "1.25rem",
    transition: "0.2s",
  },
  toolbar: {
    margin: "0",
    paddingLeft: "2.5rem",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "2rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0.1rem",
    },
  },
}));
const Navbar = ({ slug }) => {
  const dispatch = useDispatch();
  const drawerState = useSelector((state) => state.drawerReducer);
  const classNamees = useStyles();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("/hire-developer/front-end");
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(drawerOpenClose(false));
  };
  let activeStyle = {
    padding: "10px 16px !important",
    borderRadius: "60px",
  };

  const selectedTechnology = () => {};

  useEffect(() => {
    if (drawerState.drawer) {
      handleDrawerClose(!drawerState.drawer);
    }
  }, [drawerState?.drawer]);

  const { pathname } = useLocation();
  //Here the variable use for navigation.
  const navigate = useNavigate();
  //Here is navigation button function
  const handleNavbarButtonClick = (e) => {
    const name = event.target.getAttribute("data-name");
    const value = event.target.getAttribute("data-value");
    dispatch(setTech(name));
    navigate(`/hire-developers/${value}`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        className={classNamees.mainContainer}
      >
        <Container maxWidth="lg" style={{ padding: "0" }}>
          <Toolbar className={classNamees.toolbar}>
            <Link to="/" style={{ textDecoration: "none", flexGrow: 1 }}>
              <Typography className={classNamees.logo} style={{ flexGrow: 1 }}>
                <div className="App">
                  <img src={Logo} className="App-logo" alt="logo" />
                </div>

                <div
                  style={{
                    display: "flex",

                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3 className={classNamees.logoTitle}>
                    Senwell Group Private Limited
                  </h3>
                  <p textAlign="end" className={classNamees.slogan}>
                    {t("slogan")}
                    {/* Empowered by Innovation...  */}
                  </p>
                </div>
              </Typography>
            </Link>
            <div className={classNamees.sectionDesktop}>
              <div className="main-menu">
                <nav>
                  <ul>
                    <Button
                      className={classNamees.ButtonStyle}
                      component={NavLink}
                      to="/"
                      style={{ background: "none" }}
                      disableFocusRipple={true}
                    >
                      <div className="home"> {t("Home")} </div>
                    </Button>

                    <li className="has-dropdown">
                      <Button
                        className={classNamees.ButtonStyle}
                        component={NavLink}
                        // to="/company"
                        style={({ isActive }) =>
                          pathname.match(`/outsourcing-pricing-models`) ||
                          pathname.match(`/portfolio`) ||
                          isActive
                            ? activeStyle
                            : undefined
                        }
                      >
                        {t("company")}
                        {/* Company */}
                      </Button>

                      <ul className="sub-menu">
                        <li>
                          <MenuItems />
                        </li>
                      </ul>
                    </li>
                    <li className="has-dropdown">
                      <Button
                        className={classNamees.ButtonStyle}
                        component={NavLink}
                      >
                        {t("service")}
                        {/* Services */}
                      </Button>

                      <ul className="sub-menu">
                        <li>
                          <ServiceMenuItems />
                        </li>
                      </ul>
                    </li>
                    <li className="has-dropdown">
                      <Button
                        className={classNamees.ButtonStyle}
                        component={NavLink}
                        // to="/hire-developers/front-end"
                        style={({ isActive }) =>
                          pathname.match(`/hire-developers/${slug}`) || isActive
                            ? activeStyle
                            : undefined
                        }
                      >
                        {t("technology")}
                        {/* Technologies */}
                      </Button>

                      <div className="sub-menu-new">
                        <div className="inner-sub-menu">
                          <span>FrontEnd</span>

                          <a
                            href="#"
                            data-name="React"
                            data-value="react"
                            onClick={handleNavbarButtonClick}
                            style={{ textDecoration: "none" }}
                          >
                            React
                          </a>
                          <a
                            href="#"
                            data-name="Angular"
                            data-value="angular"
                            onClick={handleNavbarButtonClick}
                            style={{ textDecoration: "none" }}
                          >
                            Angular
                          </a>
                          <a
                            href="#"
                            data-name="Next"
                            data-value="Next"
                            onClick={handleNavbarButtonClick}
                            style={{ textDecoration: "none" }}
                          >
                            Next Js
                          </a>
                        </div>

                        <div className="inner-sub-menu">
                          <span>BackEnd</span>

                          <a
                            href="#"
                            data-name=".Net"
                            data-value="dot-net"
                            onClick={handleNavbarButtonClick}
                            style={{ textDecoration: "none" }}
                          >
                            .Net
                          </a>
                          <a
                            href="#"
                            data-name="Node.js"
                            data-value="nodejs"
                            onClick={handleNavbarButtonClick}
                            style={{ textDecoration: "none" }}
                          >
                            Node Js
                          </a>
                          <a
                            href="#"
                            data-name="Java"
                            data-value="Java"
                            onClick={handleNavbarButtonClick}
                            style={{ textDecoration: "none" }}
                          >
                            Java
                          </a>
                          <a
                            href="#"
                            data-name="Nest"
                            data-value="Nest"
                            onClick={handleNavbarButtonClick}
                            style={{ textDecoration: "none" }}
                          >
                            Nest Js
                          </a>
                        </div>

                        <div className="inner-sub-menu">
                          <span>Mobile</span>
                          <a
                            href="#"
                            data-name="Ios"
                            data-value="Ios"
                            onClick={handleNavbarButtonClick}
                            style={{ textDecoration: "none" }}
                          >
                            iOS
                          </a>
                          <a
                            href="#"
                            data-name="Android"
                            data-value="Android"
                            onClick={handleNavbarButtonClick}
                            style={{ textDecoration: "none" }}
                          >
                            Android
                          </a>
                          <a
                            href="#"
                            data-name="React Native"
                            data-value="react-native"
                            onClick={handleNavbarButtonClick}
                            style={{ textDecoration: "none" }}
                          >
                            React Native
                          </a>
                          <a
                            href="#"
                            data-name="Flutter"
                            data-value="Flutter"
                            onClick={handleNavbarButtonClick}
                            style={{ textDecoration: "none" }}
                          >
                            Flutter
                          </a>
                        </div>
                      </div>
                    </li>

                    <Button
                      className={classNamees.ButtonStyle}
                      component={NavLink}
                      to="/blogs"
                      style={{ background: "none" }}
                    >
                      {t("blogs")}
                    </Button>

                    <Button
                      className={classNamees.ButtonStyle}
                      component={NavLink}
                      to="/careers"
                      style={{ background: "none" }}
                    >
                      <div className="contact"> {t("careers")} </div>
                    </Button>

                    <Button
                      className={classNamees.ButtonStyle}
                      component={NavLink}
                      to="/contact"
                      style={{ background: "none" }}
                    >
                      {t("contact")} {/* Contact{" "} */}
                    </Button>
                  </ul>
                </nav>
              </div>
            </div>
            <IconButton
              className={classNamees.sectionMobile}
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
            >
              <HiOutlineMenuAlt3
                style={{ paddingRight: "1rem", fontSize: "2rem" }}
              />
            </IconButton>
            <Modal
              open={open}
              onClose={handleDrawerClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Drawer
                className={classNamees.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                classNamees={{
                  paper: classNamees.drawerPaper,
                }}
                onClose={() => setOpen(false)}
              >
                <SideDrawer close={handleDrawerClose} />
              </Drawer>
            </Modal>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default Navbar;
