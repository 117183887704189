import React from "react";
import "./OutSourcing.css";
import Lottie from "react-lottie";
import animationData from "../../../assets/OutsourcingAnimation.json";
import Development1 from "../../../images/Services/Development1.svg";
import Development2 from "../../../images/Services/development2.svg";
import DigitalMarketing1 from "../../../images/Services/DigitalMarketing.svg";
import DigitalMarketing from "../../../images/Services/DigitalMarketing2.svg";
import Mobile1 from "../../../images/Services/Mobile1.svg";
import Mobile2 from "../../../images/Services/Mobile2.svg";
import Consulting1 from "../../../images/Services/Consulting1.svg";
import Consulting2 from "../../../images/Services/Consulting2.svg";
import Software1 from "../../../images/Services/Software1.svg";
import Software2 from "../../../images/Services/Software2.svg";
import Hoisting1 from "../../../images/Services/Hoisting1.svg";
import Hoisting2 from "../../../images/Services/Hoisting2.svg";
import Image1 from "../../../images/Services/1.png";
import Image2 from "../../../images/Services/2.png";
import Image3 from "../../../images/Services/3.png";

const OutSourcing = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div style={{ marginTop: "60px" }}>
      <section className="ConsultingHeader-sections">
        <div className="container" style={{ left: "0", border: "none" }}>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 OutSourcing-banner-col">
              <div className="OutSourcing-banner-content">
                <h1>
                  <span>Outsourcing Services</span>
                </h1>
                <p className="sub-p">
                  At Senwell Group Private Limited, we specialize in transforming your
                  digital aspirations into reality. As a seasoned provider of
                  comprehensive website outsourcing services, we bring
                  innovation, expertise, and efficiency to every project. Our IT
                  outsourcing services encompass application development,
                  infrastructure and software support, maintenance, data center
                  management, as well as other strategic initiatives.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 out-image">
              <div className="image">
                <Lottie options={defaultOptions} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ padding: "50px 0px" }}>
        <div className="container" style={{ left: "0", border: "none" }}>
          <h2 className="Outsourcing-header">
            Our Expert Outsourcing Solutions
          </h2>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 project-col">
              <img
                src={Development1}
                alt="Development1"
                className="first-img"
              />
              <img
                src={Development2}
                alt="Development2"
                className="second-img"
              />
              <h3>Web Development Outsourcing</h3>
              <p>
              Senwell Group Private Limited: Top Web Development Partner. Excellence in
                design, development, and SEO-friendly features. Elevate your
                brand with us.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 project-col">
              <img
                src={DigitalMarketing1}
                alt="digital1"
                className="first-img"
              />
              <img
                src={DigitalMarketing}
                alt="digital2"
                className="second-img"
              />
              <h3>Digital Marketing Outsourcing</h3>
              <p>
              Senwell Group Private Limited: Expert Digital Marketing. Boost revenue by
                converting targets to purchase. SEO, PPC, email marketing,
                reputation management, social media.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 project-col">
              <img src={Mobile1} alt="mobile1" className="first-img" />
              <img src={Mobile2} alt="mobile2" className="second-img" />
              <h3>Mobile Dev. Outsourcing</h3>
              <p>
              Senwell Group Private Limited: Mobile App Experts. 7+ years experience, 100+
                clients served. Elevate your business with us
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 project-col">
              <img src={Consulting1} alt="Consulting1" className="first-img" />
              <img src={Consulting2} alt="Consulting2" className="second-img" />
              <h3>Strategic Consulting Outsourcing</h3>
              <p>
              Senwell Group Private Limited: Global Consulting Experts. Optimize ROI,
                speed, and risk. App, web, cloud, BI, QA, IoT, and more. Trust
                our solutions for success.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 project-col">
              <img src={Software1} alt="Software1" className="first-img" />
              <img src={Software2} alt="Software2" className="second-img" />
              <h3>Software Outsourcing</h3>
              <p>
              Senwell Group Private Limited: Leading Pune outsourcing company. Tailored
                software solutions, overcome challenges, establish
                relationships. Expertise in ERP, online presence. Unlock sales
                potential with us.
              </p>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 project-col">
              <img src={Hoisting1} alt="Hoisting1" className="first-img" />
              <img src={Hoisting2} alt="Hoisting2" className="second-img" />
              <h3>Servers and Hosting Outsourcing</h3>
              <p>
              Senwell Group Private Limited: Pune's leading server hosting company.
                Outsourced management, lower costs, higher ROI. Trust our IT
                experts for full support, reduce operational costs.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container" style={{ left: "0", border: "none" }}>
          <h2 className="Figure-header">
            Quantifying IT Outsourcing with Senwell Group Private Limited
          </h2>
          <div className="row Figure-row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="overview-item">
                <p className="item-amout-title">1.5–2x</p>
                <p className="b-overview__para">
                  Increase in earnings stemming from novel IT-driven business
                  endeavors.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="overview-item">
                <p className="item-amout-title">Over 2x</p>
                <p className="b-overview__para">
                  Diminished IT service expenditures attributed to our optimal
                  methodologies.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="overview-item">
                <p className="item-amout-title">2.5x</p>
                <p className="b-overview__para">
                  Elevated business expansion resulting from liberated
                  resources.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container" style={{ left: "0", border: "none" }}>
          <h2 className="Support-Header">Cooperation Models We Support</h2>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="ss-item">
                <div className="item-layer">
                  <img src={Image1} alt="img1" className="logo-item" />
                  <p className="item-para">Staff Augmentation</p>
                  <p className="">
                    Cover resource or skill gaps of your in-house IT team with
                    our experts managed by you directly.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="ss-item">
                <div className="item-layer">
                  <img src={Image2} alt="img1" className="logo-item" />
                  <p className="item-para">Self-Managed Team</p>
                  <p className="">
                    Get a self-managed team led by Senwell Group Private Limited PM or Team
                    Lead to carry out your IT initiative.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="ss-item">
                <div className="item-layer">
                  <img src={Image3} alt="img1" className="logo-item" />
                  <p className="item-para">Full Outsourcing</p>
                  <p className="">
                    We take care of your specific IT function(s) with full
                    responsibility for their quality and related risks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OutSourcing;
