import React from "react";
import YouTube from "react-youtube";
import styled from "@emotion/styled";

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio - Adjust this value for different aspect ratios */
  height: 0;
`;

const VideoFrame = styled(YouTube)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const VideoComponent = ({ videoId }) => {
  const opts = {
    playerVars: {
      autoplay: 1,
      mute: 1,
    },
  };

  const onError = (event) => {
    // console.log('YouTube video error:', event.target);
  };

  return (
    <VideoContainer>
      <VideoFrame
        videoId={videoId}
        opts={opts}
        onError={onError}
        allow="autoplay"
      />
    </VideoContainer>
  );
};

export default VideoComponent;
