import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Container, Box } from "@material-ui/core";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { setDuration } from "../../../redux";
import { connect } from "react-redux";

import axios from "axios";

const useStyles = makeStyles({
  heading: {
    margin: "1rem 0px",
    fontSize: "32px",
    lineHeight: "44px",
  },
  subHeading: {
    fontSize: "18px",
    lineHeight: "28px",
  },
  slider: {
    margin: "2rem 0",
    textAlign: "center",
  },
});

const ProjectDuration = (props) => {
  const classNamees = useStyles();
  const [value, setValue] = React.useState(1);
  const [durationData, setDurationData] = useState("");
  const handleChange = (event) => {
    let newValue = event.target.value;

    if (typeof newValue === "number") {
      setValue(newValue);
      const id = Math.floor(Math.random() * 100000 + 1);
      axios
        .post("http://localhost:8000/Duration", {
          id: id,
          newValue,
        })
        .then((response) => console.log("My response", response))
        .catch((error) => {
          console.log("error occur while posting data", error);
        });
      console.log("Newwwwwwwwwww", newValue);
      props.setDuration(newValue);
    }
  };

  return (
    <Container maxWidth="lg">
      <h1 className={classNamees.heading}>05. Project Duration</h1>
      <p className={classNamees.subHeading}>
        How long do you expect to be working with your team?
      </p>
      <Box className={classNamees.slider}>
        <Typography id="non-linear-slider" gutterBottom>
          Month: {value}
        </Typography>
        <Slider
          value={value}
          min={1}
          step={1}
          max={12}
          onChange={handleChange}
          valueLabelDisplay="auto"
          aria-labelledby="non-linear-slider"
        />
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    durationData: state.durationData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setDuration: function (durationData) {
      dispatch(setDuration(durationData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDuration);
