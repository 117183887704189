import React, { useEffect, useState, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import './Terminal.css';

const blinkCursor = keyframes`
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: #adff2f; 
  }
`;

const TerminalWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  background-color: #04040477;
  border-radius: 20px;
  margin-top: 100px;
  height: 25rem;
  overflow: hidden;
`;

const TerminalContent = styled.div`
  p {
    color: #adff2f;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    padding: 20px;
  }

  .cursor {
    display: inline-block;
    border-right: 2px solid transparent;
    animation: ${blinkCursor} 0.75s step-end infinite;
    margin-left: 2px; /* Adjust cursor position */
  }
`;

const InputField = styled.input`
  background: transparent;
  border: none;
  outline: none;
  color: #adff2f;
  font-size: inherit;
  width: auto;
`;

const SenwellText = styled.pre`
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 2px;
  display: ${(props) => (props.show ? 'block' : 'none')}
`;

const Terminal = () => {
  const navigate = useNavigate();
  const [text, setText] = useState('');
  const [text1, setText1] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndex1, setCurrentIndex1] = useState(0);
  const [input, setInput] = useState('');
  const [showSenwellText, setShowSenwellText] = useState(false)

  const inputRef = useRef(null)

  useEffect(() => {
    const content = `
  X-host : <strong>Welcome To Senwell Group Private Limited</strong>,<br /><br />Have an idea in mind?<br /> <br />`;

    const interval = setInterval(() => {
      setText(content.substring(0, currentIndex + 1));
      setCurrentIndex(currentIndex + 1);

      if (currentIndex === content.length - 1) {
        clearInterval(interval);
        setShowSenwellText(true)
      }
    }, 50);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex]);

  useEffect(() => {
    if (showSenwellText) {
      const content = `
      Grow your business with us!<br />Press (y) for yes`;
  
      const interval = setInterval(() => {
        setText1(content.substring(0, currentIndex1 + 1)); 
        setCurrentIndex1(currentIndex1 + 1);
  
        if (currentIndex1 === content.length - 1) {
          clearInterval(interval);
        }
        if (inputRef.current) {
            inputRef.current.focus();
          }
      }, 50);
  
      return () => {
        clearInterval(interval);
      };
    }
  }, [currentIndex1, showSenwellText]);
  

  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (e.target.value === 'y' || 'Y') {
      navigate('/contact');
    }
  };

  return (
    <TerminalWrapper>
      <div className='terminal-header'>
        <div className='bg-danger terminal-btn'></div>
        <div className='bg-warning terminal-btn2 ms-2'></div>
        <div className='bg-success terminal-btn3 ms-2'></div>
      </div>
      <TerminalContent>
        <p>
          <div dangerouslySetInnerHTML={{ __html: text }} />
          <SenwellText show={showSenwellText}>
            {`
                                                                                                                                                                                                                                                                  
            SSSSSSSSSSSSSSS                                                                                             lllllll lllllll         SSSSSSSSSSSSSSS                  lllllll                          tttt            iiii                                                      
          SS:::::::::::::::S                                                                                            l:::::l l:::::l       SS:::::::::::::::S                 l:::::l                       ttt:::t           i::::i                                                     
         S:::::SSSSSS::::::S                                                                                            l:::::l l:::::l      S:::::SSSSSS::::::S                 l:::::l                       t:::::t            iiii                                                      
         S:::::S     SSSSSSS                                                                                            l:::::l l:::::l      S:::::S     SSSSSSS                 l:::::l                       t:::::t                                                                      
         S:::::S                eeeeeeeeeeee    nnnn  nnnnnnnn wwwwwww           wwwww           wwwwwww eeeeeeeeeeee    l::::l  l::::l      S:::::S               ooooooooooo    l::::l uuuuuu    uuuuuuttttttt:::::ttttttt    iiiiiii    ooooooooooo   nnnn  nnnnnnnn        ssssssssss   
         S:::::S              ee::::::::::::ee  n:::nn::::::::nnw:::::w         w:::::w         w:::::wee::::::::::::ee  l::::l  l::::l      S:::::S             oo:::::::::::oo  l::::l u::::u    u::::ut:::::::::::::::::t    i:::::i  oo:::::::::::oo n:::nn::::::::nn    ss::::::::::s  
          S::::SSSS          e::::::eeeee:::::een::::::::::::::nnw:::::w       w:::::::w       w:::::we::::::eeeee:::::eel::::l  l::::l       S::::SSSS         o:::::::::::::::o l::::l u::::u    u::::ut:::::::::::::::::t     i::::i o:::::::::::::::on::::::::::::::nn ss:::::::::::::s 
           SS::::::SSSSS    e::::::e     e:::::enn:::::::::::::::nw:::::w     w:::::::::w     w:::::we::::::e     e:::::el::::l  l::::l        SS::::::SSSSS    o:::::ooooo:::::o l::::l u::::u    u::::utttttt:::::::tttttt     i::::i o:::::ooooo:::::onn:::::::::::::::ns::::::ssss:::::s
             SSS::::::::SS  e:::::::eeeee::::::e  n:::::nnnn:::::n w:::::w   w:::::w:::::w   w:::::w e:::::::eeeee::::::el::::l  l::::l          SSS::::::::SS  o::::o     o::::o l::::l u::::u    u::::u      t:::::t           i::::i o::::o     o::::o  n:::::nnnn:::::n s:::::s  ssssss 
                SSSSSS::::S e:::::::::::::::::e   n::::n    n::::n  w:::::w w:::::w w:::::w w:::::w  e:::::::::::::::::e l::::l  l::::l             SSSSSS::::S o::::o     o::::o l::::l u::::u    u::::u      t:::::t           i::::i o::::o     o::::o  n::::n    n::::n   s::::::s      
                     S:::::Se::::::eeeeeeeeeee    n::::n    n::::n   w:::::w:::::w   w:::::w:::::w   e::::::eeeeeeeeeee  l::::l  l::::l                  S:::::So::::o     o::::o l::::l u::::u    u::::u      t:::::t           i::::i o::::o     o::::o  n::::n    n::::n      s::::::s   
                     S:::::Se:::::::e             n::::n    n::::n    w:::::::::w     w:::::::::w    e:::::::e           l::::l  l::::l                  S:::::So::::o     o::::o l::::l u:::::uuuu:::::u      t:::::t    tttttt i::::i o::::o     o::::o  n::::n    n::::nssssss   s:::::s 
         SSSSSSS     S:::::Se::::::::e            n::::n    n::::n     w:::::::w       w:::::::w     e::::::::e         l::::::ll::::::l     SSSSSSS     S:::::So:::::ooooo:::::ol::::::lu:::::::::::::::uu    t::::::tttt:::::ti::::::io:::::ooooo:::::o  n::::n    n::::ns:::::ssss::::::s
         S::::::SSSSSS:::::S e::::::::eeeeeeee    n::::n    n::::n      w:::::w         w:::::w       e::::::::eeeeeeee l::::::ll::::::l     S::::::SSSSSS:::::So:::::::::::::::ol::::::l u:::::::::::::::u    tt::::::::::::::ti::::::io:::::::::::::::o  n::::n    n::::ns::::::::::::::s 
         S:::::::::::::::SS   ee:::::::::::::e    n::::n    n::::n       w:::w           w:::w         ee:::::::::::::e l::::::ll::::::l     S:::::::::::::::SS  oo:::::::::::oo l::::::l  uu::::::::uu:::u      tt:::::::::::tti::::::i oo:::::::::::oo   n::::n    n::::n s:::::::::::ss  
          SSSSSSSSSSSSSSS       eeeeeeeeeeeeee    nnnnnn    nnnnnn        www             www            eeeeeeeeeeeeee llllllllllllllll      SSSSSSSSSSSSSSS      ooooooooooo   llllllll    uuuuuuuu  uuuu        ttttttttttt  iiiiiiii   ooooooooooo     nnnnnn    nnnnnn  sssssssssss    
                                                                                                                                                                                                                                                                                     
          `}
          </SenwellText>
          <div style={{display:"flex"}}>
          <div className='text1' dangerouslySetInnerHTML={{ __html: text1 }} />
            <span className="cursor"></span>
            <InputField
              type="text"
              className="inputField"
              value={input}
              onChange={handleInputChange}
              autoFocus
              ref={inputRef}
            />
            </div>
        </p>
      </TerminalContent>
    </TerminalWrapper>
  );
};

export default Terminal;


