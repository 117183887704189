import { Typography, Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    paddingTop: "2rem",
    [theme.breakpoints.up('sm')] : {
      width: '90%',
      margin: 'auto'
    },
  },

  customGridContainer:{
    paddingLeft: "2.1rem",
    marginTop: "20px",
  },
  WhysenwellsTitle: {
    fontFamily: "'Manrope', sans-serif",
    paddingLeft: "3rem",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "1.6rem",
    },
  },
  whysenwellswrapper: {
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      padding: "1.5rem",
    },
  },
  Whysenwellsicon: {
    color: "#3D8ED7",
    paddingBottom: "1rem",
    fontSize: "3.2rem",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0rem",
      fontSize: "2rem",
    },
  },
  Whysenwellstitle: {
    fontFamily: "'Manrope', sans-serif",
    fontSize:"18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4rem",
    },
  },
  Whysenwellspara: {
    fontWeight: "500",
    fontSize: "14px",
    textAlign: "left",
    marginTop:"11px",
    fontFamily: "'Manrope', sans-serif",
    paddingBottom:"20px"
  },
}));

const Whysenwells = ({ Whysenwell }) => {
  const classNamees = useStyles();

  return (
    <>
      <Container maxWidth="xl" style={{ background: "#f1f1f1" }}>
        <Container maxWidth="lg" className={classNamees.mainContainer}>
          <Typography variant="h4" className={classNamees.WhysenwellsTitle}>
            Why Senwell Group Private Limited?
          </Typography>
          <Grid container className={classNamees.customGridContainer}>
            {Whysenwell?.map((val) => (
              <>
                <Grid xs={12} sm={6} md={3} lg={3}>
                  <div className={classNamees.whysenwellswrapper}>
                    <div className={classNamees.Whysenwellsflexbox}>
                      <Typography>{val.icon} </Typography>
                      <div>
                        <Typography
                          variant="h6"
                          className={classNamees.Whysenwellstitle}
                        >
                          {val.title}
                        </Typography>
                        <p className={classNamees.Whysenwellspara}>{val.para}</p>
                      </div>
                    </div>
                  </div>
                </Grid>
              </>
            ))}
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default Whysenwells;
