import { Tabs, Tab } from "react-bootstrap";
import js from "../../../images/SoftwareDev/javascript-logo.svg";
import dotNet from "../../../images/SoftwareDev/DotNet.svg";
import csharp from "../../../images/SoftwareDev/csharp.svg";
import Nestjs from "../../../images/Technologies/Nest-bgremove.png";
import java from "../../../images/SoftwareDev/Java-icon.svg";
import swift from "../../../images/SoftwareDev/swift.svg";
import cplus from "../../../images/SoftwareDev/Cplusplus.svg";
import mongodb from "../../../images/SoftwareDev/mongodb.svg";
import mysql from "../../../images/SoftwareDev/MySQL.webp";
import postgresql from "../../../images/SoftwareDev/PostgreSQL.svg";
import oracle from "../../../images/SoftwareDev/OracleDB.svg";
import IMDB from "../../../images/SoftwareDev/IBMDb.png";
import Redis from "../../../images/SoftwareDev/Redis.svg";
import Elastic from "../../../images/SoftwareDev/Elastic.svg";
import angular from "../../../images/SoftwareDev/AngularJS.webp";
import react1 from "../../../images/SoftwareDev/react-colored.svg";
import bootstrap from "../../../images/SoftwareDev/bootstrap-colored.svg";
import aws from "../../../images/SoftwareDev/AWS-icon.svg";
import nodejs from "../../../images/SoftwareDev/nodejs-colored.svg";
import mern from "../../../images/SoftwareDev/mean-stack.png";
import vue from "../../../images/SoftwareDev/vuejs-colored.svg";

const IndustrySection = () => {
  return (
    <section className="industry-section section-padding">
      <div className="container" style={{ left: "0", border: "none" }}>
        <div className="mb-30">
          <h2 className="section-title text-center">
            Technologies & Platforms We Work With
          </h2>
        </div>
        <div className="row">
          <div className="col-12">
            <Tabs
              defaultActiveKey="languages"
              id="industry-tab"
              className="mb-3 border-0"
            >
              <Tab eventKey="languages" title="Languages">
                <ul>
                  <li>
                    <img src={dotNet} alt="dotNet" className="w-100" />
                    <span>.NET</span>
                  </li>
                  <li>
                    <img src={csharp} alt="csharp" className="w-100" />
                    <span>C Sharp</span>
                  </li>
                  <li>
                    <img src={js} alt="javascript" className="w-100" />
                    <span>JavaScript</span>
                  </li>
                  <li>
                    <img src={java} alt="java" className="w-100" />
                    <span>Java</span>
                  </li>
                  <li>
                    <img src={Nestjs} alt="Nestjs" className="w-100" />
                    <span>Nest Js</span>
                  </li>
                  <li>
                    <img src={swift} alt="Swift" className="w-100" />
                    <span>Swift</span>
                  </li>
                  <li>
                    <img src={cplus} alt="cplusplus" className="w-100" />
                    <span>C++</span>
                  </li>
                </ul>
              </Tab>
              <Tab eventKey="database" title="Database">
                <ul>
                  <li>
                    <img src={mongodb} alt="mongodb" className="w-100" />
                    <span>Mongodb</span>
                  </li>
                  <li>
                    <img src={mysql} alt="mysql" className="w-100" />
                    <span>mysql</span>
                  </li>
                  <li>
                    <img src={postgresql} alt="postgresql" className="w-100" />
                    <span>postgresql</span>
                  </li>
                  <li>
                    <img src={oracle} alt="oracle" className="w-100" />
                    <span>oracle</span>
                  </li>
                  <li>
                    <img src={IMDB} alt="Imdb" className="w-100" />
                    <span>IMDB</span>
                  </li>
                  <li>
                    <img src={Redis} alt="redis" className="w-100" />
                    <span>Redis</span>
                  </li>
                  <li>
                    <img src={Elastic} alt="Elastic" className="w-100" />
                    <span>Elastic</span>
                  </li>
                </ul>
              </Tab>
              <Tab eventKey="frameworks" title="Frameworks">
                <ul>
                  <li>
                    <img src={angular} alt="Angular" className="w-100" />
                    <span>Angular</span>
                  </li>
                  <li>
                    <img src={react1} alt="React" className="w-100" />
                    <span>React</span>
                  </li>
                  <li>
                    <img src={bootstrap} alt="bootstrap" className="w-100" />
                    <span>bootstrap</span>
                  </li>
                  <li>
                    <img src={nodejs} alt="nodejs" className="w-100" />
                    <span>nodejs</span>
                  </li>
                  <li>
                    <img src={aws} alt="aws" className="w-100" />
                    <span>aws</span>
                  </li>
                  <li>
                    <img src={mern} alt="mernstack" className="w-100" />
                    <span>mern</span>
                  </li>
                  <li>
                    <img src={vue} alt="vue" className="w-100" />
                    <span>vue</span>
                  </li>
                </ul>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndustrySection;
