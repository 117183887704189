import React from 'react';
import { DialogContent, Dialog,DialogTitle,DialogActions,Button } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

export default function Thanks({openModal,setOpenModal}) {

    const handleCloseModal = () => {
        setOpenModal(false)
    };
    
    return (
        <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle><h4 style={{fontWeight:"700"}}>Thanks for submitting the form!</h4></DialogTitle>
        <DialogContent>
          <p style={{color:"green"}}>Your submission was successful.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            <CloseIcon style={{color:"black"}} />
          </Button>
        </DialogActions>
      </Dialog>
    )
}


