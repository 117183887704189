import { HomepageHeroBanner } from "../images/images";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ComputerIcon from "@material-ui/icons/Computer";
import FlipToBackIcon from "@material-ui/icons/FlipToBack";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import InputIcon from "@material-ui/icons/Input";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import { CertificationFirstImage } from "../images/images";
import { CertificationThirdImage } from "../images/images";
import { TopAwardSecondImage } from "../images/images";
import { CertificationSixthImage } from "../images/images";
import Cronage from "../images/Pastproject/Cronage1.png";
import Fooz1 from "../images/Pastproject/Fooz1.png";
import mindlife from "../images/Pastproject/MindLife.png";
import Commodity from "../images/Pastproject/commodity master1.png";
// import foozLogo from "../images/Pastproject/foozLogo.png";
import foozLogo from "../images/logo/fooz.png";

import Fork from "../images/Pastproject/Fork1.png";
import Lotto from "../images/Pastproject/Lotto1.png";
import Lumiere from "../images/Pastproject/Lumiere1.png";
import parsl from "../images/Pastproject/parsl.JPG";
// import parsl from "../images/logo/parslImg.png";
import Virus from "../images/Pastproject/Virus1.png";

import cronage from "../images/logo/cronage.png";
// import fooz from "../images/logo/fooz.JPG";
import GreekVirous from "../images/logo/GreekVirous.png";
import Lottosocialogo from "../images/logo/Lottosocialogo.png";
import Lumieres from "../images/logo/Lumieres.png";
import parslImg from "../images/logo/parslImg.png";
import { t } from 'i18next';
import Translator from '../Translator'
import { useTranslation } from "react-i18next";
import i18next from '../Translator';

const HomePageData = [
  {
    HeroBanner: [
      {
        Page: "Home",
        title: "Software Development",
        spanText: "Company",
        para: "If you care about speed and quality — you’ve come to the right place.",
        button: "Hire Now",
        img: HomepageHeroBanner,
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle: <div> {t('getintouch')} </div>,
          // "Ready to start on your development or testing project? We are!",
        Button: <div> {t('getin')} </div>, //"Get In Touch",
      },
    ],
    OurClients: [
      {
        Image: cronage,
      },

      {
        Image: GreekVirous,
      },

      {
        Image: Lottosocialogo,
      },
      {
        Image: foozLogo,
      },
      {
        Image: Lumieres,
      },
      {
        Image: parslImg,
      },
    ],
    ProjectPortfollio: [
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry. Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", "NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies. The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fooz",
        image1: Fooz1,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
      {
        title:"MindLife",
        image1:mindlife,
        technologies:["Angular","MySQL","HTML","CSS"],
        para:"MindLife’s purpose is to enhance lives and empower people to improve their well-being. We integrate science, advanced psychology and healthcare with innovative digital technology and Artificial Intelligence (AI) to create appealing next-generation solutions in Mental Health & reltated Mind-Body condtions for both Clinicans and Patients"
      },
      {
        title:"CommodityMaster",
        image1:Commodity,
        technologies:["Angular","Nextjs","HTML","CSS"],
        para:"CommodityMasters provides our clients with reliable data, forecasts, analysis of key commodities, raw material price tracking, spend analysis, sourcing services and designing best-in-className procurement processes that revolves around profitability for us to help make sound business decisions. Our reports feature the Consensus Forecast (mean average), along with best- and worst-case scenarios."
      }
    ],
    Testimonials: [
      {
        label1: <div> {t('derick')} </div>,
          // "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label1: <div> {t('kevin')} </div>,
          // "“I’ve been working with Senwell Solutions for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label1: <div> {t('nicolo')} </div>,
          // "“We are enormously satisfied with Senwell Solutions as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    Forms: [
      {
        formTitle: <div> {t('ltalk')} </div>, //"Let's talk",
        formSubtitle: "Tell us about your project. Let’s get to work!",
      },
    ],
  },
];
export { HomePageData };

const services = [
  {
    id: 1,
    title: <div> {t('design')} </div>, //"Design",
    icon: <BorderColorIcon style={{ transform: "scale(1.5)" }} />,
    description: <div> {t('servicedesign')} </div>,
      // "Improving conversions and turning complex interactions into simple flows.",
    Link: "/services/ui-ux-design",
  },
  {
    id: 2,
    title: <div> {t('develop')} </div>, //"Development",
    icon: <DeveloperModeIcon style={{ transform: "scale(1.5)" }} />,
    description: <div> {t('servicedevelop')} </div>,
      // "Creating new software and integrating new features into existing suites.",
    Link: "/services/custom-software-development",
  },
  {
    id: 2,
    title: <div> {t('testing')} </div>, //"Testing & QA",
    icon: <CheckCircleOutlineIcon style={{ transform: "scale(1.5)" }} />,
    description: <div> {t('servicetesting')} </div>,
      // "Ensuring the quality of your projects and the reliability of your code.",
    Link: "/services/testing-and-qa",
  },
];
export { services };

const technologies = [
  {
    id: 1,
    title: "Front-end",
    icon: <ComputerIcon style={{ transform: "scale(1.5)" }} />,
    description: <div> {t('technofrontp')} </div>,
      // "Building flexible, cross-platform interfaces for your business.",
    Link: "/hire-developers/front-end",
  },
  {
    id: 2,
    title: "Back-end",
    icon: <FlipToBackIcon style={{ transform: "scale(1.5)" }} />,
    description: <div> {t('technobackp')} </div>,
      // "Ensuring the scalability and reliability of your websites and software.",
    Link: "/hire-developers/back-end",
  },
  {
    id: 3,
    title: <div> {t('phone')} </div>, //"Mobile",
    icon: <PhoneAndroidIcon style={{ transform: "scale(1.5)" }} />,
    description: <div> {t('technophonep')} </div>,
      // "Creating memorable mobile experiences and reliable applications.",
    Link: "/hire-developers/mobile",
  },
  {
    id: 4,
    title: <div> {t('quality')} </div>, //"Quality Assurance",
    icon: <InputIcon style={{ transform: "scale(1.5)" }} />,
    description: <div> {t('technoqualityp')} </div>,
      // "Eliminating technical debt and supporting the stability of your products.",
    Link: "/hire-developers/qa",
  },
  {
    id: 5,
    title: <div> {t('management')} </div>, //"Business & Management",
    icon: <TrendingUpIcon style={{ transform: "scale(1.5)" }} />,
    description: <div> {t('technomanagementp')} </div>,
      // "Providing your business and employees with reliable tools and suites.",
    Link: "/cms-crm-erp",
  },
];
export { technologies };

const industries = [
  {
    id: 1,
    title: <div> {t('ecom')} </div>, //"eCommerce",
    icon: <ShoppingCartIcon style={{ transform: "scale(1.5)" }} />,
    description: <div> {t('indusecom')} </div>,
      // "Launching new eCommerce platforms and implementing web shops.",
    Link: "/industries/ecommerce",
  },
  {
    id: 2,
    title: <div> {t('health')} </div>, //"Healthcare",
    icon: <DonutLargeIcon style={{ transform: "scale(1.5)" }} />,
    description: <div> {t('indushealth')} </div>,
      // "Building robust software for healthcare businesses and organisations.",
    Link: "/industries/healthcare",
  },
  {
    id: 3,
    title: <div> {t('enter')} </div>, //"Entertainment",
    icon: <PlayCircleFilledIcon style={{ transform: "scale(1.5)" }} />,
    description: <div> {t('indusenter')} </div>,
      // "Creating software and websites for media creation and distribution.",
    Link: "/industries/media-and-entertainment",
  },
  {
    id: 4,
    title: <div> {t('finance')} </div>, //"Finance & Banking",
    icon: <LocalAtmIcon style={{ transform: "scale(1.5)" }} />,
    description: <div> {t('indusfinance')} </div>,
    // "Developing CRM and ERP tools for businesses in the ",
    Link: "/industries/banking-and-finance",
  },
];
export { industries };

const Certification = [
  {
    img: CertificationFirstImage,
  },

  {
    img: CertificationThirdImage,
  },
  {
    img: TopAwardSecondImage,
  },
  { 
    img: CertificationSixthImage,
  }
];
export { Certification };
