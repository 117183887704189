import React from "react";
import { CompanyData } from "../../API/CompanyApi";
import Skill from "../../SharedModule/Skills";
import GetInTouch from "../../SharedModule/GetInTouch";
import Whysenwell from "../../SharedModule/Whysenwell";
import Lottie from "react-lottie";
import animationData from "../../assets/AboutAnimation.json";
import { Link } from "react-router-dom";
import "./Company.css";
import { Helmet } from "react-helmet";

const Company = () => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": "https://senwellsys.com/company",
    url: "https://senwellsys.com/company",
    name: "Senwell Group Pvt Ltd | About Us | Leading Software and SaaS Solutions",
    description:
      "Senwell Group Pvt Ltd delivers innovative custom software and SaaS solutions, empowering businesses worldwide with scalable, high-performance technology for growth and efficiency.",
    inLanguage: "en-US",
    organization: {
      "@type": "Organization",
      "@id": "https://senwellsys.com/#organization",
      name: "Senwell Group Pvt Ltd | Software Company | Pune",
      url: "https://senwellsys.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellsys.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption: "Senwell Group Pvt Ltd | Software Company | Pune",
      },
      potentialAction: [
        {
          "@type": "SearchAction",
          target: "https://senwellsys.com/company?s={search_term_string}",
          "query-input": "required name=search_term_string",
        },
      ],
    },
  };

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Senwell Group Private Limited | About Us</title>
        <meta
          name="description"
          content="Learn more about Senwell Group Pvt Ltd, a leading software development company providing innovative custom software and SaaS solutions."
        />
        <link rel="canonical" href="https://senwellsys.com/company" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Senwell Group Private Limited | About Us"
        />
        <meta
          property="og:description"
          content="Senwell Group Pvt Ltd is a top software company that delivers cutting-edge custom software and SaaS solutions, driving growth for businesses worldwide."
        />
        <meta property="og:url" content="https://senwellsys.com/company" />
        <meta property="og:site_name" content="Senwell Group Private Limited" />
        <meta
          property="og:image"
          content="https://senwellsys.com/static/media/happy.598d9f57e4b911432d5a.png"
        />
        <meta property="og:image:width" content="570" />
        <meta property="og:image:height" content="371" />
        <meta property="og:image:type" content="image/png" />

        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>

      {CompanyData?.map((value) => (
        <>
          <section className="Company-header-sections">
            <div className="container" style={{ left: "0", border: "none" }}>
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 Company-banner-col">
                  <div className="Company-banner-content">
                    <h1>About Us</h1>
                    <p className="sub-p">
                      At Senwell Group Private Limited, we are your partners in
                      navigating the dynamic landscape of technology. As a
                      leading Information Technology company, we specialize in
                      delivering tailored solutions that empower businesses to
                      thrive in the digital age.
                    </p>
                    <div className="Company-banner-button">
                      <Link to="/contact">
                        <a className="Company-theme-btn">Let's Talk</a>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 about-image">
                  <div className="image" style={{ pointerEvents: "none" }}>
                    <Lottie options={defaultOptions} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Skill Skill={value.skill} />
          <Whysenwell Whysenwell={value.whysenwell} />
          <GetInTouch GetInTouch={value.GetInTouch} />
        </>
      ))}
    </>
  );
};

export default Company;
