import React from "react";
import Career from "../../Modules/Company/Careers/Career";
import Growth from "../../Modules/Company/Careers/Growth";
import Hiring from "../../Modules/Company/Careers/Hiring";
import HiringProcess from "../../Modules/Company/Careers/HiringProcess";
import WeOffer from "../../Modules/Company/Careers/WeOffer";
import WorkWithUs from "../../Modules/Company/Careers/WorkWithUs";
import "../../Pages/Careers/Careers.css";
import InsideStory from "../../Modules/Company/Careers/InsideStory";
import { Link } from "react-router-dom";

const Careers = () => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  return (
    <>
      <section className="Career-Banner-section">
        <div className="container" style={{ left: "0", border: "none" }}>
          <div className="row" style={{ alignItems: "center" }}>
            <div className="col-12 col-md-12 col-lg-6 banner-content-col">
              <div className="banner-content-Careers">
                <h1> Join Our Team </h1>
                <p className="sub-p">
                  At Senwell Group Private Limited Empowering amazing <br />
                  individuals to thrive. Join us for career growth,
                  <br /> a vibrant community, and unleash your brilliance!.
                </p>

                <div className="Careers-banner-button">
                  <Link to="/contact">
                    <a className="Careers-theme-btn">Let's Talk</a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Career />
      <WeOffer />
      <Hiring />
      <HiringProcess />
      <Growth />
      <WorkWithUs />
      <InsideStory />
    </>
  );
};

export default Careers;
