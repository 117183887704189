import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { MdDoubleArrow } from "react-icons/md";
import { makeStyles } from "@material-ui/core";
import { Link, NavLink } from "react-router-dom";
import { ServiceMenu } from "../MenuApi/MenuitemsApi";
import { hasChildren } from "./utils";
const useStyles = makeStyles((theme) => ({
  Menuitems: {
    justifyContent: "space-between",
    fontFamily: "'Poppins', sans-serif",

    "&:hover": {
      color:"#0084ff !important",
      transform: "translateX(5px)",
   },
  },
}));

export default function App() {
  return ServiceMenu?.map((item, key) => <MenuItem key={key} item={item} />);
}

const MenuItem = ({ item }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

const SingleLevel = ({ item }) => {
  const classNamees = useStyles();
  let activeStyle = {
    color:"#7d828c !important"
  };

  return (
    <ListItem
      button
      className={classNamees.Menuitems}
      component={NavLink}
      to={item.Link}
      style={({ isActive }) => (isActive ? activeStyle : undefined)}
    >
      {item.title}
    </ListItem>
  );
};

const MultiLevel = ({ item }) => {
  const classNamees = useStyles();

  const { items: children } = item;
  let activeStyle = {
    color:"#7d828c !important"
  };
  return (
    <>
      <div className="subdropdown">
        <div className="subdropbtn">
          <ListItem
            button
            className={classNamees.Menuitems}
            component={NavLink}
            to={item.Link}
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <ListItemText primary={item.title} />
          </ListItem>
        </div>

        <List className="subdropdown-content" component="div" disablePadding>
          {children?.map((child, key) => (
            <div className="anchor">
              <MenuItem key={key} item={child} />
            </div>
          ))}
        </List>
      </div>
    </>
  );
};
