import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Container } from "@material-ui/core";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import "../App.css";
import { t } from 'i18next';

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: "#fafafa",
    padding: "3rem 0rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem",
    },
  },
  root: {
    // backgroundColor:"#15315e",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "38vw",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "32vw",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.between(1768, 2000)]: {
      maxWidth: "27vw",
    },
    maxWidth: "40vw",
    flexGrow: 1,
  },
  header: {
    display: "flex",

    flexDirection: "column",
    padding: "32px",
    height: "auto",
  },

  img: {
    
    height: "45px",
    marginRight: "1rem",
    borderRadius: "100%",
    width: "45px",
  },
  testimonials: {
    
    marginTop: "20px",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
  },
  testimonialPagenation: {
    justifyContent: "center",
    
    background: "none",
    marginTop: "2rem",
  },
  TestimonialBlock: {

    
    display: "flex",
    fontFamily: "'Poppins', sans-serif",

    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  TestimonialParagraph: {
    
    color: "#1c1c1c",
    fontSize: "1.1rem",
    lineHeight: "1.5rem",
    textAlign: "justify",
    // fontFamily: " 'Lora', serif",
    fontSize: "14px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "40vw",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "30vw",
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "26vw",
    },
  },
}));

function TestimonialsBlock({ Testimonials }) {
  const classNamees = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = Testimonials.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Container maxWidth={false} className={classNamees.mainWrapper}>
        <Container className={classNamees.TestimonialBlock}>
          <Box>
            <Typography
              variant="h4"
              style={{ fontFamily: " 'Ubuntu', sans-serif" }}
            > {t('testimonal')}
          
            </Typography>
            <p className={classNamees.TestimonialParagraph}> {t('demo')}
            </p>
            <p className={classNamees.TestimonialParagraph}> {t('demo1')}
            </p>
          </Box>

          <Box>
            <div className={classNamees.root}>
              <Paper square elevation={0} className={classNamees.header}>
                <Typography
                  variant="h6"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "14px",
                    textAlign:"justify"
                  }}
                >
                  {Testimonials[activeStep].label1}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "14px",
                    textAlign:"justify",
                    background:"#15315e"
                  }}
                >
                  {Testimonials[activeStep].label2}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontSize: "14px",
                    textAlign:"justify"
                  }}
                >
                  {Testimonials[activeStep].label3}
                </Typography>
                <Box className={classNamees.testimonials}>
                  <div>
                    {/* <img
                    src={Testimonials[activeStep].img}
                    className={classNamees.img}
                    alt=""
                  /> */}
                  </div>
                  <div>
                    <h5> Name: {Testimonials[activeStep].name}</h5>
                    <h6>{Testimonials[activeStep].post}</h6>
                  </div>
                </Box>
              </Paper>

              <MobileStepper
                className={classNamees.testimonialPagenation}
                steps={maxSteps}
                position="static"
                variant="text"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    {theme.direction === "rtl" ? (
                      <ArrowBackIcon />
                    ) : (
                      <ArrowForwardIcon />
                    )}
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    {theme.direction === "rtl" ? (
                      <ArrowForwardIcon />
                    ) : (
                      <ArrowBackIcon />
                    )}
                  </Button>
                }
              />
            </div>
          </Box>
        </Container>
      </Container>
    </>
  );
}

export default TestimonialsBlock;
