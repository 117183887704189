import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import { AiFillStar } from 'react-icons/ai';
import {FaAward} from 'react-icons/fa';
import {RiPlantLine} from 'react-icons/ri'
import {FaUserGraduate} from 'react-icons/fa'
import React from "react";

const useStyles= makeStyles((theme) =>({
    container:{
        display:'flex',
        justifyContent:'center',
    },
    main:{
       [theme.breakpoints.up('xs')]: {
        padding:'0rem 2rem 0rem 2rem',
    },
    },
    icons:{
      width:'2rem',
      height:'50%',
      marginBottom:'14px',
      color:'#3D8ED7',
    } ,
    header:{
     padding:'2rem 2rem 0rem',
     width: '90%',
     margin: 'auto',
     paddingBottom:"1rem",
     [theme.breakpoints.down('xs')]: {
        padding:'4rem 1rem',
     }
    },
    title:{
      fontWeight:'700',
      padding: "0rem 5rem 1rem 2rem",
      fontFamily: "'Manrope', sans-serif",
      [theme.breakpoints.down('sm')]: {
        fontSize:"26px",
     }
    },
    subtitle:{
      fontWeight:'700',
      fontFamily: "'Manrope', sans-serif"
    },
    para:{
      lineHeight:'1.3rem',
      textAlign:'left',
      fontFamily: "'Manrope', sans-serif",
      fontSize:'14px',

      [theme.breakpoints.up('sm')]: {
        textAlign: "justify",
        hyphens: "auto",
        lineHeight: "19px",
        wordSpacing:"0px",
      }
    }
}))

const WeOffer = () => {
    const classNamees= useStyles();
  return (
<> 
    <Container maxWidth='xl' style={{background:'#f6f6f6',marginTop:"10px"}}>
        <Container maxWidth="lg" className={classNamees.header}>
          <Typography variant="h4" className={classNamees.title}>We Offer</Typography>
          <Grid container className={classNamees.container} >
            <Grid xs={12} sm={6} md={3} lg={3}>
              <div className={classNamees.main} >
              <AiFillStar className={classNamees.icons} />
              <Typography variant="h6" className={classNamees.subtitle}>Dynamic career</Typography>              
                <p style={{textAlign:"justify"}}className={classNamees.para}>
                  Utilize your skills and grab opportunities to build a
                  successful career doing what you love.
                </p>
              </div>
            </Grid>
            <Grid xs={12} sm={6} md={3} lg={3} >
              <div className={classNamees.main}>
                  <RiPlantLine className={classNamees.icons}/>
                  <Typography variant="h6" className={classNamees.subtitle}>Personal growth</Typography> 
                <p style={{textAlign:"justify"}} className={classNamees.para}>  
                  Pursue your individual goals with a team that supports your
                  desire for self-refinement.
                </p>
              </div>
            </Grid>
            <Grid xs={12} sm={6} md={3} lg={3}>
              <div className={classNamees.main}>
                  < FaUserGraduate className={classNamees.icons}/>
                  <Typography variant="h6" className={classNamees.subtitle}>Learning & mentorship</Typography> 
                <p style={{textAlign:"justify"}} className={classNamees.para}>
                  Improve your skills by working closely with experienced
                  professionals on diverse projects.
                </p>
              </div>
            </Grid>
            <Grid xs={12} sm={6} md={3} lg={3}>
              <div className={classNamees.main}>
                  <FaAward className={classNamees.icons}/>
                  <Typography variant="h6" className={classNamees.subtitle}>Latest technologies</Typography> 
                <p style={{textAlign:"justify"}} className={classNamees.para}>
                  Face new challenges and implement your ideas using innovative
                  practices and technologies.
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
    </Container>
    </>
  );
};

export default WeOffer;
