import React from "react";
import { Container, Grid, Typography, Box,Button,Breadcrumbs,} from "@material-ui/core";
import CodeIcon from "@material-ui/icons/Code";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  ServicesWrapper: {
    padding: "2rem 5rem",
    color: "#000",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem",
    },
  },
  gridContainer: {
    [theme.breakpoints.down("sm")]: {
  
    },
  },
  title: {
    textAlign: 'center',
    fontFamily: "'Manrope', sans-serif",
    [theme.breakpoints.up('sm')]: {
        textAlign: 'center',
        fontSize: '32px !important'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '32px !important'
  },
  },
  webDiv: {
    width: '95%',
    margin: 'auto'
  },
  webPara: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
        padding: '0 8rem 2rem 8rem' ,
        textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "15px",
      textAlign: "justify",
      wordSpacing: "-0.1em",
      hyphens: "auto",
      marginTop: "15px",
  },
  },
  servicesContainer: {
    padding: "7rem 2rem 1rem 2rem",
    width: "100%",
    height: "15rem",
    color: "#fff",
    background: "#0070C0",


  },
  Icons: {
    color: "#fff",
  },
  servicetitle: {
    color: "#fff",
    margin: "1rem 1rem 0rem",
    fontFamily: "'Manrope', sans-serif",
  },
  serviceparagram:{
     display:"flex",
     alignItems:"center",
     justifyContent:"center",
  }
}));
const TypeOfTesting = () => {
  const classNamees = useStyles();
  return (
    <>
      <Container className={classNamees.ServicesWrapper} maxWidth="lg">
        <div className={classNamees.webDiv}>
          <Typography className={classNamees.title} variant="h4">Types Of Testing</Typography>
          <p className={classNamees.webPara} >
            Over years of delivering software QA services, we’ve matured our
            processes and built a strong expertise in various types of manual
            and automated testing.
          </p>
          <Grid container>
            <Grid item xs={12}  sm={6} md={4} lg={4} className={classNamees.gridContainer} >
              <div className="containerWrapper">
                <div className={classNamees.servicesContainer}>
                  <Typography style={{ fontFamily: "'Manrope', sans-serif",}} variant="h5">Functionality testing</Typography>
                  <div className="overlay">
                    <Typography variant="h5" className={classNamees.servicetitle}>
                      Functionality testing
                    </Typography>
                    <p className={classNamees.serviceparagram}>
                      We will make sure every feature of your software works as
                      intended. Moreover, we’ll help you find unconventional
                      usage cases to make sure that your software still
                      functions when users get creative.
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} className={classNamees.gridContainer} >
              <div className="containerWrapper">
                <div className={classNamees.servicesContainer}>
                  <Typography style={{ fontFamily: "'Manrope', sans-serif", }} variant="h5">Compatibility testing</Typography>
                  <div className="overlay">
                    <Typography variant="h5" className={classNamees.servicetitle}>
                      Compatibility testing
                    </Typography>
                    <p className={classNamees.serviceparagram}>
                      Avoid alienating users because your software flies on one
                      platform but underperforms on another. We can test on over
                      250 physical devices to ensure smooth operation on any
                      platform.
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              className={classNamees.gridContainer}
            >
              <div className="containerWrapper">
                <div className={classNamees.servicesContainer}>
                  <Typography style={{ fontFamily: "'Manrope', sans-serif", }} variant="h5">UI/UX</Typography>
                  <div className="overlay">
                    <Typography variant="h5" className={classNamees.servicetitle}>
                      UI/UX
                    </Typography>
                    <p className={classNamees.serviceparagram}>
                      We help you avoid the risk of creating highly functional
                      software nobody likes using by checking every screen,
                      clicking every button, and making sure it’s a positive
                      user experience.
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              className={classNamees.gridContainer}
            >
              <div className="containerWrapper">
                <div className={classNamees.servicesContainer}>
                  <Typography style={{ fontFamily: "'Manrope', sans-serif", }} variant="h5">Performance testing</Typography>
                  <div className="overlay">
                    <Typography variant="h5" className={classNamees.servicetitle}>
                      Performance testing
                    </Typography>
                    <p className={classNamees.serviceparagram}>
                      Be confident that your project will perform well under
                      pressure. We put your software through load, stress,
                      spike, and endurance tests to ensure it will support the
                      growth of your business.
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              className={classNamees.gridContainer}
            >
              <div className="containerWrapper">
                <div className={classNamees.servicesContainer}>
                  <Typography style={{ fontFamily: "'Manrope', sans-serif", }} variant="h5">Security testing</Typography>
                  <div className="overlay">
                    <Typography variant="h5" className={classNamees.servicetitle}>
                      Security testing
                    </Typography>
                    <p className={classNamees.serviceparagram}>
                      Ensure the GDPR compliance of your website and the
                      thorough security of your software. Our ISO 27001
                      certified team can help you establish confidence in the
                      security of your software project.
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              className={classNamees.gridContainer}
            >
              <div className="containerWrapper">
                <div className={classNamees.servicesContainer}>
                  <Typography style={{ fontFamily: "'Manrope', sans-serif", }} variant="h5">Localization testing</Typography>
                  <div className="overlay">
                    <Typography variant="h5" className={classNamees.servicetitle}>
                      Localization testing
                    </Typography>
                    <p className={classNamees.serviceparagram}>
                      You can’t automate a human perspective. Localization
                      testing helps you ensure that your users will have an
                      equally pleasant user experience regardless of what
                      language they prefer their UI in.
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default TypeOfTesting;
