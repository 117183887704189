import React from "react";
import { HomePageData } from "../API/HomeApi";
import WeHelpWith from "../Modules/Home/WeHelpWith";
import Workflow from "../Modules/Home/Workflow";
import Achievements from "../SharedModule/Achievements";
import Slider from "../Modules/Slider";
import Carousel from "../Modules/Home/Carousel";
import OurClientNew from "../SharedModule/OurClientNew";
import OurWork from "../Modules/Home/OurWork";
import TestimonialNew from "../SharedModule/TestimonialNew";
import WhoWeAre from "../Modules/WhoWeAre/WhoWeAre";
import { Helmet } from "react-helmet";
import ChritsmasHome from "../Modules/ChritsmasSlider/Chritsmas";
import DiwaliSlider from "../Modules/DiwaliSlider/DiwaliSlider";
const Home = () => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": "https://senwellsys.com/",
    url: "https://senwellsys.com/",
    name: "Senwell Group Pvt Ltd | Custom Software Solutions and SaaS Development",
    description:
      "Senwell Group Pvt Ltd is a leading web and app development firm, dedicated to driving business growth through innovative solutions.",
    inLanguage: "en-US",
    organization: {
      "@type": "Organization",
      "@id": "https://senwellsys.com/#organization",
      name: "Senwell Group Pvt Ltd | Software Company | Pune",
      url: "https://senwellsys.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellsys.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption: "Senwell Group Pvt Ltd | Software Company | Pune",
      },
      potentialAction: [
        {
          "@type": "SearchAction",
          target: "https://senwellsys.com/?s={search_term_string}",
          "query-input": "required name=search_term_string",
        },
      ],
    },
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Senwell Group Pvt Ltd | Custom Software Solutions and SaaS Development
        </title>
        <meta
          name="description"
          content="Senwell Group Pvt Ltd is a leading web and app development firm, dedicated to driving business growth through innovative solutions."
        />
        <link rel="canonical" href="https://senwellsys.com/" />
        <meta
          name="keywords"
          content="web application development,	
                   web designer,	
                   IT company,	
                   IT services,	
                   IT support,	
                   mobile and web application development,	
                   Cybersecurity,
                   web application development services,	
                   web application development company in india,	
                   web application development company,
                   web development company,
                   web development company in india,	
                   web development agency,
                   web development company in pune,
                   software development company,
                   custom software development company,
                   it staffing,
                   it staffing services,	
                   software development company in india,
                   software development company in pune,
                   software development agency,
                   custom software development agency,
                   software outsourcing services,	
                   web development outsourcing,
                   software development agency in india,
                   best software development agency,
                   software development agency india,
                   software development agency pune,	
                   healthcare software development agency,
                   healthcare software development company,
                   healthcare software development,
                   top software development agency,
                   outsource software development agency,
                   kotlin software development agency,
                   flutter software development agency,
                   angular software development agency,
                   react native software development agency,
                   hire react js software development agency,
                   react js software development agency,
                   transportation and logistics software development agency,	
                   e-commerce software development agency,
                   b2b software development agency,
                   software development agency for startups,
                   financial software development agency,
                   hire custom software development agency,
                   hire android software development agency,
                   fintech software development agency,
                   blockchain software development agency,
                   corporate software development agency,
                   crm software development agency in india,
                   fintech application development services,
                   top software development companies in world,
                   digital marketing company,
                   digital marketing company in india,
                   digital marketing company in pune,
                   top digital marketing company in india,	
                   best digital marketing company,	
                   digital marketing company india,	
                   digital marketing agency,	
                   digital marketing agency in india,	
                   best digital marketing agency,	
                   digital marketing agency in pune,
                   digital marketing services,
                   digital marketing services in india,
                   digital marketing services in pune"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Senwell Group Pvt Ltd | Custom Software Solutions and SaaS Development"
        />
        <meta
          property="og:description"
          content="Senwell Group Pvt Ltd is a leading web and app development firm, dedicated to driving business growth through innovative solutions."
        />
        <meta property="og:url" content="https://senwellsys.com/" />
        <meta property="og:site_name" content="Senwell Group Private Limited" />
        <meta
          property="og:image"
          content="https://senwellsys.com/static/media/Who2.57b4b57cb37a7772dc26.webp"
        />
        <meta property="og:image:width" content="547" />
        <meta property="og:image:height" content="563" />
        <meta property="og:image:type" content="image/webp" />

        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>
      <Slider />
      <WhoWeAre />
      <Workflow />
      <WeHelpWith />
      <OurClientNew />
      <TestimonialNew />
      <Achievements />
      <Carousel />
      <OurWork />
    </>
  );
};

export default Home;
