import { ReactBanner } from "../images/images";
import { CmmiImage } from "../images/images";
import { healthCare } from "../images/images";
import { Union } from "../images/images";
import { LogoImage } from "../images/images";
import { Ecommerce } from "../images/images";
import { firstClient } from "../images/images";
import { secondClient } from "../images/images";
import { thirdClient } from "../images/images";
import { fourthClient } from "../images/images";
import { fifthClient } from "../images/images";
import { huffpostImg } from "../images/images";
import { Dashlane } from "../images/images";
import { Code_Better } from "../images/images";
import { Distractify } from "../images/images";
import { SiAdobephotoshop } from "react-icons/si";
import SearchIcon from "@material-ui/icons/Search";
import StarIcon from "@material-ui/icons/Star";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import { SiAdobeillustrator } from "react-icons/si";
import { SiAdobeaftereffects } from "react-icons/si";
import { SiJavascript } from "react-icons/si";
import { FaAngular } from "react-icons/fa";
import { SiTypescript } from "react-icons/si";
import { FaVuejs } from "react-icons/fa";
import { FaReact } from "react-icons/fa";
import ECommerce1 from "../images/Industries/ECommerce1.jpg";
import Healthcare1 from "../images/Industries/Healthcare1.jpg";
import Entertainmentimg from "../images/Industries/Entertainmentimg.jpg";
import Bankingimg from "../images/Industries/Bankingimg.jpeg";
import businessideas from "../images/Industries/businessideas.jpg";
import BlockChainimg from "../images/Industries/BlockChainimg.jpg";
import Telecomimg from "../images/Industries/Telecomimg.jpeg";
import "../App.css";
import Virus from "../images/Pastproject/Virus.png";
import interactivedesign from "../images/Industries/interactivedesign.png";
import HealthSub from "../images/Industries/HealthSub.jpg";
import EntertainmentImg from "../images/Industries/EntertainmentImg.png";
import BlockchainSub from "../images/Industries/BlockchainSub.jpg";
import StartupsSub from "../images/Industries/StartupsSub.jpg";
import Telecom from "../images/Industries/Telecom.png";
import HealthcareImage from "../images/Industries/HealthcareImage.jpg";
import HealthcareImage1 from "../images/Industries/HealthcareImage1.jpg";
import EntertainmentImages from "../images/Industries/EntertainmentImages.jpg";
import Exportsales from "../images/Industries/Exportsales.jpg";
import Shopingcard from "../images/Industries/Shopingcard.png";
import Paymentinfo from "../images/Industries/Paymentinfo.jpg";
import patentImage from "../images/Industries/patentImage.jpg";
import digitalsignImage from "../images/Industries/digitalsignImage.jpg";
import creationImage from "../images/Industries/creationImage.png";
import BankingImage from "../images/Industries/BankingImage.jpg";
import bankingplatform from "../images/Industries/bankingplatform.jpg";
import DigitalTransformations from "../images/Industries/DigitalTransformations.png";
import consultingstartupImage from "../images/Industries/consultingstartupImage.jpg";
import productdevelopment from "../images/Industries/productdevelopment.jpg";
import growthhacking from "../images/Industries/growthhacking.png";
import BlockchainImage from "../images/Industries/BlockchainImage.jpg";
import trainingworkshop from "../images/Industries/trainingworkshop.jpg";
import blockchainservices from "../images/Industries/blockchainservices.jpg";
const IndustriesData = [
  {
    id: 1,
    name: "ecommerce",

    HeroBanner: [
      {
        name: "ecommerce",
        Page: "Ecommerce Software Development",
        breadcrums: "Industries",
        Link: "/industries",
        title: "ECommerce Software Development Services",
        button: "Let's talk",
      },
    ],
    industriesBanner: [
      {
        image: ECommerce1,
        title: "Start Selling Online",
        para1:
          "The most basic step a business can take to ensure a good ROI is to launch an online shop or eCommerce platform. Whether your goals are local or worldwide, an online shop is the most convenient approach to reach out to customers and display your items in the best light. Our eCommerce software development company's bread and butter are creating online retail solutions. Senwell Group Private Limited' all-encompassing approach guarantees that your digital shop drives sales and converts visitors into buyers. Our designers will choose the best arrangement for your items. Our engineers will assist you in selecting the best platform. Finally, our quality assurance staff will ensure that your business runs flawlessly. Expanding to online sales is just one contact form away!",
      },
    ],
    idealSolution: [
      {
        title: "Ideal eCommerce Solutions",
        idealSolutionList: [
          {
            Number: "01",
            title: "E-commerce Website Design ",
            description:
              "Showcase your items in the best possible light. Our UI/UX team will employ industry best practices to assist you in converting casual visitors to frequent consumers and loyal customers.",
          },
          {
            Number: "02",
            title: "Development of a Quick Shop",
            description:
              "If getting up and running quickly is a priority, you've come to the ideal eCommerce software development firm. We have a lot of expertise with eCommerce solutions and can get you up and running quickly!",
          },
          {
            Number: "03",
            title: " E-commerce Module Development",
            description:
              "We've combined the most popular features into modules, allowing us to install them fast and at a substantially cheaper cost for you, based on our experience with over 250 e-commerce websites.",
          },
          {
            Number: "04",
            title: "Complete Development",
            description:
              "We can assist you throughout the development process, from first wireframes to final load tests to guarantee your eShop can manage millions of concurrent requests.",
          },
          {
            Number: "05",
            title: "E-commerce Quality Assurance & Screening",
            description:
              "We will test your eCommerce website on a variety of physical devices to guarantee that it runs smoothly. We also test at every level of development to ensure excellent quality, save time, and save expenses.",
          },
          {
            Number: "06",
            title: "Easy Store Administration",
            description:
              "What's the use of having an online store if you can't readily update your inventory? We make sure that managing your digital store is straightforward and intuitive, so you can focus on operating your business ",
          },
          {
            Number: "07",
            title: "Integration of ERP/CRM",
            description:
              "Connect your e-commerce website to any ERP/CRM system your company needs. This can assist you in automating inventory changes, managing customer information, and running a seamless digital business.",
          },
          {
            Number: "08",
            title: "Mobile e-commerce Development",
            description:
              "Serve your consumers no matter where they are or what device they are now using. Our eCommerce software development and testing team will ensure that your online store looks great on any device and on any platform.",
          },
          {
            Number: "09",
            title: "Optimization for e-commerce",
            description:
              "Is creating and launching an e-commerce site insufficient? Our experts can assist you with strengthening your SEO, implementing chatbots, and combining your store with the most up-to-date and effective third-party services.",
          },
        ],
      },
    ],
    IndusriesPartnertitle: "Healthcare Projects from a Reliable Partner",
    IndustriesPartnerPara:
      "We build highly secure, accessible, and intuitive healthcare solutions to the point they don’t require exhaustive employee training at the deployment stage. Whether you need a single engineer or an entire development team, we will have you covered! ",
    IndustriesPartner: [
      {
        title: "All-inclusive teams",
        description:
          "In addition to programmers, we also provide business analyst, project manager, UI/UX designer, system administrator, and any other role that your project might ever need.",
        img: "",
      },
      {
        title: "Quick project start",
        description:
          "We start estimating your project immediately after you send us the needed information through the contact form on this web page.",
        img: "",
      },
      {
        title: "Long-term partnerships",
        description:
          "Our current customer return rate is about 70% and we make everything we can to make it even higher. We’re proud to say most of our clients do not stop on one project with us.",
        img: "",
      },
    ],

    GetInTouch: [
      {
        GetInTouchTitle:
          "Start generating more sales by launching your web marketplace! ",
        Button: "Get In Touch",
      },
    ],
    ProjectPortfolliotitle: "Our eCommerce Solutions",
    ProjectPortfollio: [
      {
        title: "Advanced Order Processing Solutions",
        image: Exportsales,
        para: "With our outstanding order frameworks, you may have zero-error order management. We ensure that your e-commerce website performs well under pressure, whether it's for product returns or unique product delivery requirements.",
      },
      {
        title: "Shopping cart solutions ",
        image: Shopingcard,
        para: " Senwell Group Private Limited is one of the finest online shopping cart solution suppliers in the world, having delivered a large number of e-commerce websites. These shops expand depending on a range of elements like functionality, simplicity of management, adaptability to multiple devices, and administration.",
      },
      {
        title: "Payment gateway solution  ",
        image: Paymentinfo,
        para: "For efficient transactions, our unique payment gateway connection may be done with your website or application. Senwell Group Private Limited is one of the best payment gateway providers in the world, with multicurrency support, safe payment, and easier integration.",
      },
    ],

    GetInTouchSecondbanner: [
      {
        GetInTouchTitle:
          "Create a digital shop to turn casual visitors into loyal customers!",
        Button: "Let's Talk",
        image: interactivedesign,
        para: "Benefit from a versatile, scalable, and dependable online shop that was custom-built to match your unique client requirements by an online software development firm with over years of professional experience",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Choose Us",
        WhyChooseUs: [
          {
            icons: "",
            title: "Start with confidence.",
            para: "Our goal is to help you launch quickly and make sure you’ll want to work with us again.",
          },
          {
            icons: "",
            title: "100% Transparency",
            para: "Clear communication, flexible pricing, and full dedication to your success.",
          },
          {
            icons: "",
            title: "Excellent Code Quality",
            para: "We check the quality of our code at every step of development to ensure best results.",
          },
          {
            icons: "",
            title: "Cross-domain expertise",
            para: "Need services beyond front-end development? We will have you covered.",
          },
        ],
      },
    ],
    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },

  {
    id: 2,
    name: "healthcare",

    HeroBanner: [
      {
        name: "healthcare",
        Page: "Healthcare Software Development",
        breadcrums: "Industries",
        Link: "/industries",
        title: "Healthcare Software Development & Services",
        button: "Let's talk",
        img: healthCare,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "Outsource healthcare software development to a company with niche experts and decades of experience.",
      },
    ],
    industriesBanner: [
      {
        image: Healthcare1,
        title: "Software That Enhances Patient Care",
        para1:
          "The healthcare sector wants software that automates, optimizes, or otherwise simplifies daily operations because it is such a high-stakes industry that rely significantly on data organization and processing. Outsource healthcare software development to a firm that has decades of expertise providing care in the creation of dependable digital health solutions. ",
        para2:
          "We assist hospitals and personal care facilities improve their efficiency by reducing manual tasks, streamlining workflows, and making healthcare more patient-friendly.",
      },
    ],
    idealSolution: [
      {
        title: "Healthcare Solutions We Deliver",
        idealSolutionList: [
          {
            Number: "01",
            title: "CRM for Patient Management",
            description:
              "Our CRM solutions make patient information available while maintaining high levels of security.",
          },
          {
            Number: "02",
            title: "Data Management for Patients",
            description:
              "We can provide HL7-integrated EHR and EMR systems that adhere to industry requirements for interoperability and data security.",
          },

          {
            Number: "03",
            title: "Complete Development",
            description:
              "We create products from the ground up, including market research, healthcare-specific UI/UX, development, QA, and integration.",
          },
          {
            Number: "04",
            title: "Mobile Healthcare solutions",
            description:
              "Mobile applications for arranging appointments, getting updates, and preserving personal health records at the consumer level.",
          },
          {
            Number: "05",
            title: "Telehealth and Patient Engagement",
            description:
              "We can help you engage with patients directly and remotely, from doctor-patient contact to complete telemedicine capabilities.",
          },
          {
            Number: "06",
            title: "Internet of Things in Healthcare",
            description:
              "Utilize the Smart sensors in healthcare to their greatest potential, from in-hospital smart device management to consumer-level gadgets.",
          },
          {
            Number: "07",
            title: "Medical Portals and Content Management Systems",
            description:
              "Create affordable and easy-to-use clinical portals that will allow both patients and doctors to streamline healthcare communication.",
          },
          {
            Number: "08",
            title: "Healthcare software QA",
            description:
              "Our service gives testing and quality plan consists to detect any flaws in your software and deliver prompt responses.",
          },
          {
            Number: "09",
            title: "Healthcare software optimization",
            description:
              "We can make your existing software more SEO friendly and improve your collaborative working process.",
          },
        ],
      },
    ],

    GetInTouch: [
      {
        GetInTouchTitle:
          "Begin your healthcare software development project in as little as two weeks after contacting us!",
        Button: "Work with us",
      },
    ],
    ProjectPortfolliotitle: "Revenue Cycle Management",
    ProjectPortfollio: [
      {
        title: "VirusGeeks",
        image: Virus,
        para: "Healthcare Platform Graphical user interface, application, table Description automatically generatedVirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Senwell Group Private Limited",
        image: HealthcareImage,
        para: "system is an edge unique RCM solution that discovers and resolves redundancies in processes to accomplish the triple goal of cost savings, increased revenue, and improved workflows.  We collaborate with globally optimal partners in the industry to provide the most cutting-edge RCM technologies.",
      },
      {
        title: "Medical care platform",
        image: HealthcareImage1,
        para: " Our totally modern se Organization medical care as a Software offers future-proof medical care systems by pushing the boundaries of modularity, customization, agility, the flexibility of integration, intuitive user experience, availability, and scalability. Senwell Group Private Limited is based on SaaS solutions and has its own custom-built components. Claims Processing and Management Services Module is a Senwell Group Private Limited provider. ",
      },
      {
        title: "Patient Enrollment",
        image: patentImage,
        para: " We thoroughly check them for authenticity, and any anomalies are reported to the appropriate doctor’s offices. As a result, our smart Patient Enrolment system eliminates any manipulation of demographic and insurance information, providing a basis for error-free medical billing submission and realization. These services are particularly useful when switching from one HL7-compliant program to another. Physicians have saved time, money, and effort owing to audits and regular updates. Our Patient Enrolment, which is part of our full Medical Billing Revenue Cycle Management, is scalable and versatile with a variety of firm disciplines and dimensions. ",
      },
    ],

    IndusriesPartnertitle: "Healthcare Projects from a Reliable Partner",
    IndustriesPartnerPara:
      "We build highly secure, accessible, and intuitive healthcare solutions to the point they don’t require exhaustive employee training at the deployment stage. Whether you need a single engineer or an entire development team, we will have you covered! ",
    IndustriesPartner: [
      {
        title: "All-inclusive teams",
        description:
          "In addition to programmers, we also provide business analyst, project manager, UI/UX designer, system administrator, and any other role that your project might ever need.",
        img: "",
      },
      {
        title: "Quick project start",
        description:
          "We start estimating your project immediately after you send us the needed information through the contact form on this web page.",
        img: "",
      },
      {
        title: "Long-term partnerships",
        description:
          "Our current customer return rate is about 70% and we make everything we can to make it even higher. We’re proud to say most of our clients do not stop on one project with us.",
        img: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle:
          "Outsource healthcare software development to get to market faster!",
        Button: "Let's Talk",
        image: HealthSub,
        para: "Highly secure, accessible, and intuitive to the point it doesn’t require employee training at the deployment stage. That’s what your product will be like if you choose us to develop it with.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Healthcare Providers Choose Senwell  ",
        WhyChooseUs: [
          {
            Number: "01",
            title: "Award-winning vendor",
            para: "Senwell Group Private Limited brand name was recognized by IT agencies more than once for its outstanding performance.",
          },
          {
            Number: "02",
            title: "100% Transparency",
            para: "As a product owner, you get direct access to the workflow so that you could check the results of our work on your project anytime.",
          },
          {
            Number: "03",
            title: "Strict quality control",
            para: "Quality assurance is our second specialization after programming, so your product will be tested thoroughly to say the least.",
          },
          {
            Number: "04",
            title: "Post-production support",
            para: "After your product finally sees the market, we continue to check how it works and provide updates when there’s a need.",
          },
        ],
      },
    ],
    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },

  {
    id: 3,
    name: "media-and-entertainment",

    HeroBanner: [
      {
        name: "media-and-entertainment",
        Page: " Media & Entertainment Solutions",
        breadcrums: "Industries",
        title: "Expert  Media & Entertainment Solutions",
        Link: "/industries",
        spanText: "Expert",
        title2: " Media & Entertainment Solutions",
        button: "Hire now",
        img: ReactBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "Focus on the creative while we take care of your tech and manage your IT needs.",
      },
    ],
    industriesBanner: [
      {
        image: Entertainmentimg,
        title: "Build software that makes an impression!",
        para1:
          "Increase the productivity of your entertainment firm with a software solution adapted to your company's particular demands! We have the technical abilities and subject understanding to engage your target audience with modern technology, innovative features, and long-lasting experiences. We can develop and create a cutting-edge media and entertainment solution for your company, through tiny information websites and mobile apps to production tools and massive media platforms. ",
        para2:
          "With our sophisticated project management solutions, we can also assist increase the security, speed, and flexibility of data and process management across your firm. Processes should be simplified and automated. Improve communication across departments. Improve your performance.",
      },
    ],
    idealSolution: [
      {
        title: "Ideal Media & Entertainment Solutions",
        idealSolutionList: [
          {
            Number: "01",
            title: "Integrated Platforms and Websites",
            description:
              "We provide a variety of unique and highly configurable media and entertainment web solutions to assist you in providing an engaging user experience and establishing a strong community.",
          },
          {
            Number: "02",
            title: "Systems for Content Management",
            description:
              "With robust content management tools and future-proof distribution systems, you can efficiently manage your communication channels and captivate the hearts of your target audience.",
          },
          {
            Number: "03",
            title: "Streaming Multimedia Software",
            description:
              "On PC and mobile devices, we build cutting-edge video and music streaming software that is fully prepared to give your users practical tools and excellent sources.",
          },
          {
            Number: "04",
            title: "Mobile Applications & Games",
            description:
              "Build a strong mobile presence and exceed market demand with our native and cross-platform tech knowledge and successful mobile-first business tactics.",
          },
          {
            Number: "05",
            title: "Third-party Integrations",
            description:
              "Integrate your entertainment solution with third-party APIs, CRM/ERP systems, social networks, or payment gateways to engage users and increase revenue",
          },
          {
            Number: "06",
            title: "Software for AR/VR",
            description:
              "Use immersive media and entertainment channels to their full capacity. With our well-designed, engaging, and cheap AR/VR games and tools, you can create unforgettable experiences.",
          },
          {
            Number: "07",
            title: "Rights and Digital Asset Management Tools",
            description:
              "We create comprehensive DAM systems and merciless DRM security technologies that let you manage materials effectively while keeping them safe from modifications and redistribution",
          },
          {
            Number: "08",
            title: "Software for video and music production",
            description:
              "Our professional developers can construct whatever production tools your company need, from high-end codecs and media players to advanced video editing software and digital audio workstations.",
          },
          {
            Number: "09",
            title: "Enhancement of System Performance",
            description:
              "By cleaning up your code, optimizing features and modules, and enhancing the cross-platform compatibility of your entertainment solution, we can help you increase user engagement.",
          },
        ],
      },
    ],

    GetInTouch: [
      {
        GetInTouchTitle:
          "Need help with a media or entertainment software development project?",
        Button: "Let's talk",
      },
    ],
    ProjectPortfolliotitle: "Featured Cases",
    ProjectPortfollio: [
      {
        title: "Digital Asset Management",
        image: EntertainmentImages,
        para: "We enable teams throughout the global label digital assets and deliver them via safe and searchable access to a DAM repository. To gather and monitor data from many sources, our experts link solutions with third-party systems like as CRM, ERP, as well as others.",
      },
      {
        title: "Video Creation & Distribution",
        image: creationImage,
        para: "We assist media companies in developing, producing, and distributing content for television and the web, assisting them in engaging viewers and reaching new audiences more quickly and easily than ever before. Our experts ensure that the best content is delivered to a multitude of platforms, on any platform, because in any environment. ",
      },
      {
        title: "Digital Signage",
        image: digitalsignImage,
        para: "Senwell's experts provide complete digital media management, publishing, and playback solutions for interconnected display screens. We create systems that offer consumers a variety of media types, screen customization possibilities, and high-quality audio/video output. Our expertise in video coding, including H.264,.265, and MPEG codecs, helps our clients achieve better compression algorithms results. ",
      },
    ],

    IndusriesPartnertitle: "Reasons to go with Senwell Group Private Limited",
    IndustriesPartnerPara:
      " that provides a full spectrum of services from design, development, and quality assurance to business & content management optimization, DevOps, and IT consulting. We are customer-obsessed professionals who only deliver positive experience and make sure clients come back for more. ",
    IndustriesPartner: [
      {
        title: "All-inclusive teams",
        description:
          "In addition to programmers, we also provide business analyst, project manager, UI/UX designer, system administrator, and any other role that your project might ever need.",
        img: "",
      },
      {
        title: "Quick project start",
        description:
          "We start estimating your project immediately after you send us the needed information through the contact form on this web page.",
        img: "",
      },
      {
        title: "Long-term partnerships",
        description:
          "Our current customer return rate is about 70% and we make everything we can to make it even higher. We’re proud to say most of our clients do not stop on one project with us.",
        img: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle:
          "Become the one source of entertainment for your users!",
        Button: "Get a quote",
        image: EntertainmentImg,
        para: "We can help you build a strong entertainment brand with an amazing website, mobile app, or social platform that engages millions of users.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Healthcare Providers Choose Senwell",
        WhyChooseUs: [
          {
            icon: (
              <FlashOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Start with confidence.",
            para: "We want you to find a team quickly and get straight to turning your vision into a reality.",
          },
          {
            icon: (
              <SearchIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "100% Transparency",
            para: "Clear and consistent communication, flexible pricing, and full dedication to your success",
          },
          {
            icon: (
              <StarIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Excellent Code Quality",
            para: "Beyond QA, we ensure your code is clean and free of technical debt. ",
          },
          {
            icon: (
              <LocationOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Cross-domain expertise",
            para: "There’s no challenge we can’t handle with confidence and great aplomb.",
          },
        ],
      },
    ],
    Forms: [
      {
        formTitle: "Let’s get the party started!",
        formSubtitle: "Enter your project details.",
      },
    ],
  },

  {
    id: 4,
    name: "banking-and-finance",

    HeroBanner: [
      {
        name: "banking-and-finance",
        Page: "Healthcare Software Development",
        title: "Banking Software Development Services",
        breadcrums: "Industries",
        Link: "/industries",
        spanText: "Banking ",
        title2: "Software Development Services",
        button: "Hire Now",
        img: ReactBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
        para: "Bring new ideas to market with our world-className expertise in fintech solutions from small banking apps to complex financial platforms.",
      },
    ],
    industriesBanner: [
      {
        image: Bankingimg,
        title: "Expand to new markets and audiences!",
        para1:
          "Financial companies are a keystone of society, but they must reform to stay up with demanding and changing times. To stay up with and surpass continually evolving consumer wants, altering regulatory obligations, and rising cybersecurity concerns while keeping prices low, a major adjustment is required. A quick company change that is based on severe digitization and new technology.  ",
        para2:
          "Senwell has been a valued partner in the financial services industry for generations, and our knowledge has helped us lead some of the most significant initiatives. Allow us to assist you in improving your customer satisfaction and generating new sources of revenue by leveraging the data you do have on an efficient and private.",
      },
    ],
    idealSolution: [
      {
        title: "Banking Solutions",
        idealSolutionList: [
          {
            Number: "01",
            title: "Systems for Automated Trading",
            description:
              "We will help design successful operating, forecasting, and asset management solutions, ranging from simple algorithms and analysis tools to complicated trading systems and platforms.",
          },
          {
            Number: "02",
            title: "Payment Systems & Digital Wallets",
            description:
              "We provide powerful and secure solutions for your users to handle their online transactions with ease and for companies to collect payments online efficiently.",
          },
          {
            Number: "03",
            title: "ERP/EDI Integration",
            description:
              "We provide a variety of unique and highly customized management solutions to assist financial firms in increasing business flexibility through clarity and improved operations.",
          },
          {
            Number: "04",
            title: "Point-of-Sale Systems",
            description:
              "Our point-of-sale systems may help your company improve staff management and minimise human error in duties like order calculation and stock management.",
          },
          {
            Number: "05",
            title: "Tools for data analysis and reporting",
            description:
              "Our Big Data knowledge and vast technological abilities allow us to create strong solutions that assist our clients in discovering useful insights and improving their decision-making processes.",
          },
          {
            Number: "06",
            title: "Solutions for Investment and Loan Management",
            description:
              "We develop safe and scalable investment automation tools as well as solutions to decrease loan risks, automate issuance, and save costs across credit markets.",
          },
          {
            Number: "07",
            title: "Mobile and Web Applications",
            description:
              "We build unique B2C and B2B apps to assist our clients better their online presence and client relations with their institutions, insurance firms, and financial institutions.",
          },
          {
            Number: "08",
            title: "Integrations with third-parties",
            description:
              "Integrate your fintech solution with third-party APIs, payment gateways, and systems that will help your company develop to attract customers and create additional cash flow sources.",
          },
          {
            Number: "09",
            title: "Tools for Risk Management",
            description:
              "We assist organizations in expanding their operations by utilizing innovative technology and solutions to successfully identify and manage economic problems as well as compliance with legal and regulatory requirements.",
          },
          {
            Number: "10",
            title: "Security Improvements",
            description:
              "We are industry leaders in the development of secure authorization, data encryption, and automated fraud prevention systems that  efficiently secure your firm and your client’s funds.",
          },
          {
            Number: "11",
            title: "Enhancement of performance",
            description:
              "By cleaning up your code, optimizing features and modules, and boosting the cross-platform compatibility of your financial solution, we could help you increase client experience",
          },
          {
            Number: "12",
            title: "Customer Service Resources",
            description:
              "To build up efficient processes and manage large traffic, use our customer service automation tools, chatbots, and contact center management software to ramp up your customer care.",
          },
        ],
      },
    ],

    GetInTouch: [
      {
        GetInTouchTitle:
          "With Senwell Group Private Limited competence and flexibility in financial software development, you can conquer the financial frontiers",
        Button: "Let's Start",
      },
    ],
    ProjectPortfolliotitle: "Featured FinTech Cases",
    ProjectPortfollio: [
      {
        title: "Digital transformation ",
        image: DigitalTransformations,
        para: "Senwell Group Private Limited provide businesses with online presence, advising, readiness assessments, and technology implementation by bringing in digital transformation professionals. Experience designers, data scientists, design engineers, architects, and programmers work together to produce genuinely digital solutions that affect Channels, Payments and Cards, Emerging Core (API banking), and Credit Services.",
      },
      {
        title: "Open Banking platform ",
        image: bankingplatform,
        para: " Senwell Group Private Limited Open Banking API gateway includes a complete set of capabilities in compliance with regulations, standard protocols, democratic accountability, open environments, and co-innovation to help banks and financial institutions to embark on a smooth transformation phase.",
      },
      {
        title: "Banking for people",
        image: BankingImage,
        para: "Senwell Group Private Limited continues to deliver tailored solutions for institutions that wish to stay competitive and prosper in a highly competitive market despite major competition. Our financial services consulting products and support include a huge variety of professional guidance, from front-end platforms to back-office core systems, and include automation. By highlighting specific techniques and new technologies connected to open financial networks, API-driven frameworks, and cognitive systems, we benefit the future of the digital bank's environment.",
      },
    ],

    IndusriesPartnertitle: "Why work with us?",
    IndustriesPartnerPara:
      "Senwell Group Private Limited can provide you with a full spectrum of services from design, development, and quality assurance to business & content management optimization, DevOps, and IT consulting. We respond to your needs quickly, deliver services of impressive quality, and make sure you remember us when planning your next project. ",
    IndustriesPartner: [
      {
        title: "All-inclusive teams",
        description:
          "In addition to programmers, we also provide business analyst, project manager, UI/UX designer, system administrator, and any other role that your project might ever need.",
        img: "",
      },
      {
        title: "Quick project start",
        description:
          "We start estimating your project immediately after you send us the needed information through the contact form on this web page.",
        img: "",
      },
      {
        title: "Long-term partnerships",
        description:
          "Our current customer return rate is about 70% and we make everything we can to make it even higher. We’re proud to say most of our clients do not stop on one project with us.",
        img: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle:
          "Banking software development services from planning to growth",
        Button: "Get a quote",
        image: BlockchainSub,
        para: "Adapt to market needs quickly and leverage the latest technology in rapid and innovative ways with banking software development expertise refined by years of building reliable fintech solutions! ",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Healthcare Providers Choose Senwell",
        WhyChooseUs: [
          {
            icon: (
              <FlashOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Start with confidence.",
            para: "We want you to find a team quickly and get straight to turning your vision into a reality.",
          },
          {
            icon: (
              <SearchIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "100% Transparency",
            para: "Clear and consistent communication, flexible pricing, and full dedication to your success",
          },
          {
            icon: (
              <StarIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Excellent Code Quality",
            para: "Beyond QA, we ensure your code is clean and free of technical debt. ",
          },
          {
            icon: (
              <LocationOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Cross-domain expertise",
            para: "There’s no challenge we can’t handle with confidence and great aplomb.",
          },
        ],
      },
    ],
    Forms: [
      {
        formTitle: "Get a quote today! ",
        formSubtitle: "Enter your project details.",
      },
    ],
  },
  {
    id: 5,
    name: "startups",

    HeroBanner: [
      {
        name: "startups",
        Page: "Startups Software Development",
        breadcrums: "Industries",
        Link: "/industries",
        title: "Software Development for Startups",
        spanText: "Startups",
        button: "Let's talk",
      },
    ],
    industriesBanner: [
      {
        image: businessideas,
        title: "Bring Your Startup Idea to Life",
        para1:
          "When establishing a business, there are a lot of considerations. But the most essential thing is to turn your idea into a product that can be utilized by a certain audience and generate cash. The last thing you want to worry about is the technical difficulties of publishing your program. You are a visionary who must think about the big picture. Everything else is covered by our software development services for startups.",
        para2: "We will manage every aspect of your software product.",
        para3:
          "Our UI/UX designers will be in the care about the aesthetics, while our professional developers will ensure that every feature you want in the end product is successfully executed. And everything will be double-checked by our quality assurance team.",
      },
    ],
    idealSolution: [
      {
        title: "Our Solutions for Startups",
        idealSolutionList: [
          {
            Number: "01",
            title: "MVP",
            description:
              "Want to put your concept to the test without investing a lot of money and time? We specialize in MVP creation for companies, which can assist you in demonstrating the viability of your idea to investors.",
          },
          {
            Number: "02",
            title: "Prototyping ",
            description:
              "MVP development doesn't often come initially; sometimes it is best to start with a prototype to understand what your concept may look like in real life or to make immediate modifications to the design.",
          },
          {
            Number: "03",
            title: "Market Research",
            description:
              'You want your program to look attractive and function properly, but you also want your startup to succeed. As a result, our "IT outsourcing for start-ups" package covers market research services as well.',
          },
          {
            Number: "04",
            title: "Additional Personnel",
            description:
              "As a startup, you don't have the time or money to look for, hire, and integrate new team members. Through effective implementations, you may obtain talent for your start-up in a matter of days with little overhead expenditures.",
          },
          {
            Number: "05",
            title: "Dedicated Teams",
            description:
              "Senwell Group Private Limited specialist team service completely eliminates the hassle of recruitment. High-className designers, developers, quality assurance engineers, and project managers are ready to take your project to the next level.",
          },
          {
            Number: "06",
            title: "Consulting",
            description:
              "Do you want your product launch to go off without a hitch? Our business specialists will look at every aspect of your startup, from the name to the app's functionality, to determine what can be done to improve results.",
          },
          {
            Number: "07",
            title: "Testing of Physical Devices",
            description:
              "It's vital to make sure your software works with a variety of devices and systems. You won't need to possess every phone and tablet on the market to use our testing services.",
          },
          {
            Number: "08",
            title: "Quality Assurance/Quality Control for Startups",
            description:
              "Don't let a faulty product, a lack of testing, or a lack of quality lead your startup to underperform or fail. Our quality assurance/quality control services will ensure that no fault is ignored.",
          },
          {
            Number: "09",
            title: "DevOps",
            description:
              "Developing your company's existing foundation is an important step toward success, and you only have to do it once if you get it right the first time. Our DevOps team is knowledgeable in this domain.",
          },
        ],
      },
    ],

    GetInTouch: [
      {
        GetInTouchTitle:
          "The appropriate outsourcing partner is the first step in your startup's success story.",
        Button: "Work with us",
      },
    ],
    ProjectPortfolliotitle: "Start-Up Process",
    ProjectPortfollio: [
      {
        title: "Tech Consulting/Ideation for Start-ups",
        image: consultingstartupImage,
        para: "Before starting off, it's critical to have confirmation for any plan. To tailor a technical solution, we discuss your concept, the challenges it tries to answer, target clients, goals, and other factors. Then we'll show you how to use technology-enabled accelerators to create a powerful product. We assist startups in evaluating, selecting, and implementing cutting-edge technology stacks for their products and apps, whether online, mobile, or cloud. We choose the appropriate framework for development after weighing the advantages and disadvantages of the technological stack.",
      },
      {
        title: "Product Development for Start-ups.",
        image: productdevelopment,
        para: " Every start-up goes through a new product development process, from discovering a market opportunity through launch. classNameic Infotech provides full-service start-up business development to help you get your product on the market quicker.You can entrust us with the entire development of your product or just one or more parts/modules to increase its worth. We have developers, designers, testers, QA engineers, and other tech experts to help you accelerate the development of your startup business.",
      },
      {
        title: "Growth Hacking for Start-ups",
        image: growthhacking,
        para: " Senwell’s product marketing services assist entrepreneur’s growth-hack their path to success. Performance marketing, inbound marketing, content marketing, search engine optimization, and other strategies are among them. We ensure that your company is not only visible on the internet, but also that it is reaching the correct audience with the right message.",
      },
    ],

    IndusriesPartnertitle: "Healthcare Projects from a Reliable Partner",
    IndustriesPartnerPara:
      "We build highly secure, accessible, and intuitive healthcare solutions to the point they don’t require exhaustive employee training at the deployment stage. Whether you need a single engineer or an entire development team, we will have you covered! ",
    IndustriesPartner: [
      {
        title: "All-inclusive teams",
        description:
          "In addition to programmers, we also provide business analyst, project manager, UI/UX designer, system administrator, and any other role that your project might ever need.",
        img: "",
      },
      {
        title: "Quick project start",
        description:
          "We start estimating your project immediately after you send us the needed information through the contact form on this web page.",
        img: "",
      },
      {
        title: "Long-term partnerships",
        description:
          "Our current customer return rate is about 70% and we make everything we can to make it even higher. We’re proud to say most of our clients do not stop on one project with us.",
        img: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle:
          "Get Your Startup From an Idea To A Profitable Product.",
        Button: "Let's Talk",
        image: StartupsSub,
        para: "Let us take care of the full cycle of startup product development and take advantage of our years of expertise, flexibility, and attention to detail while you focus on the big picture.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Healthcare Providers Choose Senwell",
        WhyChooseUs: [
          {
            icon: (
              <FlashOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Start with confidence.",
            para: "We want you to find a team quickly and get straight to turning your vision into a reality.",
          },
          {
            icon: (
              <SearchIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "100% Transparency",
            para: "Clear and consistent communication, flexible pricing, and full dedication to your success",
          },
          {
            icon: (
              <StarIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Excellent Code Quality",
            para: "Beyond QA, we ensure your code is clean and free of technical debt. ",
          },
          {
            icon: (
              <LocationOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Cross-domain expertise",
            para: "There’s no challenge we can’t handle with confidence and great aplomb.",
          },
        ],
      },
    ],
    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },
  {
    id: 6,
    name: "blockchain",

    HeroBanner: [
      {
        name: "blockchain",
        Page: "Blockchain Consulting and Development Services",
        breadcrums: "Industries",
        Link: "/industries",
        title: "Blockchain Consulting and Development Services",
        spanText: "Expert",
        title2: "Media & Entertainment Solutions",
        button: "Let's talk",
      },
    ],
    industriesBanner: [
      {
        image: BlockChainimg,
        title: "Seamless Delivery. Future-proof Results.",
        para1:
          "Use our blockchain consulting services to fully explore the full of blockchain and receive significant insight into your projects, including DeFi, NFTs, Web3, and Metaverse. ",
        para2:
          " Our staff takes your ideas and transforms them into reality, from concept through protocol selection.",
      },
    ],
    idealSolution: [
      {
        title: "What you get",
        idealSolutionList: [
          {
            Number: "01",
            title: " Modular construction",
            description:
              "Token from Distributed Lab is a standardized, modular framework that allows you to customize your blockchain platform more easily and quickly.",
          },
          {
            Number: "02",
            title: "Agile product development",
            description:
              "Senwell Group Private Limited scrum methodology offers greater speed, data-driven choices, and flexibility thanks to established business and development processes.",
          },
          {
            Number: "03",
            title: "Broad expertise",
            description:
              "Senwell Group Private Limited and Shared Research center bring together years of expertise and in-depth knowledge to create a successful framework that meets all of your objectives.",
          },
          {
            Number: "04",
            title: "Communication that works",
            description:
              "Through open communication and effective product development, we reduce the number of roadblocks and dangers on your journey to a successful launch.",
          },
          {
            Number: "05",
            title: "White-label strategy",
            description:
              "The basic modules of Distributed Lab's TokenD framework may be acquired along with the codebase, reducing risk and removing vendor lock.",
          },
          {
            Number: "06",
            title: "Ongoing assistance",
            description:
              "We provide such a CI/CD pipeline that ensures light and efficient software delivery, as well as Agile platform enhancement depending on the market input.",
          },
        ],
      },
    ],

    GetInTouch: [
      {
        GetInTouchTitle:
          "Looking for a secure and flexible blockchain solution to support your business operations?",
        Button: "Work with us",
      },
    ],
    ProjectPortfolliotitle: "BlockChain",
    ProjectPortfollio: [
      {
        title: "Workshop and training on strategy",
        image: trainingworkshop,
        para: "Our team hosts blockchain conferences and live webinars to assist you in better understanding blockchain technology, as well as its potential applications and applications. With case studies from our initiatives, we show how cryptocurrency can change your profession.",
      },
      {
        title: "Blockchain Consulting Services",
        image: BlockchainImage,
        para: "We evaluate your current solution, determine the requirement for a blockchain solution tailored to your business use case, and assess how blockchain can benefit your company. For the suggested project, we recommend the appropriate products and expertise.",
      },
      {
        title: "Blockchain Technology Development",
        image: blockchainservices,
        para: "You may advance your project to development after you grasp the blockchain viability for your use case. We design and offer scalable blockchain solutions to revolutionize your business processes as a top blockchain development company.",
      },
    ],

    IndusriesPartnertitle: "Blockchain Projects from a Reliable Partner",
    IndustriesPartnerPara:
      "We build highly secure, accessible, and intuitive healthcare solutions to the point they don’t require exhaustive employee training at the deployment stage. Whether you need a single engineer or an entire development team, we will have you covered! ",
    IndustriesPartner: [
      {
        title: "All-inclusive teams",
        description:
          "In addition to programmers, we also provide business analyst, project manager, UI/UX designer, system administrator, and any other role that your project might ever need.",
        img: "",
      },
      {
        title: "Quick project start",
        description:
          "We start estimating your project immediately after you send us the needed information through the contact form on this web page.",
        img: "",
      },
      {
        title: "Long-term partnerships",
        description:
          "Our current customer return rate is about 70% and we make everything we can to make it even higher. We’re proud to say most of our clients do not stop on one project with us.",
        img: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: "Connect your business to the Financial Internet ",
        Button: "Start a project",
        image: BlockchainSub,
        para: "Based on Distributed Lab’s TokenD technology, our blockchain development company builds powerful solutions across various domains from security token offering platforms and remittance systems to eCommerce solutions and government infrastructure.",
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Blockchain Providers Choose Senwell",
        WhyChooseUs: [
          {
            icon: (
              <FlashOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Start with confidence.",
            para: "We want you to find a team quickly and get straight to turning your vision into a reality.",
          },
          {
            icon: (
              <SearchIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "100% Transparency",
            para: "Clear and consistent communication, flexible pricing, and full dedication to your success",
          },
          {
            icon: (
              <StarIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Excellent Code Quality",
            para: "Beyond QA, we ensure your code is clean and free of technical debt. ",
          },
          {
            icon: (
              <LocationOnIcon
                style={{
                  color: "#3D8ED7",
                  paddingBottom: "1rem",
                  fontSize: "2.5rem",
                }}
              />
            ),
            title: "Cross-domain expertise",
            para: "There’s no challenge we can’t handle with confidence and great aplomb.",
          },
        ],
      },
    ],
    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },
];
export { IndustriesData };
