import React from "react";
import { Grid, Container, Typography } from "@material-ui/core";
import { WorkflowImage } from "../../../images/images";
import { makeStyles } from "@material-ui/core";
import Aos from "aos";
//import "aos/dist/aos.css";
import { useEffect } from "react";
import DigitalCarousel from "./DigitalCarousel";
import "../../../Pages/Services/DigitalMarketing/QuickStart.css";

import SEO from "../../../images/DigitalMarketing/SEO.webp";
import SocialMarketing from "../../../images/DigitalMarketing/SocialMedia.webp";
import PPC from "../../../images/DigitalMarketing/PPC.webp";
import Reputation from "../../../images/DigitalMarketing/ReputationManagement.webp";
import Content from "../../../images/DigitalMarketing/ContentCreation.webp";

const useStyles = makeStyles((theme) => ({
  ImageWrapper: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3rem 3rem 0rem 3rem",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 'auto',
      padding: '1rem 0 0',
      padding: "2rem 2rem 2rem 2rem",
    },
  },
  ImageWrapper2:{
    display: "flex",
    padding: "3rem 3rem 0rem 1rem",
    marginTop: "2rem",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "2rem 2rem 2rem 2rem",
    },
  },
  ImageWrapper3:{
    display: "flex",
    padding: "3rem 3rem 0rem 3rem",
    marginTop: "2rem",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "2rem 2rem 2rem 2rem",
    },
  },
  ImageWrapper4:{
    display: "flex",
    padding: "3rem 3rem 0rem 1rem",
    marginTop: "2rem",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      padding: "2rem 2rem 2rem 2rem",
    },
  },
  

  Image: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
    borderRadius:"11px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
      objectFit: "contain",
    },
  },
  ContentWraper: {
    marginTop: "2rem",
    padding: "7rem 6rem 0rem 0rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 1rem 0rem 1rem",
      marginTop:"0rem",
    },
  },
  grid: {
    marginTop: "30px",
  },

  ContentWraper2:{
    marginTop: "2rem",
    padding: "4rem 3rem 0rem 3rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 1rem 0rem 1rem",
    },
  },

  ContentWraper3:{
    marginTop: "2rem",
    padding: "5rem 3rem 0rem 1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 1rem 0rem 1rem",
    },
  },

  ContentWraper4:{
    marginTop: "2rem",
    padding: "8rem 3rem 0rem 3rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 1rem 0rem 1rem",
    },
  },

  ContentWraper5:{
    marginTop: "2rem",
    padding: "5rem 3rem 0rem 1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 1rem 0rem 1rem",
    },
  },
  WorkflowTitle: {
    fontSize: "1.7rem",
    lineHeight: "1.3",
    fontWeight: "800",
    color:"#131022",
    margin: "0px",
    marginBottom:"1.5rem",
    fontFamily: "'Manrope','sans-serif'",

    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
      fontWeight: "1000",
    },
  },

  WorkflowTitle2:{
    fontSize: "1.7rem",
    lineHeight: "1.3",
    fontWeight: "800",
    color:"#131022",
    margin: "0px",
    marginBottom: "15px",
    fontFamily: "'Manrope','sans-serif'",

    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
      fontWeight: "1000",
    },
  },
  workfolwsubTitle:{
     fontSize:"17px",
     lineHeight:"1.4",
     marginTop:"0",
     marginBottom: "1rem",
     fontWeight:"800",
     color:"#131022",
     fontFamily: "'Manrope','sans-serif'",
  },
  workfolwsubTitle2:{
    fontSize:"17px",
    lineHeight:"1.4",
    marginTop:"0",
    fontWeight:"800",
    color:"#131022",
    fontFamily: "'Manrope','sans-serif'",
 },
  WorkflowPara: {
    fontSize: "1.6",
    fontWeight:"400",
    color: "#5a5a5a",
    textAlign: "justify",
    fontSize: "1rem",

    [theme.breakpoints.down("sm")]: {
      color:"#5a5a5a",
      fontSize: "15px",
      textAlign: "justify",
      fontWeight: "400",
      wordSpacing: "-0.1em",
      hyphens: "auto",
      lineHeight:"inharit"
    },
  },
  WorkflowPara2:{
    fontSize: "1.6",
    fontWeight:"400",
    color: "#5a5a5a",
    textAlign: "justify",
    fontSize: "1rem",

    [theme.breakpoints.down("sm")]: {
      color:"#5a5a5a",
      fontSize: "1rem",
      textAlign: "justify",
      fontWeight: "400",
      wordSpacing: "-0.1em",
      hyphens: "auto",
    },
  },
  

  WorkflowsubPara: {
    fontWeight: 400,
    padding: "0px 5px",
    textAlign:"justify",
    color:"#585c7b",
    fontFamily: "'Manrope', sans-serif",
  },
  StartFastText: {
    color: "#3D8ED7",
  },
  Mainwrapper: {
    marginBottom: "5rem",
    width: '90%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },

  
}));
const QuickStart = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const classNamees = useStyles();
  return (
    <>
      <div data-aos="fade-up">
        <Container maxWidth="lg" className={classNamees.Mainwrapper}>
          <Grid container>
            <Grid xs={12} sm={12} md={6}>
              <div className={classNamees.ImageWrapper} >
                <img src={SEO} className={classNamees.Image} alt="Seo" />
              </div>
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <div className={classNamees.ContentWraper}>
                <Typography variant="h4" className={classNamees.WorkflowTitle}>Search Engine Optimization </Typography>
                <Typography variant="h5" className={classNamees.workfolwsubTitle}>Make your website search-engine-friendly</Typography>
                <p className={classNamees.WorkflowPara}>
                     We're India's top online marketing agency, with skilled SEO experts and content writers.
                     Expect stellar results from keyword research to off-page optimization. Our professional 
                     SEO services in Mumbai also prioritize platforms that boost your brand's reputation,
                     ensuring a strong online presence. Trust us to maximize your digital strategy and 
                     establish your company as a credible and influential online force.
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid container className={classNamees.grid}>
            <Grid xs={12} sm={12} md={6}>
              <div className={classNamees.ContentWraper2}>
                <Typography variant="h4" className={classNamees.WorkflowTitle2}>
                  Social Media Marketing
                </Typography>
                <p className={classNamees.workfolwsubTitle2}>
                Social media marketing services to boost online presence.
                </p>
                <p className={classNamees.WorkflowPara}>
                Harness the power of social media's billion users for instant recognition, 
                growth, and popularity. As India's leading agency, we assist in acquiring customers
                and reaching specific audiences. Our innovative methods engage prospects, 
                making things happen. Elevate your presence today!
                </p>

                <p className={classNamees.WorkflowPara}>
                  Utilize our comprehensive social media optimization services to build an engaging and magnetic 
                  social media profile that boosts your bottom line. Our SMO expertise covers strategy creation 
                  and execution, ensuring remarkable results. Backed by a skilled and competent team, we guarantee
                  credibility and success in SMO services for your company.
                </p>
              </div>
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <div className={classNamees.ImageWrapper2} >
                <img src={SocialMarketing} className={classNamees.Image} id="sco-image" alt="SocialMarketing" />
              </div>
            </Grid>
          </Grid>

          <Grid container className={classNamees.grid}>
            <Grid xs={12} sm={12} md={6}>
            <div className={classNamees.ImageWrapper3} >
              <img src={PPC} className={classNamees.Image} id="sco-image" alt="PPC" />
            </div>
          </Grid>
            <Grid xs={12} sm={12} md={6}>
              <div className={classNamees.ContentWraper3}>
                <Typography variant="h4" className={classNamees.WorkflowTitle2}>
                PPC Ads
                </Typography>
                <p className={classNamees.workfolwsubTitle2}>
                Gain High-Level Brand Exposure and Attract More Leads.
                </p>
                <p className={classNamees.WorkflowPara}>
                    At Senwell Group Private Limited in India, we create low-cost clickbait ads,
                    ensuring a high ROI and increased profits. With a vast online audience and millions of searches every second,
                    running Google ads guarantees valuable leads and website traffic. Trust us for remarkable PPC results.
                </p>
                <p className={classNamees.WorkflowPara}>
                  Being a reputable PPC management firm in India, we follow a proven methodology to elevate your brand across search results and social media feeds. 
                  Our result-driven PPC services ensure maximum returns from your campaigns.
                  Trust us to propel your brand to success.
                </p>
              </div>
            </Grid>
          </Grid> 
          <Grid container className={classNamees.grid}>
            <Grid xs={12} sm={12} md={6}>
              <div className={classNamees.ContentWraper4}>
                <Typography variant="h4" className={classNamees.WorkflowTitle2}>
                  Online Reputation Management
                </Typography>
                <p className={classNamees.workfolwsubTitle2}>
                  88% of online users read reviews to determine a QUALITY and TRUST of the businesses!
                </p>
                <p className={classNamees.WorkflowPara}>
                At Senwell Group Private Limited, we highly value your Company Reputation, a crucial foundation for building strong business relationships. Through meticulous evaluation, we collaborate closely with top management to attain the desired reputation, safeguarding your brand's integrity. Trust us to enhance and protect your company's image.
                </p>
              </div>
            </Grid>
            <Grid xs={12} sm={12} md={6}>
            <div className={classNamees.ImageWrapper4} >
              <img src={Reputation} className={classNamees.Image} alt="Reputation" />
            </div>
          </Grid>
          </Grid>
          <Grid container className={classNamees.grid}>
            <Grid xs={12} sm={12} md={6}>
            <div className={classNamees.ImageWrapper3} >
              <img src={Content} className={classNamees.Image} id="sco-image" alt="Content" />
            </div>
          </Grid>
            <Grid xs={12} sm={12} md={6}>
              <div className={classNamees.ContentWraper5}>
                <Typography variant="h4" className={classNamees.WorkflowTitle2}>Online Content Creation </Typography>
                <p className={classNamees.workfolwsubTitle2}>
                  Unique and SEO-friendly content Creation Services
                </p>
                <p className={classNamees.WorkflowPara}>          
                Senwell Group Private Limited sparks conversations with prospects through compelling content.
                     We cultivate relationships and drive sales with great storytelling at the right time and through proper channels.
                     Our team's diligent research ensures impactful results.
                </p>
                <p className={classNamees.WorkflowPara2}>
                   We research specific topics for precise content, backed by stats and aligned with your goals. 
                   Targeting a specific audience, we explore link-building and employ cutting-edge marketing,
                   from ideation to conversion. Trust us for content that scales your brand effectively.
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
          <DigitalCarousel />
      </div>
    </>
  );
};

export default QuickStart;