import React from "react";
import { Services } from "../../../API/ServicesApi";
import { useParams } from "react-router-dom";
import TechnologiesExperties from "../../../SharedModule/TechnologiesExperties";
import GetInTouchSecondBanner from "../../../SharedModule/GetInTouchSecondBanner";
import "./WebDevelopment.css";
import Lottie from "react-lottie";
import animationData from "../../../assets/WebAnimation.json";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Popup from "../../../Modules/Popupform";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core";
import Website from "../../../images/Services/Website-Development.svg";
import Ecommerce from "../../../images/Services/E-Commerce-Web-App-Development.svg";
import Software from "../../../images/Services/Software-Development.svg";
import WebAppSupport from "../../../images/Services/Mobile-App-Support.svg";
import WebAppTesting from "../../../images/Services/Web-Application-Testing.svg";
import Progress from "../../../images/Services/Progressive-Web-Apps.svg";
import AngularLogo from "../../../images/Services/Angularnew.png";
import ReactLogo from "../../../images/Services/react.svg";
import NodejsLogo from "../../../images/Services/nodejs.svg";
import DotNetLogo from "../../../images/Services/DotNet.svg";
import PostgreLogo from "../../../images/Services/postgre-sql.svg";
import mysqlLogo from "../../../images/Services/mysql.svg";
import mongodbLogo from "../../../images/Services/mongodb.svg";
import SqlServerLogo from "../../../images/Services/microsoft-sql-server.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTech } from "../../../redux/tech/techActions";

const WebDevelopment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { name, slug } = useParams();
  const [open, setOpen] = React.useState(false);
  const data = Services.find((value) => value.name === name);
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }));

  const classNamees = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFooterButtonClick = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    //console.log(name, value);
    dispatch(setTech(name));
    navigate(`/hire-developers/${value}`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classNamees.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Popup Close={handleClose} />
        </Fade>
      </Modal>
      <section className="WebApp-sections">
        <div className="container" style={{ left: "0", border: "none" }}>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 WebApp-banner-col">
              <div className="WebApp-banner-content">
                <h1>Web Development</h1>
                <p className="sub-p">
                  Empowering Your Web Apps Harnessing Cutting-Edge Tech and
                  Battle-Tested Tools through Custom Web App Development
                  Services.
                </p>
                <div className="Web-banner-button">
                  <Link to="/contact">
                    <button className="WebApp-btn">Let's Talk</button>{" "}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 development-image">
              <div className="image">
                <Lottie options={defaultOptions} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ padding: "50px 0px" }}>
        <div
          className="container web-container"
          style={{ left: "0", border: "none" }}
        >
          <h2 className="text-center Web-header">
            Web Application Development Company
          </h2>
          <p className="WebDev-para">
            As a leading web app development company, we leverage long-standing
            experience in building and offering custom web application
            development services to increase customer satisfaction. We help you
            implement the right technologies in areas that are best suited to
            bring your business value. Our focus is to understand “What does the
            client want?” From ideation to testing and delivery, our developers
            develop and offer exceptional web app development services for
            sustainable business growth.
          </p>
        </div>
      </section>

      <section className="section_part">
        <div
          className="container container1"
          style={{ left: "0", border: "none" }}
        >
          <div className="row" style={{ rowGap: "20px" }}>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-widget">
                <div className="sw-head">
                  <div className="sw-logo">
                    <img src={Website} alt="logo" />
                  </div>
                  <div className="sw-title">WebSite Development</div>
                </div>
                <div className="sw-content">
                  <p>
                    We specialize in custom, user-friendly websites built from
                    scratch, ensuring glitch-free experiences for our clients.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-widget">
                <div className="sw-head">
                  <div className="sw-logo">
                    <img src={Ecommerce} alt="logo" />
                  </div>
                  <div className="sw-title">E-Commerce Web App Development</div>
                </div>
                <div className="sw-content">
                  <p>
                    Our E-Commerce web app development solution ensures an
                    exceptional customer experience and leverages web-based
                    marketing channels for business growth.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-widget">
                <div className="sw-head">
                  <div className="sw-logo">
                    <img src={Software} alt="logo" />
                  </div>
                  <div className="sw-title">Software Development</div>
                </div>
                <div className="sw-content">
                  <p>
                    At Senwell Group Private Limited, we build scalable and customized
                    mobile/web apps to suit your needs. Partner with us for
                    top-notch software development.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-widget">
                <div className="sw-head">
                  <div className="sw-logo">
                    <img src={WebAppSupport} alt="logo" />
                  </div>
                  <div className="sw-title">
                    Web Application Support & Maintenance
                  </div>
                </div>
                <div className="sw-content">
                  <p>
                    At Senwell Group Private Limited, we understand that app development is
                    just the beginning. We recognize the importance of timely
                    updates and maintenance for a seamless user experience.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-widget">
                <div className="sw-head">
                  <div className="sw-logo">
                    <img src={WebAppTesting} alt="logo" />
                  </div>
                  <div className="sw-title">Web Application Testing</div>
                </div>
                <div className="sw-content">
                  <p>
                  Senwell Group Private Limited offers unmatched web app testing services,
                    combining automated and manual testing for superior quality,
                    usability, and security.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="service-widget">
                <div className="sw-head">
                  <div className="sw-logo">
                    <img src={Progress} alt="logo" />
                  </div>
                  <div className="sw-title">Progressive Web Apps</div>
                </div>
                <div className="sw-content">
                  <p>
                    PWAs are reliable, fast and engaging, making it a new
                    standard to increase a client's revenue. Moreover, there are
                    many proven case studies of PWAs like Pinterest, Tinder,
                    Bookmyshow,Trivago and more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="senwell-technology-inner gray-background-block">
        <div className="container" style={{ left: "0", border: "none" }}>
          <div className="senwell-technology-detail">
            <h2>Frontend</h2>
            <p>
              Transform Web Apps with JavaScript, HTML & CSS Enhance aesthetics,
              elevate UX, and captivate users. Reinvent digital experience for
              maximum impact.
            </p>
            <div className="senwell-technology-links">
              <ul className="technology-listing" style={{ gap: "20px" }}>
                <li style={{ listStyleType: "none" }}>
                  <button
                    name="Angular"
                    value="angular"
                    onClick={handleFooterButtonClick}
                    className="blue-border-btn"
                  >
                    Angular
                  </button>
                </li>
                <li style={{ listStyleType: "none" }}>
                  <button
                    name="React"
                    value="react"
                    onClick={handleFooterButtonClick}
                    className="blue-border-btn"
                  >
                    {" "}
                    React
                  </button>
                </li>
              </ul>
            </div>
            <div className="Senwell-technology-logos">
              <ul className="technology-logos-listing">
                <li style={{ listStyleType: "none" }}>
                  <img
                    className="js-Angular-image"
                    alt="Angular Js"
                    src={AngularLogo}
                  />
                </li>
                <li style={{ listStyleType: "none" }}>
                  <img className="js-React-image" alt="React" src={ReactLogo} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="senwell-technology-inner gray-background-block1">
        <div className="container" style={{ left: "0", border: "none" }}>
          <div className="senwell-technology-detail">
            <h2>Backend</h2>
            <p>
              Overcome Development Challenges Enhance App Backend with Seamless
              Communication, Feature Sync, and Database Integration. Achieve
              efficient Co-ordination, smooth operation, and optimal performance
              with relational or NoSQL databases.
            </p>
            <div className="senwell-technology-links">
              <ul className="technology-listing" style={{ gap: "20px" }}>
                <li style={{ listStyleType: "none" }}>
                  <button
                    name="Node.js"
                    value="nodejs"
                    onClick={handleFooterButtonClick}
                    className="blue-border-btn"
                  >
                    {" "}
                    Node js
                  </button>
                </li>
                <li style={{ listStyleType: "none" }}>
                  <button
                    name=".Net"
                    value="dot-net"
                    onClick={handleFooterButtonClick}
                    className="blue-border-btn"
                  >
                    {" "}
                    .Net
                  </button>
                </li>
              </ul>
            </div>
            <div className="Senwell-technology-logos">
              <ul className="technology-logos-listing">
                <li style={{ listStyleType: "none" }}>
                  <img
                    className="js-lazy-image js-lazy-image--handled"
                    alt="Node Js"
                    src={NodejsLogo}
                  />
                </li>
                <li style={{ listStyleType: "none" }}>
                  <img
                    className="js-lazy-image js-lazy-image--handled"
                    alt=".Net"
                    src={DotNetLogo}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="senwell-technology-inner gray-background-block2">
        <div className="container" style={{ left: "0", border: "none" }}>
          <div className="senwell-technology-detail">
            <h2>Database</h2>
            <p>
              Maximize Insights: Consolidate Complex Data, Integrate End-to-End,
              Predict Actionable Insights. Unify diverse sources, unlock
              predictive analytics, and drive informed decisions.
            </p>
            <div className="Senwell-technology-logos">
              <ul className="technology-logos-listing">
                <li style={{ listStyleType: "none" }}>
                  <img
                    className="DatabaseLogo"
                    alt="PostgreeLogo"
                    src={PostgreLogo}
                  />
                </li>
                <li style={{ listStyleType: "none" }}>
                  <img
                    className="DatabaseLogo"
                    alt="MySqlLogo"
                    src={mysqlLogo}
                  />
                </li>
                <li style={{ listStyleType: "none" }}>
                  <img
                    className="DatabaseLogo"
                    alt="MongoDb Logo"
                    src={mongodbLogo}
                  />
                </li>

                <li style={{ listStyleType: "none" }}>
                  <img
                    className="DatabaseLogo"
                    alt="Sql-Server Logo"
                    src={SqlServerLogo}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <GetInTouchSecondBanner
        GetInTouchSecondBanner={data.GetInTouchSecondbanner}
      />
      <TechnologiesExperties
        TechnologiesExperties={data.TechnologiesExperties}
        title={data.TechnologiesExpertiesTitle}
        para={data.TechnologiesExpertiesPara}
      />
    </>
  );
};

export default WebDevelopment;
