import React from "react";
import { Grid, Container, Typography } from "@material-ui/core";
import { WorkflowImage } from "../../../images/images";
import { makeStyles } from "@material-ui/core";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Quickstart from "../../../../src/images/Services/Quickstart.jpg";

const useStyles = makeStyles((theme) => ({
  ImageWrapper: {
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3rem 3rem 0rem 3rem",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: 'auto',
      padding: '1rem 0 0'
    },
  },
  Image: {
    width: "80%",
    height: "auto",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      height: "auto",
      objectFit: "contain",
    },
  },
  ContentWraper: {
    marginTop: "6rem",
    padding: "3rem 6rem 0rem 0rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 1rem 0rem 1rem",
    },
  },
  WorkflowTitle: {
    fontSize: "36px",
    lineHeight: "56px",
    fontWeight: "600",
    margin: "0px",
    marginBottom:"13px",
    fontFamily: " 'Manrope', sans-serif",
    [theme.breakpoints.down('sm')]: {
      margin: "0px",
      fontSize: "27px",
      fontWeight: "600",
      lineHeight: "38px",
      marginBottom: "18px",
      textAlignc:"center"
    },

  },
  WorkflowPara: {
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "justify",
     fontFamily: "'Manrope', sans-serif",
  },
  WorkflowsubPara: {
    color: "#5a5a5a",
    fontSize: "17px",
    fontWeight: 400,
    padding: "0px 5px",
    fontFamily: "'Manrope', sans-serif",
  },
  StartFastText: {
    color: "#3D8ED7",
  },
  Mainwrapper: {
    marginBottom: "5rem",
    width: '90%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
}));
const QuickStart = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const classNamees = useStyles();
  return (
    <>
      <div data-aos="fade-up" data-aos-duration="2000">
        <Container maxWidth="lg" className={classNamees.Mainwrapper}>
          <Grid container>
            <Grid xs={12} sm={12} md={6} style={{display:" flex",justifyContent: "center",alignItems: "center"}}>
              <div className={classNamees.ImageWrapper}>
                <img src={Quickstart} className={classNamees.Image} />
              </div>
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <div className={classNamees.ContentWraper}>
                <Typography variant="h4" className={classNamees.WorkflowTitle}>
                  Quick Start, Quality finish.
                </Typography>
                <p className={classNamees.WorkflowPara}>
                  When you come to Senwell Group Private Limited, you get a reliable tech
                  partner business to turn to time and time again. Professional
                  software QA services can assist you to streamline your SDLC
                  procedures and get complete control over your project's code
                  quality.
                </p>
                <p className={classNamees.WorkflowPara}>
                  Over the years, we’ve matured our internal workflows and honed
                  our skills to ensure efficient performance that gives you
                  accurate product quality information. With decade-long
                  experience, CMMI-appraised processes, and a large roster of
                  ISTQB-certified testers, we help our clients create
                  high-quality software on time and within budget.
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default QuickStart;
