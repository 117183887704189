import { SET_DURATION } from "./durationType";
import { GET_DURATION } from "./durationType";

export const setDuration = (response) => {
  //console.log("duration action", response);
  return {
    type: SET_DURATION,
    payload: response,
  };
};

export const getDuration = (response) => {
  return {
    type: GET_DURATION,
    payload: response,
  };
};
