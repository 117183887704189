import React from "react";
import "./ApplicationDev.css";
import shape2 from "../../../images/SoftwareDev/shape2.svg";
import shape3 from "../../../images/SoftwareDev/shape3.svg";
import shape4 from "../../../images/SoftwareDev/shape4.svg";
import ProductConsulting from "../../../images/SoftwareDev/product consulting.png";
import softwareproduct from "../../../images/SoftwareDev/software product development.png";
import Maintenance1 from "../../../images/SoftwareDev/maintenance and support.png";
import MobileApp from "../../../images/SoftwareDev/Mobile-App-Development.jpg";
import client9 from "../../../images/Pastproject/client9.webp";
import client10 from "../../../images/Pastproject/client10.webp";
import client11 from "../../../images/Pastproject/CiscoLogo.png";
import IndustrySection from "./IndustrySection";
import InsightSection from "./InsightSection";
import ApplicationD from "../../../images/SoftwareDev/application development service.png";
import ApplicationMord from "../../../images/SoftwareDev/application modernization.png";
import prototype from "../../../images/SoftwareDev/prototype and design.png";
import Uiuxdesing from "../../../images/SoftwareDev/ui ux design.png";
import { Helmet } from "react-helmet";

const ApplicationDev = () => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": "https://senwellsys.com/mobile-app-development-services",
    url: "https://senwellsys.com/mobile-app-development-services",
    name: "Senwell Group Pvt Ltd | Mobile App Development Services",
    description:
      "Explore Senwell Group's expertise in mobile app development, offering innovative and scalable solutions tailored to your business needs.",
    inLanguage: "en-US",
    organization: {
      "@type": "Organization",
      "@id": "https://senwellsys.com/#organization",
      name: "Senwell Group Pvt Ltd | Software Company | Pune",
      url: "https://senwellsys.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellsys.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption: "Senwell Group Pvt Ltd | Software Company | Pune",
      },
      potentialAction: [
        {
          "@type": "SearchAction",
          target:
            "https://senwellsys.com/mobile-app-development-services?s={search_term_string}",
          "query-input": "required name=search_term_string",
        },
      ],
    },
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Senwell Group Private Limited | Mobile App Development Services
        </title>
        <meta
          name="description"
          content="Explore Senwell Group's expertise in mobile app development, offering innovative and scalable solutions tailored to your business needs."
        />
        <link
          rel="canonical"
          href="https://senwellsys.com/mobile-app-development-services"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Mobile App Development Services" />
        <meta
          property="og:description"
          content="Explore Senwell Group's expertise in mobile app development, offering innovative and scalable solutions tailored to your business needs."
        />
        <meta
          property="og:url"
          content="https://senwellsys.com/mobile-app-development-services"
        />
        <meta property="og:site_name" content="Senwell Group Private Limited" />
        <meta
          property="og:image"
          content="https://senwellsys.com/static/media/Mobile-App-Development.2053124060ab8962f65c.jpg"
        />
        <meta property="og:image:width" content="376" />
        <meta property="og:image:height" content="360" />
        <meta property="og:image:type" content="image/png" />

        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>

      <div className="CustomeDev">
        <section>
          <div className="main-banner">
            <div className="container" style={{ left: "0", border: "none" }}>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <h1 className="Product-title">Mobile App Development</h1>
                </div>
                <div className="col-lg-6 col-md-12">
                  <p className="content-para">
                    We work with global brands to design and develop exceptional
                    mobile applications in particular progressive stages,
                    enabling a unified customer experience across all cutting
                    edge platforms and devices.
                  </p>
                </div>
              </div>
            </div>

            <div class="shape2 rotateme">
              <img src={shape2} alt="img" />
            </div>

            <div className="shape3">
              <img src={shape3} alt="shape" />
            </div>
            <div className="shape4">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape5">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape6 rotateme">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape7">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape8 rotateme">
              <img src={shape2} alt="shape" />
            </div>
          </div>
        </section>

        <section className="section-services">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row" style={{ marginBottom: "60px" }}>
              <div className="col-lg-12 col-md-12 text-center">
                <h2 className="section-head">
                  Mobile Application Development Services
                </h2>
                <p className="mob-section-para">
                  Our staff of highly skilled mobile app developers at Senwell
                  Group Private Limited. Through the development of
                  customer-focused mobile applications, they can bring your
                  business concepts to life.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={ProductConsulting} alt="img" />
                  </div>
                  <h3>Design & Prototyping of Apps</h3>

                  <p>
                    You can get help from our mobile app designers in developing
                    and prototyping a custom mobile application. We provide an
                    easy-to-use interface and experience that guarantees
                    increased ROI and increased engagement.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={softwareproduct} alt="img" />
                  </div>
                  <h3>Native App development</h3>

                  <p>
                    Our native app developers have a great deal of experience
                    making secure and distinctive apps for particular platforms.
                    They include specialists in iOS and Android development.
                    Every app is customized to meet your goals and objectives.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={Maintenance1} alt="img" />
                  </div>
                  <h3>Cross-Platform App development</h3>

                  <p>
                    Our cross-platform app developers are experts at building
                    hybrid apps with React Native and Flutter, among other
                    technologies. We create affordable solutions for companies
                    of all sizes and in a variety of industries.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-padding-product">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row align-items-lg-center pt-md-3 pb-2 mb-2 mb-lg-4 mb-xl-2">
              <div className="col-md-12 col-lg-5 mb-4 mb-md-0 ">
                <img src={MobileApp} alt="img" className="leftimg" />
              </div>

              <div className="col-md-12 col-lg-6 offset-lg-1">
                <div className="pe-xl-2 me-md-2 me-lg-2">
                  <h2 className="h4 mb-3 mb-sm-2 design-and-content-header">
                    Custom Mobile Application Development Services
                  </h2>

                  <p class="mb-sm-2 mb-0 f-16 text-justify design-and-content-para">
                    Senwell Group Private Limited has an experienced team of
                    mobile app developers that can create feature-rich,
                    performant apps for various platforms. We use innovative
                    methods and tools to make sure your company stays ahead of
                    the competition in a highly competitive market. Even the
                    most complex business needs can be expertly handled by our
                    staff. Millions of users have benefited from the robust apps
                    we've created for iOS and Android. Being a top mobile app
                    development company, we place a high value on reliable
                    functionality, secure designs, and UI/UX. Work together with
                    our developers to combine technical expertise and
                    inventiveness to bring your digital ideas to life.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-services">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row" style={{ marginBottom: "60px" }}>
              <div className="col-lg-12 col-md-12 text-center">
                <h2 className="section-head">
                  Mobile App Development Experience
                </h2>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={ApplicationD} alt="img" />
                  </div>
                  <h3>Android App Development</h3>

                  <p>
                    One popular open-source operating system for mobile apps is
                    called Android. Our knowledgeable staff creates unique
                    business apps to help you stay one step ahead of the
                    competition. Our Android app developers create flexible,
                    affordable, and user-friendly solutions using the best
                    frameworks and tools. Join our team in embracing digital
                    transformation.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={ApplicationMord} alt="img" />
                  </div>
                  <h3>iOS App Development</h3>

                  <p>
                    iOS has gained widespread popularity thanks to its strong
                    brand value and safe, effective interactive designs. You can
                    create innovative business applications with the assistance
                    of our knowledgeable iOS developers. Using Swift and
                    Objective-C primarily, we provide eye-catching designs and
                    unique iOS solutions across multiple domains.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={prototype} alt="img" />
                  </div>
                  <h3>Cross Platform Mobile Apps</h3>

                  <p>
                    App development companies can create applications that work
                    with multiple operating systems by using cross-platform
                    development. Our group specializes in creating these kinds
                    of applications for customers in different industries. With
                    native-like apps for iOS and Android, we can revolutionize
                    your company and help you succeed in the digital sphere.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={Uiuxdesing} alt="img" />
                  </div>
                  <h3>React Native Cross Platform App Development</h3>

                  <p>
                    The benefits of React Native app development paired with the
                    Senwell Group Private Limited team's experience create the
                    ideal recipe for a successful mobile application. This
                    combination provides you with an advantage over your
                    competitors through efficient cross-platform app development
                    for iOS and Android.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={ProductConsulting} alt="img" />
                  </div>
                  <h3>Flutter Cross Platform App Development</h3>

                  <p>
                    Your app will have a native feel and look thanks to Flutter
                    app development, which your users will love. In order to
                    produce a top-notch app for users of iOS and Android, our
                    mobile app development services will further streamline the
                    Flutter app development process.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={Maintenance1} alt="img" />
                  </div>
                  <h3>Mobile App Support & Maintenance</h3>

                  <p>
                    As we all know, creating an application is not enough.
                    Maintaining a smooth user experience and providing timely
                    updates are equally crucial. We can keep your app updated
                    with the newest features and versions while offering full
                    support and maintenance services for mobile apps.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section-padding clients-section ">
          <div class="container" style={{ left: "0", border: "none" }}>
            <div class="row align-items-center">
              <div class="col-lg-4 pr-lg-5">
                <h2 class="section-heading">
                  Our Leading Multinational Clients
                </h2>
                <p>
                  Join our diverse client base for a digital transformation
                  journey
                </p>
              </div>
              <div class="col-lg-8">
                <div class="clients-logo-list" style={{ display: "flex" }}>
                  <div class="logo-col">
                    <div class="logo-image">
                      <img
                        width="250"
                        height="102"
                        src={client9}
                        alt="MindLift Industries Logo"
                        title="MindLife"
                        className="entered lazyloaded"
                      />
                    </div>
                  </div>
                  <div class="logo-col">
                    <div class="logo-image">
                      <img
                        width="250"
                        height="102"
                        src={client10}
                        alt="worldbank Logo | senwell"
                        title="Worldbank"
                        data-ll-status="loaded"
                        class="entered lazyloaded"
                      />
                    </div>
                  </div>
                  <div class="logo-col">
                    <div class="logo-image">
                      <img
                        width="250"
                        height="102"
                        src={client11}
                        alt="client11"
                        title="cisco"
                        data-ll-status="loaded"
                        class="entered lazyloaded"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <IndustrySection />

        <InsightSection />
      </div>
    </>
  );
};

export default ApplicationDev;
