import React from "react";
import { makeStyles, Grid, Container, Typography } from "@material-ui/core";
import { Box } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    padding: "2rem 1rem",
    width: "80%",
    margin: "auto",
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    },
    // borderRadius:"15px"
  },
  title: {
    fontFamily: "'Ubuntu', sans-serif",
  },
  subtitle: {
    fontFamily: "'Ubuntu', sans-serif",
    textAlign: "justify",
    padding: "1rem 0rem",
  },
  para: {
    fontFamily: "'Poppins', serif",
    fontSize: "17px",
    textAlign: "justify",
    // lineHeight:'1.2rem',
  },
}));
const OurExperties = ({ OurExperties }) => {
  const classNamees = useStyles();

  return (
    <>
      <Container maxWidth="lg" className={classNamees.mainWrapper}>
        {OurExperties?.map((data) => (
          <>
            <Box container>
              <Typography variant="h4" className={classNamees.title}>
                Our Expertise
              </Typography>
              <Typography variant="h6" className={classNamees.subtitle}>
                {data.title}
              </Typography>

              <Typography className={classNamees.para}>{data.para}</Typography>
            </Box>
          </>
        ))}
      </Container>
    </>
  );
};

export default OurExperties;
