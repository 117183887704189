import React from "react";
import { Container, Typography, Grid, Button } from "@material-ui/core";
import "../../css/bannarstylesheet.css";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  HireNowButton: {
    padding: "0.7rem 4rem",
    color: "#fff",
    marginBottom: "2rem",
    letterSpacing: "2.5px",
    fontSize: "1rem",
    fontWeight: "600",
    fontFamily: "'Manrope', sans-serif",
    border: "2px solid rgb(31 142 203)",
    borderRadius: "25rem",
    background:
      "linear-gradient(90deg, hsla(200, 69%, 50%, 1) 24%, hsla(216, 62%, 39%, 1) 83%)",
    "&:hover": {
      background: "transparent",
      borderRadius: "25rem",
      border: "2px solid #fff",
    },
  },
  HeroBannerTitle: {
    fontSize: "3rem",
    color: "#fff",
    fontWeight: "700",
    fontFamily: "'Ubuntu', sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
  paragraph: {
    fontFamily: "'Poppins', sans-serif",

    [theme.breakpoints.up("md")]: {
      padding: "0.3rem 10rem",
    },
  },
}));
const Bannar = ({ HeroBanner }) => {
  const classNamees = useStyles();
  return (
    <>
      {HeroBanner?.map((data) => (
        <>
          {data.name === "aerospace-and-defense" ? (
            <div
              className="aerospacejumbotron aerospaceparallax"
              id="aerospaceparallax-static"
            >
              <div className="showcasebanner">
                <div className="overlaybanner">
                  <Container
                    style={{ padding: "2rem 1rem", marginTop: "15rem" }}
                    maxWidth="md"
                  >
                    <Grid
                      container
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <Grid items xs={12} sm={12} md={12}>
                        <h1 className={classNamees.HeroBannerTitle}>
                          {data.title}
                        </h1>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          ) : data.name === "automotive" ? (
            <div
              className="automotivejumbotron productparallax"
              id="automotiveparallax-static"
            >
              <div className="showcasebanner">
                <div className="overlaybanner">
                  <Container
                    style={{ padding: "2rem 1rem", marginTop: "15rem" }}
                    maxWidth="md"
                  >
                    <Grid
                      container
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <Grid items xs={12} sm={12} md={12}>
                        <h1 className={classNamees.HeroBannerTitle}>
                          {data.title}
                        </h1>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          ) : data.name === "communications" ? (
            <div
              className="communicationsjumbotron communicationsparallax"
              id="communicationsparallax-static"
            >
              <div className="showcasebanner">
                <div className="overlaybanner">
                  <Container
                    style={{ padding: "2rem 1rem", marginTop: "15rem" }}
                    maxWidth="md"
                  >
                    <Grid
                      container
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <Grid items xs={12} sm={12} md={12}>
                        <h1 className={classNamees.HeroBannerTitle}>
                          {data.title}
                        </h1>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          ) : data.name === "education" ? (
            <div
              className="educationjumbotron educationparallax"
              id="educationparallax-static"
            >
              <div className="showcasebanner">
                <div className="overlaybanner">
                  <Container
                    style={{ padding: "2rem 1rem", marginTop: "15rem" }}
                    maxWidth="md"
                  >
                    <Grid
                      container
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <Grid items xs={12} sm={12} md={12}>
                        <h1 className={classNamees.HeroBannerTitle}>
                          {data.title}
                        </h1>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          ) : data.name === "platforms-and-software-products" ? (
            <div
              className="platformsinjumbotron platformsparallax"
              id="platformsparallax-static"
            >
              <div className="showcasebanner">
                <div className="overlaybanner">
                  <Container
                    style={{ padding: "2rem 1rem", marginTop: "15rem" }}
                    maxWidth="md"
                  >
                    <Grid
                      container
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <Grid items xs={12} sm={12} md={12}>
                        <h1 className={classNamees.HeroBannerTitle}>
                          {data.title}
                        </h1>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          ) : data.name === "industrial-and-process-manufacturing" ? (
            <div
              className="industrialjumbotron industrialparallax"
              id="industrialparallax-static"
            >
              <div className="showcasebanner">
                <div className="overlaybanner">
                  <Container
                    style={{ padding: "2rem 1rem", marginTop: "15rem" }}
                    maxWidth="md"
                  >
                    <Grid
                      container
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <Grid items xs={12} sm={12} md={12}>
                        <h1 className={classNamees.HeroBannerTitle}>
                          {data.title}
                        </h1>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          ) : data.name === "travel-and-transportation" ? (
            <div
              className="traveljumbotron travelparallax"
              id="travelparallax-static"
            >
              <div className="showcasebanner">
                <div className="overlaybanner">
                  <Container
                    style={{ padding: "2rem 1rem", marginTop: "15rem" }}
                    maxWidth="md"
                  >
                    <Grid
                      container
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <Grid items xs={12} sm={12} md={12}>
                        <h1 className={classNamees.HeroBannerTitle}>
                          {data.title}
                        </h1>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      ))}
    </>
  );
};

export default Bannar;
