import React from "react";
import "../Footer/LastFooter.css";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setTech } from "../../redux/tech/techActions";
import TwitterLogo from "../../images/footer/Twitter-new-logo.png";
import Logo1 from "../../images/Company/finalsenwellgrouplogo.png";

const LastFooter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFooterButtonClick = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log("nameValue : ", name, value);
    dispatch(setTech(name));
    navigate(`/hire-developers/${value}`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <>
      <footer className="footer dark-mode border-top border-light pt-3 pb-4 pb-lg-4 sn-footer">
        <div
          className="container pt-lg-4"
          style={{ left: "0", border: "none" }}
        >
          <div className="row pb-5">
            <div className="col-xl-12 col-lg-12 col-md-12 pt-4 pt-md-1 pt-lg-0">
              <div id="footer-links" className="row">
                <div className="col-lg-5 col-sm-5  ">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "30px",
                    }}
                  >
                    <div>
                      <img src={Logo1} alt="Logo" width="70px" height="70px" />
                    </div>
                    <div
                      style={{
                        marginLeft: "5px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <h5
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          color: "white",
                          marginBottom: "-0.5rem",
                        }}
                      >
                        Senwell Group <br></br>
                        Private Limited
                      </h5>
                    </div>
                  </div>
                  <div
                    id="useful-links"
                    className='data-bs-parent="#footer-links"'
                  >
                    <h5>Office Address</h5>

                    <div className="d-flex flex-row  mt-2">
                      <i class="bx bxs-location-plus footer-address"></i>
                      <span className="footer-address-span">
                        Gera's Imperium Alpha, Near Eon IT Park,<br></br>{" "}
                        Kharadi, Pune - 411014 <br />
                        Maharashtra (India)
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-center mt-2">
                      <i className="bx bxs-envelope footer-address"></i>
                      <span className="footer-address-span">
                        <a href="mailto:info@senwellsys.com">
                          Email: info@senwellsys.com
                        </a>
                      </span>
                    </div>
                    <div className="mt-3">
                      <h6 className="Get-In-Touch">Get In Touch With Us!</h6>
                      <div className="d-flex " style={{ columnGap: "5px" }}>
                        <a
                          href="https://www.linkedin.com/company/70994100/admin/"
                          target="_blank"
                          className="f-icon-style"
                          style={{ textDecoration: "none" }}
                        >
                          <i className="touch-icon Touch-btn-outline-secondary bx bxl-linkedin-square f-20 btn-linkedin"></i>
                        </a>
                        <a
                          href="https://www.facebook.com/people/Senwell-Solutions/100068983571168/"
                          target="_blank"
                          className="f-icon-style mr-3"
                          style={{ textDecoration: "none" }}
                        >
                          <i className="touch-icon Touch-btn-outline-secondary bx bxl-facebook-square f-20 btn-facebook"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/senwellgroup/"
                          target="_blank"
                          className="f-icon-style mr-3"
                          style={{ textDecoration: "none" }}
                        >
                          <i className="touch-icon Touch-btn-outline-secondary bx bxl-instagram-alt f-20 btn-instagram"></i>
                        </a>
                        <a
                          href="https://www.youtube.com/@senwellgroup"
                          target="_blank"
                          className="f-icon-style"
                          style={{ textDecoration: "none" }}
                        >
                          <i className="touch-icon Touch-btn-outline-secondary bx bxl-youtube f-20 btn-linkedin"></i>
                        </a>

                        <a
                          href="https://twitter.com/i/flow/login?redirect_after_login=%2FSenwellPune"
                          target="_blank"
                          className="f-icon-style mr-3"
                          style={{ textDecoration: "none" }}
                        >
                          <i className="touch-icon Touch-btn-outline-secondary f-20 btn-twitter">
                            <img
                              src={TwitterLogo}
                              alt="twitter-log"
                              style={{ width: "20px" }}
                            />
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-2">
                  <div
                    id="useful-links"
                    className='data-bs-parent="#footer-links"'
                  >
                    <h5>Popular Categories</h5>
                    <ul className="nav flex-column pb-lg-1 mb-lg-3">
                      <li className="nav-item">
                        <Link to="/softwareproduct" className="nav-link">
                          Software Product Engineering
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/custom-software-development"
                          className="nav-link"
                        >
                          Custom Software Development
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="saas-development-services"
                          className="nav-link"
                        >
                          Software as a Services
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/mobile-app-development-services"
                          className="nav-link"
                        >
                          Mobile App Development
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/web-development" className="nav-link">
                          Web Development
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          to="/qa-and-software-testing-services"
                          className="nav-link"
                        >
                          Software Testing & QA
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/ui-ux-design-services" className="nav-link">
                          UI/UX Design
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-2 col-sm-2">
                  <div
                    id="useful-links"
                    className='data-bs-parent="#footer-links"'
                  >
                    <h5>Company</h5>
                    <ul className="nav flex-column pb-lg-1 mb-lg-3">
                      <li className="nav-item">
                        <Link to="/portfolio" className="nav-link">
                          Portfolio
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/blogs" className="nav-link">
                          Blogs / Articles
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/careers" className="nav-link">
                          Careers
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/company" className="nav-link">
                          About us
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/contact" className="nav-link">
                          Contact us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-2 col-sm-2">
                  <div
                    id="useful-links"
                    className='data-bs-parent="#footer-links"'
                  >
                    <h5>Technologies</h5>
                    <ul className="nav flex-column pb-lg-1 mb-lg-3">
                      <li className="nav-item">
                        <button
                          name="React"
                          value="react"
                          onClick={handleFooterButtonClick}
                          className="nav-link-tech"
                        >
                          React
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          name="Angular"
                          value="angular"
                          onClick={handleFooterButtonClick}
                          className="nav-link-tech"
                        >
                          Angular
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          name="Node.js"
                          value="nodejs"
                          onClick={handleFooterButtonClick}
                          className="nav-link-tech"
                        >
                          Node Js
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          name=".Net"
                          value="dot-net"
                          onClick={handleFooterButtonClick}
                          className="nav-link-tech"
                        >
                          .Net
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          name="Java"
                          value="Java"
                          onClick={handleFooterButtonClick}
                          className="nav-link-tech"
                        >
                          Java
                        </button>
                      </li>
                      <li class="nav-item">
                        <button
                          name="React Native"
                          value="react-native"
                          onClick={handleFooterButtonClick}
                          className="nav-link-tech"
                        >
                          React Native
                        </button>
                      </li>

                      <li class="nav-item">
                        <button
                          name="Android"
                          value="Android"
                          onClick={handleFooterButtonClick}
                          className="nav-link-tech"
                        >
                          Android
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <p class="d-flex justify-content-center fs-xs text-center text-md-start pb-2 pb-lg-0 mb-1 mt-4">
          <span class="text-light opacity-50">
            &#169;
            <span id="year-div-footer">{new Date().getFullYear()}</span> |&nbsp;
            <Link
              class="nav-link d-inline-block p-0"
              to="/privacy"
              target="_blank"
            >
              Privacy Policy
            </Link>{" "}
            |&nbsp;
            <a
              class="nav-link d-inline-block p-0"
              href="https://senwellsys.com/"
              target="_blank"
            >
              Senwell Group Private Limited
            </a>{" "}
            |&nbsp; All rights reserved
          </span>
        </p>
      </footer>
    </>
  );
};

export default LastFooter;
