import React from "react";
import Typewriter from "typewriter-effect";
import "../Modules/Slider.css";
//import HomeVideo from "../assets/HomeVideo.mp4";

export default function SliderDemo() {
  return (
    <>
      <div className="bg-video-wrap">
        <div className="landing-container">
          <div id="ggg" className="videocontainer">
            <div className="clickwrapper">
              <iframe
                src="https://player.vimeo.com/video/870167957?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;background=1&amp;loop=1&amp;muted=1&amp;allowfullscreen=1&amp;playsinline=1"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                playsine
                autoplay
                allowFullScreen
                title="ls-welcome"
              ></iframe>
            </div>
          </div>
        </div>

        <div className="overlay"></div>
        <div className="Home-Heading">
          <div className="video-container-text Video-text">
            <div className="text-div">
              <p className="first-para">
                Discover a New Vision for Your Business Solutions with Our
                Expertise
              </p>
              <h2 className="Home-heading-h2">We</h2>
              <div className="Home-heading-h2">
                <Typewriter
                  options={{
                    autoStart: true,
                    loop: true,
                    delay: 40,
                    strings: ["Create", "Build", "Digitize"],
                  }}
                />
              </div>
              <p className="second-para">
                The next stage of your digital success begins right here with
                Senwell Group Private Limited.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
