import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import "./WeHelpWith.css";
import Web1 from "../../images/ServiceWeOffer/Web-1.webp";
import Dig2 from "../../images/ServiceWeOffer/Digital-2.webp";
import Mob1 from "../../images/ServiceWeOffer/Mob-1.webp";
import It2 from "../../images/ServiceWeOffer/It-2.webp";
import Dev1 from "../../images/ServiceWeOffer/Dev-1.webp";
import Tes1 from "../../images/ServiceWeOffer/Testing-1.webp";
import Out1 from "../../images/ServiceWeOffer/Out-1.webp";
import Desing from "../../images/ServiceWeOffer/UI Desing.webp";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  TabBox: {},
}));

const WeHelpWith = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const classNamees = useStyles();
  const [value, setValue] = useState(0);
  const handleTabs = (e, val) => {
    setValue(val);
  };

  return (
    <div>
      <section style={{ backgroundColor: "#f3f6ff", padding: "50px 0px" }}>
        <div className="container" style={{ left: "0", border: "none" }}>
          <h2
            className="text-center serviceWeOffer"
            style={{ marginBottom: "30px" }}
          >
            Services We Offer
          </h2>
          <p className="text-center serviceWeOffer-para">
            We build software solutions with clean code and engage in all stages
            of product development.
          </p>
          <div
            className="row"
            style={{ marginTop: "50px", rowGap: "50px", cursor: "default" }}
          >
            <div className="col-lg-6 col-md-6 col-sm-12">
              <Link to="/softwareproduct" style={{ textDecoration: "none" }}>
                <div className="card-first">
                  <div className="image">
                    <img src={Web1} style={{ width: "100%" }} alt="web-logo" />
                  </div>
                  <div className="content-block">
                    <h3 className="services-subheader">
                      Software Product Development
                    </h3>
                    <p className="Services-Para">
                      Senwell Group Private Limited can help you realize your
                      business's vision because we are experts in developing
                      software products that are intuitive and driven by design.
                      Our objective is to accelerate the innovation of software
                      products, offering a unique competitive advantage and a
                      superior customer experience.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <Link
                to="/custom-software-development"
                style={{ textDecoration: "none" }}
              >
                <div className="card-first">
                  <div className="content-block">
                    <h3 className="services-subheader">
                      Custome Software Development
                    </h3>
                    <p className="Services-Para">
                      Senwell Group Private Limited can help you achieve a
                      unique advantage for your complex business needs. Realize
                      the full potential of our expertise in developing
                      dependable, agile custom software solutions and
                      applications. Accelerate the digital transformation of
                      your company with our dedication to scalability, agility,
                      and technological innovation.
                    </p>
                  </div>
                  <div className="image">
                    <img src={Dig2} style={{ width: "100%" }} alt="Dig2" />
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <Link to="/saas-development-services" style={{ textDecoration: "none" }}>
                <div className="card-first">
                  <div className="image">
                    <img src={Mob1} style={{ width: "100%" }} alt="Mob1" />
                  </div>
                  <div className="content-block">
                    <h3 className="services-subheader">SaaS Development</h3>
                    <p className="Services-Para">
                      Creating a successful SaaS product requires more than just
                      writing code, it also requires careful planning,
                      user-centered design thinking, efficient development, and
                      ongoing improvement. With our comprehensive SaaS
                      development services, which cover every stage from
                      conception to deployment and beyond, you can take your
                      business to new heights.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <Link to="/qa-and-software-testing-services" style={{ textDecoration: "none" }}>
                <div className="card-first">
                  <div className="content-block">
                    <h3 className="services-subheader">
                      Software Testing & QA
                    </h3>
                    <p className="Services-Para">
                      Work with Senwell Group Private Limited to fully immerse
                      yourself in the dynamic world of software testing and
                      quality assurance services. Our dedication assures you of
                      cost-effectiveness as well as unmatched delivery speed
                      without sacrificing quality. Set new industry standards
                      for excellence with a significant increase in the
                      efficiency of code deployment and an optimized Software
                      Development Life Cycle.
                    </p>
                  </div>
                  <div className="image">
                    <img src={It2} style={{ width: "100%" }} alt="It2" />
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <Link to="/mobile-app-development-services" style={{ textDecoration: "none" }}>
                <div className="card-first">
                  <div className="image">
                    <img src={Tes1} style={{ width: "100%" }} alt="Desing" />
                  </div>
                  <div className="content-block">
                    <h3 className="services-subheader">
                      Application Development
                    </h3>
                    <p className="Services-Para">
                      Senwell Group Private Limited is a leading mobile app
                      development company that prides itself on having a team of
                      experienced developers with a track record of
                      accomplishments. Our professionals are skilled in creating
                      hybrid, iOS, and Android apps with a clear emphasis on
                      user-centric design. Put your trust in us to use our
                      unmatched experience and digital intelligence to bring
                      your vision to life.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <Link to="/ui-ux-design-services" style={{ textDecoration: "none" }}>
                <div className="card-first">
                  <div className="content-block">
                    <h3 className="services-subheader">UI/UX Design</h3>
                    <p className="Services-Para">
                      Create a user interface that is enticing in order to
                      improve business engagement and provide memorable online
                      experiences. Unlock Senwell Group Private Limited' full
                      potential by making use of state-of-the-art tools and
                      technologies to create aesthetically pleasing and
                      intuitive designs. To make sure your online presence
                      sticks out in the crowded online space, take advantage of
                      our extensive creative designing experience.
                    </p>
                  </div>
                  <div className="image">
                    <img src={Desing} style={{ width: "100%" }} alt="Tes1" />
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <Link to="/web-development" style={{ textDecoration: "none" }}>
                <div className="card-first">
                  <div className="image">
                    <img src={Dev1} style={{ width: "100%" }} alt="Dev1" />
                  </div>
                  <div className="content-block">
                    <h3 className="services-subheader">Web Development</h3>
                    <p className="Services-Para">
                      Give Senwell Group Private Limited the task of
                      meticulously planning, developing, and implementing web
                      apps that result in notable changes to transform your web
                      development projects. Within our comprehensive development
                      approach, we begin by conducting a thorough analysis of
                      the disruptive web development challenges. Next, with
                      ease, we design a user-friendly user interface (UI/UX)
                      that ensures a reliable, fully functional web solution
                      tailored to your unique needs.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <Link to="/cloud-consulting-services" style={{ textDecoration: "none" }}>
                <div className="card-first">
                  <div className="content-block">
                    <h3 className="services-subheader">Cloud</h3>
                    <p className="Services-Para">
                      Senwell Group Private Limited is a prominent provider of
                      cloud solutions and a leading cloud development company.
                      Its mission is to help businesses transform their IT
                      infrastructure seamlessly by offering highly affordable
                      cloud services. Our team of seasoned professionals
                      specializes in providing cutting-edge cloud computing
                      consulting services, enabling businesses to achieve
                      previously unheard-of levels of productivity.
                    </p>
                  </div>
                  <div className="image">
                    <img src={Out1} style={{ width: "100%" }} alt="Out1" />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WeHelpWith;
function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <h1>{children}</h1>}</div>;
}
