import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Modules/WhoWeAre/WhoWeAre.css";
import whoLogo from "../../images/Homepage/Who2.webp";
import { Link } from "react-router-dom";

const WhoWeAre = () => {
  return (
    <>
      <section style={{ backgroundColor: "#f3f6ff", padding: "5px 0px" }}>
        <div className="container my-5" style={{ left: "0", border: "none" }}>
          <div className="row">
            <div className="col-md-6 d-flex flex-column">
              <div style={{ padding: "30px" }}>
                <h1 className="whowe-header">Who Are We?</h1>
                <p className="whowe-para">
                  Senwell Group Private Limited is an innovative global
                  platform, seamlessly blending web development, design, and
                  digital marketing services, including social media
                  marketing.Our mission is to empower you with strategic
                  solutions and state-of-the-art technology, propelling you to
                  market excellence and achieving triumph. Rely on us for
                  comprehensive support to thrive and succeed.
                </p>
                <p className="whowe-para" style={{ paddingBottom: "1rem" }}>
                  We are not just a team, but a dynamic and motivated
                  powerhouse, passionately dedicated to crafting innovative
                  solutions that resonate with the pulse of today's
                  ever-evolving business landscape. Our unwavering commitment
                  lies in not only providing top-notch services but also in
                  understanding and adapting them to your distinct needs, thus
                  granting you the tools not just to survive but to truly
                  flourish in the cutthroat competitive market. Count on us to
                  not only be a reliable partner but an enthusiastic architect
                  of your triumphant journey towards success.
                </p>
                <Link to="/company">
                  {" "}
                  <a href="#" className="btn-primary know-more">
                    Know more about us
                  </a>{" "}
                </Link>
              </div>
            </div>
            <div className="whowe-section col-md-6 d-flex align-items-center justify-content-center">
              <img src={whoLogo} className="img-fluid" alt="WhoLogo" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhoWeAre;
