import InfoIcon from "@material-ui/icons/Info";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import ComputerIcon from "@material-ui/icons/Computer";
import FlipToBackIcon from "@material-ui/icons/FlipToBack";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";

import React from "react";
export const menu = [
  {
    title: "Home",
    Link: "/",
  },
  {
    title: "Company",
    items: [
      {
        title: "Portfolio",
        Link: "/portfolio",
      },

      {
        icon: <InfoIcon />,
        title: "About Us",
        Link: "./company",
      },
    ],
  },

  {
    title: "Services",
    items: [
      {
        title: "Software Product Development",
        Link: "/softwareproduct",
      },
      {
        title: "Software Development",
        Link: "/custom-software-development",
      },

      {
        title: "SaaS Development",
        Link: "/saas-development-services",
      },

      {
        title: "Application Development",
        Link: "/mobile-app-development-services",
      },
      {
        title: "Web App Development",
        Link: "/web-development",
      },

      {
        title: "Software Testing",
        Link: "/qa-and-software-testing-services",
      },

      {
        title: "UI/UX Design",
        Link: "/ui-ux-design-services",
      },

      {
        title: "Cloud",
        Link: "/cloud-consulting-services",
      },
    ],
  },

  {
    title: "Technologies",
    items: [
      {
        icon: <ComputerIcon />,
        title: "Front-End",
        Link: "/hire-developers/front-end",
        items: [
          {
            title: "React",
            to: "/thedowtheory",
            Link: "/hire-developers/react",
          },
          {
            title: "Angular",
            to: "/thedowtheory",
            Link: "/hire-developers/angular",
          },
          {
            title: "Next",
            to: "/thedowtheory",
            Link: "hire-developers/Next",
          },
        ],
      },
      {
        icon: <FlipToBackIcon />,
        title: "Back-end",
        Link: "/hire-developers/back-end",
        items: [
          {
            title: "Node.js",
            to: "/thedowtheory",
            Link: "hire-developers/nodejs",
          },
          {
            title: ".Net",
            to: "/thedowtheory",
            Link: "/hire-developers/dot-net",
          },

          {
            title: "Java",
            to: "/thedowtheory",
            Link: "/hire-developers/Java",
          },

          {
            title: "Nest",
            to: "/thedowtheory",
            Link: "/hire-developers/Nest",
          },
        ],
      },

      {
        icon: <PhoneAndroidIcon />,
        title: "Mobile",
        Link: "/hire-developers/mobile",
        items: [
          {
            title: "Ios",
            to: "/thedowtheory",
            Link: "/hire-developers/Ios",
          },
          {
            title: "Android",
            to: "/thedowtheory",
            Link: "/hire-developers/Android",
          },
          {
            title: "React Native",
            to: "/thedowtheory",
            Link: "/hire-developers/react-native",
          },
          {
            title: "Flutter",
            to: "/thedowtheory",
            Link: "/hire-developers/Flutter",
          },
        ],
      },
    ],
  },

  {
    title: "Blog",
    Link: "/blogs",
  },

  {
    icon: <ImportContactsIcon />,
    title: "Career",
    Link: "/careers",
  },

  {
    title: "Contact",
    Link: "contact",
  },
];
