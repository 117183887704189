import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyle = makeStyles((theme) => ({
  mainWrapper: {
    padding: "4rem 0rem",
    [theme.breakpoints.down('sm')]: {
      padding: "0 0"
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    
  },
  linkWithIcons: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  PastProjectBlockPara: {
    textAlign: "left",
    lineHeight: "1.6rem",
    fontSize: "17px",
    letterSpacing: "0.5px",
    color:"#000",
    paddingRight: '3rem',

    [theme.breakpoints.between(380, 600)]: {
      maxWidth: "26rem",
      padding: '0',
      overFlow: 'scroll'
    },
    [theme.breakpoints.between(320, 380)]: {
      padding: '0',
      overFlow: 'scroll'
    },
  },
  PastProjectBlockMainHeading: {
    fontSize: "36px",
    fontWeight: "600",
    lineHeight: "56px",
    fontFamily: 'Ubuntu',
    [theme.breakpoints.down('sm')]: {
      textAlign:'center'
    },
  },

  PastProjectBlockSubHeading: {
    fontWeight: "600",
    fontSize: "1.75rem",
    paddingBottom: "0rem",
    [theme.breakpoints.down('sm')]: {
      textAlign:'center'
    },
  },
  BottomLinkWithIcons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  viewallLink: {
    textDecoration: "underline",
    marginRight: "1.3rem",
    fontSize: "1rem",
  },
  arrowBlock: {
    display: "flex",
  },
  DownArrowBlock: {
    marginTop: "10px",
    display: "flex",
  },
  slider: {
    
    display: "flex !important",
    alignItems: "center !important",
    [theme.breakpoints.down("sm")]: {
      display: "flex !important",
      flexDirection: "column-reverse !important",

      alignItems: "center !important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex !important",
      flexDirection: "column-reverse !important",
      margin: "0 1rem",
    },
  },
  marginCls: {
    width: '80%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  slider_imgs: {
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
    borderRadius: '8px',
    [theme.breakpoints.between(320, 380)]: {
      maxWidth: "17.5rem",
      maxHeight: "17.5rem",
      margin: '0',
      objectFit: 'cover'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: "17.5rem",
      maxHeight: "17.5rem",
      objectFit: 'cover'
    },
    maxWidth: "48vw",
    margin: "10px",
  },
}));

const PastProjects = ({ ProjectPortfolio, ProjectPortfolliotitle }) => {
  const [sliderRef, setSliderRef] = useState(null);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const classNamees = useStyle();

  return (
    <>
      <Container maxWidth={false} className={classNamees.mainWrapper}>
        <Container maxWidth="lg" className={classNamees.marginCls}>
          <div className={classNamees.header}>
            <h1 className={classNamees.PastProjectBlockMainHeading}>
              {ProjectPortfolliotitle}
            </h1>
            <div className={classNamees.linkWithIcons}>
              {/* <Link to="/portfolio" className={classNamees.viewallLink}>
                {" "}
                View all
              </Link> */}
              <span className={classNamees.arrowBlock}>
                <ArrowBackIcon
                  style={{ color: "#3D8ED7", marginRight: "15px",cursor:"pointer" }}
                  onClick={sliderRef?.slickPrev}
                />
                <ArrowForwardIcon
                  style={{ color: "#3D8ED7",cursor:"pointer" }}
                  onClick={sliderRef?.slickNext}
                />
              </span>
            </div>
          </div>
          <Slider ref={setSliderRef} {...settings} arrows={false}>
            {ProjectPortfolio?.map((data) => (
              <>
                <div className={classNamees.slider}>
                  <div>
                    <h3 className={classNamees.PastProjectBlockSubHeading}>
                      {data.title}
                    </h3>
                    <p className={classNamees.PastProjectBlockPara} style={{ width:"auto" ,height:"250px", textAlign:"justify"}}>{data.para}</p>
                    {/* <a href="#" className={classNamees.viewallLink}>
                      Read more
                    </a> */}
                  </div>
                  <div>
                    <img
                      src={data.image}
                      alt="images"
                      className={classNamees.slider_imgs}
                      height="350px"
                      paddingBottom="0px"
                    />
                  </div>
                </div>
              </>
            ))}
          </Slider>
          <div>
            <div className={classNamees.BottomLinkWithIcons}>
              {/* <a href="#" className={classNamees.viewallLink}>
                view all
              </a> */}
              <span className={classNamees.DownArrowBlock}>
                <ArrowBackIcon
                  style={{ color: "#3D8ED7", marginRight: "15px" }}
                  onClick={sliderRef?.slickPrev}
                />
                <ArrowForwardIcon
                  style={{ color: "#3D8ED7" }}
                  onClick={sliderRef?.slickNext}
                />
              </span>
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default PastProjects;
