/* eslint-disable default-case */
import { SET_SCOPE } from "./scopeType";
import { GET_SCOPE } from "./scopeType";


const initialState = {
  loading: true,
  error: "",
  data: [],
};

export const scopeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCOPE:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_SCOPE:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    default:
      return {};
  }
};
