import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckedIcon from '@material-ui/icons/Check';
import UnCheckedIcon from '@material-ui/icons/Close';
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme)=>({
    Title:{
        color: '#3D8ED7',
        fontSize:'larger', 
        border:'3px solid #fff',
        background:'#f7f7f7',
        margin:'3px', 
        fontFamily: "'Poppins', sans-serif"

    },
    data:{
     justifyContent:"center",
     textAlign:"center",
     alignContent:"center",
      border:'3px solid #fff',
      background:'#f7f7f7',
     
    },
    MainWrapper:{
        padding:'2rem 6rem 4rem 6rem',
        width: '90%',
        margin:' auto',
        [theme.breakpoints.down('sm')]: {
          padding:'1rem 1rem',
          width: '100%'
        },
    },
    tableHeading:{
      
      border:'3px solid #fff',
      background:'#f7f7f7',
      fontFamily: "'Poppins', sans-serif"

    },
    table:{
      
     boxShadow:'none',
     border:'none',
    }
  
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Quick start', <CheckedIcon style={{color:"green"}} />, <CheckedIcon style={{color:"green"}} />, <CheckedIcon style={{color:"green"}} />),
  createData('Fixed budget', <UnCheckedIcon style={{color:"red"}} />, <UnCheckedIcon style={{color:"red"}} />, <UnCheckedIcon style={{color:"red"}} />,),
  createData('Full team engagement', <UnCheckedIcon style={{color:"red"}} />, <CheckedIcon style={{color:"green"}} />, <UnCheckedIcon style={{color:"red"}} />),
  createData('Direct control over the team', <UnCheckedIcon style={{color:"red"}} />, <CheckedIcon style={{color:"green"}} />, <CheckedIcon style={{color:"green"}} />),
  createData('Flexible management', <UnCheckedIcon style={{color:"red"}}/>, <CheckedIcon style={{color:"green"}}/>, <CheckedIcon style={{color:"green"}} />),
  createData('Fixed deadlines', <UnCheckedIcon style={{color:"red"}}/>, <UnCheckedIcon style={{color:"red"}}/>, <UnCheckedIcon style={{color:"red"}}/>,),
  createData('Cost-efficient search and recruitment', <UnCheckedIcon style={{color:"red"}}/>, <CheckedIcon style={{color:"green"}}/>, <CheckedIcon style={{color:"green"}}/>,),
  createData('Suitable for continuous development / maintenance projects', <CheckedIcon style={{color:"green"}}/>, <CheckedIcon style={{color:"green"}}/>, <CheckedIcon style={{color:"green"}}/>),
  createData('Easy to scale the team', <CheckedIcon style={{color:"green"}}/>, <CheckedIcon style={{color:"green"}}/>, <CheckedIcon style={{color:"green"}}/>,),
  createData('Access to our proprietary code quality tools', <CheckedIcon style={{color:"green"}}/>, <CheckedIcon style={{color:"green"}}/>, <CheckedIcon style={{color:"green"}}/>),
];

export default function BudgetTable() {
  const classNamees = useStyles();

  return (
      <Container maxWidth='lg' className={classNamees.MainWrapper}>
    <TableContainer>
      <Table className={classNamees.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" className={classNamees.Title} >Details</TableCell>
            <TableCell align="Center" className={classNamees.Title}>Time and Material</TableCell>
            <TableCell align="Center" className={classNamees.Title}>Dedicated Team</TableCell>
            <TableCell align="Center" className={classNamees.Title}> Staff Augmentation</TableCell>
         
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row" className={classNamees.tableHeading}>
                {row.name}
              </TableCell>
              <TableCell align="Center" className={classNamees.data}>{row.calories}</TableCell>
              <TableCell align="Center"  className={classNamees.data}>{row.fat}</TableCell>
              <TableCell align="Center"  className={classNamees.data}>{row.carbs}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
  );
}
