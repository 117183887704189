import React from 'react';
import Lottie from "react-lottie";
import animationData from "../../../assets/Animation.json";
import "../../../Pages/Services/IT-Staf/ITStaf.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { faAward } from '@fortawesome/free-solid-svg-icons';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import Rapid from "../../../images/Staff/Rapid Access.png";
import Flexible from "../../../images/Staff/Flexible Pricing.webp";
import LongTerm from "../../../images/Staff/Long term Partnership.webp";
import Continuous from "../../../images/Staff/Continuous Learning.webp";
import {Link} from "react-router-dom";

const ITStaf = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return (
    <>
      <div>
        <section className="StaffingHeader-sections">
          <div className="container" style={{left:"0",border:"none"}}>
            <div className="row">
              <div className="col-lg-8 col-md-12 col-sm-12 Staffing-banner-col">
                <div className='Staffing-banner-content'>
                  <h1><span>IT Staff Augmentation</span></h1> 
                  <p className='sub-p'>
                    Our Key Solution for Establishing or Expanding Your Tech Team.
                  </p>
                  <div className='IT-banner-button'>
                     <Link to="/contact"><a className='Contact-theme-btn'>Contact Us</a></Link>   
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 image-animation">
                <div className="image">
                  <Lottie options={defaultOptions} />
                </div>
              </div>
            </div>
          </div>
        </section>


        <section style={{paddingBottom:"20px"}}>
          <div className='container mt-5 mb-4 pt-0 pb-0' style={{left:"0",border:"none"}}>
            <div className='row staf-Agu'>
              <div className='col-10 text-center'>
                <span>SCALE UP YOUR TEAM WITH</span>

                <div className='staf-header'>
                  <h2>Staff Augmentation</h2>
                  <p>Get yourself a team of developers that are process oriented, possess technical expertise, offer workforce transparency & are cost-effective to your company.</p>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-3 col-md-6'>
                <div className='featured-box'>
                  <div className='box-content px-0 py-0'>
                    <FontAwesomeIcon icon={faAward} className='icon-feature' />
                    <h4 className='font-weight-bold pb-1 mb-2 Experties-h4'>Technical Expertise</h4>
                    <p className='Experties-para'>In-house technical experts to help find solutions to all your technical problems in a whole array of technologies</p>
                  </div>
                </div>
              </div>

              <div className='col-lg-3 col-md-6'>
                <div className='featured-box'>
                  <div className='box-content px-0 py-0'>
                    <FontAwesomeIcon icon={faWallet} className='icon-feature' />
                    <h4 className='font-weight-bold pb-1 mb-2 Experties-h4'>Cost Effectiveness</h4>
                    <p className='Experties-para'>Implement what makes sense for your business to get the best value in your budget</p>
                  </div>
                </div>
              </div>

              <div className='col-lg-3 col-md-6'>
                <div className='featured-box'>
                  <div className='box-content px-0 py-0'>
                    <FontAwesomeIcon icon={faMagnifyingGlass} className='icon-feature' />
                    <h4 className='font-weight-bold pb-1 mb-2 Experties-h4'>Workforce Transparency</h4>
                    <p className='Experties-para'>Advanced tools for communication & project management to ensure maximum visibility at all times</p>
                  </div>
                </div>
              </div>

              <div className='col-lg-3 col-md-6'>
                <div className='featured-box'>
                  <div className='box-content px-0 py-0'>
                    <FontAwesomeIcon icon={faDesktop} className='icon-feature' />
                    <h4 className='font-weight-bold pb-1 mb-2 Experties-h4'>Process Oriented</h4>
                    <p className='Experties-para'>Best in className processes to ensure maximum efficiency & quality at every step</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className='section-hiring'>
          <div className='container' style={{left:"0",border:"none"}}>
            <h1 className='text-center HiringModelSection'>Hiring Model</h1>

            <div className='row' style={{rowGap:"20px"}}>
              <div className='col-lg-4 col-md-6 col-sm-12'>
                <div className='hiring-card'>
                  <FontAwesomeIcon icon={faUserGroup} className='icon-Modal' />
                  <h4>Committed Team</h4>
                  <p><i className="fas fa-check Modal-Icon"></i>For large Projects</p>
                  <p><i className="fas fa-check Modal-Icon"></i>Progressive Refinements</p>
                  <p><i className="fas fa-check Modal-Icon"></i>Full-time Committed Resource Pool</p>
                  <p><i className="fas fa-check Modal-Icon"></i>Transparent Pricing</p>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
                <div className='hiring-card'>
                  <FontAwesomeIcon icon={faUser} className='icon-Modal' />
                  <h4>Singular Assets</h4>
                  <p><i className="fas fa-check Modal-Icon"></i> Full-time Devoted Teammate</p>
                  <p><i className="fas fa-check Modal-Icon"></i> For Augmenting Remote Teams</p>
                  <p><i className="fas fa-check Modal-Icon"></i> You take care of Project Management.</p>
                  <p><i className="fas fa-check Modal-Icon"></i> Transparent Pricing</p>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
                <div className='hiring-card'>
                  <FontAwesomeIcon icon={faBriefcase} className='icon-Modal' />
                  <h4>Project Outsource</h4>
                  <p><i className="fas fa-check Modal-Icon"></i>  Pre-Defined Projects</p>
                  <p><i className="fas fa-check Modal-Icon"></i> Milestone based Payment</p>
                  <p><i className="fas fa-check Modal-Icon"></i>Project Management by Us</p>
                  <p><i className="fas fa-check Modal-Icon"></i> Transparent Pricing</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className='container' style={{left:"0",border:"none"}}>
            <p className='elementor-header'> Our 3-Step Staff Augmentation Process</p>
            <p className='elementor-para'> Streamline your team scaling with our 3-step IT outsourcing staff augmentation process</p>
            <div className='row'>
              <div className='col-lg-4 col-md-6 col-sm-12 staff-col'>
                <div className="card">
                  <div className="slide slide1">
                    <div className="content">
                      <div className="share">
                        <h3 className='heading-h3'>Share your requirements</h3>
                      </div>
                    </div>
                  </div>
                  <div className="slide slide2">
                    <div className="content">
                      <p>Share your resource and staff augmentation requirements, including tech stack, team size, and staffing duration. The more details we receive, the quicker we'll match you with the perfect candidates.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12 staff-col'>
                <div className="card">
                  <div className="slide slide1">
                    <div className="content">
                      <div className="onboard">
                        <h3 className='heading-h3'>Onboard remote programmers</h3>
                      </div>
                    </div>
                  </div>
                  <div className="slide slide2">
                    <div className="content">
                      <p>Senwell Group Private Limited Tech Staffing seamlessly integrates with your in-house team, ensuring effective collaboration. Provide project details and assign responsibilities to team members for successful cooperation.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12 staff-col'>
                <div className="card">
                  <div className="slide slide1">
                    <div className="content">
                      <div className="Project">
                        <h3 className='heading-h3'> Get your projects delivered</h3>
                      </div>
                    </div>
                  </div>
                  <div className="slide slide2">
                    <div className="content">
                      <p>Our IT staffing services encompass project management, guaranteeing the punctual delivery of your robust digital solution. We also provide continuous monitoring to align with your evolving needs.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className='Offer-section'>
          <div className='container' style={{ left: "0", border: "none" }}>
            <h2 className='service-title'>The Value We Offer Our Clients</h2>
            <div className='feature-list'>
              <div className='row' style={{ rowGap: "20px" }}>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                  <div className='left-image'>
                    <img src={Rapid}  alt='Rapid'/>
                  </div>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12 mainRight-Content'>
                  <div className='right-content'>
                    <h3 className='feature-title'>Rapid access to talent</h3>
                    <p className='feature-text'>We have over 150+ developers with 100+ skills available in-house, and we can provide you with a special dedicated team for your purpose.</p>
                  </div>
                </div>
              </div>

              <div className='row' style={{ rowGap: "20px",marginTop:"90px" }}>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                  <div className='left-image'>
                    <img src={Flexible} alt='Flexible' />
                  </div>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12 mainRight-Content'>
                  <div className='right-content'>
                    <h3 className='feature-title'>Flexible Pricing Model</h3>
                    <p className='feature-text'>At Senwell Group Private Limited, you will find your work done for a much lower price. You will find that the costing is well within your budget and the work done is of exceptional quality.</p>
                  </div>
                </div>
              </div>


              <div className='row' style={{ rowGap: "20px",marginTop:"90px" }}>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                  <div className='left-image'>
                    <img src={LongTerm} alt='LongTerm' />
                  </div>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12 mainRight-Content'>
                  <div className='right-content'>
                    <h3 className='feature-title'>Long-term Partnership</h3>
                    <p className='feature-text'>We promise to give the best service you have ever received and form connections which help you in future projects. Senwell Group Private Limited forms a bond with it's customers to help them in their future projects as well.</p>
                  </div>
                </div>
              </div>

              <div className='row' style={{ rowGap: "20px",marginTop:"90px" }}>
                <div className='col-lg-6 col-md-12 col-sm-12'>
                  <div className='left-image'>
                    <img src={Continuous} alt='Continuous' />
                  </div>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12 mainRight-Content'>
                  <div className='right-content'>
                    <h3 className='feature-title'>Continuous Learning</h3>
                    <p className='feature-text'>Our team is developing skills as you are visiting our website. Upskilling as per industry standards are embraced at Senwell Group Private Limited & It helps keep the team informed and educated about new technologies and updates in the field.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
    </>
  )
}

export default ITStaf