import React from "react";
import "./Cloud.css";
import shape2 from "../../../images/SoftwareDev/shape2.svg";
import shape3 from "../../../images/SoftwareDev/shape3.svg";
import shape4 from "../../../images/SoftwareDev/shape4.svg";
import ourTech from "../../../images/SoftwareDev/CloudConsulting.png";
import client9 from "../../../images/Pastproject/client9.webp";
import client10 from "../../../images/Pastproject/client10.webp";
import client11 from "../../../images/Pastproject/CiscoLogo.png";
import AwsLogo from "../../../images/SoftwareDev/Amazon_Web_Services_Logo.svg";
import GoogleCloud from "../../../images/SoftwareDev/google-cloud-icon.svg";
import Azure from "../../../images/SoftwareDev/microsoft-azure.svg";
import ArchitectureC from "../../../images/SoftwareDev/architecture consulting.png";
import CloudInfra from "../../../images/SoftwareDev/cloud infrastructure management.png";
import CloudModer from "../../../images/SoftwareDev/cloud modernization and migration.png";
import ProductConsulting from "../../../images/SoftwareDev/product consulting.png";
import softwareproduct from "../../../images/SoftwareDev/software product development.png";
import Maintenance1 from "../../../images/SoftwareDev/maintenance and support.png";
import awsl from "../../../images/SoftwareDev/AWS-icon (1).svg";
import azureIcon from "../../../images/SoftwareDev/Azure-icon.svg";
import GCloudPlatform from "../../../images/SoftwareDev/Google-Cloud-Platform-icon.svg";
import Ansible from "../../../images/SoftwareDev/Ansible-colored-icon.svg";
import Kubernetes from "../../../images/SoftwareDev/Kubernetes-icon.svg";
import docker from "../../../images/SoftwareDev/Docker-icon.webp";
import { Helmet } from "react-helmet";

const Cloud = () => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": "https://senwellsys.com/cloud-consulting-services",
    url: "https://senwellsys.com/cloud-consulting-services",
    name: "Senwell Group Pvt Ltd | Cloud Consulting Services",
    description:
      "Explore Senwell Group's expertise in cloud consulting, offering scalable cloud solutions tailored to your business needs.",
    inLanguage: "en-US",
    organization: {
      "@type": "Organization",
      "@id": "https://senwellsys.com/#organization",
      name: "Senwell Group Pvt Ltd | Software Company | Pune",
      url: "https://senwellsys.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellsys.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption: "Senwell Group Pvt Ltd | Software Company | Pune",
      },
      potentialAction: [
        {
          "@type": "SearchAction",
          target:
            "https://senwellsys.com/cloud-consulting-services?s={search_term_string}",
          "query-input": "required name=search_term_string",
        },
      ],
    },
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Senwell Group Private Limited | Cloud Consulting Services</title>
        <meta
          name="description"
          content="Explore Senwell Group's expertise in cloud consulting, offering scalable cloud solutions tailored to your business needs."
        />
        <link
          rel="canonical"
          href="https://senwellsys.com/cloud-consulting-services"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Cloud Consulting Services" />
        <meta
          property="og:description"
          content="Explore Senwell Group's expertise in cloud consulting, offering scalable cloud solutions tailored to your business needs."
        />
        <meta
          property="og:url"
          content="https://senwellsys.com/cloud-consulting-services"
        />
        <meta property="og:site_name" content="Senwell Group Private Limited" />
        <meta
          property="og:image"
          content="https://senwellsys.com/static/media/CloudConsulting.fe5c2564fc009d492393.png"
        />
        <meta property="og:image:width" content="376" />
        <meta property="og:image:height" content="360" />
        <meta property="og:image:type" content="image/png" />

        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>
      <div className="CustomeDev">
        <section>
          <div className="main-banner">
            <div className="container" style={{ left: "0", border: "none" }}>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <h1 className="Product-title">
                    Cloud Transformation Services
                  </h1>
                </div>
                <div className="col-lg-6 col-md-12">
                  <p className="content-para">
                    With our cloud transformation services, you can grow your
                    company and operations without breaking the bank.
                  </p>
                </div>
              </div>
            </div>

            <div class="shape2 rotateme">
              <img src={shape2} alt="img" />
            </div>

            <div className="shape3">
              <img src={shape3} alt="shape" />
            </div>
            <div className="shape4">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape5">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape6 rotateme">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape7">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape8 rotateme">
              <img src={shape2} alt="shape" />
            </div>
          </div>
        </section>

        <section className="section-services">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row" style={{ marginBottom: "60px" }}>
              <div className="col-lg-12 col-md-12 text-center">
                <h2 className="section-head">
                  Consulting and Implementation Services for Cloud Computing
                </h2>
                <p className="mob-section-para">
                  Senwell Group Private Limited While increasing productivity
                  and time-to-market, cloud consulting services provide the
                  luxury of reduced IT expenditures and resources. Take use of
                  the full potential of our cloud app development services by
                  using the finest practices for your company's needs.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={ProductConsulting} alt="img" />
                  </div>
                  <h3>Cloud Migration Services</h3>

                  <p>
                    Combine the architecture of the current solutions and update
                    them to better meet business needs for content management
                    and scalability. We provide workload migration,
                    cloud-to-cloud mobility, validation, and performance
                    testing.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={softwareproduct} alt="img" />
                  </div>
                  <h3>Cloud Automation</h3>

                  <p>
                    With an emphasis on assisting clients in centralizing the
                    administration of their multi-cloud platforms, we provide
                    cloud automation services for all popular cloud platforms.
                    As your partner for cloud solutions, we'll work to hasten
                    the deployment of cloud automation to boost productivity and
                    creativity in the workplace.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={CloudInfra} alt="img" />
                  </div>
                  <h3>Cloud Security</h3>

                  <p>
                    When a lot of sensitive corporate data is stored on the
                    cloud, there is always a risk of cyberattacks. To find
                    vulnerabilities and maintain the security of your systems,
                    you can work with our cloud service provider in the USA. We
                    provide data protection, security and risk assessment,
                    performance optimization, and ongoing monitoring.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={CloudModer} alt="img" />
                  </div>
                  <h3>Highly Available and Fault Tolerant Infra</h3>

                  <p>
                    We provide highly available infrastructure that can be
                    scaled to meet demand and traffic levels. Give your
                    application a fault-free infrastructure so that it can
                    operate with maximum uptime and fewer problems.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={ArchitectureC} alt="img" />
                  </div>
                  <h3>Cost Optimization</h3>

                  <p>
                    60% of businesses maximize their current cloud usage to cut
                    costs. In order to keep cloud costs as low as possible, our
                    US-based cloud support company is mindful of financial
                    constraints. We guarantee that you will save money on cloud
                    expenses while still receiving the performance that your
                    most demanding IT workloads require.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={Maintenance1} alt="img" />
                  </div>
                  <h3>Continuous Monitoring</h3>

                  <p>
                    To make sure your solution architecture is optimal, we
                    provide cloud-based application monitoring, customized
                    parameter and check creation, and personalized alert setup.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-padding-product">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row align-items-lg-center pt-md-3 pb-2 mb-2 mb-lg-4 mb-xl-2">
              <div className="col-md-12 col-lg-5 mb-4 mb-md-0 ">
                <img src={ourTech} alt="img" className="leftimg" />
              </div>

              <div className="col-md-12 col-lg-6 offset-lg-1">
                <div className="pe-xl-2 me-md-2 me-lg-2">
                  <h2 className="h4 mb-3 mb-sm-2 design-and-content-header">
                    Cloud Transformation Company
                  </h2>

                  <p class="mb-sm-2 mb-0 f-16 text-justify design-and-content-para">
                    Senwell Group Private Limited is a cloud development and
                    solution provider that offers affordable cloud services to
                    help businesses modernize their IT infrastructure. Our team
                    of professionals offers innovative cloud computing
                    consulting services that enable businesses to achieve
                    unprecedented levels of productivity. You can modernize your
                    systems to promote innovation and business success with the
                    aid of our automation and cloud migration services. Cloud
                    transformation solutions also improve the security of your
                    data and digital infrastructure against cyberattacks,
                    providing you the peace of mind you need to concentrate on
                    running your business.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section-padding clients-section ">
          <div class="container" style={{ left: "0", border: "none" }}>
            <div class="row align-items-center">
              <div class="col-lg-4 pr-lg-5">
                <h2 class="section-heading">
                  Our Leading Multinational Clients
                </h2>
                <p>
                  Join our diverse client base for a digital transformation
                  journey
                </p>
              </div>
              <div class="col-lg-8">
                <div class="clients-logo-list" style={{ display: "flex" }}>
                  <div class="logo-col">
                    <div class="logo-image">
                      <img
                        width="250"
                        height="102"
                        src={client9}
                        alt="MindLift Industries Logo"
                        title="MindLife"
                        class="entered lazyloaded"
                      />
                    </div>
                  </div>
                  <div class="logo-col">
                    <div class="logo-image">
                      <img
                        width="250"
                        height="102"
                        src={client10}
                        alt="worldbank Logo | senwell"
                        title="World Bank"
                        data-ll-status="loaded"
                        class="entered lazyloaded"
                      />
                    </div>
                  </div>
                  <div class="logo-col">
                    <div class="logo-image">
                      <img
                        width="250"
                        height="102"
                        src={client11}
                        alt="client11"
                        title="cisco"
                        data-ll-status="loaded"
                        class="entered lazyloaded"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section-padding capabilities-section ">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div class="row top-row">
              <div class="col-lg-11 mx-auto">
                <h2 class="section-heading">
                  For us, Cloud Platforms Digital Capabilities
                </h2>
                <p>
                  Senwell Group Private Limited offers cloud application
                  development services across all major cloud platforms. Our
                  group of specialists in solution design selects the suitable
                  Cloud platform.
                </p>
              </div>
            </div>
            <div class="row bottom-row">
              <div class="row bottom-row digital-list">
                <div class="col-md-4 digital-capabilities-col">
                  <div class="digital-wrapper">
                    <div class="digital-img">
                      <img src={AwsLogo} alt="img" />
                    </div>

                    <div class="digital-content">
                      <h3 class="section-sub-heading-cloud">
                        <a href="#">AWS Consulting Services</a>
                      </h3>

                      <p>
                        We have been providing enterprise-level AWS-based
                        solutions and a wide range of AWS consulting services as
                        a certified Amazon Consulting Partner. We are able to
                        provide clients throughout the world with value-added
                        solutions because to Amazon goods' special capabilities.
                      </p>
                      <p>
                        We provide to build your cloud-native development or AWS
                        migration plan and make it possible for your AWS-based
                        infrastructure to be used economically.
                      </p>
                      <p>Our offerings include:</p>
                      <ul>
                        <li>Services for AWS migration</li>
                        <li>development services with AWS</li>
                        <li>built on AWS microservices</li>
                        <li>architecture without servers</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 digital-capabilities-col">
                  <div class="digital-wrapper">
                    <div class="digital-img">
                      <img src={GoogleCloud} alt="img" />
                    </div>

                    <div class="digital-content">
                      <h3 class="section-sub-heading-cloud">
                        <a href="#">Google Cloud Consulting Services</a>
                      </h3>

                      <p>
                        We provide full-service Google Cloud consultancy to
                        assist businesses of all sizes in maximizing the
                        benefits of the distinctive capabilities provided by
                        Google Cloud Platform (GCP).
                      </p>
                      <p>
                        We offer round-the-clock Google Cloud consulting and
                        support services, and our Google Cloud consulting
                        professionals will help you effectively design and
                        create a Google Cloud infrastructure.
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 digital-capabilities-col">
                  <div class="digital-wrapper">
                    <div class="digital-img">
                      <img src={Azure} alt="img" />
                    </div>

                    <div class="digital-content">
                      <h3 class="section-sub-heading-cloud">
                        <a href="#">Microsoft Azure Consulting Services</a>
                      </h3>

                      <p>
                        We offer round-the-clock Google Cloud consulting and
                        support services in addition to helping you effectively
                        plan and construct a Google Cloud infrastructure with
                        the help of our Google Cloud consulting professionals.
                      </p>

                      <p>Our offerings include:</p>
                      <ul>
                        <li>migration services for Azure</li>
                        <li>services for developing on Azure</li>
                        <li>Services for optimizing Azure environments</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section-padding-product tools-section pb-0">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div class="row top-row">
              <div class="col-md-11 mx-auto text-center">
                <h2 class="section-heading">
                  Our Technical Strength Is Developing Cutting-Edge Cloud-Based
                  Solutions
                </h2>
                <p>
                  Our solution design specialists' tech stack knowledge enables
                  organizations to migrate to the cloud more affordably,
                  implement cost-effective and specialized cloud solutions for
                  key components of their IT infrastructure, and adapt to
                  changing business requirements
                </p>
              </div>
            </div>

            <div class="row bottom-row">
              <div class="col-md-12">
                <div class="tools-title-list">
                  <div
                    data-id="#tools-1"
                    class="tools-title active"
                    style={{ border: "none" }}
                  ></div>
                </div>
                <div class="tools-content-list">
                  <div
                    id="tools-1"
                    class="tools-content active"
                    style={{ border: "none" }}
                  >
                    <div class="tools-icon-list">
                      <ul>
                        <li>
                          <img src={awsl} alt="img" />
                          <span>AWS</span>
                        </li>

                        <li>
                          <img src={azureIcon} alt="img" />
                          <span>Azure</span>
                        </li>

                        <li>
                          <img src={Kubernetes} alt="img" />
                          <span>Kubernetes</span>
                        </li>

                        <li>
                          <img src={docker} alt="img" />
                          <span>Docker</span>
                        </li>

                        <li>
                          <img src={GCloudPlatform} alt="img" />
                          <span>Google Cloud Platform</span>
                        </li>

                        <li>
                          <img src={Ansible} alt="img" />
                          <span>Ansible</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Cloud;
