import React, { useState } from "react";
import {
  Container,
  makeStyles,
  Typography,
  Tab,
  Tabs,
  Grid,
} from "@material-ui/core";
import { Time } from "./WorkflowTabs";
import { Team } from "./WorkflowTabs";
import { Staff } from "./WorkflowTabs";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  TabBox: {
    // borderBottom: '2px solid transparent',
  },
  OurServicesSectionWrappr: {
    background: "#f6f6f6",
    padding: "2rem 5rem ",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem",
    },
  },

  newDesk: {
    [theme.breakpoints.up("sm")]: {
      width: '90%',
      margin: 'auto',
      // textAlign: 'center'
    },
  },

  OurServicesSectionTitle: {
    fontWeight: "700",
    paddingBottom: "3rem",
  },
  PrivateTabIndicatorColorSecondary15: {
    backgroundColor: "#000",
  },
  QualityfirstsectionParaWrapper: {
    color: "#000",
    lineHeight: "1.5rem",
    paddingLeft: "2rem ",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 2rem",
    },
  },
  QualityfirstsectionWrapper: {
    padding: "2rem 0rem",
    // fontFamily: " 'Lora', serif",
    fontFamily: "Georgia, serif",
  },
  QualityfirstsectionTitleWrapper: {
    padding: "2rem 0rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0rem",
    },
  },
  TextWrapper: {
    color: "#000",
    // padding: "0rem 2rem",
    fontFamily: " system-ui",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      padding:"1rem 1rem 0",
      textAlign:"center"
    },
  },
  box1:{
    padding: "2rem 5rem",
    width: '90%',
    margin: 'auto',
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 0rem",
    },
  },
}));
const CustomWorkflow = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const classNamees = useStyles();
  const [value, setValue] = useState(0);
  const handleTabs = (e, val) => {
    setValue(val);
  };
  return (
    <>
      <Container maxWidth="lg" className={classNamees.OurServicesSectionWrappr}>
          <Grid container className={classNamees.newDesk}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classNamees.QualityfirstsectionTitleWrapper}
            >
              <Typography variant="h4" className={classNamees.TextWrapper}>
              Begin quickly with senwell deeply grounded processes!
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classNamees.QualityfirstsectionParaWrapper}
            >
              <p style={{ fontSize: "16px", textAlign:"justify" }}>
              Here you can see our custom work processes for every commitment model. No matter which collaboration approach you choose, we intend to take about fourteen days from the initial request to the first line of code composed by our developers.
              </p>
            </Grid>
          </Grid>

      </Container>

      <Container
          maxWidth="lg"
          className={classNamees.box1}
        >
          <Grid item xs={12}>
            <div className={classNamees.OurServicesTabWrapper}>
              <div data-aos="fade-up" data-aos-duration="2000">
                <Tabs
                  value={value}
                  onChange={handleTabs}
                  indicatorColor="primary"
                  style={{ borderBottom: "1px solid #c1baba" }}
                >
                  <Tab label="Time and material"> Time and material</Tab>
                  <Tab label="Technologies"> Technologies</Tab>
                  <Tab label="Industries"> Industries</Tab>
                </Tabs>
                <Container maxWidth="lg">
                  <TabPanel
                    value={value}
                    index={0}
                    className={classNamees.TabPanel}
                  >
                    {<Time />}
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Team />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Staff />{" "}
                  </TabPanel>
                </Container>
              </div>
            </div>
          </Grid>
        </Container>
      
    </>
  );
};
export default CustomWorkflow;
function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <h1>{children}</h1>}</div>;
}
