import React from "react";
import "./Design.css";
import { DesignData } from "../../../API/DesignApi";
import WeCanHelpYou from "../../../Modules/Services/Design/WeCanHelpYou";
import EngagingConcept from "../../../Modules/Services/Design/EngagingConcept";
import GetInTouch from "../../../SharedModule/GetInTouch";
import BenefitsofDevelopment from "../../../SharedModule/BenefitsofDevelopment";
import TechnologiesExperties from "../../../SharedModule/TechnologiesExperties";
import Lottie from "react-lottie";
import animationData from "../../../assets/UxDesingAnimation.json";
import {Link} from "react-router-dom";

const Design = () => {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  return (
    <>
      {DesignData?.map((value) => (
        <>
          <section className="DesingHeader-sections">
            <div className="container" style={{ left: "0", border: "none" }}>
              <div className="row" style={{rowGap:"30px"}}>
                <div className="col-lg-6 col-md-12 col-sm-12 Design-banner-col">
                  <div className='Design-banner-content'>
                    <h1>UI/UX  Design</h1>
                    <p className='sub-p'>
                      For all your business needs, we connect you with creative experts for a professional look and feel. Great design and unique content elevate your business!.
                    </p>
                    <div className='Disign-banner-button'>
                        <Link to="/contact"><a  className='Design-theme-btn'>Let's Talk</a></Link> 
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 design-images">
                  <div className="image">
                  <Lottie options={defaultOptions}/>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <WeCanHelpYou />
          <EngagingConcept Skill={value.skill} />
          <GetInTouch GetInTouch={value.GetInTouch} />
          <BenefitsofDevelopment Benefits={value.Benefits} />
          <TechnologiesExperties
            TechnologiesExperties={value.TechnologiesExperties}
            title={value.TechnologiesExpertiesTitle}
            para={value.TechnologiesExpertiesPara}
          />
        </>
      ))}
    </>
  );
};

export default Design;
