import React from "react";
import { DigitalData } from "../../../API/ServicesApi";
import QuickStart from "./QuickStart";
import DigitalTask from "../../../SharedModule/DigitalTask";
import Lottie from "react-lottie";
import animationData from "../../../assets/DigitalAnimation.json";
import "../DigitalMarketing/DigitalMarketing.css";
import {Link} from "react-router-dom";


const DigitalMarketing = () => {
  const anchorStyle = {
    textDecoration: "none",
    color: "inherit"
  };

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  return (
    <>
      {DigitalData?.map((value) => (
        <>
        <section className="DigitalHeader-sections">
        <div className="container" style={{left:"0",border:"none"}}>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 Digital-banner-col">
              <div className='Digital-banner-content'>
                <h1>Digital Marketing</h1>
                <p className='sub-p'>
                    Leverage our expertise to maximize your digital strategy's impact and seize new opportunities for a robust online presence. Empower your growth.
                </p>
                <div className='Digital-banner-button'>
                    <Link to="/contact"><a  className='Digital-theme-btn'>Talk to our Digital Expert</a></Link> 
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 digital-image">
              <div className="image">
                  <Lottie options={defaultOptions}  />
              </div>
            </div>
          </div>
        </div>
      </section>
          <QuickStart />
          <DigitalTask />
        </>
      ))}
    </>
  );
};

export default DigitalMarketing;