import { Typography, Container, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core';
import React from 'react';
import "./DevelopmentStepbyStep.css";
const useStyles = makeStyles((theme) => ({
    mainContainer: {
        padding:"4rem 2rem",
        [theme.breakpoints.up('sm')]: {
            width: '90%',
            margin: 'auto'
        },
    },
    WhysenwellsTitle: {
        textAlign:"center",
        paddingTop:"0rem",
        fontWeight: '700',
        fontFamily: '"Manrope", sans-serif',
        fontSize: "2.5rem",

       [theme.breakpoints.down('sm')]: {
             fontSize:"2rem"
       },
    },
    whysenwellswrapper: {
      padding:'1rem 1.5rem',
      
    },
    Whysenwellsicon: {
        color: '#3D8ED7',
        paddingBottom: '1rem',
        fontSize:'3.2rem',
        [theme.breakpoints.down('xs')]: {
            paddingTop: '0rem',
            fontSize:'2rem',
        },
    },
    Whysenwellstitle:{
        fontWeight:'700',
        paddingBottom:'1rem',
        fontFamily: '"Manrope", sans-serif',

        [theme.breakpoints.down('sm')]: {
            fontSize:"19px"
      },
},
    Whysenwellspara:{
        fontWeight:'500',
        lineHeight:'1.5rem',
        color:'#5a5a5a',
        textAlign:'justify',
        fontFamily: '"Manrope", sans-serif',
        wordSpacing: "-0.1em",
        hyphens: "auto",
    }
}))
const Whysenwells = ({DevelopmentSteps}) => {
    const classNamees = useStyles();

    return (
        <>
            <Container maxWidth='xl'>
            {DevelopmentSteps?.map((val)=><>

                <Container maxWidth='lg' className={classNamees.mainContainer}>
                    <Typography variant='h3' className={classNamees.WhysenwellsTitle} style={{marginBottom:"1.5rem"}}>{val.DevelopmentSteptitle}</Typography>
                    <Grid container>
                        {val?.Developmentstep?.map((value)=><>
                        <Grid xs={12} sm={6} md={3} lg={3}>
                            <div className={classNamees.whysenwellswrapper}>
                                <div className={classNamees.Whysenwellsflexbox}>
                                    <Typography>{value.icon} </Typography>
                                    <div>
                                        <Typography align='center' variant="h6" className={classNamees.Whysenwellstitle}>{value.title}</Typography>
                                        <Typography className={classNamees.Whysenwellspara}>{value.description}</Typography>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        </> )}
                    </Grid>
                </Container>
                </> )}

            </Container>
        </>)
}

export default Whysenwells