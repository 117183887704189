import React, { useState, useEffect } from "react";
import db from "../Components/firebase.config";
import Lottie from "react-lottie";
import animationData from "../../assets/ContactAnimation.json";
import "../../Pages/Contact/Contact.css";
import { Link, animateScroll as scroll } from "react-scroll";
import LeftContactImagea from "../../images/Contact/LeftImage.webp";
import { collection, addDoc } from "firebase/firestore/lite";
import emailjs from "emailjs-com";
import Thanks from "./Thanks";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_USER_ID;
const ContactSection = () => {
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });
  const initialErrors = {
    name: false,
    email: false,
    mobile: false,
    subject: false,
    message: false,
  };

  const [errors, setErrors] = useState(initialErrors);

  const [openModal, setOpenModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  // Validate Name field on onBlur
  const handleNameBlur = () => {
    if (formData.name.trim() === "") {
      setErrors({ ...errors, name: "Please Enter Name" });
    } else {
      setErrors({ ...errors, name: false });
    }
  };

  // Validate Email field on onBlur
  const handleEmailBlur = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrors({ ...errors, email: "Invalid email address" });
    } else {
      setErrors({ ...errors, email: false });
    }
  };

  // Validate Mobile field on onBlur
  const handleMobileBlur = () => {
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(formData.mobile)) {
      setErrors({ ...errors, mobile: "Invalid mobile number (10 digits)" });
    } else {
      setErrors({ ...errors, mobile: false });
    }
  };

  // Validate Subject field on onBlur
  const handleSubjectBlur = () => {
    if (formData.subject.trim() === "") {
      setErrors({ ...errors, subject: "Subject is required" });
    } else if (formData.subject.length > 50) {
      setErrors({
        ...errors,
        subject: "Subject should not exceed 50 characters",
      });
    } else {
      setErrors({ ...errors, subject: false });
    }
  };

  function sendEmail(email, name) {
    const message = `\nThank you for reaching out to us. We appreciate your interest in our company and are happy to help answer any questions you may have. We will respond to your inquiry as soon as possible.\n
    Thank you again for your interest, and we look forward to hearing from you soon.`;
    emailjs
      .send(
        SERVICE_ID,
        TEMPLATE_ID,
        {
          to_email: email,
          to_name: name,
          message: message,
        },
        USER_ID
      )
      .then((response) => {
        console.log("Email sent:", response);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform form validation here
    let isValid = true;

    // Validate Name
    if (formData.name.trim() === "") {
      setErrors({ ...errors, name: "Name is required" });
      isValid = false;
    } else {
      setErrors({ ...errors, name: false });
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setErrors({ ...errors, email: "Invalid email address" });
      isValid = false;
    } else {
      setErrors({ ...errors, email: false });
    }

    // Validate Mobile
    const mobileRegex = /^[0-9]{10}$/;
    if (!mobileRegex.test(formData.mobile)) {
      setErrors({ ...errors, mobile: "Invalid mobile number (10 digits)" });
      isValid = false;
    } else {
      setErrors({ ...errors, mobile: false });
    }

    // Validate Subject
    if (formData.subject.trim() === "") {
      setErrors({ ...errors, subject: "Subject is required" });
      isValid = false;
    } else if (formData.subject.length > 50) {
      setErrors({
        ...errors,
        subject: "Subject should not exceed 50 characters",
      });
      isValid = false;
    } else {
      setErrors({ ...errors, subject: false });
    }

    if (
      !formData.name.trim() ||
      !formData.email.trim() ||
      !formData.mobile.trim() ||
      !formData.subject.trim()
    ) {
      setErrors({
        name: !formData.name.trim() ? "Please enter your name." : false,
        email: !formData.email.trim()
          ? "Please enter your email address.."
          : false,
        mobile: !formData.mobile.trim()
          ? "Please enter your Phone Number."
          : false,
        subject: !formData.subject.trim() ? "Subject is required" : false,
      });
      isValid = false;
    }

    if (isValid) {
      try {
        const contactsRef = collection(db, "contacts");

        await addDoc(contactsRef, formData);
        sendEmail(formData.email, formData.name);
        setFormData({
          name: "",
          email: "",
          mobile: "",
          subject: "",
          message: "",
        });

        setOpenModal(true);
        navigate("/thankyou");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": "https://senwellsys.com/contact",
    url: "https://senwellsys.com/contact",
    name: "Senwell Group Pvt Ltd | Contact Us",
    description:
      "Get in touch with Senwell Group Pvt Ltd for business inquiries, support, or any other queries. We are here to assist you with all your software and consulting needs.",
    inLanguage: "en-US",
    organization: {
      "@type": "Organization",
      "@id": "https://senwellsys.com/#organization",
      name: "Senwell Group Pvt Ltd | Software Company | Pune",
      url: "https://senwellsys.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellsys.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption: "Senwell Group Pvt Ltd | Software Company | Pune",
      },
      potentialAction: [
        {
          "@type": "SearchAction",
          target: "https://senwellsys.com/contact?s={search_term_string}",
          "query-input": "required name=search_term_string",
        },
      ],
    },
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Senwell Group Pvt Ltd | Contact Us for Business Inquiries</title>
        <meta
          name="description"
          content="Get in touch with Senwell Group Pvt Ltd for business inquiries and support. We're here to help you with all your software and consulting needs."
        />
        <link rel="canonical" href="https://senwellsys.com/contact" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Contact Us" />
        <meta
          property="og:description"
          content="Get in touch with Senwell Group Pvt Ltd for business inquiries, support, or any other queries. We are here to assist you with all your software and consulting needs."
        />
        <meta property="og:url" content="https://senwellsys.com/contact" />
        <meta property="og:site_name" content="Senwell Group Private Limited" />
        <meta
          property="og:image"
          content="https://senwellsys.com/static/media/LeftImage.3876c2f8a4965fc2bb2a.webp"
        />
        <meta property="og:image:width" content="376" />
        <meta property="og:image:height" content="360" />
        <meta property="og:image:type" content="image/webp" />

        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>

      <section className="ContactHeader-sections">
        <div className="container" style={{ left: "0", border: "none" }}>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 Contact-banner-col">
              <div className="Contact-banner-content">
                <h1>Contact Us</h1>
                <p className="sub-p">
                  Whether you want to collaborate, give projects, or join our
                  team, we welcome the opportunity to engage with you. Our
                  dedicated team is just a message away, ready to provide the
                  support you need.Feel free to reach out through the provided
                  contact details or simply fill out the contact form. Your
                  feedback and queries are important to us, and we look forward
                  to being in touch.
                </p>
                <div className="Contact-banner-button">
                  <Link to="targetSection" smooth={true} duration={500}>
                    <a className="Conact-theme-btn">Get in Touch with Us</a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="image" style={{ pointerEvents: "none" }}>
                <Lottie options={defaultOptions} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="main-sections">
        <div className="container" style={{ left: "0", border: "none" }}>
          <div
            className="row"
            style={{ rowGap: "30px", marginBottom: "20px", marginTop: "70px" }}
          >
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="card contact-card">
                <img
                  src="https://res.cloudinary.com/dpzbtzz94/image/upload/v1633590691/AdminMedia/images%20/contact/sale_qrdzem.png"
                  alt=""
                />
                <p className="para1">SALES</p>
                <Link to="/portfolio">
                  <a>Send us an email</a>
                </Link>
                <p className="para2">
                  Please share your requirements, and our expert team will be
                  happy to assist you.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="card contact-card">
                <img
                  src="https://res.cloudinary.com/dpzbtzz94/image/upload/v1633590690/AdminMedia/images%20/contact/support_lgqieu.png"
                  alt=""
                />
                <p className="para1">SUPPORT</p>
                <Link to="/contact">
                  <a>Your satisfaction, Our Goal.</a>{" "}
                </Link>
                <p className="para2">
                  Our expert team is ready to support you 24/7. We are always
                  willing to resolve any doubts or problems as soon as possible.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="card contact-card">
                <img
                  src="https://res.cloudinary.com/dpzbtzz94/image/upload/v1633590694/AdminMedia/images%20/contact/career_m40f6t.png"
                  alt=""
                />
                <p className="para1">CAREER</p>
                <Link to="/careers">
                  <a>Please consider joining our team.</a>
                </Link>
                <p className="para2">
                  Looking for a diverse work culture? Senwell Group Private
                  Limited is the right choice for you!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="form-section" name="targetSection" id="targetSection">
        <div
          className="container"
          style={{ padding: "40px 0px", left: "0", border: "none" }}
        >
          <div className="row Main-form-Header">
            <div className="col-12 col-lg-10 col-xl-8 section-head">
              <span className="head-outSpan">
                <span>
                  Complete the form, and we'll get back to you shortly!
                </span>
              </span>
              <h5 className="all_heading">How may we assist you?</h5>
            </div>
          </div>

          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-12 col-lg-12">
              <div className="row" style={{ justifyContent: "center" }}>
                <div className="col-md-6">
                  <div className="contact_leftImg">
                    <img
                      src={LeftContactImagea}
                      alt="LeftContactImages"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>

                <div className="col-md-6 form-contact">
                  <form onSubmit={handleSubmit} className="contact-us-form">
                    <div className="row contact-us-form-row" style={{}}>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            className={`medium-input bg-white mb-0 ${
                              errors.name ? "is-invalid" : ""
                            }`}
                            type="text"
                            name="name"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleChange}
                            onBlur={handleNameBlur}
                          />
                          {errors.name && (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            className={`medium-input bg-white mb-0 ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            type="email"
                            name="email"
                            placeholder="Email ID"
                            value={formData.email}
                            onChange={handleChange}
                            onBlur={handleEmailBlur}
                          />
                          {errors.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            className={`medium-input bg-white mb-0 ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            name="mobile"
                            type="text"
                            placeholder="Mobile Number"
                            value={formData.mobile}
                            onChange={(e) => {
                              const numericValue = e.target.value.replace(
                                /\D/g,
                                ""
                              );
                              setFormData({
                                ...formData,
                                mobile: numericValue,
                              });
                            }}
                            onBlur={handleMobileBlur}
                            maxLength={10}
                            onInput={(e) => {
                              if (e.target.value.length > 10) {
                                e.target.value = e.target.value.slice(0, 10);
                              }
                            }}
                          />
                          {errors.mobile && (
                            <div className="invalid-feedback">
                              {errors.mobile}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            className={`medium-input bg-white mb-0  ${
                              errors.subject ? "is-invalid" : ""
                            }`}
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="Subject"
                            maxLength={50}
                            value={formData.subject}
                            onChange={handleChange}
                            onBlur={handleSubjectBlur}
                          />
                          {errors.subject && (
                            <div className="invalid-feedback">
                              {errors.subject}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            className="medium-textarea h-100px bg-white"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Message"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 Send-button">
                      <button
                        className="formBtn text-light btn-medium mb-0"
                        id="submit"
                        name="submit"
                        type="submit"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.387985502463!2d73.95251807425498!3d18.556538968076413!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c7f167b7470b%3A0xec281f2b9ea8e820!2sSenwell%20Solutions!5e0!3m2!1sen!2sin!4v1689934794911!5m2!1sen!2sin"
            width="100%"
            height="250"
            style={{
              border: "0",
              height: "300px",
              marginBottom: "-6px",
              width: "100%",
            }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
      <Thanks openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
};

export default ContactSection;
