import { SET_SPECIALISTS } from "./specialistsType";
import { GET_SPECIALISTS } from "./specialistsType";


export const setSpecialist = (response) => {
  //console.log('setSpecialist action', response)
  return {
    type: SET_SPECIALISTS,
    payload: response,
  };
};

export const getSpecialist = (response) => {
  return {
    type: GET_SPECIALISTS,
    payload: response,
  };
};




