import { CmmiImage } from "../images/images";
import { Union } from "../images/images";
import { LogoImage } from "../images/images";
import { WebDevelopmentBanner } from "../images/images";
import { impresNewClientFlowImg } from "../images/images";


import {IoLogoJavascript} from "react-icons/io";
import Java from "../images/Technologies/java2.jpg";
import Dotnet from "../images/Technologies/net-logo.png";
import Postman from "../images/Technologies/postman.png";
import jenkins1 from "../images/Technologies/jenkins.jpeg";
import Csharp from "../images/Technologies/csharp.png";
import Vue from"../images/Technologies/vue.png";
import Gitlab from "../images/Technologies/gitlab.png"
import Python from "../images/Technologies/python3.png";
import Kotlin1 from "../images/Technologies/Kotlin.png";
import Selenium1 from "../images/Technologies/Selenium.png"
import Jmeter from "../images/Technologies/jmeter.jpg";
import junit from "../images/Technologies/junit.webp";
import mobileAppImage from "../images/Services/mobileAppImage.png";
import BrouserStack1 from "../images/Technologies/BrouserStack.png"
import { FaAngular } from "react-icons/fa";
import { SiTypescript } from "react-icons/si";
import { FaVuejs } from "react-icons/fa";
import { FaReact } from "react-icons/fa";
import { DiJava } from "react-icons/di";
import { DiJavascript } from "react-icons/di";
import { FaPhp } from "react-icons/fa";
import { FaNode } from "react-icons/fa";
import { SiPostman } from "react-icons/si";
import { SiSelenium } from "react-icons/si";
import { FaAndroid } from "react-icons/fa";
import { DiSwift } from "react-icons/di";
import { DiIonic } from "react-icons/di";
import { SiKotlin } from "react-icons/si";
import { FaFirefoxBrowser } from "react-icons/fa";
import { SiJunit5 } from "react-icons/si";
import { SiApachejmeter } from "react-icons/si";
import "../App.css";
import { FaJenkins } from "react-icons/fa";
import { FaGitlab } from "react-icons/fa";
import { FaDocker } from "react-icons/fa";
import { SiAnsible } from "react-icons/si";
import ITconsulting1 from "../images/Services/ITconsulting1.png";
import AngularImg from "../images/Services/AngularImg.png";
import dedacatedImage from "../images/Services/dedicatedTeam.png";
import MVPimg from "../images/Services/MVPimg.png";
import DevOps from "../images/Services/DevOps.png";
import webdevelopmentImage from "../images/Technologies/webdevelopmentImage.png";
import Cronage from "../images/Pastproject/Cronage.png";
import Fooz1 from "../images/Pastproject/Fooz1.png";
import Fork from "../images/Pastproject/Fork1.png";
import Lotto from "../images/Pastproject/Lotto.png";
import Lumiere from "../images/Pastproject/Lumiere.png";
import parsl from "../images/Pastproject/parsl.JPG";
import Virus from "../images/Pastproject/Virus.png";
import { color } from "@mui/system";
const Services = [
  {
    name: "web-development",
    HeroBanner: [
      {
        name: "web-development",
        Page: "Web Development",
        title: "Web Development Services",
        breadcrums: "services",
        Link: "/services",
        para: "Help your business to reach new digital heights with a powerful web solution reinforced and optimized by your friendly neighborhood web development outsourcing company.",
        button: "Hire Now",
        img: WebDevelopmentBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
      },
    ],
    GetInTouchTitle:
      "Ready to start on your development or testing project? We are!",
    Button: "GetInTouch",
    formTitle: "Get a quote today!",
    formSubtitle: "Enter your project details.",
    ImpressYourClientTitle: "Impress new Clients",
    ImpressYourClientPara:
      "We work towards both your short- and long-term goals. We quickly identify, build, and enable those features that will get you the most leads and prospects in the shortest possible time.",
    ImpressYourClientImage: impresNewClientFlowImg,
    ImpressYourClientPoints: [
      {
        li: "corporate websites",
      },
      {
        li: "eCommerse Platforms",
      },
      {
        li: "Sass & cloud Solutions",
      },
      {
        li: "media Portals",
      },
    ],
    DevelopmentSolutionsTitle: "Ideal Web Development Solutions",
    DevelopmentSolutionsList: [
      {
        ariaControls: "panel1a-content",
        id: "panel1a-header",
        Number: "01",
        AccordianSummary: "Websites & Web Apps",
        AccordianDetails:
          "Build your online image with engaging looks, natural layouts, and compelling user experience.",
        // List1: "corporate websites",
      },
      {
        ariaControls: "panel2a-content",
        id: "panel2a-header",
        Number: "02",
        AccordianSummary: "API & Backend Development",
        AccordianDetails:
          "Complete your website or web application with seamless performance and efficient data processing.",
        // List1: "Web services API (REST/SOAP)",
        List2: "Library based API",
        List3: "Socket/Web Socket API",
      },
      {
        ariaControls: "panel3a-content",
        id: "panel3a-header",
        Number: "03",
        AccordianSummary: "CRM/CMS/ERP customization",
        AccordianDetails:
          "Custom-tailored management tools to fulfil your unique business needs and optimize processes.",
        // List1: "Custom CMS for Your Website",
        List2: "ERP and CRM Systems Using Odoo",
        List3: "Third-party Integrations to Existing System",
      },
      {
        ariaControls: "panel4a-content",
        id: "panel4a-header",
        Number: "04",
        AccordianSummary: "SaaS & Cloud Services",
        AccordianDetails:
          " Our experts can help you build, set up, or effectively move your services and operations to the cloud. ",
        // List1: "ATS",
        List2: "Betting service",
        List3: " Progressive Web Apps",
        List4: "Financial services",
      },
      {
        ariaControls: "panel5a-content",
        id: "panel5a-header",
        Number: "05",
        AccordianSummary: "eCommerce",
        AccordianDetails:
          "Start an online store quickly and easily or upgrade an existing one to your customers’ expectations. ",
        // List1: "Marketplace",
        List2: "Shopify, Magento, WooCommerce, nopCommerce",
        List3: "Payment Gateways",
      },
      {
        ariaControls: "panel6a-content",
        id: "panel6a-header",
        Number: "06",
        AccordianSummary: "API Integratione",
        AccordianDetails:
          "We can help you quickly and seamlessly integrate third-party APIs and optimize their performance.",
        // List1: "Social Network API Integration",
        List2: "Payment Gateways",
        List3: "Authentication APIs",
      },
    ],
    TechnologiesExpertiesTitle: "Our Technology Expertise",
    TechnologiesExpertiesPara:
      "Our knowledge extends beyond Angular development outsourcing. Our knowledge and experience span practically every programming language, framework, and library, both established over time and recently released by industry leaders such as Google and Microsoft.",
    TechnologiesExperties: [
      {
        icon: <IoLogoJavascript className="icon" style={{ i: "#4eb7ff", backgroundColor:"yellow", color:"black" }}  />,
        title: "JavaScript",
      },
      {
        icon: <FaAngular className="icon" style={{ i: "#4eb7ff" ,color:"red"}} />,
        title: "Angular",
      },
      {
        icon: <FaReact className="icon" style={{ i: "#4eb7ff" }} />,
        title: "React",
      },
      {
        icon: <FaNode className="icon" style={{ i: "#4eb7ff", color:"green"}} />,
        title: "Node.js",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: "Make your business accessible globally.",
        Button: "Get a Quote",
        image: webdevelopmentImage,
        para: "Present users and visitors with a positive, memorable experience of interacting with your business online. How? Hire a web development outsourcing company with two decades of experience in the field!",
      },
    ],

    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    Forms: [
      {
        formTitle: "Let's talk",
        formSubtitle: "Tell us about your project. Let’s get to work!",
      },
    ],
  },

  {
    name: "mobile-development",
    HeroBanner: [
      {
        name: "mobile-development",
        Page: "Application Development",
        title: "Mobile Application Development Experts",
        breadcrums: "services",
        Link: "/services/",
        para: "Solid tech skilled, nearly two decades of experience, and cross-domain background make Senwell the best choice for custom mobile app development services.",
        button: "Hire Now",
        img: WebDevelopmentBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
      },
    ],
    ImpressYourClientTitle: "Dazzle Your Users",
    ImpressYourClientPara:
      "we have an expert team of Mobile Application Developers along with Website Development team to deliver the customized, advanced and flawless Mobile Application to your clients. Also, the Graphics Designing team don’t comprise with the ordinary designs. Then match to the updated version of the technologies and keep in view all the industry standards, to increase the engagement and convert the user into the customer.",
    ImpressYourClientImage: impresNewClientFlowImg,
    ImpressYourClientPoints: [
      {
        li: "Native & Cross-Platform Apps",
      },
      {
        li: "Mobile UI/UX Design",
      },
      {
        li: "AR Applications",
      },
      {
        li: "APIs and Cloud solutions",
      },
      {
        li: "mCommerce apps",
      },
    ],
    DevelopmentSolutionsTitle: "Superb Mobile App Development Services",
    DevelopmentSolutionsList: [
      {
        ariaControls: "panel1a-content",
        id: "panel1a-header",
        Number: "01",
        AccordianSummary: "Native Application Development",
        AccordianDetails:
          "No app performs as properly as a native mobile application built specifically for iOS or Android.",
        // List1: "iOS Applications",
        List2: "Android Applications",
      },
      {
        ariaControls: "panel2a-content",
        id: "panel2a-header",
        Number: "02",
        AccordianSummary: "Cross-Platform & Hybrid Applications",
        AccordianDetails:
          "Hybrid mobile development allows using one app on disparate platforms, from smartphones to PCs.",
        // List1: "React Native development",
        List2: "Xamarin development",
        List3: "Flutter development",
      },
      {
        ariaControls: "panel3a-content",
        id: "panel3a-header",
        Number: "03",
        AccordianSummary: "Application Porting",
        AccordianDetails:
          "Already enjoying the achievement of your app on a platform? Port it to other platforms to expand your audience!",
        // List1: "Web App to Mobile App porting",
        List2: "iOS to Android porting",
        List3: "Android to iOS porting",
      },
      {
        ariaControls: "panel4a-content",
        id: "panel4a-header",
        Number: "04",
        AccordianSummary: "Mobile SDK Development",
        AccordianDetails:
          "SDK contain things like libraries, documents, and integration tools for custom app development.",
        // List1: "IoT integrations",
        List2: "Integration of Geo, Accelerometer, and other sensors",
        List3: " Bluetooth API",
        List4: "Use of custom sensors",
        List5: "AR/VR implementation",
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Need a mobile application development partner to turn your idea into a noticeable mobile solution?",
        Button: "GetInTouch",
      },
    ],
    TechnologiesExpertiesTitle: "Technology We Mastered",
    TechnologiesExpertiesPara:
      "When deciding on programming languages,  tools for another mobile app development project, we always focus on future code quality, product value for the market, and budget frames to stay in. That’s why mobile solutions we release always conform to industry standards as well as reflect every penny invested in development.",
    TechnologiesExperties: [
      {
        icon: <DiSwift className="icon" style={{ i: "#4eb7ff", backgroundColor:"red",color:"white"}} />,
        title: "Swift",
      },
      {
        icon: <DiIonic className="icon" style={{ i: "#4eb7ff", color:"blue" }} />,
        title: "Ionic",
      },
      {
        icon: <FaReact className="icon" style={{ i: "#4eb7ff" }} />,
        title: "React Native",
      },
      {
        icon: <img src={Vue} className="icon"  style={{color:"green"}} />,
        title: "Vue.Js",
      },
      {
        icon: <img src={Kotlin1} className="icon" />,
        title: "Kotlin",
      },
      {
        icon: <FaAndroid className="icon" style={{ i: "#4eb7ff", color:"green" }} />,
        title: "Anodroid",
      },
    ],

    ProjectPortfollio: [
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },

      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fooz",
        image1: Fooz1,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },

      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: "We’re ready to start. Are you?",
        Button: "Start a project",
        image: mobileAppImage,
        para: "Tell us what type of mobile application you’ve got in mind. From researching the app’s market niche to designing, coding, and then testing it the remaining is the job of our mobile application development services.",
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    Forms: [
      {
        formTitle: "Get a quote today!",
        formSubtitle: "Enter your project details.",
      },
    ],
  },
  {
   

    ImpressYourClientTitle: "Start Development Quickly",
    ImpressYourClientPara:
      "Don’t worry if you don’t have any technical background, we here are ready to help you. Our IT consulting services can help you. we provide outstanding services that can improve you to determine your idea’s viability, create a development roadmap, and assist you in creating a prototype or MVP that will help you validate your ideas.",
    ImpressYourClientImage: impresNewClientFlowImg,
    ImpressYourClientPoints: [
      {
        li: "CTO as a Service",
      },
      {
        li: "Market research",
      },
      {
        li: "Creating development roadmaps",
      },
      {
        li: "Guided MVP creation",
      },
    ],
    DevelopmentSolutionsTitle: "IT Consulting Services  ",
    DevelopmentSolutionsList: [
      {
        ariaControls: "panel1a-content",
        id: "panel1a-header",
        Number: "01",
        AccordianSummary: "SDLC Workflow Optimization",
        AccordianDetails:
          "Make sure that every phase of your development lifecycle is designed to reduce costs while improving productivity. We can assist your team in running like a Swiss watch.",
        // List1: "Development & QA process audit",
        List2: "Creating thorough documentation",
        List3: "Improving onboarding practices",
        List4: "Agile & Lean methodology coaching",
      },
      {
        ariaControls: "panel2a-content",
        id: "panel2a-header",
        Number: "02",
        AccordianSummary: "Project Planning",
        AccordianDetails:
          " Make a plan for turning your concept into a functional product with millions of users. Our product development experts can assist you with creating a development plan, selecting important features for your MVP, and assembling a team to write your first lines of code.",
        // List1: "Market & Competitor research",
        List2: "Prototype and MVP planning",
        List3: "Creating development roadmaps",
        List4: "Assistance with forming a team and hiring",
      },
      {
        ariaControls: "panel3a-content",
        id: "panel3a-header",
        Number: "03",
        AccordianSummary: "Adherence to industry Best practice",
        AccordianDetails:
          "Ensure that your team follows industry best practices when it comes to protocols, data security, and quality standards.",

        // List1: "Assistance with CMMI-DEV maturity",
        List2: "Information security audit ISO:27001",
        List3: "Assistance with achieving industry-specific standards ",
      },
      {
        ariaControls: "panel2a-content",
        id: "panel2a-header",
        Number: "04",
        AccordianSummary: "CTO as a Service",
        AccordianDetails:
          "Outsource your project's technical oversight to a certified CTO with years of expertise. This expert will assist you in selecting the most appropriate languages, frameworks, and architecture for your project.",
        List2: "ut development plans into action",
        List3: " Ensure thorough technical supervision of your project",
        List4: "Leverage decades of hands-on experience",
        List5: "Make well-informed technical decisions",
      },
    ],
    TechnologiesExpertiesTitle: "Tech Stack",
    TechnologiesExpertiesPara:
      "One of the competent services we provide is IT consulting outsourcing. Senwell employs over 280 devoted engineers who are experts in  technologies, programming languages, frameworks, and libraries. We go beyond just designing efficient code, concentrating on the final product that will assist your company reach its goals.",
    TechnologiesExperties: [
      {
        icon: <img src={Java} className="icon" style={{ i: "#4eb7ff" }} />,
        title: "Java",
      },
      {
        icon: <FaReact className="icon" style={{ i: "#4eb7ff" }} />,
        title: "React",
      },
      {
        icon: <FaPhp className="icon" style={{ i: "#4eb7ff" }} />,
        title: "PHP",
      },
      {
        icon: <FaAngular className="icon" style={{ i: "#4eb7ff", color:"red" }} />,
        title: "Angular",
      },
      {
        icon: <FaNode className="icon" style={{ i: "#4eb7ff", color:"green" }} />,
        title: "Nodejs",
      },
      {
        icon: <SiTypescript className="icon" style={{ i: "#4eb7ff", backgroundColor:"black",color:"yellow" }} />,
        title: "TypeScript",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle:
          "Set your project on the course to continuous improvement",
        Button: "Lets's talk",
        image: ITconsulting1,
        para: "Hire an experienced DevOps services company to help ensure utmost functional and non-functional quality of your software solutions.",
      },
    ],

    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    Forms: [
      {
        formTitle: "Let's talk",
        formSubtitle: "Tell us about your project. Let’s get to work!",
      },
    ],
  },
  {
    name: "out-sourcing",


    ImpressYourClientTitle:
      "Why should you hire a software development  and digital Marketing team ?",
    ImpressYourClientPara:
      "Unlock Your Business Potential with IT Consulting & Digital Marketing. Validate ideas, create roadmap & prototype. Overcome technical challenges. Drive growth with expert marketing strategies. Don't let lack of expertise hinder success. Maximize potential.",
    // ImpressYourClientImage: impresNewClientFlowImg,
    
    ImpressYourClientPoints: [
      {
        li: "CTO as a Service",
      },
      {
        li: "Market research",
      },

      {
        li: "Creating development roadmaps",
      },
      {
        li: "Guided MVP creation",
      },
    ],
    DevelopmentSolutionsTitle:
      "Why should you hire a dedicated software development team",
    DevelopmentSolutionsList: [
      {
        ariaControls: "panel1a-content",
        id: "panel1a-header",
        Number: "01",
        AccordianSummary: "Quickly add the tech expertise your project needs ",
        AccordianDetails:
          "Strengthen your in-house team with our developers who are proficient in every technology imaginable.",
      },
      {
        ariaControls: "panel2a-content",
        id: "panel2a-header",
        Number: "02",
        AccordianSummary: "Quality development service at a reasonable cost ",
        AccordianDetails:
          " Assemble your dedicated team in no time and launch your development project without any delays.",
      },
      {
        ariaControls: "panel3a-content",
        id: "panel3a-header",
        Number: "03",
        AccordianSummary: "Get full control over the development process",
        AccordianDetails:
          "Don’t waste time on scouting and hiring an entire team of developers, QA engineers, designers, and PMs — we’ve got them all.",
      },
      {
        ariaControls: "panel2a-content",
        id: "panel2a-header",
        Number: "04",
        AccordianSummary: "Startups that need to scale fast",
        AccordianDetails:
          "Your dedicated development team for hire can be scaled up and down easily and as often as you need..",
      },
      {
        ariaControls: "panel2a-content",
        id: "panel2a-header",
        Number: "05",
        AccordianSummary:
          // "Highly experienced and knowledgeable in every technology that you needed",
          "Highly experienced and knowledgeable Team ",
        AccordianDetails:
          "At the first stage, we want to know as much as possible about the project you have in mind and where you want to see it in the near future. We will also want to know your deadlines and how you envision the work on your project overall",
      },
      {
        ariaControls: "panel2a-content",
        id: "panel2a-header",
        Number: "06",
        AccordianSummary:
          "Get top edge finish product at the end of completion.",
        AccordianDetails:
          "After getting a clear idea about your project specifications and goals, we will present you with our vision of your team, its composition, and the projected milestones. Moreover, we will reach an agreement with you on the project cost and deadline.",
      },
      {
        ariaControls: "panel2a-content",
        id: "panel2a-header",
        Number: "07",
        AccordianSummary: "Selecting the candidates",
        AccordianDetails:
          "Once we know what kind of expertise your project needs and how many engineers are required, we will start preparing the pool of candidates. We will use both our own resources and search for candidates with relevant experience outside of the company.",
      },
      {
        ariaControls: "panel2a-content",
        id: "panel2a-header",
        Number: "08",
        AccordianSummary: "Final interviews",
        AccordianDetails:
          "With a shortlist of candidates at hand, we begin the last round of the selection process. You will get to interview the candidates and ask them the questions you consider important. You will always have the final say when it comes to your dedicated team composition. ",
      },
    ],
    TechnologiesExpertiesTitle: "Tech Stack",
    TechnologiesExpertiesPara:
      "Dedicated Development Teams is just one of the qualified services we impart. Senwell has a  inventory of more than 280 dedicated engineers with immense knowledge of niche technologies, programming languages, frameworks, and libraries. We go beyond writing efficient code, focusing on an end result that will help your business achieve its objectives.",
    TechnologiesExperties: [
      {
        icon: <img src={Java} className="icon" style={{ i: "#4eb7ff" }} alt="Java" />,
        title: "Java",
      },
      {
        icon: <FaReact className="icon" style={{ i: "#4eb7ff" }} />,
        title: "React",
      },
      {
        icon: <FaPhp className="icon" style={{ i: "#4eb7ff" }} />,
        title: "PHP",
      },
      {
        icon: <FaAngular className="icon" style={{ i: "#4eb7ff", color:"red" }} />,
        title: "Angular",
      },
      {
        icon: <FaNode className="icon" style={{ i: "#4eb7ff", color:"green" }} />,
        title: "Nodejs",
      },
      {
        icon: <SiTypescript className="icon" style={{ i: "#4eb7ff", color:"yellow",backgroundColor:"black" }} />,
        title: "Tyescript",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: " Dedicated Development Teams",
        Button: "Lets's talk",
        image: dedacatedImage,
        para: "Our dedicated development team services can help you achieve your project goals without the time and money it takes to assemble an in-house team. Share the details of your project and we will find a solution that works for you.",
      },
    ],

    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    Forms: [
      {
        formTitle: "Let's talk",
        formSubtitle: "Tell us about your project. Let’s get to work!",
      },
    ],
  },
  {
    name: "mvp-development",
    HeroBanner: [
      {
        name: "mvp-development",
        Page: "mvp-development",
        title: "MVP Development Services",
        breadcrums: "Services",
        Link: "/services",
        spanText: "Consulting ",
        title2: "Services",
        para: "Build the foundation for the successful growth of your digital solution by hiring an experienced MVP development company.",
        button: "Hire Us",
        img: WebDevelopmentBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
      },
    ],
    industriesBanner: [
      {
        image: MVPimg,
        title: "The concept of MVP development",
        para1:
          "You cannot hurry a Minimum Viable Product without conducting thorough research. While limiting functionality to a bare minimum is critical for speeding up time to market and lowering development costs, you must also maintain your digital solution sustainable.",
        para2:
          "Take, for example, MVP development of a banking application, where security is a high need. Features like Cashback and Scheduled Payments aren't required straight immediately. After your early consumers are pleased with the performance of your core feature, they may be added later in the development process. What you should concentrate on are data encryption and multi-factor authentication, so that your early customers can trust you with their data and assets. B",
      },
    ],
    Benefits: [
      {
        title: "MVP software ",
        Benefitstitle: "development benefits",
        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "Innovative idea ",
            description:
              "MVP development allows you to test your concept before investing your full money in a digital solution that may or may not succeed.",
          },
          {
            Number: "02",
            title: "Market research ",
            description:
              "Examine the demand for your digital solution to prevent squandering money on something your target audience doesn't want.",
          },
          {
            Number: "03",
            title: "Low risk",
            description:
              "You may gather user input and evaluate analytics using MVP development to make educated decisions about future product development. ",
          },
          {
            Number: "04",
            title: "Quick launch ",
            description:
              "Faster time to market. Before your rivals have even begun, expand your user base, collect feedback, and begin enhancing your digital product.",
          },
          {
            Number: "05",
            title: "Budget friendly ",
            description:
              "Companies may use MVP development to evaluate their digital goods before committing to a full-scale software development project.",
          },
          {
            Number: "06",
            title: "Deliverable Decision ",
            description:
              "MVP development allows you to iterate quickly and inexpensively based on input from actual prospects.",
          },
        ],
      },
    ],
    WhyChooseUs: [
      {
        WhyChooseUsTitle: "Why Choose Us",
        WhyChooseUs: [
          {
            icons: "",
            title: "Rapid project start-up",
            para: "Skip the time-consuming sourcing and recruitment procedure and get right into MVP development.",
          },
          {
            icons: "",
            title: "Complete Transparency",
            para: "With our experienced project management practises, we can ensure clear and efficient communication",
          },
          {
            icons: "",
            title: "Excellent code Quality ",
            para: "MVP development experience in Code Quality to assist in establishing industry-best coding standards for your project.",
          },
          {
            icons: "",
            title: " Domains Expertise",
            para: "Hire a team with specialised tech expertise and MVP development experience in your industry.",
          },
        ],
      },
    ],

    DevelopmentSolutionsTitle: "IT Consulting Services  ",
    DevelopmentSolutionsList: [
      {
        ariaControls: "panel1a-content",
        id: "panel1a-header",
        Number: "01",
        AccordianSummary: "SDLC Workflow Optimization",
        AccordianDetails:
          " Make sure every step of your development lifecycle is geared to minimizing costs and increasing output. We can help your team run like a fine-tuned Swiss watch.",
        List1: "Development & QA process audit",
        List2: "Creating thorough documentation",
        List3: "Improving onboarding practices",
        List4: "Agile & Lean methodology coaching",
      },
      {
        ariaControls: "panel2a-content",
        id: "panel2a-header",
        Number: "02",
        AccordianSummary: "Project Planning",
        AccordianDetails:
          " Create a roadmap for turning your idea into a working product used by millions. Our product development consultants can help you create a development roadmap, choose key features for your MVP, and find a team to write your first lines of code.",
        List1: "Market & Competitor research",
        List2: "Prototype and MVP planning",
        List3: "Creating development roadmaps",
        List4: "Assistance with forming a team and hiring",
      },
      {
        ariaControls: "panel3a-content",
        id: "panel3a-header",
        Number: "03",
        AccordianSummary: "Adherence to Industry Best Practices",
        AccordianDetails:
          " Make sure your team is adhering to industry best practices in regards to processes, information security, and quality standards.",

        List1: "Assistance with CMMI-DEV maturity",
        List2: "Information security audit ISO:27001",
        List3: "Assistance with achieving industry-specific standards ",
      },
      {
        ariaControls: "panel2a-content",
        id: "panel2a-header",
        Number: "02",
        AccordianSummary: "CTO as a Service",
        AccordianDetails:
          " Completely outsource the technical supervision of your project to a qualified CTO with years of experience. This professional will help you choose the best frameworks, languages, and architecture for your project.",
        List2: "ut development plans into action",
        List3: " Ensure thorough technical supervision of your project",
        List4: "Leverage decades of hands-on experience",
        List5: "Make well-informed technical decisions",
      },
    ],
    TechnologiesExpertiesTitle: "Tech Stack",
    TechnologiesExpertiesPara:
      "Our expertise does not end with Angular development outsourcing. Our skills and experience cover almost every programming language, framework, and library, both proven by years and recently introduced by industry leaders like Google or Microsoft.",
    TechnologiesExperties: [
      {
        icon: <DiSwift className="icon" style={{ i: "#4eb7ff", backgroundColor:"red", color:"white" }} />,
        title: "Swift",
      },
      {
        icon: <DiIonic className="icon" style={{ i: "#4eb7ff",color:"blue" }} />,
        title: "Ionic",
      },
      {
        icon: <FaReact className="icon" style={{ i: "#4eb7ff" }} />,
        title: "React Native",
      },
      {
        icon: <img src={Vue} className="icon"  style={{color:"green"}} alt="Vue" />,
        title: "Vue.Js",
      },
      {
        icon: <img src={Kotlin1} className="icon" alt="Kotlin1" />,
        title: "Kotlin",
      },
      {
        icon: <FaAndroid className="icon" style={{ i: "#4eb7ff", color:"green" }} />,
        title: "Anodroid",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle:
          "Set your project on the course to continuous improvement",
        Button: "Lets's talk",
        image: "",
        para: "Hire an experienced DevOps services company to help ensure utmost functional and non-functional quality of your software solutions.",
      },
    ],

    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    ProjectPortfollio: [
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fooz",
        image1: Fooz1,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
    ],
    Forms: [
      {
        formTitle: "Let's talk",
        formSubtitle: "Tell us about your project. Let’s get to work!",
      },
    ],
  },
];
export { Services };

const DevopsData = [
  {
    name: "devops",
    HeroBanner: [
      {
        name: "devops",
        Page: "DevOps",
        title: "DevOps Services For SMEs and Enterprises",
        breadcrums: "services",
        Link: "/services",
        title2: "Enterprises",
        para: "Plan your projects thoroughly and improve your ability to deliver applications, scale them, and build your solutions on reliable infrastructure.",
        button: "Get In Touch",
        img: WebDevelopmentBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
      },
    ],

    ImpressYourClientTitle: "Serve Your Users",
    ImpressYourClientPara:
      "DevOps allows teams to deliver more often while still maintaining a balance of quality and innovation. Give your customers the benefit of regular updates, and your stakeholders the benefit of a shorter time-to-market. Collaborate with us to enhance your",
    ImpressYourClientImage: impresNewClientFlowImg,
    ImpressYourClientPoints: [
      {
        li: "Deployment & Delivery process",
      },
      {
        li: "Scalability using cloud services",
      },
      {
        li: "Global availability of your product",
      },
      {
        li: "Release frequency",
      },
    ],
    DevelopmentSolutionsTitle: "Ideal Web Development Solutions",
    DevelopmentSolutionsList: [
      {
        ariaControls: "panel1a-content",
        id: "panel1a-header",
        Number: "01",
        AccordianSummary: "Cloud Adoption",
        AccordianDetails:
          " Reduce your IT costs, improve the performance of your software, and ensure the scalability and reliability of your project.",
        // List1: "Cloud Migration",
        List2: "DDos Detection and Prevention",
        List3: "AWS Infrastructure",
        List4: "Regular Backups",
      },
      {
        ariaControls: "panel2a-content",
        id: "panel2a-header",
        Number: "02",
        AccordianSummary: "Team Improvements",
        AccordianDetails:
          "Go beyond improvements to your product and increase the efficiency of your in-house development and testing teams.",
        // List1: "Streamline deployment and delivery",
        List2: "Improve employee engagement",
        List3: " Create thorough technical documentation",
      },
      {
        ariaControls: "panel3a-content",
        id: "panel3a-header",
        Number: "03",
        AccordianSummary: "Delivery Pipeline (CI/CD)",
        AccordianDetails:
          " Help your team implement CI/CD best practices to reduce post-release debugging, improve issue resolution time, and achieve a faster TTM.",
        // List1: "End-to-End CI/CD Automation with Azure",
        List2: "Streamlined Deployment",
        List3: " Thorough quality control",
        List4: "Reduced lead time",
      },

      {
        ariaControls: "panel4a-content",
        id: "panel4a-header",
        Number: "04",
        AccordianSummary: "Websites & Web Apps",
        AccordianDetails:
          " Build your online image with engaging looks, intuitive layouts, and compelling user experience.",
        // List1: "corporate websites",
        List2: "Landing Pages",
        List3: " Progressive Web Apps",
        List4: "Single-page Applications",
        List5: "Microservices",
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Prepare your next project for continuous success with our DevOps outsourcing!",
        Button: "Schedule a Call",
      },
    ],

    TechnologiesExpertiesTitle: "Our Technology Expertise",
    TechnologiesExpertiesPara:
      "Our Technology Expertise DevOps services are only one of the many areas in which we have expertise. Sewell Solutions employs over 280 engineers that are experts in specialised technologies, programming languages, frameworks, and libraries. We go beyond just producing solid code, concentrating on the final product that will help your company reach its goals.",
    TechnologiesExperties: [
      {
        icon: <img src={jenkins1} className="icon" style={{ i: "#4eb7ff" }} alt="Jenkins1" />,
        title: "Jenkins",
      },
      {
        icon: <FaDocker className="icon" style={{ i: "#4eb7ff"}} />,
        title: "Dockers",
      },
      {
        icon: <SiAnsible className="icon" style={{ i: "#4eb7ff", color:"red" }} />,
        title: "Ansible",
      },
      {
        icon: <img src={Gitlab} className="icon" style={{ i: "#4eb7ff" }} alt="Gitlab" />,
        title: "Gitlab CI",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: "Want a free consultation on your project?",
        Button: "Get In Touch",
        image: DevOps,
        para: "We can help you choose the best-suited engagement model for your project so you’ve reached your business goals the soonest and without overpaying for it.",
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    ProjectPortfollio: [
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },

      {
        title: "Fooz",
        image1: Fooz1,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    Forms: [
      {
        formTitle: "Let's talk",
        formSubtitle: "Tell us about your project. Let’s get to work!",
      },
    ],
  },
];
export { DevopsData };

const TestingData = [
  {
    name: "testing-and-qa",
    HeroBanner: [
      {
        name: "testing-and-qa",
        Page: "Testing & QA",
        title: "Software QA Services",
        Breadcrumbs: "Testing & QA",
        Link: "/services",
        section: "Services",
        spanText: "Software QA",
        title2: " Services",
        para: "Improve your SDLC by implementing entirely planned and polished quality reassure workflows on your project. Get the most out of your QA and testing",
        button: "Let's talk",
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle: "Download a sample of our thorough testing reports.",
        Button: "Download",
      },
    ],
    ProjectPortfollio: [
      {
        title: "Fooz",
        image1: Fooz1,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },

      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    TechnologiesExpertiesTitle: "Quality Assurance Technologies",
    TechnologiesExpertiesPara:
      "Our software QA services involve a wide range of robust tools and latest technologies to effectively report bugs and maintain your software product at an extraordinary level of quality. The automated and manual testing we perform ensures speedy delivery and broad test coverage on your project.",
    TechnologiesExperties: [
      {
        icon: <img src={Postman} className="icon" style={{ i: "#4eb7ff" }} alt="Postman" />,
        title: "Postman",
      },

      {
        icon: <img src={Selenium1} className="icon" style={{ i: "#4eb7ff" }} alt="Selenium1" />,
        title: "Selenioum",
      },
      {
        icon: <img src={BrouserStack1} className="icon" style={{ i: "#4eb7ff" }} alt="BrouserStack1" />,
        title: "BrowserStack",
      },
      {
        icon: <img src={junit} className="icon" style={{ i: "#4eb7ff",}} alt="Junit" />,
        title: "JUnit",
      },
      {
        icon: <img src={Jmeter} className="icon" style={{ i: "#4eb7ff", width:"50%"}} alt="Jmeter" />,
        title: "Jmeter",
      },

    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },
];
export { TestingData };



const ProductDevelopmentData = [
  {
    name: "product-development",
    HeroBanner: [
      {
        name: "product-development",
        Page: "product",
        title: "Product Development Solutions We Deliver",
        breadcrums: "services",
        Link: "/services",
        para: "increment the value delivered to the market with a software product built and refined by our internal product development team.",
        button: "Get In Touch",
        img: WebDevelopmentBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
      },
    ],
    Benefits: [
      {
        title: "What you get",

        BenefitsofDevelopmentWithSenwell: [
          {
            Number: "01",
            title: "Agile Product Development ",
            description:
              "We merge well-established business processes, industry development best practices and innovative thinking to deliver in a timely manner.",
          },
          {
            Number: "02",
            title: "Technical product ownership",
            description:
              "Product Technical Support As we manage the architecture, design and technical flow on the project, your product team can focus on strategy.",
          },
          {
            Number: "03",
            title: "Business Expertise",
            description:
              "We have extensive experience in business knowledge to ensure a successful phase and adapt a robust product and market",
          },
          {
            Number: "04",
            title: "Data-informed decisions",
            description:
              "We built strong product vision with organised goal with comprehensive data Form business analysis and software architects.",
          },
          {
            Number: "05",
            title: "Efficient communication",
            description:
              "Through clyster clear communication and efficient project management, we reduce number of blockers hindering your product delivery process. ",
          },
          {
            Number: "06",
            title: "DevOps",
            description:
              "a fast and efficient release cycle with a seamless CI / CD pipeline supported by a flexible and cross-functional team of experienced DevOps engineers b ",
          },
        ],
      },
    ],

    DevelopmentSolutionsTitle: "Ideal Web Development Solutions",
    DevelopmentSolutionsList: [
      {
        ariaControls: "panel1a-content",
        id: "panel1a-header",
        Number: "01",
        AccordianSummary: "New Product Development",
        AccordianDetails:
          "From confirming your ideas to building full-fledged solutions, we will help drive your project to success..",
        List1: "Proof of concept",
        List2: "Prototype development",
        List3: "MVP development",
        List4: "SaaS development",
      },
      {
        ariaControls: "panel2a-content",
        id: "panel2a-header",
        Number: "02",
        AccordianSummary: "Perfect Product/Market Fit",
        AccordianDetails:
          "From solid product vision to best-fitting business model, we refine ideas into profitable and scalable products.",
        List1: "Data-driven analytics",
        List2: "Code quality assessment",
        List3: "Market research",
        List4: "Considered UI/UX",
      },
      {
        ariaControls: "panel3a-content",
        id: "panel3a-header",
        Number: "03",
        AccordianSummary: "Integrations & Microservices",
        AccordianDetails:
          " Upgrade your existing software with self-sustaining microservices, seamlessly integrated modules.",
        List1: "New feature implementation",
        List2: "Microservices-based architecture",
        List3: "Third-party API integration",
        List4: "Continuous maintenance and support",
      },
      {
        ariaControls: "panel4a-content",
        id: "panel4a-header",
        Number: "04",
        AccordianSummary: "B2B & B2C Software",
        AccordianDetails:
          "Extensive experience and industry knowledge in building strong-suited mobile, web, and desktop solutions.",
        List1: "Social networks & dating platforms",
        List2: "Project management & business tools",
        List3: "Code quality & source code security tools",
        List4: "PDF generators",
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Looking for a product development team to filter your ideas into strong-suited software?",
        Button: "Schedule a Call",
      },
    ],

    TechnologiesExpertiesTitle: "Our Technology Expertise",
    TechnologiesExpertiesPara:
      "Our bespoke product development team is well-versed in a variety of programming languages and technologies, which they employ to create everything from a basic mobile app to a large ERP system.With our extensive domain knowledge, we will assist you in selecting the technology stack that best meets industry standards and meets your business requirements.",
    TechnologiesExperties: [
      {
        icon: <IoLogoJavascript className="icon" style={{backgroundColor:"yellow", color:"black"}} />,
        title: "JavaScript",
      },
      {
        icon: <FaAngular className="icon" style={{ i: "#4eb7ff",color:"red"}} />,
        title: "Angular",
      },
      {
        icon: <FaReact className="icon" style={{ i: "#4eb7ff" }} />,
        title: "React",
      },
      {
        icon: <FaNode className="icon" style={{ i: "#4eb7ff", color:"green"}} />,
        title: "Node.js",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: "Want a free consultation on your project?",
        Button: "Get In Touch",
        image: DevOps,
        para: "We can help you choose the best-suited engagement model for your project so you’ve reached your business goals the soonest and without overpaying for it.",
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: "Follow best Angular development standards",
        Button: "Estimate a project",
        image: AngularImg,
        para: "The client-facing side of an app determines its success. After all, if it doesn’t look and work well, who’s going to use it? Our Angular development services pair developers with designers to create the most inviting, intuitive, and memorable user interfaces for your users to enjoy.",
      },
    ],
    ProjectPortfollio: [
      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },
      {
        title: "Fooz",
        image1: Fooz1,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },

      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    Forms: [
      {
        formTitle: "Let's talk",
        formSubtitle: "Tell us about your project. Let’s get to work!",
      },
    ],
  },
];
export { ProductDevelopmentData };

const DigitalData = [
  {
    name: "digital-marketing",
    HeroBanner: [
      {
        name: "digital-marketing",
        Page: "Testing & QA",
        title: "Digital Marketing",
        Breadcrumbs: "Digital Marketing",
        Link: "/services",
        section: "Services",
        spanText: "Software Digital Marketing",
        title2: " Services",
        para: "Improve your SDLC by implementing entirely planned and polished quality reassure workflows on your project. Get the most out of your QA and testing",
        button: "Let's talk",
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle: "Download a sample of our thorough testing reports.",
        Button: "Download",
      },
    ],
    ProjectPortfollio: [
      {
        title: "Fooz",
        image1: Fooz1,
        technologies: [" React", "Redux", "Node.js", "Bootstrap"],
        para: "The UAE-born, play-to-win online platform introduced by Arab Millionaire is offering participants the chance to “Dream Big, Give More” with the largest prizes in the Arab World",
      },
      {
        title: "LottoSocial",
        image1: Lotto,
        technologies: [" Angular", "Ionic", "Node.js", "Firebase"],
        para: "Web Application to create and manage online lottery syndicates. Increase your chances of winning big and join a lottery syndicate. We made it simple to create and manage lottery syndicates online, offering a combination of ticket types.",
      },
      {
        title: "Fork-Freight",
        image1: Fork,
        technologies: [" Angular", "Asp.Net"],
        para: "Trucking/Transportation Web application for transportation Industry.Fork Freight is an app which provides high-end, affordable services that will help carriers, shippers, brokers, and dispatchers grow their business.",
      },
      {
        title: "Lumiere32- B2B Supplier Marketplace",
        image1: Lumiere,
        technologies: [" Angular", ", NestJs", "MySQL"],
        para: " We developed an effective web application that helps developers to write semantically correct code and form useful coding habits with better practices, superior tools, and proven methodologies.The application provides thorough data analysis and real-time statistics as well as features such as sorting errors by priority. The app is compatible with all popular desktop and mobile devices.",
      },

      {
        title: "VirusGeeks",
        image1: Virus,
        technologies: [" Angular", "NextJs", "NestJs"],
        para: "Healthcare Platform VirusGeeks, a BioHealth Technology platform, which provide health tech solutions such as end-to-end Covid-19 testing.",
      },
      {
        title: "Parsl",
        image1: parsl,
        technologies: [" Angular", "Node.js"],
        para: "Parsl is a platform that combines blockchain, NFC and IoT to create a track and trace technology that not only automates compliance efficiently and accurately but offers a range of tangible business benefits for every stage of the cannabis supply chain. ",
      },
      {
        title: "Cronage",
        image1: Cronage,
        technologies: [" Angular", "Bootstrap", "HTML", "CSS"],
        para: "  New businesses must strike while the iron is hot. Chasing after a fast time-to-market can make your business vulnerable to performance risks. We helped Distractify perform a fast but extremely thorough Quality Assurance process before they hit “publish” on their first viral story.",
      },
    ],
    TechnologiesExpertiesTitle: "Quality Assurance Technologies",
    TechnologiesExpertiesPara:
      "Our software QA services involve a wide range of robust tools and latest technologies to effectively report bugs and maintain your software product at an extraordinary level of quality. The automated and manual testing we perform ensures speedy delivery and broad test coverage on your project.",
    TechnologiesExperties: [
      {
        icon: <img src={Postman} className="icon" style={{ i: "#4eb7ff" }} alt="Postman" />,
        title: "Postman",
      },

      {
        icon: <img src={Selenium1} className="icon" style={{ i: "#4eb7ff" }} alt="Selenium1" />,
        title: "Selenioum",
      },
      {
        icon: <img src={BrouserStack1} className="icon" style={{ i: "#4eb7ff" }} alt="BrouserStack1"/>,
        title: "BrowserStack",
      },
      {
        icon: <img src={junit} className="icon" style={{ i: "#4eb7ff",}} alt="junit"/>,
        title: "JUnit",
      },
      {
        icon: <img src={Jmeter} className="icon" style={{ i: "#4eb7ff", width:"50%"}} alt="Jmeter" />,
        title: "Jmeter",
      },

    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },
];
export { DigitalData };


const ConsultingData = [
  {
    name: "it-consulting",
   }
  ]
  export {ConsultingData}