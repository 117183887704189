import { CmmiImage } from "../images/images";
import { Union } from "../images/images";
import { LogoImage } from "../images/images";
import GroupIcon from "@material-ui/icons/Group";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import LanguageIcon from "@material-ui/icons/Language";
import { BusinesModelsBanner } from "../images/images";
import { BiCheck } from "react-icons/bi";
import { BMGetinTouch } from "../images/images";
import consultationImage from "../images/Company/consultationImage.png";
const BusinessModelData = [
  {
    name: "outsourcing-pricing-models",
    HeroBanner: [
      {
        name: "outsourcing-pricing-models",
        Page: "Business Models",
        title: "Let’s find our work culture",
        spanText: "We ",
        title2: "",
        button: "Let's talk",
        para: "Utilize the utmost efficiency of our flexible collaboration models and customer-preoccupied approach!  With well-established processes and deep expertise proven by years of experience in the full-cycle software development market, we will be a  faithful partner that delivers on your goals flawlessly from start to finish.",
        img: BusinesModelsBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Need assistance building a strong engine to run your product arrangement?",
        Button: "Get In Touch",
      },
    ],
    GetInTouchSecondbanner: [
      {
        GetInTouchTitle: "Want Free Consultation?",
        Button: "Get In Touch",
        image: consultationImage,
        para: "We help you choose the best engagement model for your project so you can reach your business goals as quickly as possible without overpaying",
      },
    ],
    Testimonials: [
      {
        label1:
          "“They are incredibly client-focused, so we saw them as partners rather than service suppliers. Applying industry cutting edge- practices and knowledge of travel, real estate, technology, and software business domain they have managed to deliver the project on time and of the highest quality.”",
        img: "",
        name: "Derick, Hatchster Ltd. UK",
        post: "",
      },
      {
        label2:
          "“I’ve been working with Senwell Group Private Limited for several outsourcing projects for clients in Canada and US, and I  have to say that the guys here deliver really great work and oftentimes outperform the clients’ own  development teams. I’ve never met such productivity before and that’s probably one of the biggest,  although not the only one advantage of the company..”",
        img: "",
        name: "Kevin Atom, Property Hatch Ltd, United State",
        post: "",
      },
      {
        label3:
          "“We are enormously satisfied with Senwell Group Private Limited as contractor. Highly responsive team with  profound understanding of subject matter and really very, very fast code delivery that is particularly  important for our travel business. . ”",
        img: "",
        name: "Nicolo F, Italy",
        post: "",
      },
    ],
    Forms: [
      {
        formTitle: "Let's talk",
        formSubtitle: "Tell us about your project. Let’s get to work!",
      },
    ],
  },
];
export { BusinessModelData };

const HeroBannerSubsection = [
  {
    icon: <GroupIcon style={{ fontSize: "2.5rem", color: "#3D8ED7" }} />,
    title: "Time and Material ",
    subtitle:
      "The most ideal for complex, long-haul projects with indistinct necessities.",
    para: "T and M's extraordinary adaptability permits you to adjust to showcase changes and change necessities right away whenever during advancement. You will get a legitimate arrangement variant each time the contract is rehashed. Assuming you are discontent with the outcome, you can end the agreement whenever.",
    list1: "Convertible workflow and predictable budgeting",
    list2: "High adaptability and convert control at a low risk",
    list3: "Available at any phase of programming advancement life cycle",
    list4: "High command over scope and necessities",
  },
  {
    icon: <WatchLaterIcon style={{ fontSize: "2.5rem", color: "#3D8ED7" }} />,
    title: "Dedicated Team",
    subtitle:
      "The most ideal for medium size or enterprise projects and business extensions.",
    para: "You'll have a total group of experts (counting project directors, designers, originators, business examiners, and QA engineers) that can be effortlessly scaled as your undertaking advances. You have unlimited authority over your group's size, scope, improvement interaction, and deadlines.",
    list1: "Simple expense tracking and Unsurprising planning",
    list2: "Full team commitment and responsibility",
    list3: "Adaptable level of guidelines over the project",
    list4: "Quick resource scaling and replacement",
  },
  {
    icon: <LanguageIcon style={{ fontSize: "2.5rem", color: "#3D8ED7" }} />,
    title: "Staff Augmentation",
    subtitle:
      "Works marvels for improvement projects of any intricacy and scale.",
    para: "Try not to waste months looking, recruiting, and onboarding! This is an extraordinary choice for in-house group projects were experts with significant experience and abilities need to assume a particular part. They impart the necessities and get the right possibility for the position.",
    list1: "Quick admittance to talent with fast onboarding",
    list2: "Full command over the group from start to finish",
    list3: "Expected cost with no in-house work overheads",
    list4: "No amenities or administrative investment",
  },
];
export { HeroBannerSubsection };

const time = [
  {
    id: 1,
    srNo: "01",
    duration: "<1 day",
    title: "Requirements analysis",
    list1 : "- Business, tech, and marketing analysis",
    list2: "- Documentation studying",
    list3: "- Project plan and scheduling",
  },
  {
    id: 2,
    srNo: "02",
    duration: "<1 day",
    title: " Pre-sale engineer introduction",
    list1: "- Fast first contact with the team",
    list2: "- High-quality company presentation",
    list3: "- Sprint demo",
  },
  {
    id: 1,
    srNo: "03",
    duration: "<1 day",
    title: "Proposal and estimation",
    list1: "- Project scope agreement",
    list2: "- Project scope estimate",
    list3: "- Contract terms agreement",
  },
  {
    id: 1,
    srNo: "04",
    duration: "1-7 days",
    title: "Team selection",
    list1: "- Vacancy registration",
    list2: "- CV generation",
    list2: "- Interviews",
  },
];
export { time };

const staff = [
  {
    id: 1,
    srNo: "01",
    duration: "<1 day",
    title: "Requirements analysis",
    list1: "- Project documentation studying",
    list2: "- Tech stack analysis",
    list3: "- Recruitment scheduling",
  },
  {
    id: 2,
    srNo: "02",
    duration: "<1 day",
    title: "Company presentation ",
    list1: "- Fast first contact with potential candidates",
    list2: "- High-quality company presentation",
    list3: "- Positive references and testimonials",
  },
  {
    id: 1,
    srNo: "03",
    duration: "<1 day",
    title: "Vacancy registration of internal hiring",
    list1: "- Schedule hiring processes",
    list2: "- Check for available candidates",
  },
  {
    id: 1,
    srNo: "04",
    duration: "1-7 days",
    title: "CV generation",
    list1: "- Recruiting",
    list2: "- Preparing documentation",
  },
  {
    id: 1,
    srNo: "05",
    duration: "<1 day",
    title: "Interviews",
    list1: "- Interview setup",
    list2: "- Interview results",
  },
];
export { staff };

const team = [
  {
    id: 1,
    srNo: "01",
    duration: "<1 day",
    title: "Requirements analysis",
    list1: "- Project documentation studying",
    list2: "- Tech stack analysis and suggestions",
    list3: "- Project plan and scheduling",
  },
  {
    id: 2,
    srNo: "02",
    duration: "<1 day",
    title: "Presentation and meeting",
    list1: "- Company introduction",
    list2: "- Acquaintance with processes and workflows",
    list3: "- Positive references and testimonials",
  },
  {
    id: 1,
    srNo: "03",
    duration: "<1 day",
    title: "Vacancy registration of internal hiring",
    list1: "- Schedule hiring processes",
    list2: "- Check for available candidates",
  },
  {
    id: 1,
    srNo: "04",
    duration: "1-7 days",
    title: "CV generation",
    list1: "- Recruiting",
    list2: "- Preparing documentation",
  },
  {
    id: 1,
    srNo: "05",
    duration: "<1 day",
    title: "Interviews",
    list1: "- Interview setup",
    list2: "- Interview results",
  },
];
export { team };

const flexibleApproch = [
  {
    title: "Time & Material",
    subtitle: "Kanban or Scrum",
    description:
      "Depending on your business consideration and current situation, Kanban and Scrum are a great fit for the Time & Material model which usually implies continuous incremental delivery.",
    list1: "2000+h projects",
    list2: "Quick start",
    list3: "Support or continuous development",
  },
  {
    title: "Fixed Cost",
    subtitle: "Waterfall, Spiral, or V-Model",
    description:
      "A fixed-cost project requires constant monitoring, control, and supervision of almost each and every variable on the project: its scope, risks, budget, and many other factors.",
    list1: "PoC, MVP projects",
    list2: "Solid requirements",
    list3: "Strict deadlines and budget",
  },
  {
    title: "Dedicated Team & Outstaffing",
    subtitle: "Scrum or any other Agile",
    description:
      "This type of collaboration is most suitable for projects with mature management, looking to extend their in-house team and delivery capacity with talented engineers.",
    list1: "Team extension",
    list2: "Agile and alternative methodologies",
    list3: "Team as a part of client company",
  },
];
export { flexibleApproch };
