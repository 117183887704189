import React from "react";
import { Container, Typography, Grid, Button } from "@material-ui/core";
import healthcare from "../images/Services/Testing/healthcare.jpg";
import auto from "../images/Services/Testing/auto.jpg";
import { ParallaxBanner } from "react-scroll-parallax";
import "../css/bannarstylesheet.css";
import { makeStyles } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Popup from "../Modules/Popupform";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme) => ({
  HireNowButton: {
    padding: "0.7rem 4rem",
    color: "#fff",
    marginBottom: "2rem",
    letterSpacing: "2.5px",
    fontSize: "1rem",
    fontWeight: "600",
    fontFamily: "'Manrope', sans-serif",
    border: "2px solid rgb(31 142 203)",
    borderRadius: "25rem",
    background:
      "linear-gradient(90deg, hsla(200, 69%, 50%, 1) 24%, hsla(216, 62%, 39%, 1) 83%)",
    "&:hover": {
      background: "transparent",
      borderRadius: "25rem",
      border: "2px solid #fff",
    },
  },
  HeroBannerTitle: {
    fontSize: "3rem",
    color: "#fff",
    fontWeight: "700",
    fontFamily: "'Ubuntu', sans-serif",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
  paragraph: {
    fontFamily: "'Poppins', sans-serif",

    [theme.breakpoints.up("md")]: {
      padding: "0.3rem 10rem",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const Banner = ({ HeroBanner }) => {
  const classNamees = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {HeroBanner?.map((data) => (
        <>
          <div
            className="companyjumbotron companyparallax"
            id="companyparallax-static"
          >
            <div className="showcasebannersecond">
              <div className="overlaybannersecond">
                <Container
                  style={{ padding: "2rem 1rem", marginTop: "10rem" }}
                  maxWidth="md"
                >
                  <Grid
                    container
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <Grid items xs={12} sm={12} md={12}>
                      <h1 className={classNamees.HeroBannerTitle}>{data.title}</h1>
                      {data.button ? (
                        <Button
                          className={classNamees.HireNowButton}
                          onClick={handleOpen}
                        >
                          {data.button}
                        </Button>
                      ) : (
                        <p className={classNamees.paragraph}>{data.para}</p>
                      )}
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </div>
        </>
      ))}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classNamees.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Popup Close={handleClose} />
        </Fade>
      </Modal>
    </>
  );
};

export default Banner;
