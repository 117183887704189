import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { menu } from "../MenuApi/SideDrawerMenuApi";
import { hasChildren } from "./utils";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setTech } from "../../redux/tech/techActions";
import {drawerOpenClose} from "../../redux/drawerModal/drawerAction"

export default function zApp({ close }) {
  return menu?.map((item, key) => <MenuItem key={key} item={item} onClick={close}/>);
}
const MenuItem = ({ item, close }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} onClick={close} />;
};
const SingleLevel = ({ item, close }) => {
  const dispatch = useDispatch();
  return (
    <ListItem button component={NavLink} to={item.Link} onClick={close} >
      <ListItemText onClick={()=>{
        dispatch(setTech(item.title));
        dispatch(drawerOpenClose(true))

      }} primary={item.title} />
    </ListItem>
  );
};
const MultiLevel = ({ item, close }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    // console.log('am clicked!');
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  }


  return (
    <React.Fragment>
        <ListItem  button onClick={handleClick} onClickAway={handleClickAway}>
          {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
          <ListItemText className="spanFont" primary={item.title} onClick={close} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children?.map((child, key) => (
              <MenuItem
                key={key}
                item={child}
                component={NavLink}
                to={item.Link}
                onClick={handleClose}
              />
            ))}
          </List>
        </Collapse>
    </React.Fragment>
  );
};
