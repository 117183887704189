import { SET_SERVICE } from "./serviceType";
import { GET_SERVICE } from "./serviceType";


export const setService = (response) => {
  //console.log('setService action', response)
  return {
    type: SET_SERVICE,
    payload: response,
  };
};

export const getSpecialist = (response) => {
  return {
    type: GET_SERVICE,
    payload: response,
  };
};




