import React from 'react';
import "../../Services/Design/WeCanHelpYou.css"

const WeCanHelpYou = () => {
  return (
    <section style={{padding:"100px 0px"}} className="help-section">
      <div className="container" style={{left:"0",border:"none"}}>
      <h2 className="text-center Ui-help">We Can Help You</h2>
      <p className="text-center Ui-help-pare">Outstanding UI/UX design services fueled by a shared passion, refined skill, and years of experience.</p>
       <div className="row" style={{rowGap:"20px"}}>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="card help-card" style={{gap:"10px",height:"200px"}}>
              <h5 className="text-center help-h5">UX/UI Design</h5>
              <p className="text-center help-para" style={{width:"80%"}}>Outstanding UI/UX design services fueled by a shared passion, refined skill, and years of experience.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="card help-card" style={{gap:"10px" , height:"200px"}}>
              <h5 className="text-center help-h5">Brand/Logo Design</h5>
              <p className="text-center help-para" style={{width:"80%"}}>We create distinctive brand identities with engaging, memorable aesthetics and distinguishing characteristics.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="card help-card" style={{gap:"10px" , height:"200px"}}>
              <h5 className="text-center help-h5">App Design</h5>
              <p className="text-center help-para" style={{width:"80%"}}>We transform ideas into useful, pleasant apps with visual execution and experiences that your consumers will gladly promote to others.</p>
          </div>
        </div>
       </div>
      </div>
    </section>
  )
}

export default WeCanHelpYou
