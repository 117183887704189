import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Container, Grid } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Checkbox from "@material-ui/core/Checkbox";

import { setEstimate } from "../../../redux";
import { connect } from "react-redux";

const useStyles = makeStyles({
  heading: {
    margin: "1rem 0px",
    fontSize: "32px",
    lineHeight: "44px",
  },
  subHeading: {
    fontSize: "18px",
    lineHeight: "28px",
    textAlign:"justify"
  },
  formContainer: {
    margin: "2rem 0px 5rem",
  },
  formControl: {
    margin: "0.5rem 0px 1rem !important",
  },
  textField: {
    color: "#b8b8b8",
    fontSize: "12px !important",
  },
  tnc: {
    color: "#5a5a5a",
    fontSize: "14px",
  },
  policy: {
    margin: "0px 0.5rem",
    color: "rgb(32,142, 255)",
    "&:hover": {
      color: "#5a5a5a",
      transition: "0.3s",
    },
  },
  check: {
    color: "rgb(32,142, 255) !important",
    margin: "0",
    padding: "0",
  },
});

const GetEstimate = (props) => {
  const classNamees = useStyles();
  const [name, setName] = useState();
  const [email, setEmail] = useState("");
  const [discription, setDescription] = useState("");
  const [check, setCheck] = useState(false);
  // const [allData, setAllData] = useState([])

  const handleName = (e) => {
    setName(e.target.value);
    props.setEstimate([name, email, discription, check]);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    props.setEstimate([name, email, discription, check]);
  };
  const handleDiscription = (e) => {
    setDescription(e.target.value);
    props.setEstimate([name, email, discription, check]);
  };
  const handleChecked = (e) => {
    if (e.target.checked) {
      setCheck(true);
      if (check) {
        props.setEstimate([name, email, discription, check]);
      }
    }
  };

  return (
    <Container maxWidth="lg">
      <h1 className={classNamees.heading}>06. Get Your Estimate</h1>
      <p className={classNamees.subHeading}>
        We’re ready to start crunching numbers, but if there’s more you can tell
        us — it will allow us to give you a more accurate estimate!
      </p>
      <div className={classNamees.formContainer}>
        <form>
          <Grid
            container
            spacing={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} sm={6} className={classNamees.formControl}>
              <TextField
                className={classNamees.textField}
                name="name"
                variant="standard"
                required
                fullWidth
                id="name"
                label="Name"
                autoFocus
                size="small"
                // onChange={(e)=>setName(e.target.value)}
                onChange={(e) => handleName(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classNamees.formControl}>
              <TextField
                className={classNamees.textField}
                name="email"
                variant="standard"
                required
                fullWidth
                id="email"
                label="Email"
                autoFocus
                size="small"
                // onChange={(e)=>setEmail(e.target.value)}
                onChange={(e) => handleEmail(e)}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classNamees.formControl}>
              <TextField
                name="projectDescription"
                multiline
                minRows={4}
                variant="standard"
                required
                fullWidth
                id="projectDescription"
                label="Project Description"
                autoFocus
                size="small"
                // onChange={(e)=>setDescription(e.target.value)}
                onChange={(e) => handleDiscription(e)}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classNamees.formControl}>
              <Checkbox
                // unchecked={checked}
                inputProps={{ "aria-label": "primery checkbox" }}
                className={classNamees.check}
                onClick={(e) => handleChecked(e)}
              />

              <span className={classNamees.tnc}>
                I agree to the Senwell Group Private Limited{" "}
                <a href="#cookie" className={classNamees.policy}>
                  Cookie Policy
                </a>{" "}
                and
                <a href="#privacy" className={classNamees.policy}>
                  {" "}
                  Privacy Policy
                </a>
                *
              </span>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    allData: state.allData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setEstimate: function (allData) {
      dispatch(setEstimate(allData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetEstimate);
