import React from "react";
import "./TechnologiesBanner.css";
import Lottie from "react-lottie";
import { useParams, useNavigate } from "react-router-dom";

const TechnologiesBanner = ({ data }) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/contact");

    setTimeout(() => {
      const targetSection = document.getElementById("targetSection");
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 500);
  };

  const { name, slug } = useParams();
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <section className="Technologyheader-sections">
        <div className="container" style={{ left: "0", border: "none" }}>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="Technology-banner-content">
                <h1>
                  <span>{data?.HeroBanner[0]?.title1}</span>{" "}
                  {data?.HeroBanner[0]?.title2}
                </h1>
                <p className="sub-p">{data?.HeroBanner[0]?.para}</p>
                <div className="Technology-banner-button">
                  {data?.HeroBanner[0]?.button && (
                    <button
                      onClick={handleButtonClick}
                      className="Tech-theme-btn"
                    >
                      {data?.HeroBanner[0]?.button}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 technologies-image">
              <div className="image" style={{ pointerEvents: "none" }}>
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: data?.HeroBanner[0]?.loti,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default TechnologiesBanner;
