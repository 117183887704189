import { SET_ESTIMATE } from "./estimateType";
import { GET_ESTIMATE } from "./estimateType";

export const setEstimate = (response) => {
  //console.log("response action", response);
  return {
    type: SET_ESTIMATE,
    payload: response,
  };
};

export const getEstimate = (response) => {
  return {
    type: GET_ESTIMATE,
    payload: response,
  };
};
