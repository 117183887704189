import React from "react";
import { makeStyles } from "@material-ui/core";
import { Container, Typography, Grid } from "@material-ui/core";
import { flexibleApproch } from "../../API/BusinessModelsApi";

const useStyles = makeStyles((theme) => ({
  QualityfirstsectionParaWrapper: {
    color: "#5a5a5a",
    lineHeight: "1.5rem",
    padding: "2rem 4rem",
    textAlign:"justify",
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 2rem",
    },
  },
  QualityfirstsectionTitleWrapper: {
    [theme.breakpoints.up("sm")]: {
      padding: "3rem 2rem",
    },
  },
  QualityfirstsectionWrapper: {
    padding: "1rem 0rem 2rem",
    [theme.breakpoints.up("sm")]: {
      width: '90%',
      margin: 'auto'
    },
  },
  TextWrapper: {
    color: "#1c1c1c",
    padding: "0rem 2rem",
    fontFamily: " 'Ubuntu', sans-serif",
    [theme.breakpoints.down("sm")]: {
      marginTop: '1rem'
    },
  },
  subtitle: {
    // fontFamily: " 'Lora', serif",
    fontSize: "14px",
  },
  QualitysecondsectionWrapper: {
    color: "#",
    paddingRight: "2rem",
    width: '90%',
    margin: 'auto',
    [theme.breakpoints.down("sm")]: {
      padding: '0 2rem 1rem 2rem',
      width: '100%'
    },
  },

  titleText: {
    fontFamily: " 'Ubuntu', sans-serif",
  },
  QualitysecondsectionParaWrapper: {
    padding: "0rem 2rem",
    fontFamily: " 'Lora', serif",
    fontSize: "14px",

    margin: "1rem",
    [theme.breakpoints.down("xs")]: {
      padding: "0rem 0.2rem",
      margin: "0.5rem",
    },
  },
  paraText: {
    color: "#000",
    fontWeight: "700",
  },
  Paracontainer: {
    color: "#000",
  },
  Paracontainertext: {
    textAlign: "justify",
    // fontFamily: " 'Lora', serif",
    fontSize: "14px",
  },
  listwrapper: {
    margin: 0,
    padding: "1rem",
  },
  list: {
    color: "#1c1c1c",
    fontWeight: "500",
    // fontFamily: " 'Lora', serif",
    fontSize: "14px",
    padding: "0.4rem 0rem",
  },
}));

const FlexibleApproch = () => {
  const classNamees = useStyles();
  return (
    <>
      <Container maxWidth="lg" style={{ background: "#f6f6f6" }}>
        <Container className={classNamees.QualityfirstsectionWrapper} maxWidth="lg">
          <Grid container style={{ borderBottom: "1px solid #7a7a7a" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classNamees.QualityfirstsectionTitleWrapper}
            >
              <Typography variant="h4" className={classNamees.TextWrapper}>
                Flexible approaches to project development
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={classNamees.QualityfirstsectionParaWrapper}
            >
              <p style={{ fontSize: "14px" }}>
                We are experts in numerous traditional and Agile software
                development methodologies. We are a trustworthy partner who
                consistently attempts to exceed the client's expectations. We
                offer clear communication, transparency, and flexibility in
                cooperation with our clients.
              </p>
            </Grid>
          </Grid>
        </Container>
        <Container className={classNamees.mainWrapper} maxWidth="lg">
          <Grid container>
            <Grid item xs={6} sm={6} md={3} lg={3}></Grid>
          </Grid>
        </Container>
        <Container
          className={classNamees.QualitysecondsectionWrapper}
          maxWidth="lg"
        >
          <Grid container>
            {flexibleApproch?.map((data) => (
              <>
                <Grid xs={12} sm={12} md={4} lg={4}>
                  <div className={classNamees.QualitysecondsectionParaWrapper}>
                    <div>
                      <Typography>{data.icon}</Typography>
                      <Typography variant="h6" className={classNamees.titleText}>
                        {data.title}
                      </Typography>
                      <p className={classNamees.subtitle}>{data.subtitle}</p>
                    </div>
                    <div className={classNamees.Paracontainer}>
                      <Typography className={classNamees.Paracontainertext}>
                        {data.description}
                      </Typography>
                    </div>
                    <div>
                      <ul className={classNamees.listwrapper}>
                        <li className={classNamees.list}>{data.list1}</li>
                        <li className={classNamees.list}>{data.list2}</li>
                      </ul>
                    </div>
                  </div>
                </Grid>
              </>
            ))}
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default FlexibleApproch;
