import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@material-ui/core/Typography";

import { setService } from "../../../../redux";
import { connect } from "react-redux";

import axios from "axios";

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: "1rem 0px",
    fontSize: "32px",
    lineHeight: "44px",
  },
  subHeading: {
    fontSize: "18px",
    lineHeight: "28px",
  },
  switchContainer: {
    margin: "2rem 0px 4rem !important",
  },
  gridContainer: {
    margin: "1rem 0px",
    [theme.breakpoints.down("xs")]: {
      margin: "0rem 0px",
    },
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    fontSize: "20px",
    lineHeight: "28px",
    color: "#000",
  },
}));

const Services = (props) => {
  const classNamees = useStyles();
  const [choose, setChoose] = useState("");

  const handleChecked = (e, value) => {
    if (e.target.checked) {
      setChoose(value);

      const id = Math.floor(Math.random() * 100000 + 1);
      axios
        .post("http://localhost:5000/Services", {
          id: id,
          choose: value,
        })
        .then((response) => console.log("My response", response))
        .catch((error) => {
          console.log("error occur while posting data", error);
        });
      props.setService(value);
    }
  };

  return (
    <Container maxWidth="lg">
      <h1 className={classNamees.heading}>02. Services</h1>
      <p className={classNamees.subHeading}>
        Please select which services our team will need to handle.
      </p>
      <div className={classNamees.switchContainer}>
        <Grid container className={classNamees.gridContainer}>
          <Grid item xs={12} sm={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch onClick={(e) => handleChecked(e, "Development")} />
                }
                label={
                  <Typography variant="headline" className={classNamees.label}>
                    {" "}
                    Development{" "}
                  </Typography>
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Switch onClick={(e) => handleChecked(e, "Design")} />}
              label={
                <Typography variant="headline" className={classNamees.label}>
                  {" "}
                  Design{" "}
                </Typography>
              }
            />
          </Grid>
        </Grid>
        <Grid container className={classNamees.gridContainer}>
          <Grid item xs={12} sm={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch onClick={(e) => handleChecked(e, "Testing & QA")} />
                }
                label={
                  <Typography variant="headline" className={classNamees.label}>
                    {" "}
                    Testing & QA{" "}
                  </Typography>
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Switch onClick={(e) => handleChecked(e, "Consulting")} />
              }
              label={
                <Typography variant="headline" className={classNamees.label}>
                  {" "}
                  Consulting{" "}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    paramData: state.paramData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setService: function (paramData) {
      dispatch(setService(paramData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
