/* eslint-disable default-case */
import { DRAWER_CLOSE } from "./drawerTypes";

const initialState = {};

export const drawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case DRAWER_CLOSE:
      return {
        ...state,
        drawer: action.payload,
      };

    default:
      return {};
  }
};
