import React from "react";
import { Grid, Container, makeStyles, Typography } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  title: {
    display:"grid",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "larger",
    marginTop: "20px",
    padding:"5rem",
    "&:hover": {
      color: "#3D8ED7",
    },
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "1rem",
    fontSize: "xx-large",
  },
  mainWrapper: {
    padding: "0rem 5rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem",
    },
  },
  paraWrapper: {
    padding: "1rem 3rem",
    textAlign: "left",
    fontFamily: '"Manrope", sans-serif',
    fontSize: "17px",
    textAlign:"justify",
    wordSpacing: "-0.1em",
     hyphens: "auto",

    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
      fontSize:"16px",
    },
  },
  titleWrapper: {
    padding: "0rem",
    fontFamily: '"Manrope", sans-serif',

    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
      paddingTop:"0rem",
      fontSize:"30px",
    },
  },
  flexbox: {
    display: 'flex',
    padding: "2rem 1rem 1rem",
    justifyContent: "center",
    alignItems: "center",
    gap: "25",
    [theme.breakpoints.down("sm")]: {
      padding: "2rem 0rem",
    },
  },
  TechWrapper: {
    padding: "2rem 0rem",
    
    [theme.breakpoints.down("sm")]: {
      padding: "1rem",
    },
  },
  expertiseDesc: {
    borderRadius: "20px",
    color: "black",
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
      margin: '0px, 1rem',
    },
  },
}));

const TechnologiesExperties = ({ TechnologiesExperties, title, para }) => {
  const classNamees = useStyles();
  return (
    <>
      <Container maxWidth={false} className={classNamees.mainWrapper}>
        <Container maxWidth="lg">

          <div>
          <Typography variant="h4" className={classNamees.titleWrapper} style={{textAlign:"center",paddingTop:"2rem"}}>
                {title}
              </Typography>

              <Typography className={classNamees.paraWrapper}>{para}</Typography>
          </div>


          <Grid container className={classNamees.flexbox}>
            {TechnologiesExperties?.map((value) => (
              <>
                <Grid
                  item
                  className={classNamees.Container}
                  
                >
                  <Container className="main-wrapper" maxWidth="lg">
                    <div className="serviceBox">
                      <div className="icon-wrapper">{value.icon}</div>
                      <div className="content">
                        <Typography
                          variant="h6"
                          style={{fontFamily:'"Manrope",sans-serif'}}
                        >
                          {value.title}
                        </Typography>
                      </div>
                    </div>
                  </Container>
                </Grid>
              </>
            ))}
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default TechnologiesExperties;
