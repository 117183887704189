/* eslint-disable default-case */
import { SET_DURATION } from "./durationType";
import { GET_DURATION } from "./durationType";

const initialState = {
  loading: true,
  error: "",
  data: [],
};

export const durationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DURATION:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_DURATION:
      return {
        ...state,
        loading: false,
        error: "",
      };

    default:
      return {};
  }
};
