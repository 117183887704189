import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import GroupsIcon from "@mui/icons-material/Groups";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { setTeam } from "../../../redux";
import { connect } from "react-redux";

import axios from "axios";

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: "1rem 0px",
    fontSize: "32px",
    lineHeight: "44px",
  },
  subHeading: {
    fontSize: "18px",
    lineHeight: "28px",
  },
  gridContainer: {
    margin: "3rem 0px 5rem !important",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  gridItem: {
    flex: 1,
    width: "45%",
    cursor: "pointer",
    margin: "0 1rem",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0.5rem 0px",
    },
  },
  card: {
    border: "2px solid rgb(43,162, 222)",
    minHeight: '100%',
  },
  cardBorder: {
    border: "2px solid transparent",
    minHeight: '100%',
    "&:hover": {
      border: "2px solid rgb(43,162, 222)",
    },
  },
  gridHeading: {
    display: "flex",
    alignItems: "center",
    margin: "0.5rem 0px",
  },
  cardContent: {
    padding: "0px 1rem",
    textAlign:"justify",

  },
  cardHeading: {
    fontSize: "18px !important",
    fontWeight: "bold",
    color: "black",
  },
  icon: {
    color: "rgb(43,162, 222)",
    marginRight: "1rem",
    fontSize: "30px !important",
  },
}));

const teamData = {
  title: "Team Calculator",
  text: "You need a team of professional developers with unique expertise to help you to build your projects. You need to fill in a few key points in an existing team with developers you can believe.",
};

const projectData = {
  title: "Project Calculator",
  text: "If you have any unique idea, then you need a team of skilled professionals to turn it into reality. We will manage the whole SDLC for you, including a project manager and a team.",
};

const ChooseModal = (props) => {
  const classNamees = useStyles();
  const [paramData, setParamData] = useState();

  useEffect(() => {
    setParamData(teamData);
    if (teamData) {
      // axios
      const id = Math.floor(Math.random() * 100000 + 1);
      axios
        .post("http://localhost:8000/Model", {
          id: id,
          teamData,
        })
        .then((response) => console.log("My response", response))
        .catch((error) => {
          console.log("error occur while posting data", error);
        });
      props.setTeam(teamData);
    }
  }, [teamData]);

  const handleCheck = () => {
    const id = Math.floor(Math.random() * 100000 + 1);
    axios
      .post("http://localhost:5000/Model", {
        id: id,
        projectData,
      })
      .then((response) => console.log("My response", response))
      .catch((error) => {
        console.log("error occur while posting data", error);
      });
  };

  return (
    <Container maxWidth="lg">
      <h1 className={classNamees.heading}>01. Choose your Collaboration Modal</h1>
      <p className={classNamees.subHeading}>
        Choose between a project-based pricing model or hiring a dedicated team
        for your project.
      </p>

      <div className={classNamees.gridContainer}>
        <div className={classNamees.gridItem}>
          <Card
            className={
              props.activeComp === "team" ? classNamees.card : classNamees.cardBorder
            }
            onClick={() => {
              props.setActiveComp("team");
              // getTeamsData(teamData);
              setParamData(teamData);
              props.setTeam(paramData);
            }}
          >
            <CardContent className={classNamees.cardContent}>
              <div className={classNamees.gridHeading}>
                <GroupsIcon className={classNamees.icon} />
                <Typography className={classNamees.cardHeading}>
                  {teamData && teamData.title}
                </Typography>
              </div>
              <Typography variant="body2" color="black">
                {teamData && teamData.text}
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div className={classNamees.gridItem}>
          <Card
            className={
              props.activeComp === "project" ? classNamees.card : classNamees.cardBorder
            }
            onClick={() => {
              props.setActiveComp("project");
              // getProjectsData(projectData);
              setParamData(projectData);
              props.setTeam(projectData);
              handleCheck();
            }}
          >
            <CardContent className={classNamees.cardContent}>
              <div className={classNamees.gridHeading}>
                <BusinessCenterIcon className={classNamees.icon} />
                <Typography className={classNamees.cardHeading}>
                  {projectData && projectData.title}
                </Typography>
              </div>
              <Typography variant="body2" color="black">
                {projectData && projectData.text}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    paramData: state.paramData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTeam: function (paramData) {
      dispatch(setTeam(paramData));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseModal);
