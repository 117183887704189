import { ComapnayBanner } from "../images/images";
import { CmmiImage } from "../images/images";
import { Union } from "../images/images";
import { LogoImage } from "../images/images";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import SearchIcon from "@material-ui/icons/Search";
import StarIcon from "@material-ui/icons/Star";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import GroupIcon from "@material-ui/icons/Group";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import LanguageIcon from "@material-ui/icons/Language";

const CompanyData = [
  {
    name: "company",
    HeroBanner: [
      {
        name: "company",
        Page: "Company",
        title: "Reliable Innovative Development & Creative Solutions.",
        para: "We do great things. Deliver outstanding content solutions and technology-ready transformation. We prosper with the impact and value we can provide.",
        img: ComapnayBanner,
        image1: CmmiImage,
        image2: Union,
        image3: LogoImage,
      },
    ],

    skill: [
      {
        PerformanceTitle: "Quality Centered, Customer Obsessed.",

        PerformancePara:
          "With decades of experience in Fully Managing various IT firms. We provide companies with the technical support, expertise, and products and services they need to be productive and proactive - helping them work smarter, more securely, and with greater efficiency.",
        PerformanceList: [
          {
            Numbers: "83%",
            PerformanceListTitle: "Customer Retention",
            PerformanceListPara:
              "Most of the client are extremely delighted after improving their current software and launch new projects with Senwell",
          },
          {
            Numbers: "84%",
            PerformanceListTitle: "Employee Retention",
            PerformanceListPara:
              "Our developers and QA engineers have worked with Senwell for an average of over 6 years",
          },
          {
            Numbers: "93%",
            PerformanceListTitle: "Professional Staff",
            PerformanceListPara:
              "We only hire Junior engineers as there will always be a senior-level mentor available for 1-on-1 mentorship.",
          },
        ],
      },
    ],
    GetInTouch: [
      {
        GetInTouchTitle:
          "Do you care about the quality? Run a test project with Senwell to see if we’re the right fit for you!",
        Button: "Get In Touch",
      },
    ],
    whysenwell: [
      {
        icon: (
          <FlashOnIcon
            style={{
              color: "#3D8ED7",
              paddingBottom: "1rem",
              fontSize: "2.5rem",
              // backgroundColor:"#000"
              // background:"rgba(31, 59, 95, 0.404)",
              // position: "absolute",
              // top: 0,
              // left: 0,
            // zIndex:99999,
              
            }}
          />
        ),
        title: "Start fast. Finish with confidence.",
        para: "We want you to find a team rapidly and get straight to turning your vision into a reality.",
      },
      {
        icon: (
          <LocationOnIcon
            style={{
              color: "#3D8ED7",
              paddingBottom: "1rem",
              fontSize: "2.5rem",
            }}
          />
        ),
        title: "Cross-domain expertise",
        para: "There’s no challenge we can’t handle with confidence and great courage.",
      },
      {
        icon: (
          <StarIcon
            style={{
              color: "#3D8ED7",
              paddingBottom: "1rem",
              fontSize: "2.5rem",
            }}
          />
        ),
        title: "Excellent Code Quality",
        para: "Beyond QA, we ensure your code is immaculate  and free of technical debt.",
      },

      {
        icon: (
          <SearchIcon
            style={{
              color: "#3D8ED7",
              paddingBottom: "1rem",
              fontSize: "2.5rem",
            }}
          />
        ),
        title: "100% Transparency",
        para: "Explicit and Consistent communication, flexible pricing, and full dedication to your success.",
      },
      
    ],
    Forms: [
      {
        formTitle: "Get a quote today!",
        formSubtitle: "Enter your project details.",
      },
    ],
  },
];
export { CompanyData };

const HeroBannerSubsection = [
  {
    icon: <GroupIcon style={{ fontSize: "2.5rem", color: "#3D8ED7" }} />,
    title: "Time and Material",
    subtitle:
      "Best suited for complex, long-term projects with unclear requirements.",
    para: "T&M’s superb flexibility enables you to react to market changes with immediate requirement modifications at any point of development. You get a functioning version of your solution with each iteration and can stop the contract at any time you’re satisfied with the outcome.",
    list1: "Changeable workflow and predictable budgeting",
    list2: "High flexibility and change control at low risk",
    list3: "Available at any stage of software development life cycle",
    list4: "High control over requirements and scope",
  },
  {
    icon: <WatchLaterIcon style={{ fontSize: "2.5rem", color: "#3D8ED7" }} />,
    title: "Dedicated Team",
    subtitle:
      "Best suited for mid-size or enterprise projects and business expansion.",
    para: "You get a full team of professionals (including project managers, designers, developers, business analysts, QA engineers) that you can easily scale as your project develops. The size of your team, development methodology, scope, and deadlines are under your full control.",
    list1: "Predictable budgeting and easy costs tracking",
    list2: "Full team commitment and accountability",
    list3: "Flexible level of control over the project",
    list4: "Fast resource scaling and replacement",
  },
  {
    icon: <LanguageIcon style={{ fontSize: "2.5rem", color: "#3D8ED7" }} />,
    title: "Staff Augmentation",
    subtitle:
      "Works miracles for development projects of any complexity and scale.",
    para: "Don’t waste months on scouting, recruiting, and onboarding! It’s a perfect option for projects with in-house teams that need specific roles to be filled by experts with relevant experience and skills. You provide the requirements and get a perfect candidate for the position.",
    list1: "Instant access to talent with fast onboarding",
    list2: "Full control over the team from start to finish",
    list3: "Predictable cost with no in-house labor overheads",
    list4: "No infrastructure or administrative investment",
  },
];
export { HeroBannerSubsection };
