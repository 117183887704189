import image1 from "../images/DigitalMarketing/Digital-1.webp";
import image2 from "../images/DigitalMarketing/Digital-2.webp";
import image3 from "../images/DigitalMarketing/Digital-3.webp";
import image4 from "../images/DigitalMarketing/Digital-4.webp";


export const dataDigitalBestSeller = [
    {
      id: 1,
      linkImg:image1
    },
    {
      id: 2,
      linkImg:image2   
    },
    {
      id: 3,
      linkImg:image3 
    },
    {
      id: 4,
      linkImg:image4  
    },
   
  ];