import React from 'react';
import "./TestimonialNew.css";
import Slider from "react-slick";


const TestimonialNew = () => { 
    return (
        <>
            <section style={{padding:"50px 0px", backgroundColor:"#f9fafb"}}>
                <div className="container" style={{left:"0",border:"none" }}>
                    <h1 className="text-center clientSay-Header">What Our Clients Say</h1>
                    <div className='row' style={{rowGap:"40px",marginBottom:"30px",}}>
                        <div className='col-12 col-lg-6'>
                            <div className='testimonial-box'>
                                <p className="slider-p"><strong>TOP NOTCH</strong></p>
                                <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.2"><path d="M0 19.2589C0 7.92167 9.45455 1.69587 14.1818 0.00012207C8.36364 4.6513 8.12121 9.93233 8.72727 11.9915C14.9091 10.9013 18.9091 14.5351 18.9091 20.7124C18.9091 25.945 16 28.5451 13.0909 29.4334C8.72727 30.7658 0 30.5962 0 19.2589Z" fill="#2490EF"></path><path d="M21.0898 19.2589C21.0898 7.92167 30.5444 1.69587 35.2717 0.00012207C29.4535 4.6513 29.2111 9.93233 29.8171 11.9915C35.9989 10.9013 39.9989 14.5351 39.9989 20.7124C39.9989 25.945 37.0898 28.5451 34.1808 29.4334C29.8171 30.7658 21.0898 30.5962 21.0898 19.2589Z" fill="#2490EF"></path></g></svg>
                                <p className='description-para'>Through Senwell Group Private Limited, I've participated in multiple outsourcing projects for North American clients, with their performance consistently exceeding that of in-house teams. Their remarkable productivity sets the company apart, among other advantages.</p>
                                <h4 className='c-name'>Derick</h4>
                                <p className='c-project'>Hatchster Ltd. UK</p>
                            </div>
                        </div>

                        <div className='col-12 col-lg-6'>
                            <div className='testimonial-box'>
                            <p className="slider-p"><strong>INCREDIBLE</strong></p>
                                <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.2"><path d="M0 19.2589C0 7.92167 9.45455 1.69587 14.1818 0.00012207C8.36364 4.6513 8.12121 9.93233 8.72727 11.9915C14.9091 10.9013 18.9091 14.5351 18.9091 20.7124C18.9091 25.945 16 28.5451 13.0909 29.4334C8.72727 30.7658 0 30.5962 0 19.2589Z" fill="#2490EF"></path><path d="M21.0898 19.2589C21.0898 7.92167 30.5444 1.69587 35.2717 0.00012207C29.4535 4.6513 29.2111 9.93233 29.8171 11.9915C35.9989 10.9013 39.9989 14.5351 39.9989 20.7124C39.9989 25.945 37.0898 28.5451 34.1808 29.4334C29.8171 30.7658 21.0898 30.5962 21.0898 19.2589Z" fill="#2490EF"></path></g></svg>
                                <p className='description-para'>I've engaged in numerous outsourcing projects for North American clients through Senwell Group Private Limited. Their performance consistently surpasses that of clients' in-house teams, exemplifying remarkable productivity. This advantage, among others, sets the company apart significantly.</p>
                                <h4 className='c-name'>Kevin Atom</h4>
                                <p className='c-project'>Property Hatch Ltd, United States</p>
                            </div>
                        </div>

                        <div className='col-12 col-lg-6'>
                            <div className='testimonial-box'>
                            <p className="slider-p"><strong>PHENOMENAL</strong></p>
                                <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.2"><path d="M0 19.2589C0 7.92167 9.45455 1.69587 14.1818 0.00012207C8.36364 4.6513 8.12121 9.93233 8.72727 11.9915C14.9091 10.9013 18.9091 14.5351 18.9091 20.7124C18.9091 25.945 16 28.5451 13.0909 29.4334C8.72727 30.7658 0 30.5962 0 19.2589Z" fill="#2490EF"></path><path d="M21.0898 19.2589C21.0898 7.92167 30.5444 1.69587 35.2717 0.00012207C29.4535 4.6513 29.2111 9.93233 29.8171 11.9915C35.9989 10.9013 39.9989 14.5351 39.9989 20.7124C39.9989 25.945 37.0898 28.5451 34.1808 29.4334C29.8171 30.7658 21.0898 30.5962 21.0898 19.2589Z" fill="#2490EF"></path></g></svg>
                                <p className='description-para'>We are highly pleased with Senwell Group Private Limited' performance as our contractor. Their team is extremely responsive, possesses a deep grasp of the subject matter, and consistently delivers code at an impressively rapid pace. This swiftness in code delivery holds particular significance for our travel-oriented business.</p>
                                <h4 className='c-name'>Nicolo Anderson</h4>
                                <p className='c-project'>England</p>
                            </div>
                        </div>

                        <div className='col-12 col-lg-6'>
                            <div className='testimonial-box'>
                            <p className="slider-p"><strong>EXTRAORDINARY SERVICE</strong></p>
                                <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity="0.2"><path d="M0 19.2589C0 7.92167 9.45455 1.69587 14.1818 0.00012207C8.36364 4.6513 8.12121 9.93233 8.72727 11.9915C14.9091 10.9013 18.9091 14.5351 18.9091 20.7124C18.9091 25.945 16 28.5451 13.0909 29.4334C8.72727 30.7658 0 30.5962 0 19.2589Z" fill="#2490EF"></path><path d="M21.0898 19.2589C21.0898 7.92167 30.5444 1.69587 35.2717 0.00012207C29.4535 4.6513 29.2111 9.93233 29.8171 11.9915C35.9989 10.9013 39.9989 14.5351 39.9989 20.7124C39.9989 25.945 37.0898 28.5451 34.1808 29.4334C29.8171 30.7658 21.0898 30.5962 21.0898 19.2589Z" fill="#2490EF"></path></g></svg>
                                <p className='description-para'>Working with you has been a terrific experience. Yes, your customer assistance is excellent, and your SEO and SMO services are really effective. I'm confident that I won't ever collaborate with anyone else on my digital marketing campaign.</p>
                                <h4 className='c-name'>Daniel Peters</h4>
                                <p className='c-project'>Colombia</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TestimonialNew
