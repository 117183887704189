import "../App.css";
import Consulting from "../images/Industries/Consulting.jpg";
import ProductEngg from "../images/Industries/ProductEngg.jpeg";
import tech from "../images/Industries/tech.jpg";
import bussprocess from "../images/Industries/bussprocess.jpg";
import smartmanu from "../images/Industries/smartmanu.jpg";
import autosolimg from "../images/Industries/autosolimg.jpg";
import drivesafely from "../images/Industries/drivesafely.jpg";
import Digitalmarketing from "../images/Industries/Digitalmarketing.jpg";
import dealermanag from "../images/Industries/dealermanag.jpg";
import autocomm from "../images/Industries/autocomm.jpg";
import automotiveinnovation from "../images/Industries/automotiveinnovation.jpeg";
import Gnetwork from "../images/Industries/Gnetwork.jpg";
import Consultingimg from "../images/Industries/Consultingimg.jpg";
import Digitalenterprice from "../images/Industries/Digitalenterprice.jpg";
import Digitalnetwork from "../images/Industries/Digitalnetwork.jpg";
import Digitalplatform from "../images/Industries/Digitalplatform.jpg";
import Digitaloperations from "../images/Industries/Digitaloperations.jpg";
import DigitalBussines from "../images/Industries/DigitalBussines.jpg";
import EducationDigital from "../images/Industries/EducationDigital.jpg";
import EducationModearn from "../images/Industries/EducationModearn.jpg";
import cyber from "../images/Industries/cyber.jpg";
import Educationstudent from "../images/Industries/Educationstudent.jpg";
import colaraz from "../images/Industries/colaraz.jpg";
import MetalsSteel from "../images/Industries/MetalsSteel.jpg";
import chemicals from "../images/Industries/chemicals.jpg";
import aftermarketing from "../images/Industries/aftermarketing.jpg";
import remanufacturing from "../images/Industries/remanufacturing.jpg";
import productengineering from "../images/Industries/productengineering.jpg";
import airline from "../images/Industries/airline.jpg";
import croamis from "../images/Industries/croamis.png";
import CruiseRentals from "../images/Industries/CruiseRentals.jpg";
import remote from "../images/Industries/remote.jpg";
import gameplatfrom from "../images/Industries/gameplatfrom.jpg";
import Hotels from "../images/Industries/Hotels.jpg";
import automation from "../images/Industries/automation.jpg";
import creationanimation from "../images/Industries/creationanimation.jpg";
import rebouting from "../images/Industries/rebouting.png";
import productlocalization from "../images/Industries/productlocalization.jpg";
import Restaurants from "../images/Industries/Restaurants.jpg";
import Airlines from "../images/Industries/Airlines.jpeg";
const IndustriesData = [
  {
    id: 1,
    name: "aerospace-and-defense",

    HeroBanner: [
      {
        name: "aerospace-and-defense",
        Page: "Aerospace and Defense",
        breadcrums: "Industries",
        Link: "/industries",
        title: "Aerospace and Defense",
        button: "Let's talk",
      },
    ],
    ourExpertise: [
      {
        title:
          "Realize the benefits of transformational digital technology at all levels, from customers to operations and offers.",
        para: "To meet the issues of diminishing budgets, growing operating costs, infrastructure management, MRO (Maintenance, Repair, and Overhaul), and a complicated global supply chain, the aerospace and defense sector is fast embracing innovative technology. Senwell Group Private Limited is at the forefront, assisting aerospace and military firms in overcoming these obstacles and carefully navigating them through this period of rapid digital change.",
      },
    ],

    WhatWeDo: [
      {
        services: [
          {
            img: Consulting,
            title: "Consulting",
            para: "Catalyze and Optimize",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "With top-notch consulting services, you may evaluate technical possibilities, implement tactical initiatives, and increase profitability.",
            explorepara:
              "Senwell provides consulting services to Aerospace and Defense companies to help them improve Supply Chain Management, Process Design, Collaboration, Technological Innovation, and Resource Optimization. Our experienced consulting team is backed by a worldwide operating model and provides technologically sophisticated system integration solutions, as well as goods and services that cover the whole life cycle.",
          },
          {
            img: ProductEngg,
            title: "Product Engineering Services ",
            para: " Launch next generation products and systems",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Fulfill safety requirements, reduce product lifespans, and increase income.",
            explorepara:
              "Senwell Group Private Limited offers consulting services to Aerospace and Defense clients to help them improve Supply Chain Management, Process Design, Collaboration, Technological Innovation, and Resource Optimization. Our experienced consulting team is backed by a worldwide operating model and provides technologically sophisticated system integration solutions, as well as goods and services that cover the whole life cycle.",
          },
          {
            img: tech,
            title: "Technical Publications ",
            para: " Word-perfect documentation",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Adapt legacy publications rapidly to the latest standards required by the industry",
            explorepara:
              "Even though our closed source Evidence Process Model does provide a change blueprint, mental wellbeing, and talent transformation capabilities, clients in the Aerospace and Defense industry prefer to engage Senwell Group Private Limited for documentation projects, technical illustrations and authoring, data validation and conversion, and learning solutions production. Industry-wide documentation standards such as ATA 100/104 Specs, ATA iSpec 2200 Specs, ASD STE, MIL Standards, ANSI Benchmarks, and S1000D are all supported by our Technical Publication solution. Clients that use our service may swiftly update existing products to meet industry requirements. ",
          },
          {
            img: bussprocess,
            title: "Business Process Services ",
            para: " Give flight to operational efficiency",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Using next-generation tools and solutions to standardize, simplify, and optimize operations, you may achieve dramatic change.",
            explorepara:
              "Senwell offers customized and industry-specific Business Process Services (BPS) to aerospace clients in the areas of the MRO value chain, CRM, consultancy services, and back-office merchant services. Clients that use our service save up to 30% on the total cost of ownership and enhance end-customer satisfaction by up to 20%.",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },
  {
    id: 2,
    name: "automotive",

    HeroBanner: [
      {
        name: "automotive",
        Page: "Automotive",
        breadcrums: "Industries",
        Link: "/industries",
        title: "Automotive",
        button: "Let's talk",
      },
    ],
    ourExpertise: [
      {
        title:
          "Utilize cutting-edge technology to fulfill client demands for comfort, convenience, performance, and greater safety.",
        para: "Senwell Group Private Limited collaborate with clients to ensure that they focus on innovation and remain at the forefront of this trend as digital transformation and connectivity impact many elements of the Automotive Sector. We cooperate with vehicle manufacturers, original equipment manufacturers, and the extended supply chain to provide automobiles that are healthier and more enjoyable to drive by combining traditional solutions with the promise of Industrial Revolution 4.0 technology.",
      },
    ],

    WhatWeDo: [
      {
        services: [
          {
            img: drivesafely,
            title: "Connected Cars",
            para: "Flashy? Useful? Futuristic? It’s all here, in one plac",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Everything a linked automobile need, from security to sophisticated analytics, in-vehicle navigation, and diagnostics, is available from a single trusted source.",
            explorepara:
              "Car manufacturers that want to take use of the connection, cloud, IoT, and data/analytics collaborate with Senwell Group Private Limited to create and deliver driverless vehicles that are safe, efficient, and simple to operate. Clients benefit from our technical competence, deep domain understanding in in-vehicle infotainment and vehicle diagnostics, cloud-based platforms, and expertise in interconnected car services and security.  ",
          },
          {
            img: Digitalmarketing,
            title: "Digital Marketing",
            para: "Digitizing Customer's Journey",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Automotive OEMs may benefit from comprehensive digital solutions across the customer lifetime.",
            explorepara:
              "Senwell Group Private Limited demonstrated experience in digitising each stage in the automotive customer's journey is used by automotive clients preparing for the digital retailing revolution. Through insights-driven brand management, digital lead share, dealer omnipresence, in-store digitization, digital field force enablement, next-generation auto retail format creation, post-sales customer interaction strategic planning, and Modern CRM, our solutions generate premium perceptions.",
          },
          {
            img: dealermanag,
            title: "Dealer Management",
            para: "Road-tested Dealer Ecosystem Management",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Complete insight into day-to-day operations including inventory simplifies dealer management.",
            explorepara:
              "The Dealer Management System (DMS) is a centralized program that collects information about customers, vehicles, and inventories. Pre-sales, vehicle sales, parts and supplies, service, CRM, financial accounting basics, and workflow automation with alerts are all covered by the DMS. Manufacturers that use the system have access to a streamlined process for onboarding and certifying franchisees, enhanced inventory, consumer conversions, and retention, as well as a remote support desk.",
          },
          {
            img: autocomm,
            title: "AutoComm ",
            para: "Digital model for downstream markets",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Increase B2B and B2C aftermarket income with an e-commerce solution.",
            explorepara:
              "Senwell Group Private Limited collaborate with a growing number of automotive companies, OEMs, and dealers to meet the demands of their B2B and B2C clients who wish to shop across several channels. We offer clients a configurable solution stacks that tackles aftermarket products and services, based on our existing e-commerce experience. The system allows for data comes, supply chain management, improved customer service, and faster delivery times.",
          },
          {
            img: smartmanu,
            title: "Smart Manufacturing",
            para: "Breakthrough, game-changing, disruptive",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Integrate plant and enterprise in a real-time, data-driven environment to reap the benefits of Smart Manufacturing.",
            explorepara:
              " Senwell Group Private Limited has made it a priority to integrate intelligence into the activities of our industrial clients. To develop Smart Manufacturing environments, we concentrate on the primary parts of Industry 4.0—digital, automation, linked and genuine data analytics. Our topic and technological expertise use existing investments to coordinate OT and IT as a seamless whole that propels businesses towards the advent of assembly.",
          },
          {
            img: automotiveinnovation,
            title: "Automotive Innovation Center",
            para: "Collaboration powering innovation",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "The confluence of automotive and technology is more necessary than ever before in order to establish a new ecosystem that will drive the new rules of profitability for automobile manufacturers.",
            explorepara:
              "In order to deliver a compelling customer experience with the proper technology in place, competing in the auto business is transitioning from being the best vehicle maker to creating the right set of partners. The Automotive Innovation Center is a collaborative area where employees can experiment with and co-create captivating experiences for end customers while also reaching corporate objectives more quickly and efficiently. The technological center in Detroit facilitates communication with prominent automotive forums and partners while also providing hands-on experience. Senwell Group Private Limited is at the forefront of technological innovation, thanks to our community of partners. ",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },
  {
    id: 3,
    name: "communications",

    HeroBanner: [
      {
        name: "communications",
        Page: "communications",
        breadcrums: "Industries",
        Link: "/industries",
        title: "Communications",
        button: "Let's talk",
      },
    ],
    ourExpertise: [
      {
        title:
          "Increase business value and stay relevant to customers using innovation and disruptive technologies in the telecom industry",
        para: "We understand that you're certainly balancing a lot of responsibilities, limited funds and employees, and the need to deliver. We understand that you can't recruit the best individual for every project internally, and therefore that coordinating many agencies takes time. That's why we've put together a special group of professionals who can act as an extension of your team, delivering exactly whatever you need, when you need it. Our staff is professionals in our Connected Content strategy, regardless of the project or service area you want assistance with. To provide great outcomes that generate the outcomes you need, we combine the art of captivating communications with the science of performance marketing. ",
      },
    ],

    WhatWeDo: [
      {
        services: [
          {
            img: Gnetwork,
            title: "5G Commercialization",
            para: "Take your operations and business to the next level with 5G",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "5G represents an entirely new generation of telecommunications technology ",
            explorepara:
              "5G is a whole digital communications technology generation.is a quantum leap forward from prior mobile technology. With its outstanding speeds, reduced latency, and enhanced dependability, 5G is expected to outperform 3G and 4G by a wide margin, allowing a wide range of services. For the first time in cellular technology development, 5G focuses on the networking demands of a significantly bigger number of wireless modems rather than client phones",
          },
          {
            img: Digitalnetwork,
            title: "Digital Consumer",
            para: "Unlock the next level of digital customer experience",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "To make the best impression, make the proper connections",
            explorepara:
              "Digital Customer Experience products use the most up-to-date digital interaction technologies to satisfy the Communications firm's specialized demands. These solutions combine a rich, tailored, and engaging user experience across numerous channels with a simple interface and are accessible always, everywhere, and on any device.",
          },
          {
            img: DigitalBussines,
            title: "Digital Business",
            para: "Be the change",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Transform from communication service providers to digital service providers.",
            explorepara:
              "Communication Service Providers (CSPs) may use digital business solutions to focus on emerging technologies and develop services that offer new revenue possibilities, increase company agility, and shorten time to market in a B2B setting. Digital Business offerings assist CSPs in developing powerful functionalities in the digital domains they pursue, such as SD-WAN, SDN/NFV, digitizing customer journeys, technology venture product design, rationalizing and standardizing overall operations, ensuring quality and reliability, and developing platforms to support customers ’ service planning with actionable insights and automation.",
          },
          {
            img: Consultingimg,
            title: "Consulting",
            para: "Dial up for the coming decade",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Lead the communication services industry into the next decade by completely transforming product and service portfolios, infrastructure, and applications.",
            explorepara:
              "Senwell Group Private Limited  consulting experience is being used by telecom operators that are anticipating tremendous growth in the next ten years as a result of increased connectivity, the proliferation of devices, and the availability of consumer data. Developing customer experience and communication; digital infrastructure (SDN/NFV, Telco Cloud, Virtual workspace), automation, and deployment; lean frameworks for optimization (cost optimization, improving efficiency and process efficiency, and so on); virtualization of non-core operations; leveraging existing assets to offer new services; and deploying new technology to build competitive capabilities are all part of our telecom advisory services.",
          },
          {
            img: Digitalenterprice,
            title: "Digital Enterprise",
            para: "Be always-on, always-available",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Transform to stay relevant and grow in a digital and connected world",
            explorepara:
              "Senwell Group Private Limited Digital Enterprise service helps CSPs enhance customer satisfaction and time-to-market by using agile and DevOps processes, digital architecture, and concept patterns such as crowdsourcing, as well as adopting innovative practices and the latest technology",
          },
          {
            img: Digitalnetwork,
            title: "Digital Networks",
            para: "Connect to digital business transformation",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Power your networks with innovation to achieve agility and efficiency",
            explorepara:
              "To achieve agility and efficiency, fuel your networks with innovation. Senwell Group Private Limited services under the 'Digital Networks' solution category help CSPs stay relevant in an ever-changing industrial context. While 'Network Managed Services' caters to operators' 'Run' requirements, 'Network Transformation Services' caters to their 'Change' requirements. Network Managed Services save 25% to 30% on network operating expenses (demonstrated through case studies). Senwell Group Private Limited solve contemporary technical complexity in the convergent IT and telecom networks arena by leveraging a network of Centers of Excellence and resources with cross-domain capabilities scattered across continents.",
          },
          {
            img: Digitalplatform,
            title: "Digital Platforms",
            para: "Power your digital business",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Pre-built digital platform with ready-to-rollout methodology and use cases that can be delivered as a service.",
            explorepara:
              " Senwell Group Private Limited Digital Platform provides service providers with customer administration, revenue management, policy control, and billing, enterprise product catalog, network parameters, and analytics-based viable technique utilizing best-of-breed industry solutions. Platform services are available through open APIs that comply with industry standards, making it simple to integrate front channels, which the company has allowed, and third-party apps. The platform's ready-to-use pre-built processes (eTOM level 4 and higher) and industry-specific use cases significantly decrease implementation time and effort. Platform ecosystems are available as a remote server or as a standalone implementation.",
          },
          {
            img: Digitaloperations,
            title: "Agile Operations",
            para: "Accelerate your business",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Leverage automation-as-a-service, Lean telco, shared services, analytics, and other advanced technologies for exponential improvement in efficiency and accuracy",
            explorepara:
              "For exponential improvements in efficiency and accuracy, use automation-as-a-service, Fast Telecom, shared services, analytics, and other sophisticated technologies. The introduction of Cloud, SDN, NFV, 4G, 5G, IoT, or other video content adds to the complexity, necessitating a move from traditional operations to more flexible, accessible, and authentic dependent digital operations.",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },
  {
    id: 4,
    name: "education",

    HeroBanner: [
      {
        name: "education",
        Page: "education",
        breadcrums: "Industries",
        Link: "/industries",
        title: "Education",
        button: "Let's talk",
      },
    ],
    ourExpertise: [
      {
        title: "Expertise in Education",
        para: "The educational landscape is changing dramatically. Educational establishments must negotiate changes throughout their operations and service delivery from preschool to high school, universities to technical training. Hybrid and flexible learning models change the way students learn as well as how instructors support new delivery methods. Without even a doubt, there has never been a bigger need for fast digital change in education. Industry executives are faced with the task of increasing service efficiency and student success without sacrificing the whole experience. Technology may assist in driving these obligations and ensuring that institutions provide value in education, research, and engagement in the future. However, realizing this vision will need a partner with the knowledge and competence to expedite digital transformation in education, allowing all parties to achieve their goals.",
      },
    ],

    WhatWeDo: [
      {
        services: [
          {
            img: EducationDigital,
            title: "Digital transformation with crowdsourcing",
            para: "Topcoders pioneering crowdsourcing methodology",
            exploretitle: "Our Expertise",
            exploresubtitle: "PUBLIC SECTOR & GOVERNMENT",
            explorepara:
              "Across the student lifecycle, from prospect to alumnus, process and domain expertise are required to transform the education value chain. It also necessitates a broad partner ecosystem that allows for greater flexibility in implementing best-in-className platforms for any circumstance or necessity. Senwell Group Private Limited combines in-house domain and technical knowledge with a strong partner network to make digital transformation a reality in education and operations.",
          },
          {
            img: Educationstudent,
            title: "Student Experience",
            para: "The Appirio Higher Ed Student Experience",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Today's learners have higher expectations of technology. They demand a unique experience that is suited to their specific requirements. ",
            explorepara:
              "Senwell Group Private Limited honors Student Community Lightning Bolt gives you the resources you need to provide amazing learning environments. This society framework has a pre-built UI with a modern look and feels that can be scaled to fit the needs of your whole student population.",
          },
          {
            img: colaraz,
            title: "Cloud learning ",
            para: "Educator and employer collaboration platform",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Senwell Group Private Limited unifies everything under a single, secure cloud-based platform that provides main decisions with a wide range of options and a media platform interface for all users. To address the difficulties encountered by all main stakeholders, our entire solution set focuses on the professional journey across the continuum of career and lifetime learning.",
            explorepara:
              "To address the difficulties encountered by all main stakeholders, our entire solution set focuses on the professional journey across the continuum of career and lifetime learning.",
          },
          {
            img: cyber,
            title: "Cyber risk services for higher ED",
            para: "From Cyber Security to Cyber Resilience for Higher Education",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Institutions now operate in a widely dispersed technological environment, which exposes them to ongoing cybersecurity concerns such as phishing/malware assaults, insecure devices, and a general lack of security awareness among staff. ",
            explorepara:
              "Hackers are using the increased attention on the COVID-19 outbreak to spread malware, steal credentials, and target students in order to steal funds, personal information, and more.",
          },
          {
            img: EducationModearn,
            title: "Modern Campus",
            para: "Digital Navigator Assessment (DNA) for Modern Campus",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "The Modern Campus is a consultative assessment approach that will assist Universities in their digital transformation journey by identifying the best and most optimum path to digital value – all while using Oracle's best-of-breed capabilities. ",
            explorepara:
              "This will assist you in establishing a contemporary platform to combine numerous dispersed apps, monoliths, ERPs, data, and services, as well as offer content suited to the demands of individual users.",
          },
          {
            img: remote,
            title: "Remote Learning",
            para: "Rapid response: Pivot to remote learning",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Because our education team has worked with educators and students for so long, we have a thorough grasp of their objectives and obstacles. We may lessen possible hazards and disruption during this moment of unprecedented change by combining empathy with technology initiatives.",
            explorepara: "",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },
  {
    id: 5,
    name: "platforms-and-software-products",

    HeroBanner: [
      {
        name: "platforms-and-software-products",
        Page: "Platforms and software products",
        breadcrums: "Industries",
        Link: "/industries",
        title: "Platforms and software products",
        button: "Let's talk",
      },
    ],
    ourExpertise: [
      {
        title:
          "With software development and maintenance services, such as game engineering, you may achieve faster time to market and cost savings.",
        para: "Senwell Group Private Limited unique blend of product engineering, business operations, and IT services experience helps software suppliers meet their most pressing demands. We also offer game engineering, art collection, game localization and personalization, platform migration, and verification. Our main focus is on developing marketing strategies that reduce time to market and increase operational efficiencies.",
      },
    ],

    WhatWeDo: [
      {
        services: [
          {
            img: gameplatfrom,
            title: "Game Engineering & Development",
            para: "Play like never before",
            exploretitle: "Our Expertise",
            exploresubtitle: "Game Engineering & Development",
            explorepara: "",
          },
          {
            img: autosolimg,
            title: "IMS™",
            para: "Next-gen IT management made simple",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Our substantial experience in Infrastructure Management Services, including Datacentre, End User Computing, and Customized Autonomics Development, is leveraged in this solution. ",
            explorepara:
              "modular automation framework includes a manufacturing reference model that can be customised to function with both industry-standard OEM-specific automation tools and our own customized solutions. The solution forecasts future values of key performance metrics for IT Facilities using a statistical statistical method. ",
          },

          {
            img: automation,
            title: "Helping you deliver customer success",
            para: "Software product offerings brochure",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "The focus has switched from marketing a product or service to assisting end-user outcomes in the digital era. Customers want products that provide greater experiences, adapt rapidly to their changing demands, and upgrade to newer versions without difficulty. A new approach to product engineering is required to develop and maintain this online content.",
            explorepara: "",
          },
          {
            img: creationanimation,
            title: "Art, Asset Creation and Animation Services",
            para: "Make winning a habit",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Our clients have no trouble keeping up with the creative, artistic, and immersive demands of game publishing. To produce increasingly sophisticated and engaging experiences, they use our Art, Asset Creation, and Animation service. ",
            explorepara:
              ". Our Art and Asset Creation solution includes IT design services for original artwork and storyboard development, as well as scalable computing and storage assets. The Animation service offers scalable computing platforms for 2D/3D game cinematic, mechanical, and biomechanical animation, as well as data access, content reuse techniques, and continuity planning.  ",
          },
          {
            img: rebouting,
            title: "Rebooting Business",
            para: "Making the hybrid office and work from home a possibility",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Throughout countless engagements and technological collaborations with top corporations across regions, we have developed in-depth technical experience and fine-tuned our proposed solution. Our focus has been on combining with top partners' proven technology and professional consulting teams to provide creative and dependable solutions that drive corporate change.",
            explorepara: "",
          },
          {
            img: productlocalization,
            title: "Product Localization",
            para: "Local flavor for local markets",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Senwell Group Private Limited help companies fulfil country-specific language, cultural, and regulatory compliance needs as they grow into new locations. ",
            explorepara:
              "Through broad relationships for interpreters, unique tools for test automation, solid program management, and a factory model that can be scaled up/down based on demands, the service is matched with the needs of local markets. Development and testing, user interface and manual translations, localization testing, localized document verification, and website translation are all handled by the service.",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },
  {
    id: 6,
    name: "industrial-and-process-manufacturing",

    HeroBanner: [
      {
        name: "industrial-and-process-manufacturing",
        Page: "Industrial and Process Manufacturing",
        breadcrums: "Industries",
        Link: "/industries",
        title: "Industrial and Process Manufacturing",
        button: "Let's talk",
      },
    ],
    ourExpertise: [
      {
        title:
          "Allow for digital transformation with the primary purpose of improving customer experience. Sewell Solutions assists Manufacturing & Procedure Industrial plants in their digital transformational journey to achieve operational excellence and superior customer experience across the entire value chain by leveraging a combination of its own IPs and Platforms, as well as the power of an unrivalled ecological system of partners.",
        para: "",
      },
    ],

    WhatWeDo: [
      {
        services: [
          {
            img: MetalsSteel,
            title: "Metals and Steel",
            para: "Robust. Responsive. Resilient.",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "manufacturers may make use of digital solutions for supply chain management, process optimization, ERP, and legacy system conversion to get end-to-end digital solutions. ",
            explorepara:
              "These services include IT portfolio moral justification and consolidation, purchasing and transportation management, supply chain management, logistics tracking and optimization, process improvement, portfolio management, environmental, health, and safety strategic planning, as well as consulting as well as deployment. Our solutions are backed by a broad number of industry-specific experts with strong technical capabilities and cross-value chain knowledge, allowing customers to adapt quickly to changing needs.",
          },
          {
            img: chemicals,
            title: "Chemicals",
            para: "Catalyzing progress with digital",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Use digital to deal with supply chain and raw material price volatility. ",
            explorepara:
              "Senwell Group Private Limited digital solutions help chemical firms deal with issues including sustainability, supply chain, and pricing volatility. Chemical Company in a Box is one of our industry-specific solutions, supported by a huge pool of industry-specific experts with strong technological capabilities and cross-value chain knowledge. To decrease risk, lower infrastructure/licensing costs, and secure our client’s future-readiness, we deploy social collaboration, mobility, and analytics solutions.",
          },
          {
            img: smartmanu,
            title: "Smart Manufacturing",
            para: "Breakthrough, game-changing, disruptive",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Integrate plant and enterprise in a real-time, data-driven environment to reap the benefits of Smart Manufacturing.",
            explorepara:
              "Senwell Group Private Limited has made it a priority to integrate intelligence into the operations of our manufacturing clients. To establish Smart Manufacturing environments, we focus on the primary parts of Industry 4.0—digital, automation, linked, and real-time data analytics. Our team of domain and technology professionals uses current investments to coordinate OT and IT as a seamless whole that propels businesses towards the future of manufacturing.",
          },
          {
            img: aftermarketing,
            title: "Aftermarket Services",
            para: "Pump up aftermarket opportunities",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Improve customer satisfaction and generate new income streams by transforming and capitalizing on aftermarket services.",
            explorepara:
              "Senwell collaborates with manufacturers to improve aftermarket service performance. To accomplish so, we use technologies like IoT, Big Data, Analytics, and Augmented Reality to redefine business models. Our initiatives improve the consumer experience while also generating new and long-term income sources",
          },
          {
            img: remanufacturing,
            title: "Remanufacturing",
            para: "Disrupt the ‘take-make-dispose’ cycle",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Adopt the circular economy to save money and decrease waste while also boosting future product design.",
            explorepara:
              "Remanufacturing services help clients adopt the resource efficiency by formulating strategy, designing procedures, and implementing reverse logistics. Our remanufacturing service seeks to decrease costs, energy consumption, raw material use, greenhouse gas emissions, preserve intellectual property and increase brand awareness. Furthermore, comments and insights gained from device disassembly are used by OEMs to improve product design and reduce warranty costs in the future.",
          },
          {
            img: productengineering,
            title: "Product Engineering",
            para: "Innovate and accelerate",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Reduce development expense and risks to market in discrete manufacturing by using Product Engineering Excellence.",
            explorepara:
              "By leveraging the power of innovation in technology, engineering, and business systems, our manufacturing clients rely on our knowledge and Product Engineering Excellence to produce world-className products. The technique focuses on services, adds modularization to discrete processes, and employs value engineering and value analysis to localize goods for emerging markets. Our clients report that using this method lowers investment costs and shortens the time to market.",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },

  {
    id: 7,
    name: "travel-and-transportation",

    HeroBanner: [
      {
        name: "travel-and-transportation",
        Page: "Travel & Transportation",
        breadcrums: "Industries",
        Link: "/industries",
        title: "Travel & Transportation",
        button: "Let's talk",
      },
    ],
    ourExpertise: [
      {
        title:
          "Bring cutting-edge technology and vast knowledge to continually innovate and improve customer-centric goods and services.",
        para: "Senwell Group Private Limited is using cutting-edge technology to revolutionize the world of travel and transportation. Our clients stand out in front of their visitors and consumers by integrating Designit's radical design thinking, Opera SignalHub's Big Data Analytics Travel & Transportation technologies. To develop excellent customer experiences, we create a unique combination of design, business thinking, process management, and technology.",
      },
    ],

    WhatWeDo: [
      {
        services: [
          {
            img: airline,
            title: "Airline",
            para: "Next-gen airline flight operations solution",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Senwell Group Private Limited work with its airline clients to strike a balance between providing excellent service at the lowest possible cost while also ensuring profitability. Furthermore, our expertise working with some of the world's most prestigious airlines guarantees that airlines can quickly negotiate the complexity of regulatory compliance and security issues while keeping lean networks with minimal inefficiencies.",
            explorepara: "",
          },
          {
            img: Airlines,
            title: "Cruise and Rentals ",
            para: "Unified Air Cargo Management",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Senwell Group Private Limited work with its airline clients to strike a balance between providing excellent service at the lowest possible cost while also ensuring profitability. Furthermore, our expertise working with a few of the world's most prestigious airlines guarantees that airlines can quickly negotiate the complexity of regulatory compliance and security issues while keeping lean infrastructures with minimal costs.",
            explorepara: "",
          },
          {
            img: Hotels,
            title: "Hotels",
            para: "Last mile delivery innovation",
            exploretitle: "Our Expertise",
            exploresubtitle: "",
            explorepara:
              "Senwell Group Private Limited  collaborates with customers in the worldwide hotel sector to guarantee that cutting-edge technology is leveraged to keep visitors engaged and enhance their stay. In the challenging hotel business, our technological collaborations drive the highest levels of excellence.",
          },
          {
            img: Restaurants,
            title: "Quick Service Restaurants",
            para: "Renway to growth",
            exploretitle: "Our Expertise",
            exploresubtitle:
              "Quick Service Restaurants (QSRs) have progressed from serving basic variety fast cuisine to providing a digitally transformed customer experience. ",
            explorepara:
              " Senwell Group Private Limited collaborates with industry experts to guarantee that business continues to evolve while meeting customer quality requirements. Our technology and process assistance are designed to keep businesses focused on the end customer and help them establish career identities.",
          },
        ],
      },
    ],

    Forms: [
      {
        formTitle: "Write to us.",
        formSubtitle: "Tell us about your project.",
      },
    ],
  },
];

export { IndustriesData };
