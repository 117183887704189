import React from "react";
import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  Details: {
    // marginTop:'3rem' ,
    margin: "auto",
    width: "85%",
    [theme.breakpoints.down('sm')]: {
      marginBottom: '3rem'
    }
  },
  mob:{
    [theme.breakpoints.up('sm')]: {
      margin: "0rem 0rem 3rem 6.5rem"
    }
  },
  mobV:{
    [theme.breakpoints.up('sm')]: {
      width: '85%',
      margin: 'auto'
    }
  },

  // Number: {
  //   color: "#3D8ED7",
  //   fontWeight: "700",
  // },
  Number: {
    fontSize: "14px",
    color:"white",
    margin: "0 auto 2px auto",
    padding: "5px 8px",
    display: "inline-block",
    textAlign: "center",
    borderRadius: "50%",
    background:
      "linear-gradient(90deg, hsla(200, 73%, 52%, 1) 44%, hsla(220, 60%, 37%, 1) 100%)",
  },
  mainWrapper: {
    padding: "2rem 1rem",
    [theme.breakpoints.down("sm")]: {
      padding: "0rem",
    },
  },
  UxTitle: {
    padding: "0.3rem 0rem",
    fontFamily: '"Manrope", sans-serif',
  },
  titleWrapper: {
    fontFamily: '"Manrope", sans-serif',
    padding: " 1rem 1rem 0rem",
    fontSize: "2.3rem",
    [theme.breakpoints.down('sm')]: {
      padding: '1rem 1rem 2rem',
      textAlign: 'center',
      fontSize: "2.3rem",

      [theme.breakpoints.down('sm')]: {
            fontSize:"2rem"
      },
    },
  },
  UXDesign: {
    padding: "0rem 1rem",
  },
  desc: {
    lineHeight: "1.5rem",
    textAlign: "justify",
    fontSize: "15px",
    wordSpacing: "-0.1em",
    hyphens: "auto",
  },
}));
const Benefits = ({ Benefits }) => {
  const classNamees = useStyles();
  return (
    <div className={classNamees.UXDesign}>
      {Benefits?.map((val) => (
        <>
          <Container maxWidth="lg" className={classNamees.mainWrapper}>
            <div className={classNamees.mob}>
              <Typography style={{fontWeight:'500'}} variant="h2" className={classNamees.titleWrapper}>
                {val.title}
              </Typography>
            </div>

            <Grid container className={classNamees.mobV} >
              {val.BenefitsofDevelopmentWithSenwell?.map((data) => (
                <>
                  <Grid xs={12} sm={6} md={4} lg={4}>
                    <div className={classNamees.Details}>
                      <Typography variant="h6" className={classNamees.Number}>
                        {data.Number}
                      </Typography>
                      <Typography variant="h6" className={classNamees.UxTitle}>
                        {data.title}
                      </Typography>
                      <p className={classNamees.desc}>{data.description}</p>
                    </div>
                  </Grid>
                </>
              ))}
            </Grid>
          </Container>
        </>
      ))}
    </div>
  );
};

export default Benefits;




