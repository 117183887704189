import React from "react";
import { Grid, Container, Typography } from "@material-ui/core";
import projectmanagement from "../../images/Company/projectmanagement.webp";
import { makeStyles } from "@material-ui/core";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { t } from 'i18next';

const useStyles = makeStyles((theme) => ({
  ImageWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3rem 3rem 3rem 3rem",
  },
  Image: {
    width: "70%",
    height: "auto",
    objectFit: "contain",
  },
  ContentWraper: {
    marginTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      padding: "1rem 1rem 1rem 1rem",
    },
  },
  WorkflowTitle: {
    fontSize: "36px",
    lineHeight: "56px",
    fontWeight: "600",
    margin: "0px",
    fontFamily: " 'Ubuntu', sans-serif",
  },
  WorkflowPara: {
    fontSize: "17px",
    fontWeight: 400,
    textAlign: "justify",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "14px",
  },
  WorkflowsubPara: {
    color: "#5a5a5a",
    fontSize: "17px",
    fontWeight: 400,
    padding: "0px 5px",
    fontFamily: " 'Lora', serif",
    fontSize: "14px",
  },
  StartFastText: {
    color: "#3D8ED7",

  },
  imageCol: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentCol: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}));
const Workflow = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const classNamees = useStyles();
  return (
    <>
      <div className="container" data-aos="fade-up" data-aos-duration="2000" style={{left:"0",border:"none"}}>
        <Container maxWidth="lg" className={classNamees.Mainwrapper}>
          <Grid container>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6} className={classNamees.contentCol} data-aos="fade-left">
              <div className={classNamees.ContentWraper}>
                <Typography variant="h3" className={classNamees.WorkflowTitle}>
                  <span className={classNamees.StartFastText}>
                    Start Fast.</span>
                  <br />
                  Finish Confidently.
                </Typography>
                <p className={classNamees.WorkflowPara}> {t('sfc1')}
                </p>
                <p className={classNamees.WorkflowPara}> {t('sfc2')}
                </p>
              </div>
            </Grid>
            <Grid xs={12} sm={8} md={6} lg={6} xl={6} className={classNamees.imageCol} data-aos="fade-right">
              <div className={classNamees.ImageWrapper}>
                <img src={projectmanagement} className={classNamees.Image} />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Workflow;
