import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/translationEN.json';
import translationES from './locales/translationES.json';
import translationFR from './locales/translationFR.json';

const resources = {
    en: {
        translation: translationEN,
    },
    es: {
        translation: translationES,
    },
    fr: {
        translation: translationFR,
    },
};

i18next 
.use(initReactI18next)
.init({
    resources,
    lng: "en",
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});

export default i18next;