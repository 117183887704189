import React from "react";
import "../../../Modules/Company/Careers/InsideStory.css";
import VideoComponent from "./VideoComponent";

const InsideStory = () => {
  const VideoId = `lPwt4XBWO6I`;
  const cricketvideoId = `X8XozezuYEg`;
  const inagurationId = `YuTGbRUcLXE`;

  return (
    <section style={{ padding: "50px 0px" }}>
      <div className="container-xl" style={{ left: "0", border: "none" }}>
        <div className="row">
          <div className="col-lg-7 col-md-12">
            <div className="first-image">
              <div className="video-container">
                <VideoComponent
                  videoId={VideoId}
                  style={{ width: "500px", height: "315px" }}
                />
              </div>
              <h3 className="Sports-Day">Senwell Group Corporate Video</h3>
              <p className="Annual-Sports">
                Step into the world of Senwell Group through our corporate
                video, where excellence takes center stage and innovation knows
                no bounds. From the precision of aerospace technology to the
                intricacies of electrical components and the dynamic energy
                sector, Senwell Group stands as a beacon of quality across
                diverse industries. Our services also extend in the IT sector,
                Digital Marketing sector, and Commodity Analysis & Prediction.
                Our state-of-the-art facilities, equipped with our extremely
                talented Team, showcase the heart of Senwell. It's our people
                who truly define us – a diverse and dedicated team collaborating
                to drive success.
              </p>
              <p className="Annual-Sports">
                Beyond our different domains, we embrace sustainability with
                initiatives like tree plantation drives, reflecting our
                commitment to a greener future. With a global presence and
                strategic partnerships, Senwell Group is not just navigating
                industry currents; we're shaping tomorrow with a relentless
                pursuit of excellence, innovation, and sustainability. Join us
                on this transformative journey where every endeavor propels us
                towards a future redefined by the values that set Senwell Group
                apart from our competitors.
              </p>
            </div>
          </div>

          <div className="col-lg-5 col-md-12">
            <div className="row">
              <div className="col-lg-12 col-md-6 col-sm-12">
                <div className="second-img">
                  <VideoComponent
                    videoId={cricketvideoId}
                    autoplay={false}
                    style={{ width: "500px", height: "315px" }}
                  />
                  <h4 className="senwell-Kitli">
                    Senwell Championship Cricket Trophy 2024
                  </h4>
                  <p></p>
                </div>
              </div>

              <div className="col-lg-12 col-md-6 col-sm-12">
                <div className="second-img">
                  <VideoComponent
                    videoId={inagurationId}
                    autoplay={false}
                    style={{ width: "500px", height: "315px" }}
                  />
                  <h4 className="senwell-Kitli">
                    Senwell Exports Inauguration & Senwell Group Private Limited
                    Foundation Day Celebration
                  </h4>
                  <p>
                    Embarking on a journey of success and innovation! Celebrate
                    the grand inauguration of Senwell Exports with us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InsideStory;
