import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Container, Grid } from "@material-ui/core";
import Consulting from "../../images/Industries/aroplane.jpg";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import InfoModal from "./Modal";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "2rem 2rem",
    
    transition: 'all 300ms ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
    },
    // zIndex:99999,
  },
  media: {
    height: 300,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    background:"transparent",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
   
  },
  cardContent:{
    height:"100px",
    [theme.breakpoints.down('sm')]: {
      height:"130px"
    }
  },
  maintitle: {
    marginLeft:"2.5rem",
    // padding: "rem 0rem",
    fontFamily: "'Ubuntu', sans-serif",
    paddingBottom:"1rem",
    [theme.breakpoints.down('sm')]: {
      margin: '0',
      textAlign: 'center'
    },
  },
  mainWrapper: {
    padding: "2rem 1rem",
    [theme.breakpoints.down('sm')]: {
      padding: '0 0'
    },
    
  },
  title: {
    [theme.breakpoints.down('md')]: {
     fontSize:'24px',
  },
    fontFamily: "'Ubuntu', sans-serif !important",
  },
  para: {
    fontFamily: "'Ubuntu', sans-serif !important",
    height:"30px",
    fontSize: '16px'
  },
  marginCls: {
    width: '90%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  shadow: {
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
  },
  modaltitle: {
    color: "#000",
  },
}));

export default function MediaCard({ WhatWeDo }) {
  const classNamees = useStyles();
  const [open, setOpen] = React.useState(false);
  const [sendData, setSendData] = React.useState({});

  const handleOpen = (value) => {
    setOpen(true);
    setSendData(value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Container maxWidth="xl" className={classNamees.mainWrapper}>
        <Container className={classNamees.marginCls} maxWidth="lg">
          {WhatWeDo?.map((data) => (
            <>
              <Typography variant="h4" className={classNamees.maintitle}>
                {" "}
                What We Do
              </Typography>
              <Grid container>
                {data.services?.map((value, index) => (
                  <>
                    <Grid items xs={12} sm={6} md={4} lg={4}>
                      <Card
                        className={classNamees.root}
                        onClick={() => handleOpen(data.services[index])}
                      >
                        <CardActionArea className={classNamees.shadow}>
                          <CardMedia
                            className={classNamees.media}
                            image={value.img}
                            title="Contemplative Reptile"
                          />
                          <CardContent className={classNamees.cardContent}>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              className={classNamees.title}
                            >
                              {value.title}
                            </Typography>
                            <p className={classNamees.para}>{value.para}</p>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                      <InfoModal
                        open={open}
                        close={handleClose}
                        data={sendData}
                      />
                    </Grid>
                  </>
                ))}
              </Grid>
            </>
          ))}
        </Container>
      </Container>
    </>
  );
}
