import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TechnologiesData } from "../../API/TechnologiesApi";
import Skill from "../../SharedModule/Skills";
import TechnologiesExperties from "../../SharedModule/TechnologiesExperties";
import DevelopmentStepbyStep from "../../SharedModule/DevelopmentStepbyStep";
import GetInTouch from "../../SharedModule/GetInTouch";
import BenefitsofDevelopment from "../../SharedModule/BenefitsofDevelopment";
import GetInTouchSecondBanner from "../../SharedModule/GetInTouchSecondBanner";
import WhyChooseUs from "../../SharedModule/WhyChooseUs";
import TechnologiesBanner from "../../Pages/Technologies/TechnologiesBanner.js";
import TechnologiesBannerApi from "../../API/TechnologiesBannerApi";
import { useSelector } from "react-redux";

const Technologies = ({ getSlug }) => {
  const { name, slug } = useParams();
  const [filteredData, setFilteredData] = useState();
  const tech_data = useSelector((state) => state.techReducer);
  const data = TechnologiesData.find((value) => value.name === name);
  getSlug(data?.name);
  useEffect(() => {
    const result = TechnologiesBannerApi?.filter(
      (item) => item.name === tech_data?.data
    );
    setFilteredData(result[0]);
  }, [tech_data?.data]);

  return (
    <>
      <TechnologiesBanner selectedTech={data} data={filteredData} />
      <Skill Skill={data?.skill} />
      <TechnologiesExperties
        TechnologiesExperties={data?.TechnologiesExperties}
        title={data?.TechnologiesExpertiesTitle}
        para={data?.TechnologiesExpertiesPara}
      />
      <DevelopmentStepbyStep DevelopmentSteps={data?.DevelopmentStep} />
      <GetInTouch GetInTouch={data?.GetInTouch} />
      <BenefitsofDevelopment Benefits={data?.Benefits} />
      <GetInTouchSecondBanner
        GetInTouchSecondBanner={data?.GetInTouchSecondbanner}
      />
      <WhyChooseUs WhyChooseUs={data?.WhyChooseUs} />
    </>
  );
};

export default Technologies;
