import React from "react";
import { Container, Grid, Typography, Box, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Popup from "./Popupform";
import { t } from "i18next";
import { Scale } from "@mui/icons-material";
import { keyframes } from "@emotion/react";
import "./newSlide.css";




const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },

  mainContainer: {
    width: "100%",
    height: "90vh",
    backgroundImage: "linear-gradient(to right,rgba(0, 0, 0, 0.45),rgba(0, 0, 0, 0.30),rgba(0, 0, 0, 0),rgba(0, 0, 0, 0))",
    position: "absolute",
    top: "0",
    left: "0",
    marginTop: '4rem',

    color: "#fff",
    [theme.breakpoints.down("md")]: {
      height: '95vh',
      marginLeft: "0",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      height: '95vh'
    },
  },
  HeroBannerTitle: {
    fontSize: "3rem",
    color: "#fff",
    fontWeight: "600",
    fontFamily: "Poppins !important",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem !important",
    },
  },
  titleWrapper: {
    position: "relative",
    width: "95%",
    height: "80%",
    margin: "auto",
  },

  HeroBannerTitleCompanyText: {
    color: "#fff",
    padding: "1rem 0rem",
  },
  HireNowButton: {
    padding: "0.7rem 4rem",
    color: "#fff",
    border: "2px solid rgb(31 142 203)",
    background:
      "linear-gradient(90deg, hsla(200, 69%, 50%, 1) 15%, hsla(216, 62%, 39%, 1) 83%)",

    transition: "background border 0.5s",
    borderRadius: "50px",
    fontSize: "1rem",
    fontFamily: "'Manrope', sans-serif",
    "&:hover": {
      border: "2px solid #fff",
      background:
        "transparent",
      borderRadius: "25rem",
    },
  },
  HomeSubContainerWrapper: {
    background: "#000",
    margin: "-2px",
    paddingBottom: "1rem",
  },

  ImageWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0rem 0rem 0rem 5rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  Image: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
  },
  HomeSubContainer: {
    display: "flex",
  },
  HomeSubContainerImage: {
    marginRight: "2rem",
  },

  bgImage: {
    width: "100%",
    height: "100vh",
    objectFit: "cover",
    paddingLeft: '0px',
    paddingRight: '0px'
  },

  overlay: {
    background:`url('https://firebasestorage.googleapis.com/v0/b/senwell-b8e50.appspot.com/o/images%2Fwallpaperflare.com_wallpaper.jpg?alt=media')`,
    backgroundSize: 'cover',
    width: '100%',
    height: '90vh',
    marginTop: '4rem',
    [theme.breakpoints.down("sm")]: {
      height: '95vh',
    },

  },

  videocontainer: {
    width: '100vw',
    height: '100vh',
    objectFit: 'cover'
  },

  padding0: {
    padding: '0px'
  },

  para: {
    fontFamily: " 'Lora', serif",
    fontSize: "24px",
    background: "rgba(0,157,224,0.6)",
    padding: "2vh",
  },
  welcome: {
    fontFamily: "'Poppins', serif !important",
    fontWeight: "600",
  },
}));
const HeroBanner = () => {
  const classNamees = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container className={classNamees.overlay}>
      <Container maxWidth="lg" className={classNamees.mainContainer}>
        <Box>
          <div className={classNamees.titleWrapper}>
            <div className="heading">
              <div className="senwell">
                <span>S</span>
                <span>E</span>
                <span>N</span>
                <span>W</span>
                <span>E</span>
                <span>L</span>
                <span>L</span>
              </div>
              <div className="solutions">
                <span>S</span>
                <span>O</span>
                <span>L</span>
                <span>U</span>
                <span>T</span>
                <span>I</span>
                <span>O</span>
                <span>N</span>
                <span>S</span>
              </div>
              <div className="subtitle">
                <h4>Empowered By</h4>
                <span>
                  Innovation <br />
                  Technology <br />
                  Trust <br />
                  Experts
                </span>
              </div>

            </div>
          </div>
        </Box>
      </Container>

    </Container>
  );
};

export default HeroBanner;
