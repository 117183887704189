import React from "react";
import "./UiDesign.css";
import shape2 from "../../../images/SoftwareDev/shape2.svg";
import shape3 from "../../../images/SoftwareDev/shape3.svg";
import shape4 from "../../../images/SoftwareDev/shape4.svg";
import brand from "../../../images/SoftwareDev/brand differentiator.png";
import real from "../../../images/SoftwareDev/real customer trust (1).png";
import Communication from "../../../images/SoftwareDev/customer pull.png";
import DesignThinkn from "../../../images/SoftwareDev/design thinking.png";
import information from "../../../images/SoftwareDev/information architecture.png";
import onbording from "../../../images/SoftwareDev/onboarding critical workflow.png";
import MobileApp from "../../../images/SoftwareDev/Mobile-App-Development.jpg";
import { Tabs, Tab } from "react-bootstrap";
import bootstrapL from "../../../images/SoftwareDev/bootstrap-logo.svg";
import HTml5L from "../../../images/SoftwareDev/html5-logo.svg";
import css3L from "../../../images/SoftwareDev/css3-logo.svg";
import jq from "../../../images/SoftwareDev/jQuery-logo.svg";
import invisibleL from "../../../images/SoftwareDev/invision-logo.svg";
import adobe from "../../../images/SoftwareDev/adobe-xd-logo.svg";
import sketchL from "../../../images/SoftwareDev/sketch-logo.svg";
import googleweb from "../../../images/SoftwareDev/google-web-designer (1).svg";
import figmaL from "../../../images/SoftwareDev/figma-logo.svg";
import photoshop from "../../../images/SoftwareDev/photoshop-logo.svg";
import indesign from "../../../images/SoftwareDev/indesign.png";
import illustrator from "../../../images/SoftwareDev/illustrator-logo.svg";
import zepline from "../../../images/SoftwareDev/zeplin-logo.svg";
import AdobiCloude from "../../../images/SoftwareDev/adobe-cloud-logo.svg";
import itConsultingsolution from "../../../images/SoftwareDev/DevopsConsulting.png";
import { Helmet } from "react-helmet";

const UiDesign = () => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": "https://senwellsys.com/ui-ux-design-services",
    url: "https://senwellsys.com/ui-ux-design-services",
    name: "Senwell Group Pvt Ltd | UI/UX Design Services",
    description:
      "Explore Senwell Group's expertise in UI/UX design, offering user-centric and innovative design solutions tailored to your business needs.",
    inLanguage: "en-US",
    organization: {
      "@type": "Organization",
      "@id": "https://senwellsys.com/#organization",
      name: "Senwell Group Pvt Ltd | Software Company | Pune",
      url: "https://senwellsys.com/",
      logo: {
        "@type": "ImageObject",
        url: "https://senwellsys.com/static/media/finalsenwellgrouplogo.fbb0ce6431b7262166b7.png",
        caption: "Senwell Group Pvt Ltd | Software Company | Pune",
      },
      potentialAction: [
        {
          "@type": "SearchAction",
          target:
            "https://senwellsys.com/ui-ux-design-services?s={search_term_string}",
          "query-input": "required name=search_term_string",
        },
      ],
    },
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Senwell Group Private Limited | UI/UX Design Services</title>
        <meta
          name="description"
          content="Explore Senwell Group's expertise in UI/UX design, offering user-centric and innovative design solutions tailored to your business needs."
        />
        <link
          rel="canonical"
          href="https://senwellsys.com/ui-ux-design-services"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="UI/UX Design Services" />
        <meta
          property="og:description"
          content="Explore Senwell Group's expertise in UI/UX design, offering user-centric and innovative design solutions tailored to your business needs."
        />
        <meta
          property="og:url"
          content="https://senwellsys.com/ui-ux-design-services"
        />
        <meta property="og:site_name" content="Senwell Group Private Limited" />
        <meta
          property="og:image"
          content="https://senwellsys.com/static/media/DevopsConsulting.b1a4d5ed7144ea960655.png"
        />
        <meta property="og:image:width" content="376" />
        <meta property="og:image:height" content="360" />
        <meta property="og:image:type" content="image/png" />

        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>
      <div className="CustomeDev">
        <section>
          <div className="main-banner">
            <div className="container" style={{ left: "0", border: "none" }}>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <h1 className="Product-title">UI/UX Design</h1>
                </div>
                <div className="col-lg-6 col-md-12">
                  <p className="content-para">
                    A visually appealing user interface for amazing digital
                    experiences and increased business engagement. Utilize
                    Senwell Group Private Limited' creative design experience
                    and state-of-the-art tools and technologies to produce
                    visually appealing and user-friendly designs.
                  </p>
                </div>
              </div>
            </div>

            <div class="shape2 rotateme">
              <img src={shape2} alt="img" />
            </div>

            <div className="shape3">
              <img src={shape3} alt="shape" />
            </div>
            <div className="shape4">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape5">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape6 rotateme">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape7">
              <img src={shape4} alt="shape" />
            </div>

            <div className="shape8 rotateme">
              <img src={shape2} alt="shape" />
            </div>
          </div>
        </section>

        <section className="section-services">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row" style={{ marginBottom: "60px" }}>
              <div className="col-lg-12 col-md-12 text-center">
                <h2 className="section-head">UI & UX Development Services</h2>
                <p className="mob-section-para">
                  We brainstorm, plan, and suggest the digital design ideas for
                  applications on the web and mobile devices. Our approach,
                  which is based on a thorough understanding of the market,
                  combines content with impressive and functionally active
                  designs and the appropriate technologies to create memorable
                  brand names for businesses.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={real} alt="img" />
                  </div>
                  <h3>Prototyping</h3>

                  <p>
                    Prototyping is the first step in conceptualizing the page
                    structure. Here, we optimize the layout of the page,
                    showcasing carefully planned structural alignments and
                    arrangements that make the user's digital experience
                    responsive and easy to use.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={Communication} alt="img" />
                  </div>
                  <h3>Information Architecture design</h3>

                  <p>
                    Organise, structure and label the content according to the
                    theme of the business on web or on mobile apps. After deep
                    analysis of customer's needs, resolve issues of system
                    structure, content management and navigation and recommend
                    best fit as per the business goals.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={brand} alt="img" />
                  </div>
                  <h3>User Experience (UX) Development</h3>

                  <p>
                    a comprehensive strategy in which every option and feature
                    is considered in order to track end-user interaction and add
                    new improvements. We conduct thorough analyses of key
                    features and use visual rendering as necessary to achieve
                    business objectives.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={DesignThinkn} alt="img" />
                  </div>
                  <h3>User Interface (UI) Development</h3>

                  <p>
                    Increase revenue and boost company profitability by drawing
                    in more clients with visually appealing application designs.
                    Following careful consideration of information architecture,
                    we transform your concepts into fluid, useful designs.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={information} alt="img" />
                  </div>
                  <h3>Wireframing</h3>

                  <p>
                    The information architecture serves as a blueprint for the
                    final design, and the next stage in the design process is to
                    visualize the final product and create a wireframe.
                    Utilizing the newest digital resources, we provide
                    wireframing services to present each piece of information in
                    a unique and eye-catching way.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 work-grid">
                <div className="work-itemProduct">
                  <div className="icon">
                    <img src={onbording} alt="img" />
                  </div>
                  <h3>Usability Testing</h3>

                  <p>
                    Testing a product to determine its acceptance in the
                    marketplace based on specific scenarios and usability tests.
                    We offer a comprehensive set of usability testing like
                    screen resolution tests, compatibility testing, crowd
                    testing and similar tests within faster lifecycle and at
                    accessible prices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section-padding-product">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row align-items-lg-center pt-md-3 pb-2 mb-2 mb-lg-4 mb-xl-2">
              <div className="col-md-12 col-lg-5 mb-4 mb-md-0 ">
                <img src={itConsultingsolution} alt="img" className="leftimg" />
              </div>

              <div className="col-md-12 col-lg-6 offset-lg-1">
                <div className="pe-xl-2 me-md-2 me-lg-2">
                  <h2 className="h4 mb-3 mb-sm-2 design-and-content-header">
                    UI/UX Design Services
                  </h2>

                  <p class="mb-sm-2 mb-0 f-16 text-justify design-and-content-para">
                    By providing UI and UX development services that combine
                    creativity, technology, and personalization, you can explore
                    cutting-edge digital experiences. We create engaging user
                    experiences that have a big impact on conversion rates,
                    brand value, and customer satisfaction rates. Their success
                    graphs have been paved with our expertise and experience in
                    UX research and design using the most appropriate tools,
                    technologies, and services. For strategic business growth,
                    our team of talented creative designers understands how to
                    design concepts and produces outstanding UI and UX designs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="industry-section section-padding">
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="mb-30">
              <h2 className="section-title text-center">
                UI/UX Technology Stack
              </h2>
              <p>
                We combine many programming languages and platforms to provide
                flexible desktop and mobile UI/UX development services. Our
                talented design team makes the most of the potential of
                cutting-edge design technologies to provide greater performance,
                increased visuals, and processing power.
              </p>
            </div>
            <div className="row">
              <div className="col-12">
                <Tabs
                  defaultActiveKey="programming_languages"
                  id="industry-tab"
                  className="mb-3 border-0"
                >
                  <Tab
                    eventKey="programming_languages"
                    title="Languages & Technologies"
                  >
                    <ul>
                      <li>
                        <img
                          src={bootstrapL}
                          alt="bootstrap"
                          className="w-100"
                        />
                        <span>bootstrap</span>
                      </li>
                      <li>
                        <img src={HTml5L} alt="html5" className="w-100" />
                        <span>HTML5</span>
                      </li>
                      <li>
                        <img src={css3L} alt="css3" className="w-100" />
                        <span>CSS3</span>
                      </li>
                      <li>
                        <img src={jq} alt="javascript" className="w-100" />
                        <span>jQuery</span>
                      </li>
                    </ul>
                  </Tab>
                  <Tab eventKey="Wireframe" title="Wireframe & Prototype">
                    <ul>
                      <li>
                        <img
                          src={invisibleL}
                          alt="invisibleL"
                          className="w-100"
                        />
                        <span>InVision</span>
                      </li>
                      <li>
                        <img src={adobe} alt="adobe" className="w-100" />
                        <span>Adobe XD</span>
                      </li>
                      <li>
                        <img src={sketchL} alt="sketchL" className="w-100" />
                        <span>Sketch</span>
                      </li>
                      <li>
                        <img
                          src={googleweb}
                          alt="googleweb"
                          className="w-100"
                        />
                        <span>Google Web Designer</span>
                      </li>
                      <li>
                        <img src={figmaL} alt="FigmaL" className="w-100" />
                        <span>Figma</span>
                      </li>
                    </ul>
                  </Tab>
                  <Tab eventKey="Ui/Ux" title="UI/UX (Visual Designs)">
                    <ul>
                      <li>
                        <img
                          src={photoshop}
                          alt="photoshop"
                          className="w-100"
                        />
                        <span>Photoshop</span>
                      </li>
                      <li>
                        <img src={indesign} alt="indesign" className="w-100" />
                        <span>InDesign</span>
                      </li>
                      <li>
                        <img
                          src={illustrator}
                          alt="illustrator"
                          className="w-100"
                        />
                        <span>Illustrator</span>
                      </li>
                      <li>
                        <img src={sketchL} alt="sketchL" className="w-100" />
                        <span>Sketch</span>
                      </li>
                      <li>
                        <img src={figmaL} alt="figmaL" className="w-100" />
                        <span>Figma</span>
                      </li>
                    </ul>
                  </Tab>
                  <Tab eventKey="collaboration " title="Collaboration ">
                    <ul>
                      <li>
                        <img src={zepline} alt="zepline" className="w-100" />
                        <span>Zeplin</span>
                      </li>
                      <li>
                        <img
                          src={invisibleL}
                          alt="InvisionL"
                          className="w-100"
                        />
                        <span>InVision</span>
                      </li>
                      <li>
                        <img
                          src={AdobiCloude}
                          alt="AdobeCloude"
                          className="w-100"
                        />
                        <span>Adobe Cloud</span>
                      </li>
                    </ul>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default UiDesign;
