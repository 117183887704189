import React from "react";
import { useNavigate } from "react-router-dom";
import {
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';

export default function Notification({ openModal, setOpenModal }) {
  let navigate = useNavigate();

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate("/careers");
  };

  return (
    <Dialog open={openModal} onClose={handleCloseModal}>
      <DialogTitle>
        <h4 style={{fontWeight:"700" , textAlign: "center"}}>Thanks for applying!</h4>
      </DialogTitle>
      <DialogContent>
        <p style={{color:"green"}}>
          We appreciate your interest and the time you've taken to apply for the
          job opening. If your qualifications match our needs, we will be in
          touch with you soon.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="primary">
          <CloseIcon style={{color:"black"}} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
