/* eslint-disable default-case */
import { SET_PLATFORM } from "./platformType";
import { GET_PLATFORM } from "./platformType";


const initialState = {
  loading: true,
  error: "",
  data: [],
};

export const platformReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLATFORM:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case GET_PLATFORM:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    default:
      return {};
  }
};
