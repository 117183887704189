import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import PaymentIcon from "@material-ui/icons/Payment";
import GroupIcon from "@material-ui/icons/Group";
import InfoIcon from "@material-ui/icons/Info";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import ComputerIcon from "@material-ui/icons/Computer";
import FlipToBackIcon from "@material-ui/icons/FlipToBack";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import InputIcon from "@material-ui/icons/Input";
import Portfolio from "../../Pages/Portfolio/Portfolio";

import React from "react";

export const menu = [
  {
    icon: <Portfolio />,
    title: "Portfolio",
    Link: "/portfolio",
    items: [],
  },

  {
    icon: <InfoIcon />,
    title: "About Us",
    Link: "/company",
  },
];

export const Techmenu = [
  {
    icon: <ComputerIcon />,
    title: "Front-End",
    Link: "/hire-developers/front-end",

    items: [
      {
        title: "React",
        Link: "/hire-developers/react",
      },
      {
        title: "Angular",
        Link: "/hire-developers/angular",
      },
    ],
  },
  {
    icon: <FlipToBackIcon />,
    title: "Back-end",
    Link: "/hire-developers/back-end",

    items: [
      {
        title: "Node.js",
        Link: "/hire-developers/nodejs",
      },
      {
        title: ".Net",
        Link: "/hire-developers/dot-net",
      },
    ],
  },
  {
    icon: <PhoneAndroidIcon />,
    title: "Mobile",
    Link: "/hire-developers/mobile",

    items: [
      {
        title: "React Native",
        Link: "/hire-developers/react-native",
      },
    ],
  },

  {
    title: "Quality Assurance",
    icon: <TrendingUpOutlinedIcon />,
    Link: "/hire-developers/qa",
  },
  {
    title: "Business & Management",
    Link: "/hire-developers/cms-crm-erp",
  },
];

export const ServiceMenu = [
  {
    title: "Software Product Development",
    Link: "/softwareproduct",
  },
  {
    title: "Software Development",
    Link: "/custom-software-development",
  },

  {
    title: "SaaS Development",
    Link: "/saas-development-services",
  },
  {
    title: "Application Development",
    Link: "/mobile-app-development-services",
  },
  {
    title: "Web App Development",
    Link: "/web-development",
  },
  {
    title: "Software Testing",
    Link: "/qa-and-software-testing-services",
  },

  {
    title: "UI/UX Design",
    Link: "/ui-ux-design-services",
  },

  {
    title: "Cloud",
    Link: "/cloud-consulting-services",
  },
];
export const IndustriesMenu = [
  {
    title: "Aerospace & Defense",
    Link: "/aerospace-and-defense",
  },
  {
    title: "Automotive",
    Link: "/automotive",
  },
  {
    title: "Blockchain",
    Link: "/industries/blockchain",
  },
  {
    title: "Communications",
    Link: "/communications",
  },
  {
    title: "Entertainment",
    Link: "/industries/media-and-entertainment",
  },
  {
    title: "education",
    Link: "/education",
  },
  {
    title: "eCommerce",
    Link: "/industries/ecommerce",
  },
];

export const IndustriesMegamenu = [
  {
    title: "Finance & Banking",
    Link: "/industries/banking-and-finance",
  },
  {
    title: "Healthcare",
    Link: "/industries/healthcare",
  },
  {
    title: "Industrial & Process Manufacturing",
    Link: "/industrial-and-process-manufacturing",
  },
  {
    title: "Platforms & Software Products",
    Link: "/platforms-and-software-products",
  },
  {
    title: "StartUps",
    Link: "/industries/startups",
  },

  {
    title: "Travel & Transportation",
    Link: "/travel-and-transportation",
  },
];

export const contact = [
  {
    title: "Contact US",
    Link: "/contact",
  },
];
