import React from "react";
import "../Privacy/Privacy.css";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <div style={{ marginTop: "77px" }}>
      <section className="container" style={{ left: "0", border: "none" }}>
        <h2 className="sn-title">Privacy Policy</h2>
        <div className="sn-content">
          <div className="row">
            <div class="col-lg-12 col-md-12">
              <p className="sn-para-content">
                This privacy policy ("Policy") is designed to inform you about
                how Senwell Group Private Limited utilizes and safeguards the information
                you provide when visiting and using &nbsp;
                <Link to="/">https://senwellsys.com</Link> &nbsp; Please be
                aware that we retain the right to amend this policy at any time,
                and we will promptly notify you of any such changes.
              </p>
            </div>
          </div>

          <h2 className="sn-sub-title">Who We Are</h2>
          <div className="row">
            <div class="col-lg-12 col-md-12">
              <p className="sn-para-content">
              Senwell Group Private Limited is a dynamic global platform that harmoniously
                combines web development, design, and digital marketing
                services, including social media marketing. Our purpose is to
                equip you with strategic solutions and cutting-edge technology,
                propelling you toward market excellence and triumphant success.
                Count on us for comprehensive support in your journey to thrive
                and prosper.We are not merely a team; we are a dynamic and
                highly motivated force, passionately committed to crafting
                innovative solutions that resonate with the rhythm of today's
                ever-changing business environment. Our steadfast dedication
                extends beyond offering top-tier services; it involves
                comprehending and tailoring them to your unique requirements,
                equipping you with the means not just to survive but to thrive
                in the fiercely competitive market. Rely on us not only as a
                dependable partner but as an eager architect of your victorious
                path to success.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div
        class="container mb-md-3 mb-lg-3 py-3"
        style={{ left: "0", border: "none" }}
      >
        <div className="row Collect-row">
          <div className="col-md-6">
            <h5 className="fs-lg d-md-none d-xl-block user-data">
              What Personal Data We Collect
            </h5>
            <ul class="list-unstyled d-md-none d-xl-block pb-2 pb-md-3 mb-3">
              <li class="d-flex align-items-center mb-2">
                <i class="bx bx-check lead text-primary me-2"></i>
                Your IP address.
              </li>

              <li class="d-flex align-items-center mb-2">
                <i class="bx bx-check lead text-primary me-2"></i>
                Your contact information and email address.
              </li>

              <li class="d-flex align-items-center mb-2">
                <i class="bx bx-check lead text-primary me-2"></i>
                Data profile regarding your online behavior on our website.
              </li>

              <li class="d-flex align-items-center mb-2">
                <i class="bx bx-check lead text-primary me-2"></i>
                To customize our website according to your online behavior and
                personal preferences.
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <h5 className="fs-lg d-md-none d-xl-block user-data">
              Why We Collect Your Data:
            </h5>

            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check lead text-primary me-2"></i>
              To better understand your needs.
            </li>

            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check lead text-primary me-2"></i>
              To improve our services and products.
            </li>

            <li class="d-flex align-items-center mb-2">
              <i className="bx bx-check lead text-primary me-2"></i>
              To send you promotional emails containing the information we think
              you will find interesting
            </li>

            <li className="d-flex align-items-center mb-2">
              <i className="bx bx-check lead text-primary me-2"></i>
              To contact you to fill out surveys and participate in other types
              of market research.
            </li>
          </div>
        </div>
      </div>

      <div className="container" style={{ left: "0", border: "none" }}>
        <div className="row">
          <h2 className="fs-lg d-md-none d-xl-block Collect-data">
            Ensuring the Safety and Protection of the Data
          </h2>
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row">
              <p>
              Senwell Group Private Limited is dedicated to the security and
                confidentiality of your data. We have taken comprehensive
                measures to prevent data theft, unauthorized access, and
                disclosure. Through the implementation of cutting-edge
                technologies and software, we ensure the protection of all the
                information we gather online.
              </p>
              <p>
                We are committed to not sharing, renting, or selling your
                information to any third party. Our utmost priority is to make
                diligent efforts in preserving the confidentiality of the
                information you entrust to us.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container" style={{ left: "0", border: "none" }}>
        <div className="row">
          <h2 className="fs-lg d-md-none d-xl-block Collect-data">
            Our Cookie Policy
          </h2>
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row">
              <p>
                When you consent to our website's use of cookies, you also grant
                permission for us to utilize the collected data related to your
                online activities, such as analyzing web traffic, identifying
                the web pages you spend the most time on, and tracking the
                websites you visit.
              </p>
              <p>
                The information gathered through the use of cookies serves the
                purpose of tailoring our website to meet your specific
                requirements. Once we've conducted the necessary statistical
                analysis, the data is entirely purged from our systems.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container" style={{ left: "0", border: "none" }}>
        <div className="row">
          <h2 className="fs-lg d-md-none d-xl-block Collect-data">
            How We Secure Your Data
          </h2>
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row">
              <p>
                We employ a range of advanced security technologies and
                protocols to safeguard your personal data against unauthorized
                access, misuse, or disclosure. As an example, we securely store
                the personal information you furnish on computer systems with
                restricted access within controlled facilities.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container" style={{ left: "0", border: "none" }}>
        <div className="row">
          <h2 className="fs-lg d-md-none d-xl-block Collect-data">
            How We Change this Privacy Policy
          </h2>
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row">
              <p>
                We are committed to periodically updating this privacy statement
                to incorporate any necessary amendments or reflect feedback from
                our customers. Whenever we make revisions to this statement, we
                will update the "last updated" date at the top of the document.
                In the event of significant changes to the statement, we will
                inform you through prominent notice postings prior to their
                implementation or by directly notifying you.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container" style={{ left: "0", border: "none" }}>
        <div className="row">
          <h2 className="fs-lg d-md-none d-xl-block Collect-data">
            Contact Us
          </h2>
          <div className="container" style={{ left: "0", border: "none" }}>
            <div className="row">
              <p className="privacy-contact-para">
                If you have any privacy-related concerns, complaints, or
                questions for our Data Protection Officer, please Contact us on
                Email:
                <a href="mailto:info@senwellsys.com"> info@senwellsys.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
