// import animationData from "../assets/Animation.json";
import FrontEndData from "../assets/FrontEndAnimation.json";
import ReactData from "../assets/ReactAnimation.json";
import AngularData from "../assets/AngularAnimation.json";
import BackendData from "../assets/BackendAnimation.json";
import NodeData from "../assets/NodeAnimation.json";
import DontNetData from "../assets/DotNetAnimation.json";
import MobileData from "../assets/MobileDevAnimation.json";
import NativeData from "../assets/NativeAnimation.json";
import javaAnimation from "../assets/JavaAnimation.json";
import NestAnimation from "../assets/NestjsAnimation.json";
import NextjsData from "../assets/NextjsAnimation.json";
import IosAnimation from "../assets/IosAnimation.json";
import FlutterAnimation from "../assets/FlutterAnimation.json";

const TechnologiesBannerApi = [
  {
    id: 1,
    name: "Front-End",
    HeroBanner: [
      {
        name: "front-end",
        page: "front-end",
        title1: "FrontEnd",
        title2: "Developer",
        Link: "/front-end",
        para: "Our FrontEnd Development services deliver unmatched performance, UX, and innovation, setting your product apart from competitors. Trust us to elevate your vision with cutting-edge tech and design. Partner with us for success",
        button: "HIRE NOW",
        loti: FrontEndData,
      },
    ],
  },

  {
    id: 2,
    name: "React",
    HeroBanner: [
      {
        name: "React",
        page: "React",
        title1: "React",
        title2: "Developer",
        Link: "/react",
        para: "Elevate your business with our exceptional ReactJS development services! We craft high-performance web apps that set you apart from the competition. Our skilled team ensures custom solutions tailored to your needs, delivering top-notch performance, responsiveness, and security. Embrace digital transformation and stay ahead in the market. Contact us now to turn your vision into reality!",
        button: "HIRE NOW",
        loti: ReactData,
      },
    ],
  },
  {
    id: 3,
    name: "Angular",
    HeroBanner: [
      {
        name: "angular",
        page: "Angular",
        title1: "Angular",
        title2: "Developer",
        Link: "/Angular",
        para: "Elevate your business with our exceptional Angular development services! We craft high-performance web apps that set you apart from the competition. Our skilled team ensures custom solutions tailored to your needs, delivering top-notch performance, responsiveness, and security. Embrace digital transformation and stay ahead in the market. Contact us now to turn your vision into reality!",
        button: "HIRE NOW",
        loti: AngularData,
      },
    ],
  },

  {
    id: 4,
    name: "Next",
    HeroBanner: [
      {
        name: "Next",
        page: "Next",
        title1: "Next js",
        title2: "Developer",
        Link: "/Next",
        para: "Use our superior services to grow your company. advancement of Next.js Create incredibly effective web applications that surpass rivals. Excellent performance, responsiveness, and security are guaranteed by our skilled team's custom solutions. To remain competitive, embrace digital transformation. Reach out to us to make your idea a reality!",
        button: "HIRE NOW",
        loti: NextjsData,
      },
    ],
  },

  {
    id: 5,
    name: "Ios",
    HeroBanner: [
      {
        name: "Ios",
        page: "Ios",
        title1: "iOS App",
        title2: "Development",
        Link: "/Ios",
        para: "We take your ideas and turn them into engaging iOS applications that a large number of iPhone users can use. Senwell Group Private Limited is an industry leader in creating distinctive, eye-catching applications with creative layouts for a variety of markets. With our experience, your apps will achieve business objectives and cultivate a devoted user base.",
        button: "HIRE NOW",
        loti: IosAnimation,
      },
    ],
  },

  {
    id: 6,
    name: "Android",
    HeroBanner: [
      {
        name: "Android",
        page: "Android",
        title1: "Android App",
        title2: "Development",
        Link: "/Android",
        para: "Our expertise lies in creating feature-rich, high-performing custom Android applications. We are experts at building mission-critical Android apps for our clients that improve user engagement and work flawlessly across a range of devices.",
        button: "HIRE NOW",
        loti: MobileData,
      },
    ],
  },

  {
    id: 7,
    name: "Flutter",
    HeroBanner: [
      {
        name: "Flutter",
        page: "Flutter",
        title1: "Flutter App",
        title2: "Development",
        Link: "/Flutter",
        para: "Making use of our expertise, we are experts in creating feature-rich, efficient custom Flutter apps. We also build mission-critical Flutter apps, which guarantee improved user experience and smooth operation on a variety of devices. Our dedication to innovation drives us to provide state-of-the-art solutions that enhance your online presence and go above and beyond for our clients.",
        button: "HIRE NOW",
        loti: FlutterAnimation,
      },
    ],
  },

  {
    id: 7,
    name: "Back-end",
    HeroBanner: [
      {
        name: "Back End",
        page: "Back End",
        title1: "Back End",
        title2: "Developer",
        Link: "/back-end",
        para: "Unleashing the Power of Backend Development: Building Dynamic and Robust Web Solutions.",
        button: "HIRE NOW",
        loti: BackendData,
      },
    ],
  },

  {
    id: 4,
    name: "Node.js",
    HeroBanner: [
      {
        name: "Node js",
        page: "Node js",
        title1: "Node js",
        title2: "Developer",
        Link: "/nodejs",
        para: "Node.js Development: Empowering Web Applications with Unparalleled Scalability and Speed. To take your web apps to new heights, take advantage of our skilled Node.js development team's cutting-edge capabilities.",
        button: "HIRE NOW",
        loti: NodeData,
      },
    ],
  },

  {
    id: 5,
    name: ".Net",
    HeroBanner: [
      {
        name: ".Net",
        page: "dot-net",
        title1: "DotNet",
        title2: "Developer",
        Link: "/dot-net",
        para: "Our top-notch DotNet development services will help you grow your company! Building exceptional web apps that make you stand out is our specialty. We ensure optimal performance, responsiveness, and security by customizing solutions to your needs by our skilled team. For a competitive advantage, embrace digital transformation.",
        button: "HIRE NOW",
        loti: DontNetData,
      },
    ],
  },

  {
    id: 6,
    name: "Java",
    HeroBanner: [
      {
        name: "Java",
        page: "Java",
        title1: "Java",
        title2: "Developer",
        Link: "/java",
        para: "For client-side and server-side projects, we develop feature-rich Java web applications. We guarantee to provide high-quality, long-lasting Java Software solutions because of our extensive exposure to all the cutting-edge new technologies.",
        button: "HIRE NOW",
        loti: javaAnimation,
      },
    ],
  },

  {
    id: 7,
    name: "Nest",
    HeroBanner: [
      {
        name: "Nest",
        page: "Nest",
        title1: "Nest js",
        title2: "Developer",
        Link: "/Nest",
        para: "We specialize in crafting feature-rich Nest.js web applications, ensuring the delivery of high-quality and enduring software solutions. Our proficiency in leveraging cutting-edge technologies guarantees the development of robust Nest.js applications.",
        button: "HIRE NOW",
        loti: NestAnimation,
      },
    ],
  },

  {
    id: 8,
    name: "Mobile",
    HeroBanner: [
      {
        name: "Mobile App",
        page: "Mobile App",
        title1: "Mobile App",
        title2: "Developer",
        Link: "/mobile",
        para: "Elevate your business with our exceptional Mobile App development services! We craft high-performance web apps that set you apart from the competition. Our skilled team ensures custom solutions tailored to your needs, delivering top-notch performance, responsiveness, and security. Embrace digital transformation and stay ahead in the market. Contact us now to turn your vision into reality!",
        button: "HIRE NOW",
        loti: MobileData,
      },
    ],
  },

  {
    id: 6,
    name: "React Native",
    HeroBanner: [
      {
        name: "React Native",
        page: "React Native",
        title1: "React Native",
        title2: "Developer",
        Link: "/react-native",
        para: "Unlock a plethora of advantages by realizing the full potential of Senwell Group Private Limited' React Native expertise! Our skilled team excels at providing solutions for app development that are unmatched, guaranteeing quality throughout the entire process.",
        button: "HIRE NOW",
        loti: NativeData,
      },
    ],
  },
];
export default TechnologiesBannerApi;
